import { LngLat } from 'react-map-gl';

import { MapMarker } from './logic/MapTypes';
import MapStyle from './MapStyle';

export default class MapImage {
    private readonly token: string;
    private static baseMapUrl =
        'https://api.mapbox.com/styles/v1/mapbox/{style_id}/static/{marker}/{lon},{lat},{zoom},0/{width}x{height}@2x?access_token={access_token}';

    private static markerUrl = 'pin-l-{char}+{color}({lon},{lat})';

    constructor(token: string) {
        this.token = token;
    }

    generate(mapStyle: MapStyle, coordinates: LngLat, zoomLevel: number, width: number, height: number, marker: MapMarker): string {
        return MapImage.baseMapUrl
            .replace('{style_id}', mapStyle)
            .replace('{marker}', this.generateMarker(marker))
            .replace('{lon}', coordinates.lng.toString())
            .replace('{lat}', coordinates.lat.toString())
            .replace('{zoom}', zoomLevel.toString())
            .replace('{width}', width.toString())
            .replace('{height}', height.toString())
            .replace('{access_token}', this.token);
    }

    private generateMarker(marker: MapMarker): string {
        return MapImage.markerUrl
            .replace('{char}', 'building')
            .replace('{color}', marker.color ?? '009933')
            .replace('{lat}', marker.position.latitude.toString())
            .replace('{lon}', marker.position.longitude.toString());
    }
}
