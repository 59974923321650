import '@inovua/reactdatagrid-community/index.css';

import { Table, TableContainer } from '@mui/material';
import { EventData } from 'models/events/ProcessedEvent';

import EventHistoryTableBody from './EventHistoryTableBody';
import EventHistoryTableHead from './EventHistoryTableHead';

export type EventHistoryTableProps = {
    data: EventData[];
};

export default function EventHistoryTable(props: EventHistoryTableProps) {
    const { data } = props;

    return (
        <TableContainer sx={{ px: 2 }}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                {data && (
                    <>
                        <EventHistoryTableHead />

                        <EventHistoryTableBody data={data} />
                    </>
                )}
            </Table>
        </TableContainer>
    );
}
