import { MapMarker } from '_global/Components/base/map/logic/MapTypes';
import { areaZoom } from '_global/Utils/navigationMethods';
import { Alert, AlertTitle, Box, Stack, Typography } from '@mui/material';
import * as turf from '@turf/turf';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MarkerDragEvent } from 'react-map-gl';
import GeoProcessor from 'views/Settings/GeoProcessor';

import MapComponent from '../../../_global/Components/base/map/MapComponent';
import { AssetInfoDataModel } from './AssetModel';

type MapPolygonInteractorProps = {
    assetInfoData: AssetInfoDataModel;
    setDraggedMarker: (value: MapMarker) => void;
};

export default function MapPolygonInteractor(props: MapPolygonInteractorProps) {
    const { assetInfoData, setDraggedMarker } = props;

    const centerLatitude = useMemo(() => assetInfoData.opAreaSelected.centerPoint.position.latitude, [assetInfoData]);
    const centerLongitude = useMemo(() => assetInfoData.opAreaSelected.centerPoint.position.longitude, [assetInfoData]);
    const initialLatitude = useMemo(() => assetInfoData.opAreaSelected.initialPoint.position.latitude, [assetInfoData]);
    const initialLongitude = useMemo(() => assetInfoData.opAreaSelected.initialPoint.position.longitude, [assetInfoData]);
    const name = useMemo(() => assetInfoData.opAreaSelected.centerPoint.name, [assetInfoData]);

    const opAreaFeature = useMemo(() => {
        if (
            assetInfoData.opAreaSelected.operationalArea &&
            assetInfoData.opAreaSelected.operationalArea?.gpsPoints &&
            (assetInfoData.opAreaSelected.operationalArea.gpsPoints?.length ?? 0) > 0
        ) {
            return GeoProcessor.pointsToPolygon(assetInfoData.opAreaSelected.operationalArea.gpsPoints, {
                type: 'opArea',
                identifier: assetInfoData.opAreaSelected.operationalArea.id
            });
        } else {
            return GeoProcessor.northPoleLakePolygon({ type: 'empty' });
        }
    }, [assetInfoData.opAreaSelected.operationalArea]);

    const [error, setError] = useState<boolean>(false);

    const [mapMarker, setMapMarker] = useState<MapMarker>({
        position: {
            latitude: initialLatitude,
            longitude: initialLongitude
        },
        name: name,
        onDragEnd: useCallback((e: MarkerDragEvent) => {
            const points = turf.points([[e.lngLat.lng, e.lngLat.lat]]);

            const searchWithin = opAreaFeature;

            const ptsWithin = turf.pointsWithinPolygon(points, searchWithin);

            let latitude = centerLatitude;
            let longitude = centerLongitude;

            if (ptsWithin.features.length !== 0) {
                latitude = e.lngLat.lat;
                longitude = e.lngLat.lng;
                setError(false);
            } else {
                setError(true);
            }

            setMapMarker((_prevState) => ({
                ..._prevState,
                position: { latitude: latitude, longitude: longitude, name: name }
            }));
        }, [])
    });

    useEffect(() => {
        setDraggedMarker(mapMarker);
    }, [mapMarker]);

    return (
        <Stack direction="column">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    width: '100%',
                    height: '75vh',
                    position: 'relative'
                }}
            >
                <Alert severity="info" sx={{ marginY: '8px' }}>
                    <AlertTitle>
                        <FormattedMessage id={'info'} />
                    </AlertTitle>
                    <FormattedMessage id={'map_asset_edit'} />
                </Alert>
                <MapComponent
                    initialPosition={{
                        latitude: centerLatitude,
                        longitude: centerLongitude,
                        name: name
                    }}
                    initialZoom={areaZoom(opAreaFeature)}
                    data={opAreaFeature}
                    markers={[mapMarker]}
                    dragPan={true}
                    maxZoom={20}
                />
                {error && (
                    <Typography sx={{ mt: 1, mb: -2 }} variant="subtitle1" color="error">
                        <FormattedMessage id={'polygon_asset_validation_error'} />
                    </Typography>
                )}
            </Box>
        </Stack>
    );
}
