import '@inovua/reactdatagrid-community/index.css';

import { getData } from '_global/Services/api/networkRequester';
import { RootState } from '_global/Services/store';
import { Grid, Table, TableBody, TableContainer } from '@mui/material';
import { AlarmSeverity, AlarmStatus, AlarmType, DeviceStatus } from 'models/events/ProcessedEvent';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EventProps } from '../EventDetails';
import EventEntityTitle from './EventEntityTitle';
import EventHistoryTableRow from './EventInfoTableRow';
import EventSourceTitle from './EventSourceTitle';
import MeasurementsTableRow from './MeasurementsTableRow';
import MeasurementsTitle from './MeasurementsTitle';

interface Measures {
    classification: string | null;
    distance: string | null;
    impedance: string | null;
    saturationLevel: string | null;
    sampleRate: string | null;
    hvRatioNegativeBefore: string | null;
    hvRatioNegativeAfter: string | null;
    hvRatioHomopolarBefore: string | null;
    hvRatioHomopolarAfter: string | null;
}

export default function EventHistoryTable(props: EventProps) {
    const { alarm } = props;
    const na = 'na';
    const features = useSelector((state: RootState) => state.features);
    const [measures, setMeasures] = useState<Measures>({
        classification: null,
        distance: null,
        impedance: null,
        saturationLevel: null,
        sampleRate: null,
        hvRatioNegativeBefore: null,
        hvRatioNegativeAfter: null,
        hvRatioHomopolarBefore: null,
        hvRatioHomopolarAfter: null
    });

    useEffect(() => {
        const fetchData = async () => {
            const data = await getData<Measures>(`${features.configuration?.BaseUrl ?? ''}/datastorage/api/Event/LastData/${alarm.id}`);
            if (data) {
                setMeasures(data);
            }
        };

        fetchData().catch(console.error);
    }, []);

    const getDeviceStatus = () => {
        if (alarm.type === AlarmType.FEEDER_LINE_FUSE_BLOWN) {
            const deviceStatusResolved = alarm?.eventData?.filter((event) => event.deviceStatus === DeviceStatus.UNRESOLVED)[0];

            if (deviceStatusResolved?.imax || deviceStatusResolved?.imin || deviceStatusResolved?.iavg || deviceStatusResolved?.delta_T) {
                return {
                    imax: deviceStatusResolved?.imax,
                    imin: deviceStatusResolved?.imin,
                    iavg: deviceStatusResolved?.iavg,
                    delta_T: deviceStatusResolved?.delta_T
                };
            }
        }
    };

    return (
        <Grid container direction="row" justifyContent="space-around" sx={{ px: 3 }}>
            <Grid item>
                <EventSourceTitle source={alarm.source as number} />

                <TableContainer sx={{ px: 1, mt: 2 }}>
                    <Table>
                        <TableBody>
                            <EventHistoryTableRow
                                alarm={alarm}
                                column={'type'}
                                value={
                                    alarm.type === AlarmType.NEW_HV_LINE_DOWN
                                        ? Object.entries(AlarmType).find(([, val]) => val === AlarmType.HV_LINE_DOWN)?.[0] ?? na
                                        : Object.entries(AlarmType).find(([, val]) => val === alarm.type)?.[0] ?? na
                                }
                            />
                            <EventHistoryTableRow alarm={alarm} column={'name'} value={alarm.name} />
                            <EventHistoryTableRow
                                alarm={alarm}
                                column={'severity'}
                                value={Object.entries(AlarmSeverity).find(([, val]) => val === alarm.severity)?.[0] ?? na}
                            />
                            <EventHistoryTableRow
                                alarm={alarm}
                                column={'status'}
                                value={Object.entries(AlarmStatus).find(([, val]) => val === alarm.status)?.[0] ?? na}
                            />
                            <EventHistoryTableRow
                                alarm={alarm}
                                column={'startDate'}
                                value={alarm.startDate ? new Date(alarm.startDate).toLocaleString() : na}
                            />
                            <EventHistoryTableRow
                                alarm={alarm}
                                column={'endDate'}
                                value={alarm.endDate ? new Date(alarm.endDate).toLocaleString() : na}
                            />
                            <EventHistoryTableRow
                                alarm={alarm}
                                column={'lastModifiedDate'}
                                value={alarm.lastModifiedDate ? new Date(alarm.lastModifiedDate).toLocaleString() : na}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item>
                <EventEntityTitle entityType={alarm.sourceEntityType as number} />

                <TableContainer sx={{ px: 1, mt: 2 }}>
                    <Table>
                        <TableBody>
                            <EventHistoryTableRow
                                alarm={alarm}
                                column={'asset'}
                                value={alarm.registrationNumber ? alarm.registrationNumber : na}
                            />
                            <EventHistoryTableRow alarm={alarm} column={'device'} value={alarm.deviceSerial ? alarm.deviceSerial : na} />
                            {alarm.feederName && <EventHistoryTableRow alarm={alarm} column={'feeder'} value={alarm.feederName} />}
                            <EventHistoryTableRow alarm={alarm} column={'line'} value={alarm.line ? alarm.line : na} />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {(Object.values(measures).some((item) => item !== null) || getDeviceStatus()) && (
                <Grid item>
                    <MeasurementsTitle />

                    <TableContainer sx={{ px: 1, mt: 2 }}>
                        <Table>
                            <TableBody>
                                <MeasurementsTableRow column={'imax'} value={getDeviceStatus()?.imax} />
                                <MeasurementsTableRow column={'iavg'} value={getDeviceStatus()?.iavg} />
                                <MeasurementsTableRow column={'imin'} value={getDeviceStatus()?.imin} />
                                <MeasurementsTableRow column={'delta_T'} value={getDeviceStatus()?.delta_T} />
                                <MeasurementsTableRow column={'classification'} value={measures?.classification} />
                                <MeasurementsTableRow column={'distance'} value={measures?.distance} />
                                <MeasurementsTableRow column={'impedance'} value={measures?.impedance} />
                                <MeasurementsTableRow column={'saturation_level'} value={measures?.saturationLevel} />
                                <MeasurementsTableRow column={'sample_rate'} value={measures?.sampleRate} />
                                <MeasurementsTableRow column={'hv_ratio_negative_before'} value={measures?.hvRatioNegativeBefore} />
                                <MeasurementsTableRow column={'hv_ratio_negative_after'} value={measures?.hvRatioNegativeAfter} />
                                <MeasurementsTableRow column={'hv_ratio_homopolar_before'} value={measures?.hvRatioHomopolarBefore} />
                                <MeasurementsTableRow column={'hv_ratio_homopolar_after'} value={measures?.hvRatioHomopolarAfter} />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Grid>
    );
}
