const cache = (function () {
    const _internalCache = {};

    return {
        get: function (key, defaultValue) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
            return _internalCache[key] || defaultValue;
        },
        set: function (key, value) {
            if (typeof key === 'object') {
                for (const k in key) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                    _internalCache[k] = key[k];
                }

                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
            key && (_internalCache[key] = value);
        }
    };
})();

export default cache;
