import { OperationalArea } from 'models/layout/OperationalArea';
import { Dispatch, SetStateAction } from 'react';
import { IValidations } from 'views/Users/utils/UsersModels';

export interface IOpArea {
    selectedOpArea?: OperationalArea;
}

export interface IOpAreasPageInternal {
    setStateToast: Dispatch<
        SetStateAction<{
            openToast: boolean;
            toastMessage: string;
        }>
    >;
}

export const defaultOpAreaValidation = [
    { name: 'name', error: false, message: '' },
    { name: 'code', error: false, message: '' },
    { name: 'regionId', error: false, message: '' },
    { name: 'gpsPoints', error: false, message: '' }
] as IValidations[];
