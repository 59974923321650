import { userLocale } from '_global/Utils/GetCurrentLocale';
import { hasLastDataSet } from '_global/Utils/hooks/jotai';
import { getDefaultLanguage, loadData } from '_global/Utils/language';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { AssetResponse } from 'models/asset/AssetResponse';
import { useCallback, useEffect, useState } from 'react';
import PreviewLocation from 'views/Assets/preview/overview/PreviewLocation';
import KPIGrid from 'views/Dashboard/stats/KPIGrid';
import { KPIRequestError } from 'views/Dashboard/stats/StatsModels';

import EventsPreview from './events/EventsPreview';
import InstalationIssues from './InstalationIssues';
import LastDataPreview from './lastdata/LastDataPreview';

export default function AssetOverview({ asset }: { asset: AssetResponse }) {
    const [locale, setLocale] = useState<string>(getDefaultLanguage().lang_region);
    const requestErrors: KPIRequestError = {
        language: 'Unavailable language'
    };

    useEffect(() => {
        if (userLocale) {
            void loadData(setLocale);
        }
    }, [userLocale]);

    const renderAlarmsPreview = useCallback(() => {
        if (asset.id) return <EventsPreview id={asset.id} />;
        else return <></>;
    }, []);

    const renderLastDataPreview = useCallback(() => {
        if (asset.id) return <LastDataPreview id={asset.id} />;
        else return <></>;
    }, []);

    const renderPreviewLocation = useCallback(() => {
        if (asset.latitude && asset.longitude)
            return (
                <PreviewLocation
                    latitude={asset.latitude}
                    longitude={asset.longitude}
                    opAreaName={asset.operationalArea ?? ''}
                    regionName={asset.region ?? ''}
                />
            );
        else return <></>;
    }, [asset]);

    const renderKPIs = useCallback(() => {
        if (asset.id) {
            return <KPIGrid trigger={0} {...{ locale, assetID: asset.id, title: 'kpi', requestErrors }} />;
        } else {
            return <></>;
        }
    }, [asset]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {renderAlarmsPreview()}
                </Grid>
                <Grid item xs={12}>
                    {renderLastDataPreview()}
                </Grid>
                <Grid item xs={12}>
                    <InstalationIssues {...{ asset }} />
                </Grid>
                <Grid item xs={6}>
                    {renderPreviewLocation()}
                </Grid>
                <Grid item xs={6}>
                    {renderKPIs()}
                </Grid>
            </Grid>
        </Box>
    );
}
