import { COLORS } from '_global/Utils/Colors';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AssetInfoDataModel } from 'views/Assets/utils/AssetModel';
import MapPolygonInteractor from 'views/Assets/utils/MapPolygonInteractor';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    handleChangeNumber?: (newValue: number, id: string) => void;
    assetInfoData: AssetInfoDataModel;
};

const ModalMap = (props: ModalProps) => {
    const { isOpen, onClose, handleChangeNumber, assetInfoData } = props;
    const [draggedMarker, setDraggedMarker] = useState(assetInfoData.opAreaSelected.initialPoint);
    const handleSavePoints = useCallback(() => {
        handleChangeNumber && handleChangeNumber(draggedMarker.position.latitude, 'latitude');
        handleChangeNumber && handleChangeNumber(draggedMarker.position.longitude, 'longitude');

        onClose();
    }, [assetInfoData.opAreaSelected, draggedMarker]);

    return (
        <Dialog
            fullWidth
            scroll={'body'}
            maxWidth="md"
            open={isOpen}
            PaperProps={{
                style: {
                    boxShadow: 'none',
                    backgroundColor: 'transparent'
                }
            }}
        >
            <Card>
                <DialogTitle
                    sx={{
                        m: 0,
                        p: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        bgcolor: '#FAFAFA'
                    }}
                >
                    <FormattedMessage id="choose_substation_location" />
                </DialogTitle>
                <DialogContent>
                    <MapPolygonInteractor assetInfoData={assetInfoData} setDraggedMarker={setDraggedMarker} />
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', px: 3, pb: 3 }}>
                    <Button
                        size="small"
                        onClick={onClose}
                        variant={'outlined'}
                        sx={{ color: COLORS.ENEIDA_ALTERNATIVE_BLUE }}
                        startIcon={<CloseIcon sx={{ width: '10px' }} />}
                    >
                        <FormattedMessage id={'cancel'} defaultMessage="cancel" />
                    </Button>

                    <Button
                        size="small"
                        onClick={handleSavePoints}
                        variant={'contained'}
                        sx={{ backgroundColor: COLORS.ENEIDA_ALTERNATIVE_BLUE, borderColor: COLORS.ENEIDA_ALTERNATIVE_BLUE }}
                    >
                        <FormattedMessage id={'save'} defaultMessage="save" />
                    </Button>
                </DialogActions>
            </Card>
        </Dialog>
    );
};

export default ModalMap;
