import { gql } from '@apollo/client';

import { CableTypes_cableTypes_nodes } from './Types/CableTypes';

export type CableTypesInstance = CableTypes_cableTypes_nodes;

export const CABLE_TYPES = gql`
    query CableTypes($filter: CableTypeFilterInput, $first: Int, $order: [CableTypeSortInput!]) {
        cableTypes(where: $filter, first: $first, order: $order) {
            nodes {
                name
                id
                createdDate
                cores
                color
                coreSection
                createdBy
                fuseWay
                lastModifiedBy
                lastModifiedDate
            }
            totalCount
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;
