import 'dayjs/locale/pt';
import 'dayjs/locale/de';
import 'dayjs/locale/en-nz';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-gb';

import MainCard from '_global/Components/base/cards/MainCard';
import { availableLanguages, userLocale } from '_global/Utils/GetCurrentLocale';
import { modeAtomAssets, validationSetAssets } from '_global/Utils/hooks/jotai';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useAtom } from 'jotai';
import Mode from 'models/enums/ModeEnum';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { AssetProps } from '../utils/AssetModel';

export const optionsPhaseRotation = [
    {
        name: 'anticlockwise',
        value: 0
    },
    {
        name: 'clockwise',
        value: 1
    }
];

export const optionsPhaseType = [
    {
        name: 'monophasic',
        value: 1
    },
    {
        name: 'biphasic',
        value: 2
    },
    {
        name: 'triphasic',
        value: 3
    }
];

const TransformerInfo = (props: AssetProps) => {
    const { assetDetails, handleOnChangeTransfomerInfo, handleChangeTransfomerInfo } = props;
    const [mode] = useAtom(modeAtomAssets);
    const [hasError] = useAtom(validationSetAssets);
    const [locale, setLocale] = useState('en-gb');

    useEffect(() => {
        function loadData() {
            const langExist = availableLanguages.indexOf(userLocale.toLowerCase());
            if (langExist === -1) {
                const split = userLocale.split('-');
                const exist = availableLanguages.indexOf(split[0].toLowerCase());
                if (exist !== -1) {
                    setLocale(availableLanguages[exist]);
                }
            } else {
                setLocale(availableLanguages[langExist]);
            }
        }
        if (userLocale) {
            void loadData();
        }
    }, [userLocale]);

    return (
        <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDayjs}>
            <MainCard className="main-card" content={false} title={<FormattedMessage id={'transformer_info'} />}>
                <Grid container sx={{ px: 3, pb: 4, pt: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                disabled={mode !== Mode.EDIT && mode !== Mode.ADD}
                                error={!!hasError[8].hasError}
                                fullWidth
                                label={<FormattedMessage id={'unique_identifier'} />}
                                onChange={(event) => {
                                    handleOnChangeTransfomerInfo && handleOnChangeTransfomerInfo(event, 'name');
                                }}
                                required={true}
                                value={assetDetails.transformer.name}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                disabled={mode !== Mode.EDIT && mode !== Mode.ADD}
                                fullWidth
                                label={<FormattedMessage id={'brand'} />}
                                onChange={(event) => {
                                    handleOnChangeTransfomerInfo && handleOnChangeTransfomerInfo(event, 'brand');
                                }}
                                required={false}
                                value={assetDetails.transformer.brand}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                autoComplete="off"
                                disabled={mode !== Mode.EDIT && mode !== Mode.ADD}
                                fullWidth
                                label={<FormattedMessage id={'subs_model'} />}
                                onChange={(event) => {
                                    handleOnChangeTransfomerInfo && handleOnChangeTransfomerInfo(event, 'model');
                                }}
                                required={false}
                                value={assetDetails?.transformer.model}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                autoComplete="off"
                                disabled={mode !== Mode.EDIT && mode !== Mode.ADD}
                                error={!!hasError[7].hasError}
                                fullWidth
                                label={<FormattedMessage id={'power_rating'} />}
                                onChange={(event) => {
                                    if (
                                        event.target.value === '' ||
                                        (parseInt(event.target.value) <= 100000 && parseInt(event.target.value) > 0)
                                    ) {
                                        handleOnChangeTransfomerInfo && handleOnChangeTransfomerInfo(event, 'power');
                                    }
                                }}
                                required={true}
                                type={'number'}
                                value={assetDetails?.transformer.power}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item md={6} xs={12}>
                            <DesktopDatePicker
                                disabled={mode !== Mode.EDIT && mode !== Mode.ADD}
                                label={<FormattedMessage id="manufacture_date" />}
                                onChange={(newValue: Dayjs | null) =>
                                    handleChangeTransfomerInfo &&
                                    handleChangeTransfomerInfo(newValue ? newValue.toString() : '', 'manufactureDate')
                                }
                                renderInput={(params) => <TextField fullWidth id={'manufactureDate'} {...params} />}
                                value={
                                    assetDetails.transformer.manufactureDate && assetDetails.transformer.manufactureDate !== ''
                                        ? dayjs(assetDetails.transformer.manufactureDate)
                                        : null
                                }
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DesktopDatePicker
                                disabled={mode !== Mode.EDIT && mode !== Mode.ADD}
                                label={<FormattedMessage id="service_entry_date" />}
                                onChange={(newValue: Dayjs | null) =>
                                    handleChangeTransfomerInfo &&
                                    handleChangeTransfomerInfo(newValue ? newValue.toString() : '', 'serviceEntryDate')
                                }
                                renderInput={(params) => <TextField fullWidth id={'serviceEntryDate'} {...params} />}
                                value={
                                    assetDetails.transformer.serviceEntryDate && assetDetails.transformer.serviceEntryDate !== ''
                                        ? dayjs(assetDetails.transformer.serviceEntryDate)
                                        : null
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item md={6} xs={12}>
                            <FormControl required>
                                <FormLabel>
                                    <FormattedMessage id={'phase_rotation'} />
                                </FormLabel>

                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    onChange={(event) => {
                                        handleChangeTransfomerInfo && handleChangeTransfomerInfo(event.target.value, 'phaseRotation');
                                    }}
                                    row={true}
                                    value={assetDetails?.transformer.phaseRotation}
                                >
                                    {optionsPhaseRotation.map((option) => (
                                        <FormControlLabel
                                            control={<Radio id={option.name} />}
                                            disabled={mode !== Mode.EDIT && mode !== Mode.ADD}
                                            id={option.value.toString()}
                                            key={option.value}
                                            label={<FormattedMessage defaultMessage={option.name} id={option.name} />}
                                            value={option.value}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl required>
                                <FormLabel>
                                    <FormattedMessage id={'phase_type'} />
                                </FormLabel>

                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    onChange={(event) => {
                                        handleChangeTransfomerInfo && handleChangeTransfomerInfo(event.target.value, 'phaseType');
                                    }}
                                    row={true}
                                    value={assetDetails?.transformer.phaseType}
                                >
                                    {optionsPhaseType.map((option) => (
                                        <FormControlLabel
                                            control={<Radio id={option.name} />}
                                            disabled={mode !== Mode.ADD}
                                            id={option.value.toString()}
                                            key={option.value}
                                            label={<FormattedMessage defaultMessage={option.name} id={option.name} />}
                                            value={option.value}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
        </LocalizationProvider>
    );
};

export default TransformerInfo;
