import { ReactNode } from 'react';
import { Link, To } from 'react-router-dom';

export function ConditionalLink({ children, condition, to }: { children: ReactNode; condition: number | null; to: To }) {
    return !!condition && to ? (
        <Link to={to} style={{ textDecoration: 'none', color: 'inherit' }}>
            {children}
        </Link>
    ) : (
        <>{children}</>
    );
}
