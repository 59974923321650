import './ActionsCell.scss';

import { deleteData } from '_global/Services/api/networkRequester';
import { setUpdateReportAtom } from '_global/Utils/hooks/jotai';
import { faArrowDown, faEllipsisV, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, IconButton, Menu, MenuItem, MenuProps, styled } from '@mui/material';
import { saveAs } from 'file-saver';
import { useAtom } from 'jotai';
import { useConfiguration } from 'logic/configuration/Hooks';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

export const StyledMenu = styled((props: MenuProps) => (
    <Menu
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        elevation={0}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            }
        }
    }
}));

interface ActionsCellProps {
    ready: boolean;
    id: string;
    errors: string;
}

export default function ActionsCell({ ready, id, errors }: ActionsCellProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const configuration = useConfiguration();
    const [, setUpdateReport] = useAtom(setUpdateReportAtom);

    const downloadReport = async () => {
        if (configuration && ready && !errors) {
            const reportDownloadUrl = '/api/report/';
            const endpoint = `${configuration.BaseUrl}${reportDownloadUrl}${id}/download`;

            const response = await fetch(endpoint, {
                method: 'GET',
                headers: { 'Content-Type': 'application/octet-stream' }
            });

            if (!response.ok) {
                console.error(response);
            }

            const blob = await response.blob();
            const fileNameWithDate = `report.zip`;
            saveAs(blob, fileNameWithDate);
        }
    };

    const deleteReport = () => {
        if (configuration) {
            const reportUrl = '/api/report/';
            const endpoint = `${configuration.BaseUrl}${reportUrl}${id}`;
            deleteData(endpoint)
                .then((data) => {})
                .catch((e) => {
                    console.error(e);
                });
        }
        setUpdateReport(true);
    };

    return (
        <Box sx={{ textAlign: 'left', display: 'flex', gap: '10px' }}>
            <IconButton
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                aria-label="more"
                id="long-button"
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={faEllipsisV} />
            </IconButton>
            <StyledMenu
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={anchorEl}
                id="demo-customized-menu"
                onClose={handleClose}
                open={open}
            >
                {ready && !errors && (
                    <MenuItem
                        disableRipple
                        onClick={(e) => {
                            downloadReport();
                            handleClose(e);
                        }}
                    >
                        <div className="actionsCellMenu">
                            <FontAwesomeIcon icon={faArrowDown} />
                            <FormattedMessage id="download" />
                        </div>
                    </MenuItem>
                )}
                <MenuItem
                    disableRipple
                    onClick={(e) => {
                        deleteReport();
                        handleClose(e);
                    }}
                >
                    <div className="actionsCellMenu">
                        <FontAwesomeIcon icon={faTrashCan} />
                        <FormattedMessage id="delete" />
                    </div>
                </MenuItem>
            </StyledMenu>
        </Box>
    );
}
