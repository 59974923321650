import Mode from 'models/enums/ModeEnum';
import { ReactNode } from 'react';

import { Group } from '../../logic/misc/MapExtensions';

interface handleTabsProps {
    tabs: Group<
        string,
        {
            component: ReactNode;
            url?: string | undefined;
        }
    >;
    setMode: (mode: Mode) => void;
    currentTab: string;
}

export function handleCurrenTab({ tabs, setMode, currentTab }: handleTabsProps) {
    const current = Array.from(tabs).find((el) => el[0] === currentTab);

    if (current) {
        if (current?.[1].url?.includes('edit')) {
            setMode(Mode.EDIT);
        } else if (current?.[1].url?.includes('add')) {
            setMode(Mode.ADD);
        } else if (current?.[0] === 'List') {
            setMode(Mode.LIST);
        } else {
            setMode(Mode.PREVIEW);
        }
    } else {
        setMode(Mode.LIST);
    }
}
