import { IReportInstance } from 'queries/Reports';

export interface IItemReportDetails {
    id: number;
    name: string;
}

export interface IAssetReportDetails {
    id: number;
    name: string;
    registryNumber: string;
    externalAssetId: number;
}

export interface IReportDetails extends IReportInstance {
    accessGroups: IItemReportDetails[];
    operationalAreas: IItemReportDetails[];
    companies: IItemReportDetails[];
    regions: IItemReportDetails[];
    assets: IAssetReportDetails[];
    users: IItemReportDetails[];
}

export interface IReportCreate {
    name: string;
    report_type: string;
    period_start: string;
    period_end: string;
    requested_by: string;
    equipments: number[];
    periodic: boolean;
    description: string;
}

export function reportToRow(report: IReportInstance): IReportInstance {
    return {
        __typename: 'ReportDTO',
        created: new Date(String(report?.created)),
        description: report?.description,
        equipments: report?.equipments,
        errors: report?.errors,
        name: report?.name,
        period_end: new Date(String(report?.period_end)),
        period_start: new Date(String(report?.period_start)),
        periodic: report?.periodic,
        ready: report?.ready,
        report_type: report?.report_type,
        requested_by: report?.requested_by,
        size: report?.size,
        title: report?.title,
        _id: report?._id
    };
}

export enum TabValue {
    INFO = 0,
    ASSETS = 1,
    PREVIEW = 2,
    DETAILS = 0
}

export enum ReportSections {
    ACCESSGROUPS = 'accessGroups',
    COMPANIES = 'companies',
    ASSETS = 'assets',
    REGIONS = 'regions',
    OPAREAS = 'opareas',
    USERS = 'users'
}
