import './DateCell.scss';

import { userLocale } from '_global/Utils/GetCurrentLocale';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface DateCellProps {
    date: string;
    ready: boolean;
    errors?: string;
}

export default function DateCell({ date, ready, errors }: DateCellProps) {
    const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

    const timeOptions: Intl.DateTimeFormatOptions = { second: 'numeric', minute: 'numeric', hour: 'numeric' };

    return (
        <Box sx={{ textAlign: 'left', display: 'flex', gap: '10px', alignItems: 'center' }}>
            <span className={`circle ${errors ? 'circleNotReady' : ready ? 'circleReady' : 'circle'}`}>
                <FontAwesomeIcon icon={faFileLines} className={'icon'} />
            </span>
            {date && (
                <div className="date">
                    <span>
                        <FormattedMessage id={new Date(date).toLocaleString(userLocale, dateOptions)} />
                    </span>
                    <span>
                        <FormattedMessage id={new Date(date).toLocaleString(userLocale, timeOptions)} />
                    </span>
                </div>
            )}
        </Box>
    );
}
