import { center, featureCollection } from '@turf/turf';
import { Feature, GeoJsonProperties, Polygon } from 'geojson';
import { InstallationPoint } from 'models/layout/InstallationPoint';
import { OperationalArea } from 'models/layout/OperationalArea';

class GeoProcessor {
    static pointsToPolygon(points: InstallationPoint[], properties?: GeoJsonProperties): Feature<Polygon> {
        const newPolygon: Feature<Polygon> = {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: []
            },
            properties: { ...properties }
        };

        newPolygon.geometry.coordinates.push(points.map((point) => [point.longitude, point.latitude]));
        return newPolygon;
    }

    static polygonToPoints(polygon: Feature<Polygon>): InstallationPoint[] {
        return polygon.geometry.coordinates.flat().map((element, index) => {
            return {
                latitude: element[1],
                longitude: element[0],
                designOrder: index + 1
            };
        });
    }

    static northPoleLakePolygon(properties?: GeoJsonProperties): Feature<Polygon> {
        return {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [22.634882926940918, 79.86624337569893],
                        [22.634882926940918, 79.86596399175251],
                        [22.63535499572754, 79.86620562155697],
                        [22.634882926940918, 79.86624337569893]
                    ]
                ]
            },
            properties: { ...properties }
        };
    }

    static centerPoint(opAreaSelected: OperationalArea, properties?: GeoJsonProperties) {
        const polygon: Feature<Polygon> = {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: []
            },
            properties: { ...properties }
        };
        polygon.geometry.coordinates.push(opAreaSelected?.gpsPoints.map((point) => [point.latitude, point.longitude]));

        if (polygon && polygon.geometry.coordinates.length > 0 && opAreaSelected?.gpsPoints) {
            return center(featureCollection([polygon]));
        }
    }
}

export default GeoProcessor;
