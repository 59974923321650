import { ProcessedCompany } from 'models/companys/ProcessedCompany';
import { enterprises_enterprises_nodes } from 'queries/Types/enterprises';
import { Dispatch, SetStateAction } from 'react';
import { IValidations } from 'views/Users/utils/UsersModels';

export interface ICompany {
    selectedCompany?: ProcessedCompany;
    enterprises: (enterprises_enterprises_nodes | null)[];
}
export interface ICompanyPageInternal {
    setStateToast: Dispatch<
        SetStateAction<{
            openToast: boolean;
            toastMessage: string;
        }>
    >;
}

export const defaultCompanyValidation = [
    { name: 'country', error: false, message: '' },
    { name: 'enterpriseId', error: false, message: '' },
    { name: 'name', error: false, message: '' }
] as IValidations[];
