import { TableCell, TableHead, TableRow } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export default function DevicesTableHead() {
    const position = 'left';
    return (
        <TableHead>
            <TableRow>
                <TableCell align={position} key={'type'}>
                    <FormattedMessage id={'type'} />
                </TableCell>
                <TableCell align={position} key={'serial_number'}>
                    <FormattedMessage id={'serial_number'} />
                </TableCell>
                <TableCell align={position} key={'last_communication'}>
                    <FormattedMessage id={'last_communication'} />
                </TableCell>
                <TableCell align={position} key={'configuration_profile'}>
                    <FormattedMessage id={'configuration_profile'} />
                </TableCell>
                <TableCell align={position} key={'comissioning_date'}>
                    <FormattedMessage id={'comissioning_date'} />
                </TableCell>
                <TableCell align={position} key={'actions'} />
            </TableRow>
        </TableHead>
    );
}
