import './GrafanaHolder.css';

import { Box, Card, Container, Fade, Skeleton, Stack, Typography } from '@mui/material';
import { useRemoteConfiguration } from 'logic/configuration/Hooks';
import { useEffect, useMemo, useState, useTransition } from 'react';

export type GrafanaHolderProps = {
    url: string;
    subtitle?: string;
    height?: number;
};

export default function GrafanaHolder(props: GrafanaHolderProps) {
    const { url, height, subtitle } = props;
    const internalHeight = height ?? 400;

    const [isLoaded, setIsLoaded] = useState(false);
    const [isPending, startTransition] = useTransition();
    const [config] = useRemoteConfiguration();
    const [endpoint, setEndpoint] = useState('');

    useEffect(() => {
        if (config !== undefined) {
            setEndpoint(`${url}&var-namespace=${config?.nameSpace ?? ''}&deepgridkey=${config?.grafanaToken ?? ''}`);
        } else {
            setEndpoint(url);
        }
    }, [config]);

    const grafanaMap = useMemo(() => {
        if (config && config?.grafanaToken && config?.grafanaToken !== '') {
            return (
                <Fade in={isLoaded}>
                    {
                        <iframe
                            src={endpoint}
                            frameBorder="0"
                            height={height}
                            onLoad={() =>
                                startTransition(() => {
                                    setIsLoaded(true);
                                })
                            }
                        />
                    }
                </Fade>
            );
        } else {
            return <></>;
        }
    }, [config, endpoint, isLoaded]);

    return (
        <Card
            className="holds-the-iframe"
            sx={{
                display: 'flex',
                width: '100%',
                height: internalHeight + (subtitle !== undefined ? 40 : 0),
                flexDirection: 'column',
                p: '8px'
            }}
        >
            <Stack direction="column" justifyContent="center" alignItems="center">
                <Container maxWidth="lg">
                    <Box
                        sx={{
                            display: 'grid',
                            alignItems: 'center',
                            height: internalHeight,
                            justifyItems: 'center'
                        }}
                    >
                        {grafanaMap}

                        {isPending && <Skeleton sx={{ flexGrow: 1 }} />}
                    </Box>
                </Container>

                {subtitle !== undefined && (
                    <Fade in={isLoaded}>
                        <Typography sx={{ my: '8px' }} variant="subtitle1">
                            {subtitle}
                        </Typography>
                    </Fade>
                )}
            </Stack>
        </Card>
    );
}
