import { TableCell, TableRow, Typography } from '@mui/material';
import { LinesLastData } from 'models/dataStorage/NewSimpleLastData';

export default function LastDataTableRow(props: { lastData: LinesLastData; applyFilter: boolean }) {
    const { lastData, applyFilter } = props;
    const position = 'center';
    const lineN = 'NEUTRAL';
    const filterProps = [
        'lineName',
        'iMax',
        'iMin',
        'iRms',
        'iPhaseAngle',
        'activePower',
        'reactivePower',
        'apparentPower',
        'powerFactor',
        'iThd'
    ];
    const filterPropsBusbar = [
        'lineName',
        'iMax',
        'iMin',
        'iRms',
        'iPhaseAngle',
        'vMax',
        'vMin',
        'vRms',
        'vPhaseAngle',
        'iFfrequency',
        'activePower',
        'reactivePower',
        'apparentPower',
        'powerFactor',
        'iThd',
        'vThd'
    ];

    function handleValue(value: any) {
        switch (value) {
            case -2:
                return <Typography>---</Typography>;
            case null:
                return <Typography>---</Typography>;
            case lineN:
                return <Typography>N</Typography>;

            default:
                return <Typography>{Number(value).toFixed(2)}</Typography>;
        }
    }

    return (
        <TableRow hover>
            {Object.entries(lastData)
                .filter(([column]) => (applyFilter ? filterProps.includes(column) : filterPropsBusbar.includes(column)))
                .sort((a, b) =>
                    applyFilter
                        ? filterProps.indexOf(a[0]) - filterProps.indexOf(b[0])
                        : filterPropsBusbar.indexOf(a[0]) - filterPropsBusbar.indexOf(b[0])
                )
                .map(([column, value]) => {
                    return (
                        <TableCell align={position} key={column}>
                            {column === 'lineName' ? (
                                <Typography sx={{ fontWeight: 'bold', color: 'black' }}>{value}</Typography>
                            ) : (
                                handleValue(value)
                            )}
                        </TableCell>
                    );
                })}
        </TableRow>
    );
}
