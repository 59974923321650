import '@inovua/reactdatagrid-community/index.css';
import './Grids.scss';

import { typeFlagRefetch } from '_global/Components/base/filter/FilterTypes';
import { getActiveFilters, getSubmitFilterWithOperators, refactorFiltersToBeQuery } from '_global/Components/base/filter/FilterUtils';
import FilterView from '_global/Components/base/filter/FilterView';
import AlertModal from '_global/Components/base/modals/AlertModal';
import { addGridsPaginations, Pagination, removeGridPagination } from '_global/Services/store/gridsPaginationSlice';
import { useAppDispatch, useAppSelector } from '_global/Services/store/hooks';
import gridsPaginationsUpdateGrid from '_global/Utils/gridsPaginations';
import { gridUpdate } from '_global/Utils/GridUtils';
import { filterCompaniesAtomReports } from '_global/Utils/hooks/jotai';
import { useQuery } from '@apollo/client';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import {
    TypeComputedProps,
    TypeDataSource,
    TypePaginationProps,
    TypeSingleSortInfo,
    TypeSortInfo
} from '@inovua/reactdatagrid-community/types';
import { TypeColumn } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { Box } from '@mui/system';
import { useAtom } from 'jotai';
import { companyToRow } from 'models/companys/ProcessedCompany';
import MessageType from 'models/enums/MessageTypes';
import { Company } from 'models/layout/Company';
import { columnsMap } from 'models/settings/RemoteConfiguration';
import { COMPANYS } from 'queries/Companys';
import { companys } from 'queries/Types/companys';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { defaultRowStyle } from '../../../_global/Components/base/grids/RowUtils';
import DataFilter, { GridHandleType } from '../../Events/grid/filter/DataFilter';
import { AssetsTabProps } from '../Tabs/AssetsTab';
import { renderColumnsCompanies } from './utils';

const gridStyle = {
    flexGrow: 1,
    border: 'none',
    width: '100%',
    height: '100%'
};

const filtersValue = [
    { name: 'name', type: 'string', i18n: 'name' },
    { name: 'description', type: 'string', i18n: 'description' },
    { name: 'country', type: 'string', i18n: 'country' },
    { name: 'website', type: 'string', i18n: 'website' }
];

export default function CompaniesGrid({ report, handleChangeAll }: AssetsTabProps) {
    const { data, loading, error, fetchMore } = useQuery<companys>(COMPANYS);
    const [gridRef, setGridRef] = useState<((gridApiRef: MutableRefObject<TypeComputedProps | null>) => void) | undefined>(undefined);
    const [enableFilter, setEnableFilter] = useState(false);
    const columns_companies: TypeColumn[] = renderColumnsCompanies(report, handleChangeAll);
    const [columns, setColumns] = useState<TypeColumn[]>(columns_companies.slice(1, columns_companies.length));
    const [numberActiveFilters, setNumberActiveFilters] = useState(0);
    const [filters, setFilters] = useAtom(filterCompaniesAtomReports);
    const [flagRefetch, setFlagRefetch] = useState<typeFlagRefetch>(null);
    const [showingError, setShowingError] = useState(false);

    const location = useLocation();
    const dispatch = useAppDispatch();
    const [gridSkip, setGridSkip] = useState<number | undefined>(undefined);
    const [gridSortInfo, setGridSortInfo] = useState<TypeSingleSortInfo | undefined>(undefined);
    const [gridColumnOrder, setGridColumnOrder] = useState<string[] | undefined>(undefined);
    const [gridLimit, setGridLimit] = useState<number | undefined>(undefined);
    const gridsPaginations = useAppSelector((state) => state.gridsPaginations.gridsPaginations);
    const firstRenderRef = useRef(true);

    useEffect(() => {
        gridsPaginationsUpdateGrid({
            gridName: 'CompaniesGrid',
            gridsPaginations,
            columns: columns,
            setGridSkip,
            setGridSortInfo,
            setGridColumnOrder,
            setGridLimit,
            location
        });
    }, [gridsPaginations]);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
        } else {
            dispatch(
                addGridsPaginations({
                    url: location.pathname,
                    grid: { skip: gridSkip, sortInfo: gridSortInfo, columnOrder: gridColumnOrder, limit: gridLimit },
                    key: `CompaniesGrid`
                })
            );
        }
    }, [gridSkip, gridSortInfo, gridColumnOrder, gridLimit]);

    const refresh = () => {
        dispatch(
            removeGridPagination({
                key: `CompaniesGrid`,
                url: location?.pathname
            })
        );
        gridUpdate();
    };

    const updateVisibility = useCallback((columnsM: columnsMap) => {
        setColumns((columns) => {
            const newColumns: TypeColumn[] = [];

            columnsM.forEach((value, key) => {
                const index = columns.findIndex((nc) => nc.name === key.name);
                newColumns.push({ ...columns[index], visible: value });
            });

            return newColumns;
        });
    }, []);

    const nodes = useMemo(() => data?.companys?.nodes, [data?.companys?.nodes]);

    const pageInfo = useMemo(() => data?.companys?.pageInfo, [data?.companys?.pageInfo]);

    const loadData: TypeDataSource = useCallback(
        async (props: TypePaginationProps) => {
            const response = await fetchMore({
                variables: {
                    numberOfElements: props.skip + props.limit,
                    filter: flagRefetch ? flagRefetch : null
                }
            });

            const companies = response?.data?.companys?.nodes?.map((company) => company && companyToRow(company)) ?? [];

            return {
                data: companies.slice(props.skip, companies.length),
                count: response.data.companys?.totalCount ?? 0
            };
        },
        [pageInfo, pageInfo?.endCursor, nodes, flagRefetch]
    );

    const rowClassName = (company: Company) => {
        if (report.companies.some((comp) => comp.id === company.id)) {
            return 'selected';
        }

        return undefined;
    };

    const handleDeleteAll = () => {
        if (numberActiveFilters <= 0) {
            return;
        }
        setFilters([{ name: '', operator: [], value: [] }]);
        setFlagRefetch(null);
        void refresh();
    };

    const handleSubmitFilter = () => {
        const { filterWithoutOperators, andFilter, orFilter } = refactorFiltersToBeQuery(filters, filtersValue);

        if (Object.keys(filterWithoutOperators).length > 0) {
            const filterToApply = getSubmitFilterWithOperators(filters, filterWithoutOperators, andFilter, orFilter);
            setFlagRefetch(filterToApply);
        } else {
            setFlagRefetch(null);
        }
    };

    useEffect(() => {
        setNumberActiveFilters(getActiveFilters(filters));
    }, [filters]);

    useEffect(() => {
        if (filters.length > 0 && filters[0].name !== '') {
            setEnableFilter(true);
            const { filterWithoutOperators, andFilter, orFilter } = refactorFiltersToBeQuery(filters, filtersValue);

            if (Object.keys(filterWithoutOperators).length > 0) {
                setFlagRefetch(getSubmitFilterWithOperators(filters, filterWithoutOperators, andFilter, orFilter));
            } else {
                setFlagRefetch(null);
            }
        } else {
            setEnableFilter(false);
        }
    }, []);

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <DataFilter
                columns={columns}
                onRefresh={refresh}
                onVisibilityChanged={updateVisibility}
                userHasOptions={false}
                {...{ enableFilter, setEnableFilter, numberActiveFilters }}
                gridRef={gridRef}
            />
            {enableFilter && <FilterView {...{ filters, setFilters, filtersValue, handleSubmitFilter, handleDeleteAll, setFlagRefetch }} />}

            <Box sx={{ mt: 2, height: 600 }}>
                <ReactDataGrid
                    columnOrder={gridColumnOrder}
                    columns={[columns_companies[0]].concat(columns)}
                    dataSource={loadData}
                    handle={setGridRef as unknown as GridHandleType}
                    idProperty="id"
                    limit={gridLimit ? gridLimit : 10}
                    loading={loading}
                    onColumnOrderChange={(columnOrder) => {
                        setGridColumnOrder(columnOrder);
                    }}
                    onLimitChange={(limit) => {
                        setGridLimit(limit);
                    }}
                    onSkipChange={(skip) => {
                        setGridSkip(skip);
                    }}
                    onSortInfoChange={(column: TypeSortInfo) => {
                        setGridSortInfo(column as TypeSingleSortInfo);
                    }}
                    pagination={true}
                    rowClassName={({ data }) => rowClassName(data as Company)}
                    rowHeight={50}
                    rowStyle={defaultRowStyle}
                    showCellBorders={'horizontal'}
                    showColumnMenuTool={false}
                    showZebraRows={false}
                    skip={gridSkip}
                    sortInfo={gridSortInfo}
                    style={gridStyle}
                />
            </Box>
            {error && (
                <AlertModal
                    isOpen={showingError}
                    message={JSON.stringify(error)}
                    onOk={() => setShowingError(false)}
                    type={MessageType.ERROR}
                />
            )}
        </Box>
    );
}
