import 'dayjs/locale/pt';
import 'dayjs/locale/de';
import 'dayjs/locale/en-nz';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-gb';

import { availableLanguages, userLocale } from '_global/Utils/GetCurrentLocale';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Box, Button, Grid, Stack, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { IconCirclePlus } from '@tabler/icons';
import { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FilterProps } from './FilterTypes';
import { andOrOptions, RenderValueComponent, typeOptions } from './FilterUtils';

export default function FilterView(props: FilterProps) {
    const { filters, setFilters, filtersValue, handleSubmitFilter, handleDeleteAll, setFlagRefetch } = props;
    const intl = useIntl();
    const [locale, setLocale] = useState('en-gb');

    useEffect(() => {
        function loadData() {
            const langExist = availableLanguages.indexOf(userLocale.toLowerCase());
            if (langExist === -1) {
                const split = userLocale.split('-');
                const exist = availableLanguages.indexOf(split[0].toLowerCase());
                if (exist !== -1) {
                    setLocale(availableLanguages[exist]);
                }
            } else {
                setLocale(availableLanguages[langExist]);
            }
        }
        if (userLocale) {
            void loadData();
        }
    }, [userLocale]);

    const handleAddFilter = () => {
        if (filters.filter((el) => el.value[0] !== '' && el.value.length > 0).length === filters.length) {
            setFilters((old) => [...old, { name: '', operator: [], value: [] }]);
        }
    };

    const handleRemove = (index: number) => {
        const newFilters = [...filters];
        if (filters.length <= 1) {
            newFilters.splice(index, 1, { name: '', operator: [], value: [] });
            setFlagRefetch(null);
        } else {
            newFilters.splice(index, 1);
        }
        setFilters(newFilters);
    };

    const handleOnChangeAndOrOption = (newValue: string, i: number) => {
        const newArray = [...filters];
        newArray.splice(i, 1, { ...newArray[i], andOr: newValue });
        setFilters(newArray);
    };

    const handleOnChangeField = (newValue: string, i: number) => {
        const newArray = [...filters];
        if (i !== 0) {
            if (newArray[i].andOr === undefined) {
                newArray.splice(i, 1, {
                    name: newValue,
                    operator: filters[i].operator,
                    value: filters[i].value
                });
            } else {
                newArray.splice(i, 1, {
                    name: newValue,
                    operator: filters[i].operator,
                    value: filters[i].value,
                    andOr: newArray[i].andOr
                });
            }
        } else {
            newArray.splice(i, 1, {
                name: newValue,
                operator: [],
                value: []
            });
        }
        setFilters(newArray);
    };

    const handleOnChangeOption = (newValue: string | string[], i: number) => {
        const newArray = [...filters];
        newArray.splice(i, 1, { ...newArray[i], operator: Array.isArray(newValue) ? newValue : [newValue] });
        setFilters(newArray);
    };

    const handleOnChangeValue = (newValue: string | number | boolean, i: number, between: number | undefined) => {
        const newArray = [...filters];
        if (between !== undefined) {
            const currentValue = newArray[i].value;
            currentValue.splice(between, 1, newValue);
            newArray.splice(i, 1, { ...newArray[i], value: currentValue });
        } else {
            newArray.splice(i, 1, { ...newArray[i], value: [newValue] });
        }
        setFilters(newArray);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
            <Grid container sx={{ width: '100%', py: 2, justifyContent: 'center', alignItems: 'center' }} spacing={2}>
                {filters.map((el, index: number) => (
                    <Fragment key={`${el.name}  ${index}`}>
                        {index === 0 ? (
                            <Grid item xs={2} textAlign="right">
                                <Box>Filter by:</Box>
                            </Grid>
                        ) : (
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Box sx={{ maxWidth: '96px' }}>
                                    <Autocomplete
                                        onChange={(event, newValue) => {
                                            handleOnChangeAndOrOption(newValue.value, index);
                                        }}
                                        value={andOrOptions.find((option) => option.value === el.andOr) ?? { name: 'And', value: 'and' }} //make sure we don't have an uncontrolled component
                                        isOptionEqualToValue={(option, val) => {
                                            if (val.name === '') {
                                                return true;
                                            }

                                            return option?.name.toString() === val?.name.toString();
                                        }}
                                        disableClearable
                                        options={andOrOptions}
                                        getOptionLabel={(option) => {
                                            return option.name;
                                        }}
                                        renderInput={(params) => <TextField {...params} label={<FormattedMessage id={'AND'} />} />}
                                    />
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={9}>
                            <Grid container columnSpacing={1} justifyContent="center">
                                <Grid item xs={4}>
                                    <Autocomplete
                                        onChange={(event, newValue) => {
                                            handleOnChangeField(newValue.name, index);
                                        }}
                                        value={filtersValue?.find((option) => option?.name === el.name) ?? { name: '', type: '', i18n: '' }}
                                        disableClearable
                                        options={filtersValue}
                                        isOptionEqualToValue={(option, val) => {
                                            if (val.name === '') {
                                                return true;
                                            }

                                            return option?.name.toString() === val?.name.toString();
                                        }}
                                        getOptionLabel={(option) => {
                                            return option?.i18n ? intl.formatMessage({ id: option?.i18n }) : '';
                                        }}
                                        renderInput={(params) => <TextField {...params} label={<FormattedMessage id={'Field'} />} />}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        onChange={(event, newValue) => {
                                            handleOnChangeOption(newValue.value, index);
                                        }}
                                        value={
                                            typeOptions?.find((option) => {
                                                if (el.operator.length > 1) {
                                                    return option?.value[0] === el?.operator[0] && option.value[1] === el.operator[1];
                                                }
                                                return option?.value === el?.operator[0];
                                            }) ?? { name: '', value: '', type: [] }
                                        }
                                        disabled={el.name === ''}
                                        disableClearable
                                        isOptionEqualToValue={(option, val) => {
                                            if (val.name === '') return true;
                                            return option?.name.toString() === val.name.toString();
                                        }}
                                        options={typeOptions?.filter((option) =>
                                            option?.type?.find(
                                                (optionType) => optionType === filtersValue?.find((filter) => filter.name === el.name)?.type
                                            )
                                        )}
                                        getOptionLabel={(option) => {
                                            return option?.name ? option?.name : '';
                                        }}
                                        renderInput={(params) => <TextField {...params} label={<FormattedMessage id={'Options'} />} />}
                                    />
                                </Grid>
                                <Fragment key={`${index + 2}`}>
                                    {el.operator.length > 1 ? (
                                        <>
                                            <Grid item xs={2}>
                                                {RenderValueComponent(
                                                    filtersValue?.find((filter) => filter.name === el.name)?.type ?? 'string',
                                                    filters[index].value?.[0],
                                                    index,
                                                    0,
                                                    handleOnChangeValue,
                                                    el.operator.length <= 0
                                                )}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {RenderValueComponent(
                                                    filtersValue?.find((filter) => filter.name === el.name)?.type ?? 'string',
                                                    filters?.[index]?.value?.[1],
                                                    index,
                                                    1,
                                                    handleOnChangeValue,
                                                    el.operator.length <= 0
                                                )}
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid item xs={4}>
                                            {RenderValueComponent(
                                                filtersValue?.find((filter) => filter.name === el.name)?.type ?? 'string',
                                                filters[index].value[0],
                                                index,
                                                undefined,
                                                handleOnChangeValue,
                                                el.operator.length <= 0,
                                                filtersValue,
                                                el
                                            )}
                                        </Grid>
                                    )}
                                </Fragment>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Stack direction="row" spacing={2}>
                                <button type="button" className="menu-button" onClick={() => handleRemove(index)}>
                                    <DeleteIcon />
                                </button>
                            </Stack>
                        </Grid>
                    </Fragment>
                ))}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', ml: 3, mr: 2 }}>
                <Button variant="text" color="secondary" onClick={handleAddFilter}>
                    <IconCirclePlus style={{ marginRight: '10px' }} /> Add Filter
                </Button>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button variant="outlined" color="secondary" onClick={handleDeleteAll} sx={{ mr: 2 }}>
                        <FormattedMessage id="clear_filters" />
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmitFilter}
                        disabled={
                            filters.filter((filter) => filter.value[0] !== undefined && filter.value[0] !== '').length !== filters.length
                        }
                    >
                        <FormattedMessage id="filter" />
                    </Button>
                </Box>
            </Box>
        </LocalizationProvider>
    );
}
