import MainCard from '_global/Components/base/cards/MainCard';
import AlertModal from '_global/Components/base/modals/AlertModal';
import { modeAtomReports } from '_global/Utils/hooks/jotai';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Checkbox, Grid, IconButton, List, ListSubheader, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import MessageType from 'models/enums/MessageTypes';
import Mode from 'models/enums/ModeEnum';
import { IAssetReportDetails, IItemReportDetails, ReportSections } from 'models/report/ReportResponse';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { GlobalListItem } from '../DragDropList/ListItem';
import { GlobalNoListItems } from '../DragDropList/NoListItems';
import { AssetsTabProps } from '../Tabs/AssetsTab';
import { ListItemTitle } from './ListItemTitle';
import {
    numberOfCheckedAccessGroups,
    numberOfCheckedAssets,
    numberOfCheckedCompanies,
    numberOfCheckedOpAreas,
    numberOfCheckedRegions
} from './ReportUtils';

export const AssetsListButton = (props: AssetsTabProps) => {
    const { report, handleChangeAll } = props;
    const [mode] = useAtom(modeAtomReports);
    const [selectedAccessGroups, setSelectedAccessGroups] = useState<IItemReportDetails[]>(report.accessGroups);
    const [selectedCompanies, setSelectedCompanies] = useState<IItemReportDetails[]>(report.companies);
    const [selectedRegions, setSelectedRegions] = useState<IItemReportDetails[]>(report.regions);
    const [selectedOpAreas, setSelectedOpAreas] = useState<IItemReportDetails[]>(report.operationalAreas);
    const [selectedAssets, setSelectedAssets] = useState<IAssetReportDetails[]>(report.assets);
    const notFound = -1;

    useEffect(() => {
        setSelectedAccessGroups(report.accessGroups);
        setSelectedCompanies(report.companies);
        setSelectedRegions(report.regions);
        setSelectedOpAreas(report.operationalAreas);
        setSelectedAssets(report.assets);
    }, [report]);

    useEffect(() => {
        handleChangeAll('accessGroups', selectedAccessGroups);
    }, [selectedAccessGroups]);

    useEffect(() => {
        handleChangeAll('companies', selectedCompanies);
    }, [selectedCompanies]);

    useEffect(() => {
        handleChangeAll('regions', selectedRegions);
    }, [selectedRegions]);

    useEffect(() => {
        handleChangeAll('operationalAreas', selectedOpAreas);
    }, [selectedOpAreas]);

    useEffect(() => {
        handleChangeAll('assets', selectedAssets);
    }, [selectedAssets]);

    const [checkedAccessGroups, setCheckedAccessGroups] = useState<IItemReportDetails[]>([]);
    const [checkedCompanies, setCheckedCompanies] = useState<IItemReportDetails[]>([]);
    const [checkedRegions, setCheckedRegions] = useState<IItemReportDetails[]>([]);
    const [checkedOpAreas, setCheckedOpAreas] = useState<IItemReportDetails[]>([]);
    const [checkedAssets, setCheckedAssets] = useState<IAssetReportDetails[]>([]);

    const handleToggleAccessGroups = (accessGroup: IItemReportDetails) => () => {
        const currentIndex = checkedAccessGroups.indexOf(accessGroup);

        const newChecked = [...checkedAccessGroups];

        if (currentIndex === -1) {
            newChecked.push(accessGroup);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedAccessGroups(newChecked);
    };

    const handleToggleCompanies = (company: IItemReportDetails) => () => {
        const currentIndex = checkedCompanies.indexOf(company);

        const newChecked = [...checkedCompanies];

        if (currentIndex === -1) {
            newChecked.push(company);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedCompanies(newChecked);
    };

    const handleToggleRegions = (region: IItemReportDetails) => () => {
        const currentIndex = checkedRegions.indexOf(region);

        const newChecked = [...checkedRegions];

        if (currentIndex === -1) {
            newChecked.push(region);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedRegions(newChecked);
    };
    const handleToggleOpAreas = (operationalArea: IItemReportDetails) => () => {
        const currentIndex = checkedOpAreas.indexOf(operationalArea);

        const newChecked = [...checkedOpAreas];

        if (currentIndex === -1) {
            newChecked.push(operationalArea);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedOpAreas(newChecked);
    };
    const handleToggleAssets = (asset: IAssetReportDetails) => () => {
        const currentIndex = checkedAssets.indexOf(asset);

        const newChecked = [...checkedAssets];

        if (currentIndex === -1) {
            newChecked.push(asset);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedAssets(newChecked);
    };

    const handleToggleAllAccessGroups = (accessGroups: IItemReportDetails[]) => () => {
        if (numberOfCheckedAccessGroups(accessGroups, checkedAccessGroups) === accessGroups.length) {
            const aux = checkedAccessGroups.filter((value) => accessGroups.indexOf(value) === -1);
            setCheckedAccessGroups(aux);
        } else {
            const aux = accessGroups.filter((value) => checkedAccessGroups.indexOf(value) === -1);
            setCheckedAccessGroups([...checkedAccessGroups, ...aux]);
        }
    };

    const handleToggleAllCompanies = (companies: IItemReportDetails[]) => () => {
        if (numberOfCheckedCompanies(companies, checkedCompanies) === companies.length) {
            const aux = checkedCompanies.filter((value) => companies.indexOf(value) === -1);
            setCheckedCompanies(aux);
        } else {
            const aux = companies.filter((value) => checkedCompanies.indexOf(value) === -1);
            setCheckedCompanies([...checkedCompanies, ...aux]);
        }
    };

    const handleToggleAllRegions = (regions: IItemReportDetails[]) => () => {
        if (numberOfCheckedRegions(regions, checkedRegions) === regions.length) {
            const aux = checkedRegions.filter((value) => regions.indexOf(value) === -1);
            setCheckedRegions(aux);
        } else {
            const aux = regions.filter((value) => checkedRegions.indexOf(value) === -1);
            setCheckedRegions([...checkedRegions, ...aux]);
        }
    };
    const handleToggleAllOpAreas = (operationalAreas: IItemReportDetails[]) => () => {
        if (numberOfCheckedOpAreas(operationalAreas, checkedOpAreas) === operationalAreas.length) {
            const aux = checkedOpAreas.filter((value) => operationalAreas.indexOf(value) === -1);
            setCheckedOpAreas(aux);
        } else {
            const aux = operationalAreas.filter((value) => checkedOpAreas?.indexOf(value) === -1);

            setCheckedOpAreas([...checkedOpAreas, ...aux]);
        }
    };
    const handleToggleAllAssets = (assets: IAssetReportDetails[]) => () => {
        if (numberOfCheckedAssets(assets, checkedAssets) === assets.length) {
            const aux = checkedAssets.filter((value) => assets.indexOf(value) === -1);
            setCheckedAssets(aux);
        } else {
            const aux = assets.filter((value) => checkedAssets.indexOf(value) === -1);

            setCheckedAssets([...checkedAssets, ...aux]);
        }
    };

    const handleToggleAll =
        (
            accessGroups: IItemReportDetails[],
            companies: IItemReportDetails[],
            regions: IItemReportDetails[],
            operationalAreas: IItemReportDetails[],
            assets: IAssetReportDetails[]
        ) =>
        () => {
            if (
                numberOfCheckedAccessGroups(accessGroups, checkedAccessGroups) === accessGroups.length &&
                numberOfCheckedCompanies(companies, checkedCompanies) === companies.length &&
                numberOfCheckedRegions(regions, checkedRegions) === regions.length &&
                numberOfCheckedOpAreas(operationalAreas, checkedOpAreas) === operationalAreas.length &&
                numberOfCheckedAssets(assets, checkedAssets) === assets.length
            ) {
                const auxAccessGroups = checkedAccessGroups.filter((value) => accessGroups.indexOf(value) === -1);
                setCheckedAccessGroups(auxAccessGroups);

                const auxCompanies = checkedCompanies.filter((value) => companies.indexOf(value) === -1);
                setCheckedCompanies(auxCompanies);

                const auxRegions = checkedRegions.filter((value) => regions.indexOf(value) === -1);
                setCheckedRegions(auxRegions);

                const auxOpAreas = checkedOpAreas.filter((value) => operationalAreas.indexOf(value) === -1);
                setCheckedOpAreas(auxOpAreas);

                const auxAssets = checkedAssets.filter((value) => assets.indexOf(value) === -1);
                setCheckedAssets(auxAssets);
            } else {
                const auxAccessGroups = accessGroups.filter((value) => checkedAccessGroups.indexOf(value) === -1);
                setCheckedAccessGroups([...checkedAccessGroups, ...auxAccessGroups]);

                const auxCompanies = companies.filter((value) => checkedCompanies.indexOf(value) === -1);
                setCheckedCompanies([...checkedCompanies, ...auxCompanies]);

                const auxRegions = regions.filter((value) => checkedRegions.indexOf(value) === -1);
                setCheckedRegions([...checkedRegions, ...auxRegions]);

                const auxOpAreas = operationalAreas.filter((value) => checkedOpAreas.indexOf(value) === -1);
                setCheckedOpAreas([...checkedOpAreas, ...auxOpAreas]);

                const auxAssets = assets.filter((value) => checkedAssets.indexOf(value) === -1);
                setCheckedAssets([...checkedAssets, ...auxAssets]);
            }
        };

    const handleDeleteAll = () => {
        const accessGroups = selectedAccessGroups.filter((value) => checkedAccessGroups.indexOf(value) === -1);
        setSelectedAccessGroups(accessGroups);
        setCheckedAccessGroups([]);

        const companies = selectedCompanies.filter((value) => checkedCompanies.indexOf(value) === -1);
        setSelectedCompanies(companies);
        setCheckedCompanies([]);

        const regions = selectedRegions.filter((value) => checkedRegions.indexOf(value) === -1);
        setSelectedRegions(regions);
        setCheckedRegions([]);

        const opAreas = selectedOpAreas.filter((value) => checkedOpAreas.indexOf(value) === -1);
        setSelectedOpAreas(opAreas);
        setCheckedOpAreas([]);

        const assets = selectedAssets.filter((value) => checkedAssets.indexOf(value) === -1);
        setSelectedAssets(assets);
        setCheckedAssets([]);
    };

    const totalSelected =
        Number(numberOfCheckedAccessGroups(selectedAccessGroups, checkedAccessGroups)) +
        Number(numberOfCheckedCompanies(selectedCompanies, checkedCompanies)) +
        Number(numberOfCheckedRegions(selectedRegions, checkedRegions)) +
        Number(numberOfCheckedOpAreas(selectedOpAreas, checkedOpAreas)) +
        Number(numberOfCheckedAssets(selectedAssets, checkedAssets));
    const total =
        Number(selectedAccessGroups.length) +
        Number(selectedCompanies.length) +
        Number(selectedRegions.length) +
        Number(selectedOpAreas.length) +
        Number(selectedAssets.length);

    const [openModal, setOpenModal] = useState(false);

    const handleClickOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleOkModal = () => {
        handleDeleteAll();
        handleCloseModal();
    };
    const intl = useIntl();

    return (
        <MainCard
            content={true}
            sx={{
                backgroundColor: '#F6FBFF',
                position: 'sticky',
                width: '100%',
                height: '80vh',
                overflowY: 'scroll',
                scrollbarWidth: 'thin',
                '&::-webkit-scrollbar': {
                    width: '0.4em'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)'
                }
            }}
        >
            <AlertModal
                isOpen={openModal}
                message={`${totalSelected} ${intl.formatMessage({ id: 'items-selected' })}`}
                onCancel={handleCloseModal}
                onOk={handleOkModal}
                title={intl.formatMessage({ id: 'info_close' })}
                type={MessageType.INFO}
            />

            <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                    <Typography sx={{ mt: 1, fontSize: 16, color: 'black', fontWeight: 'bold' }}>
                        <FormattedMessage id={'assets-list'} />
                    </Typography>
                </Grid>
                <Grid item>
                    <Checkbox
                        checked={total !== 0 && total === totalSelected}
                        disabled={mode === Mode.PREVIEW}
                        indeterminate={total !== totalSelected && totalSelected !== 0}
                        onClick={handleToggleAll(selectedAccessGroups, selectedCompanies, selectedRegions, selectedOpAreas, selectedAssets)}
                    />
                    <IconButton
                        disabled={mode === Mode.PREVIEW}
                        key="delete"
                        onClick={() => {
                            handleClickOpenModal();
                        }}
                    >
                        <DeleteOutlinedIcon sx={{ fontSize: 20, color: 'black' }} />
                    </IconButton>
                </Grid>
                <Grid container>
                    <Grid item>
                        {`${totalSelected}/${total} `}
                        <FormattedMessage id={String('selected')} />
                    </Grid>
                </Grid>
            </Grid>

            <List
                dense
                subheader={<li />}
                sx={{ mt: 2, position: 'relative', overflow: 'auto', maxHeight: '100%', overflowX: 'hidden', '& ul': { paddingTop: 0 } }}
            >
                <ListSubheader sx={{ backgroundColor: '#F6FBFF' }}>
                    <ListItemTitle
                        draggedItems={selectedAccessGroups}
                        handleToggleAllItems={() => handleToggleAllAccessGroups(selectedAccessGroups)}
                        mode={mode}
                        numberOfCheckedItems={() => numberOfCheckedAccessGroups(selectedAccessGroups, checkedAccessGroups)}
                        section={ReportSections.ACCESSGROUPS}
                        title={'accessGroups'}
                    />
                </ListSubheader>
                {selectedAccessGroups.map((accessGroup: IItemReportDetails, index: number) => {
                    return (
                        <GlobalListItem
                            checkedItems={checkedAccessGroups.indexOf(accessGroup) !== notFound}
                            handleToggleItems={handleToggleAccessGroups(accessGroup)}
                            item={accessGroup}
                            key={index}
                            mode={mode}
                        />
                    );
                })}
                {selectedAccessGroups.length === 0 && <GlobalNoListItems />}
            </List>

            <List
                dense
                subheader={<li />}
                sx={{ mt: 2, position: 'relative', overflow: 'auto', maxHeight: '100%', overflowX: 'hidden', '& ul': { paddingTop: 0 } }}
            >
                <ListSubheader sx={{ backgroundColor: '#F6FBFF' }}>
                    <ListItemTitle
                        draggedItems={selectedCompanies}
                        handleToggleAllItems={() => handleToggleAllCompanies(selectedCompanies)}
                        mode={mode}
                        numberOfCheckedItems={() => numberOfCheckedCompanies(selectedCompanies, checkedCompanies)}
                        section={ReportSections.COMPANIES}
                        title={'companies'}
                    />
                </ListSubheader>
                {selectedCompanies.map((company: IItemReportDetails, index: number) => {
                    return (
                        <GlobalListItem
                            checkedItems={checkedCompanies.indexOf(company) !== notFound}
                            handleToggleItems={handleToggleCompanies(company)}
                            item={company}
                            key={index}
                            mode={mode}
                        />
                    );
                })}
                {selectedCompanies.length === 0 && <GlobalNoListItems />}
            </List>

            <List
                dense
                subheader={<li />}
                sx={{ mt: 2, position: 'relative', overflow: 'auto', maxHeight: 300, overflowX: 'hidden', '& ul': { paddingTop: 0 } }}
            >
                <ListSubheader sx={{ backgroundColor: '#F6FBFF' }}>
                    <ListItemTitle
                        draggedItems={selectedRegions}
                        handleToggleAllItems={() => handleToggleAllRegions(selectedRegions)}
                        mode={mode}
                        numberOfCheckedItems={() => numberOfCheckedRegions(selectedRegions, checkedRegions)}
                        section={ReportSections.REGIONS}
                        title={'regions'}
                    />
                </ListSubheader>
                {selectedRegions.map((region: IItemReportDetails, index: number) => {
                    return (
                        <GlobalListItem
                            checkedItems={checkedRegions.indexOf(region) !== notFound}
                            handleToggleItems={handleToggleRegions(region)}
                            item={region}
                            key={index}
                            mode={mode}
                        />
                    );
                })}
                {selectedRegions.length === 0 && <GlobalNoListItems />}
            </List>
            <List
                dense
                subheader={<li />}
                sx={{ mt: 2, position: 'relative', overflow: 'auto', maxHeight: 300, overflowX: 'hidden', '& ul': { paddingTop: 0 } }}
            >
                <ListSubheader sx={{ backgroundColor: '#F6FBFF' }}>
                    <ListItemTitle
                        draggedItems={selectedOpAreas}
                        handleToggleAllItems={() => handleToggleAllOpAreas(selectedOpAreas)}
                        mode={mode}
                        numberOfCheckedItems={() => numberOfCheckedOpAreas(selectedOpAreas, checkedOpAreas)}
                        section={ReportSections.OPAREAS}
                        title={'operational-areas'}
                    />
                </ListSubheader>
                {selectedOpAreas.map((opArea: IItemReportDetails, index: number) => {
                    return (
                        <GlobalListItem
                            checkedItems={checkedOpAreas.indexOf(opArea) !== notFound}
                            handleToggleItems={handleToggleOpAreas(opArea)}
                            item={opArea}
                            key={index}
                            mode={mode}
                        />
                    );
                })}
                {selectedOpAreas.length === 0 && <GlobalNoListItems />}
            </List>
            <List
                dense
                subheader={<li />}
                sx={{
                    mt: 2,
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: '60vh',
                    overflowX: 'hidden',
                    '& ul': { paddingTop: 0 }
                }}
            >
                <ListSubheader sx={{ backgroundColor: '#F6FBFF' }}>
                    <ListItemTitle
                        draggedItems={selectedAssets}
                        handleToggleAllItems={() => handleToggleAllAssets(selectedAssets)}
                        mode={mode}
                        numberOfCheckedItems={() => numberOfCheckedAssets(selectedAssets, checkedAssets)}
                        section={ReportSections.ASSETS}
                        title={'assets'}
                    />
                </ListSubheader>

                {selectedAssets.map((asset: IAssetReportDetails, index: number) => {
                    return (
                        <GlobalListItem
                            checkedItems={checkedAssets.indexOf(asset) !== notFound}
                            handleToggleItems={handleToggleAssets(asset)}
                            item={asset}
                            key={index}
                            mode={mode}
                        />
                    );
                })}
                {selectedAssets.length === 0 && <GlobalNoListItems />}
            </List>
        </MainCard>
    );
};
