import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ExporterState {
    blob?: string;
}

const initialState: ExporterState = {
    blob: undefined
};

export const exportSlice = createSlice({
    name: 'exporter',
    initialState,
    reducers: {
        setBlob: (state, action: PayloadAction<string>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.blob = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setBlob } = exportSlice.actions;

export default exportSlice.reducer;
