import MainCard from '_global/Components/base/cards/MainCard';
import AlertModal from '_global/Components/base/modals/AlertModal';
import { modeAtomAssets } from '_global/Utils/hooks/jotai';
import { useQuery } from '@apollo/client';
import { SelectChangeEvent, Table, TableContainer } from '@mui/material';
import { useAtom } from 'jotai';
import { FeederType, GenericFeederType } from 'models/asset/AssetModel';
import { cableTypeToRow } from 'models/cables/CableTypeResponse';
import MessageType from 'models/enums/MessageTypes';
import Type from 'models/enums/TypeEnum';
import { alarmStandardToRow } from 'models/events/AlarmStandard';
import { CABLE_TYPES } from 'queries/CableTypes';
import { STANDARDS } from 'queries/Standards';
import { CableTypes } from 'queries/Types/CableTypes';
import { standards } from 'queries/Types/standards';
import { ChangeEvent, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { IFeederTable } from '../utils/AssetModel';
import FeederTableBody from './FeederTable/FeederTableBody';
import FeederTableHead from './FeederTable/FeederTableHead';

export default function FeederTable(props: IFeederTable) {
    const { feeders, updateFeeders, feederType } = props;
    const [mode] = useAtom(modeAtomAssets);

    const intl = useIntl();
    const [openModal, setOpenModal] = useState(false);
    const [feederToDelete, setFeederToDelete] = useState<number | undefined>(undefined);

    const { data: cableTypesData } = useQuery<CableTypes>(CABLE_TYPES, { variables: { first: 100 } });
    const cableTypeOptions = cableTypesData?.cableTypes?.nodes?.map((cableType) => cableTypeToRow(cableType)) ?? [];

    const { data: profilesData } = useQuery<standards>(STANDARDS);
    const standardsOption = profilesData?.alarmStandards?.nodes?.map((profile) => alarmStandardToRow(profile)) ?? [];

    const handleDeleteFeeder = useCallback(
        (feederNumber: number | undefined) => {
            let newArray = [...feeders];
            const index = newArray.findIndex((x) => x.number === feederNumber);
            newArray = feeders.filter((el, i: number) => i !== index);

            updateFeeders(newArray);

            setOpenModal(false);
            setFeederToDelete(undefined);
        },
        [feeders]
    );

    const handleEraseCableTypeAndFuseRating = useCallback(
        (feederNumber: number) => {
            const newArray = [...feeders];
            const index = newArray.findIndex((x) => x.number === feederNumber);

            newArray.map((el, i: number) => {
                if (index === i) {
                    el.cableTypeId = null;
                    el.cableTypeName = '';
                    el.fuseRating = 0;
                }
            });

            updateFeeders(newArray);
        },
        [feeders]
    );

    const handleOnChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string, feederNumber: number, type: Type) => {
            const newArr = [...feeders];
            const index = newArr.findIndex((x) => x.number === feederNumber);

            if (type === Type.NUMBER) {
                newArr[index] = { ...newArr[index], [id]: Number(event.target.value) };
            } else {
                newArr[index] = { ...newArr[index], [id]: event.target.value };
            }
            updateFeeders(newArr);
        },
        [feeders]
    );

    const handleChangeSelect = useCallback(
        (event: SelectChangeEvent, id: string, feederNumber: number, type: Type) => {
            const newArr = [...feeders];
            const index = newArr.findIndex((x) => x.number === feederNumber);
            if (type === Type.BOOLEAN) {
                if (event.target.value === 'true') {
                    newArr[index] = { ...newArr[index], [id]: true };
                } else {
                    newArr[index] = { ...newArr[index], [id]: false };
                }
            }
            updateFeeders(newArr);
        },
        [feeders]
    );

    const handleChange = useCallback(
        (newValue: number | null, id: string, feederNumber: number) => {
            const newArr = [...feeders];
            const index = newArr.findIndex((x) => x.number === feederNumber);

            newArr[index] = { ...newArr[index], [id]: newValue };
            updateFeeders(newArr);
        },
        [feeders]
    );

    return (
        <MainCard boxShadow={false} content={false}>
            <AlertModal
                isOpen={openModal}
                message={intl.formatMessage({ id: 'irreversible_action' })}
                onCancel={() => setOpenModal(false)}
                onOk={() => handleDeleteFeeder(feederToDelete)}
                title={intl.formatMessage({ id: 'delete_feeder' })}
                type={MessageType.WARNING}
            />
            <TableContainer sx={{ px: 2 }}>
                <Table aria-labelledby="tableTitle" sx={{ minWidth: 750 }}>
                    {feeders && (
                        <>
                            <FeederTableHead feederType={feederType} mode={mode} />

                            <FeederTableBody
                                feeders={
                                    feederType === GenericFeederType.BUSBAR
                                        ? feeders.filter((x) => x.feederType === FeederType.BUSBAR)
                                        : feederType === GenericFeederType.EARTH
                                        ? feeders.filter(
                                              (x) =>
                                                  x.feederType === FeederType.EARTH_PROTECTION_LINE ||
                                                  x.feederType === FeederType.EARTH_SERVICE_LINE
                                          )
                                        : feeders.filter((x) => x.feederType === FeederType.POWERBUSLINE)
                                }
                                {...{
                                    cableTypeOptions,
                                    setOpenModal,
                                    handleChange,
                                    handleOnChange,
                                    handleChangeSelect,
                                    standardsOption,
                                    setFeederToDelete,
                                    mode,
                                    handleEraseCableTypeAndFuseRating,
                                    updateFeeders
                                }}
                            />
                        </>
                    )}
                </Table>
            </TableContainer>
        </MainCard>
    );
}
