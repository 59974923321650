import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Configuration } from '../../../logic/configuration/Configuration';

export interface FeatureState {
    configuration: Configuration | undefined;
}

const initialState: FeatureState = {
    configuration: undefined
};

export const featuresSlice = createSlice({
    name: 'features',
    initialState,
    reducers: {
        setConfiguration: (state, action: PayloadAction<Configuration>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.configuration = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setConfiguration } = featuresSlice.actions;

export default featuresSlice.reducer;
