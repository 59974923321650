export function subarray<T>(array: Array<T>, start: number, end: number): Array<T> {
    if (!end) {
        end = -1;
    }
    return array.slice(start, array.length + 1 - end * -1);
}

export function isTypedArray<T>(val: T | T[]): val is T[] {
    return Array.isArray(val);
}

export function stringIncludes(val: string, array: Array<string>): boolean {
    return array.some((item) => {
        return val.includes(item);
    });
}

export function requestUrlIncludes(val: string): boolean {
    const undesirableRequestsList = ['service=cableTypes&tableName=Assets'];
    return undesirableRequestsList.some((item) => {
        return val.includes(item);
    });
}
