import '@inovua/reactdatagrid-community/index.css';
import './Grids.scss';

import { typeFlagRefetch } from '_global/Components/base/filter/FilterTypes';
import { getActiveFilters, getSubmitFilterWithOperators, refactorFiltersToBeQuery } from '_global/Components/base/filter/FilterUtils';
import FilterView from '_global/Components/base/filter/FilterView';
import AlertModal from '_global/Components/base/modals/AlertModal';
import { addGridsPaginations, Pagination, removeGridPagination } from '_global/Services/store/gridsPaginationSlice';
import { useAppDispatch, useAppSelector } from '_global/Services/store/hooks';
import gridsPaginationsUpdateGrid from '_global/Utils/gridsPaginations';
import { gridUpdate } from '_global/Utils/GridUtils';
import { filterAccessGroupsAtomReports } from '_global/Utils/hooks/jotai';
import { useQuery } from '@apollo/client';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import {
    TypeComputedProps,
    TypeDataSource,
    TypePaginationProps,
    TypeSingleSortInfo,
    TypeSortInfo
} from '@inovua/reactdatagrid-community/types';
import { TypeColumn } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { Box } from '@mui/system';
import { useAtom } from 'jotai';
import MessageType from 'models/enums/MessageTypes';
import { AccessGroup, accessGroupToRow } from 'models/layout/AccessGroup';
import { columnsMap } from 'models/settings/RemoteConfiguration';
import { ACCESSGROUPS } from 'queries/AccessGroups';
import { accessGroups } from 'queries/Types/accessGroups';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DataFilter, { GridHandleType } from 'views/Events/grid/filter/DataFilter';

import { defaultRowStyle } from '../../../_global/Components/base/grids/RowUtils';
import { AssetsTabProps } from '../Tabs/AssetsTab';
import { renderColumnsAccessGroups } from './utils';

const gridStyle = {
    flexGrow: 1,
    border: 'none',
    width: '100%',
    height: '100%'
};

const filtersValue = [
    { name: 'name', type: 'string', i18n: 'name' },
    { name: 'description', type: 'string', i18n: 'description' },
    { name: 'isEnabled', type: 'boolean', i18n: 'isEnabled' },
    {
        name: 'createdBy',
        type: 'string',
        i18n: 'createdBy'
    },
    {
        name: 'createdDate',
        type: 'date',
        i18n: 'createdDate'
    },
    {
        name: 'lastModifiedDate',
        type: 'date',
        i18n: 'lastModifiedDate'
    },
    {
        name: 'lastModifiedBy',
        type: 'string',
        i18n: 'lastModifiedBy'
    }
];

export default function AccessGroupsGrid({ report, handleChangeAll }: AssetsTabProps) {
    const { data, loading, error, fetchMore } = useQuery<accessGroups>(ACCESSGROUPS);
    const [gridRef, setGridRef] = useState<((gridApiRef: MutableRefObject<TypeComputedProps | null>) => void) | undefined>(undefined);
    const [enableFilter, setEnableFilter] = useState(false);
    const columns_access_groups: TypeColumn[] = renderColumnsAccessGroups(report, handleChangeAll);
    const [columns, setColumns] = useState<TypeColumn[]>(columns_access_groups.slice(1, columns_access_groups.length));
    const [numberActiveFilters, setNumberActiveFilters] = useState(0);
    const [filters, setFilters] = useAtom(filterAccessGroupsAtomReports);
    const [flagRefetch, setFlagRefetch] = useState<typeFlagRefetch>(null);
    const [showingError, setShowingError] = useState(false);
    const specialFilters = { id: { nin: [1, 2] } };

    const location = useLocation();
    const dispatch = useAppDispatch();
    const [gridSkip, setGridSkip] = useState<number | undefined>(undefined);
    const [gridSortInfo, setGridSortInfo] = useState<TypeSingleSortInfo | undefined>(undefined);
    const [gridColumnOrder, setGridColumnOrder] = useState<string[] | undefined>(undefined);
    const [gridLimit, setGridLimit] = useState<number | undefined>(undefined);
    const gridsPaginations = useAppSelector((state) => state.gridsPaginations.gridsPaginations);
    const firstRenderRef = useRef(true);

    useEffect(() => {
        gridsPaginationsUpdateGrid({
            gridName: 'AccessGroupsGrid',
            gridsPaginations,
            columns: columns,
            setGridSkip,
            setGridSortInfo,
            setGridColumnOrder,
            setGridLimit,
            location
        });
    }, [gridsPaginations]);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
        } else {
            dispatch(
                addGridsPaginations({
                    url: location.pathname,
                    grid: { skip: gridSkip, sortInfo: gridSortInfo, columnOrder: gridColumnOrder, limit: gridLimit },
                    key: `AccessGroupsGrid`
                })
            );
        }
    }, [gridSkip, gridSortInfo, gridColumnOrder, gridLimit]);

    const refresh = () => {
        dispatch(
            removeGridPagination({
                key: `AccessGroupsGrid`,
                url: location?.pathname
            })
        );
        gridUpdate();
    };

    const updateVisibility = useCallback((columnsM: columnsMap) => {
        setColumns((columns) => {
            const newColumns: TypeColumn[] = [];

            columnsM.forEach((value, key) => {
                const index = columns.findIndex((nc) => nc.name === key.name);
                newColumns.push({ ...columns[index], visible: value });
            });

            return newColumns;
        });
    }, []);

    const nodes = useMemo(() => data?.accessGroups?.nodes, [data?.accessGroups?.nodes]);

    const pageInfo = useMemo(() => data?.accessGroups?.pageInfo, [data?.accessGroups?.pageInfo]);

    const loadData: TypeDataSource = useCallback(
        async (props: TypePaginationProps) => {
            const response = await fetchMore({
                variables: {
                    numberOfElements: props.skip + props.limit,
                    filter: flagRefetch ? flagRefetch : specialFilters
                }
            });

            const accessGroups =
                response?.data?.accessGroups?.nodes?.map((accessGroup) => accessGroup && accessGroupToRow(accessGroup)) ?? [];

            return {
                data: accessGroups.slice(props.skip, accessGroups.length),
                count: response.data.accessGroups?.totalCount ?? 0
            };
        },
        [pageInfo, pageInfo?.endCursor, nodes, flagRefetch, specialFilters]
    );

    const rowClassName = (company: AccessGroup) => {
        if (report.accessGroups.some((comp) => comp.id === company.id)) {
            return 'selected';
        }

        return undefined;
    };

    const handleDeleteAll = () => {
        if (numberActiveFilters <= 0) {
            return;
        }
        setFilters([{ name: '', operator: [], value: [] }]);
        setFlagRefetch(null);
        void refresh();
    };

    const handleSubmitFilter = () => {
        const { filterWithoutOperators, andFilter, orFilter } = refactorFiltersToBeQuery(filters, filtersValue);
        const newAndFilter = andFilter.concat([specialFilters]);
        if (Object.keys(filterWithoutOperators).length > 0) {
            const filterToApply = getSubmitFilterWithOperators(filters, filterWithoutOperators, newAndFilter, orFilter);
            setFlagRefetch(filterToApply);
        } else {
            setFlagRefetch(null);
        }
    };

    useEffect(() => {
        setNumberActiveFilters(getActiveFilters(filters));
    }, [filters]);

    useEffect(() => {
        if (filters.length > 0 && filters[0].name !== '') {
            setEnableFilter(true);
            const { filterWithoutOperators, andFilter, orFilter } = refactorFiltersToBeQuery(filters, filtersValue);

            if (Object.keys(filterWithoutOperators).length > 0) {
                setFlagRefetch(getSubmitFilterWithOperators(filters, filterWithoutOperators, andFilter, orFilter));
            } else {
                setFlagRefetch(null);
            }
        } else {
            setEnableFilter(false);
        }
    }, []);

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <DataFilter
                columns={columns}
                onRefresh={refresh}
                onVisibilityChanged={updateVisibility}
                userHasOptions={false}
                {...{ enableFilter, setEnableFilter, numberActiveFilters }}
                gridRef={gridRef}
            />
            {enableFilter && <FilterView {...{ filters, setFilters, filtersValue, handleSubmitFilter, handleDeleteAll, setFlagRefetch }} />}

            <Box sx={{ mt: 2, height: 600 }}>
                <ReactDataGrid
                    columnOrder={gridColumnOrder}
                    columns={[columns_access_groups[0]].concat(columns)}
                    dataSource={loadData}
                    handle={setGridRef as unknown as GridHandleType}
                    idProperty="id"
                    limit={gridLimit ? gridLimit : 10}
                    loading={loading}
                    onColumnOrderChange={(columnOrder) => {
                        setGridColumnOrder(columnOrder);
                    }}
                    onLimitChange={(limit) => {
                        setGridLimit(limit);
                    }}
                    onSkipChange={(skip) => {
                        setGridSkip(skip);
                    }}
                    onSortInfoChange={(column: TypeSortInfo) => {
                        setGridSortInfo(column as TypeSingleSortInfo);
                    }}
                    pagination={true}
                    rowClassName={({ data }) => rowClassName(data as AccessGroup)}
                    rowHeight={50}
                    rowStyle={defaultRowStyle}
                    showCellBorders={'horizontal'}
                    showColumnMenuTool={false}
                    showZebraRows={false}
                    skip={gridSkip}
                    sortInfo={gridSortInfo}
                    style={gridStyle}
                />
            </Box>
            {error && (
                <AlertModal
                    isOpen={showingError}
                    message={JSON.stringify(error)}
                    onOk={() => setShowingError(false)}
                    type={MessageType.ERROR}
                />
            )}
        </Box>
    );
}
