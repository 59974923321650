import { userLanguageAtom } from '_global/Utils/hooks/jotai';
import { useAtom } from 'jotai';
import { DefaultRootStateProps } from 'models';
import React, { useEffect, useState } from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import { useSelector } from 'react-redux';

const loadLocaleData = (locale: string) => {
    switch (locale.toLowerCase()) {
        case 'fr':
            return import('_global/Resources/locales/fr.json');
        case 'pt-pt':
        case 'pt':
            return import('_global/Resources/locales/pt-compiled.json');
        case 'de-de':
        case 'de':
            return import('_global/Resources/locales/de-compiled.json');
        case 'en-nz':
            return import('_global/Resources/locales/en-nz-compiled.json');
        case 'en-au':
            return import('_global/Resources/locales/en-au-compiled.json');
        default:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return import('virtual:language-en');
    }
};

export interface LocalsProps {
    children: React.ReactNode;
}

const Locales = ({ children }: LocalsProps) => {
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);
    const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>();
    const [local, setLocale] = useState('en');
    const [language] = useAtom(userLanguageAtom);

    useEffect(() => {
        if (language) {
            setLocale(language);
            loadLocaleData(language)
                .then((d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
                    setMessages(d.default);
                })
                .catch(console.error);
        } else {
            loadLocaleData(customization.locale)
                .then((d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
                    setMessages(d.default);
                })
                .catch(console.error);
        }
    }, [language, customization.locale]);

    return (
        <>
            {messages && (
                <IntlProvider defaultLocale="en" locale={local} messages={messages} onError={(e) => e}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

export default Locales;
