import { getData } from '_global/Services/api/networkRequester';
import { useAppDispatch } from '_global/Services/store/hooks';
import { addTab } from '_global/Services/store/tabComponentSlice';
import { validationSetAssets } from '_global/Utils/hooks/jotai';
import { IconToNode } from '_global/Utils/IconToNode';
import { ApolloQueryResult, OperationVariables, useQuery } from '@apollo/client';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { Autocomplete, Grid, InputAdornment, TextField } from '@mui/material';
import { useAtom } from 'jotai';
import { useConfiguration } from 'logic/configuration/Hooks';
import { ASSET_TYPE_PROTECTED } from 'models/asset/AssetInfo';
import { AssetModel } from 'models/asset/AssetModel';
import { AssetResponse } from 'models/asset/AssetResponse';
import Mode from 'models/enums/ModeEnum';
import { ASSET_FEEDERS_BY_NAME } from 'queries/AssetFeedersByName';
import { AssetFeedersByName, AssetFeedersByName_assets_nodes_feeders } from 'queries/Types/AssetFeedersByName';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AssetContent from 'views/Assets/preview/AssetContent';
import { AssetTypeSettingsProps, BasicDataModel } from 'views/Assets/utils/AssetModel';

function AssetTypeSettings(props: AssetTypeSettingsProps) {
    const { assetDetails, assetInfoData, mode, handleChangeNumber, handleChange, handleChangeAssetType } = props;
    const intl = useIntl();
    const { data: feeders, refetch: refetchFeeders } = useQuery<AssetFeedersByName>(ASSET_FEEDERS_BY_NAME);
    const [feedersArray, setFeedersArray] = useState<BasicDataModel[]>([]);
    const [hasError] = useAtom(validationSetAssets);
    const dispatch = useAppDispatch();
    const localConfig = useConfiguration();

    const reloadFeeders = async (
        assetDetails: AssetModel,
        feeders: AssetFeedersByName | undefined,
        refetchFeeders: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<AssetFeedersByName>>
    ) => {
        const response = await refetchFeeders({ assetName: assetDetails.parentAssetName });

        const auxFeeders: AssetFeedersByName_assets_nodes_feeders[] | null = [];
        response.data?.assets?.nodes?.map((el) => {
            el?.feeders?.map((feeder) => auxFeeders.push(feeder));
        });
        setFeedersArray(auxFeeders);
    };

    useEffect(() => {
        void reloadFeeders(assetDetails, feeders, refetchFeeders);
    }, [assetDetails.parentAssetName]);

    return (
        <Grid columnSpacing={2} container>
            <Grid item xs={4}>
                <Autocomplete
                    disableClearable
                    disabled={!(mode === Mode.ADD)}
                    getOptionLabel={(option) => {
                        if (option) {
                            return intl.formatMessage({ id: option.toString().toLowerCase() });
                        } else {
                            return '';
                        }
                    }}
                    isOptionEqualToValue={(option, val) => {
                        if (val === '') {
                            return true;
                        }

                        return option === val;
                    }}
                    key={assetDetails.assetId}
                    onChange={(event, value) => {
                        handleChangeAssetType && handleChangeAssetType(value as ASSET_TYPE_PROTECTED);
                        if (assetDetails.parentAssetId && assetDetails.parentAssetName) {
                            handleChangeNumber && handleChangeNumber(0, 'parentAssetId');
                            handleChange && handleChange('', 'parentAssetName');
                        }
                        if (assetDetails.parentFeederId && assetDetails.parentFeederName) {
                            handleChangeNumber && handleChangeNumber(0, 'parentFeederId');
                            handleChange && handleChange('', 'parentFeederName');
                        }
                    }}
                    options={assetInfoData.assetType.map((el) => el.name)}
                    renderInput={(params) => (
                        <TextField
                            error={!!hasError[1].hasError}
                            {...params}
                            label={<FormattedMessage id={'asset_type'} />}
                            required={true}
                        />
                    )}
                    sx={{ maxHeight: '51px' }}
                    value={Object.entries(ASSET_TYPE_PROTECTED).find(([, value]) => Number(value) === assetDetails.assetType)?.[0] ?? ''}
                />
            </Grid>

            <Grid item xs={4}>
                {assetInfoData.assetParent.length > 0 && assetDetails.parentAssetName !== null ? (
                    <Autocomplete
                        disableClearable
                        disabled={!(mode === Mode.ADD || mode === Mode.EDIT)}
                        isOptionEqualToValue={(option, val) => {
                            if (val === '') {
                                return true;
                            }

                            return option === val;
                        }}
                        key={assetDetails.parentAssetId}
                        onChange={(event, value) => {
                            assetInfoData.assetParent.find((el) => {
                                if (el.name === value) {
                                    handleChangeNumber && handleChangeNumber(el.id, 'parentAssetId');
                                    handleChange && handleChange(el.name, 'parentAssetName');
                                    if (assetDetails.parentFeederId && assetDetails.parentFeederName) {
                                        handleChangeNumber && handleChangeNumber(0, 'parentFeederId');
                                        handleChange && handleChange('', 'parentFeederName');
                                    }
                                }
                            });
                        }}
                        options={assetInfoData.assetParent.map((el) => el.name)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: mode == Mode.PREVIEW && assetDetails.parentAssetName !== '' && (
                                        <InputAdornment
                                            onClick={() => {
                                                if (!assetDetails.parentAssetId) {
                                                    return;
                                                }
                                                const endpoint = `${localConfig?.BaseUrl ?? ''}/equipments/api/equipment/Asset/${
                                                    assetDetails.parentAssetId
                                                }`;
                                                getData<AssetModel>(endpoint)
                                                    .then((data) => {
                                                        const assetResp = {
                                                            id: data.assetId,
                                                            registryNumber: data.registryNumber,
                                                            name: data.name,
                                                            parentAssetName: data.parentAssetName,
                                                            parentFeederName: data.parentFeederName,
                                                            assetType: data.assetType,
                                                            longitude: data.longitude,
                                                            latitude: data.latitude,
                                                            operationalArea: data.operationalAreaName,
                                                            lastCommunication: data.lastModifiedDate,
                                                            device: data.devices,
                                                            region: data.regionName,
                                                            state: data.state,
                                                            alarmStandardName: data.alarmStandardName,
                                                            description: data.description,
                                                            createdDate: data.createdDate,
                                                            lastModifiedDate: data.lastModifiedDate,
                                                            isEnabled: data.isEnabled
                                                        } as unknown as AssetResponse;
                                                        dispatch(
                                                            addTab({
                                                                name: assetResp.name ?? 'Asset',
                                                                component: <AssetContent asset={assetResp} />,
                                                                url: `/assets/${assetResp.id.toString()}`
                                                            })
                                                        );
                                                    })
                                                    .catch((e) => {
                                                        console.error(e);
                                                    });
                                            }}
                                            position="start"
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <IconToNode definition={faArrowUpRightFromSquare} />
                                        </InputAdornment>
                                    )
                                }}
                                label={<FormattedMessage id={'asset_parent'} />}
                            />
                        )}
                        sx={{ maxHeight: '51px' }}
                        value={assetDetails.parentAssetName ?? ''}
                    />
                ) : (
                    <></>
                )}
            </Grid>
            <Grid item xs={4}>
                {feedersArray.length > 0 ? (
                    <Autocomplete
                        disableClearable
                        disabled={!(mode === Mode.ADD || mode === Mode.EDIT)}
                        isOptionEqualToValue={(option, val) => {
                            if (val === '') {
                                return true;
                            }

                            if (option !== val) {
                                return true;
                            }

                            return option === val;
                        }}
                        key={assetDetails.parentFeederId}
                        onChange={(event, value) => {
                            feedersArray.find((el) => {
                                if (el.name === value) {
                                    handleChangeNumber && handleChangeNumber(el.id, 'parentFeederId');
                                    handleChange && handleChange(el.name, 'parentFeederName');
                                }
                            });
                        }}
                        options={feedersArray.map((el) => el.name)}
                        renderInput={(params) => <TextField {...params} label={<FormattedMessage id={'asset_feeder'} />} />}
                        sx={{ maxHeight: '51px' }}
                        value={assetDetails.parentFeederName ?? ''}
                    />
                ) : (
                    <></>
                )}
            </Grid>
        </Grid>
    );
}

export default AssetTypeSettings;
