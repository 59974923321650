import { TableCell, TableRow, Typography } from '@mui/material';
import { ProtectionEarth, ServiceEarth } from 'models/dataStorage/NewSimpleLastData';
import { FormattedMessage } from 'react-intl';

export default function ProtectionEarthTableRow(props: { lastData: ProtectionEarth | ServiceEarth; title: string; hasEarth: boolean }) {
    const { lastData, title, hasEarth } = props;

    return (
        <>
            <TableRow hover>
                <TableCell align="center">
                    <Typography>
                        <FormattedMessage id={title} />
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography>{hasEarth && lastData.date ? new Date(lastData.date).toLocaleString() : '---'}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography>{hasEarth ? lastData?.resistence?.toFixed(2) : <FormattedMessage id={'no-data'} />}</Typography>
                </TableCell>
            </TableRow>
        </>
    );
}
