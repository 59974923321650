import '../../../Reports/Grid/Cells/ActionsCell.scss';

import { useAppDispatch } from '_global/Services/store/hooks';
import { addTab } from '_global/Services/store/tabComponentSlice';
import { modeAtomAssets, tabAtomAssets, validationSetAssets } from '_global/Utils/hooks/jotai';
import { faClone, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, MenuItem } from '@mui/material';
import { useAtom } from 'jotai';
import { AssetResponse } from 'models/asset/AssetResponse';
import Mode from 'models/enums/ModeEnum';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AssetDetails from 'views/Assets/AssetDetails';
import { StyledMenu } from 'views/Reports/Grid/Cells/ActionsCell';

export default function ActionsCell(props: { asset: AssetResponse }) {
    const { asset } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [, setCurrentTab] = useAtom(tabAtomAssets);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };
    const [validation, setValidation] = useAtom(validationSetAssets);
    const [, setMode] = useAtom(modeAtomAssets);

    const dispatch = useAppDispatch();

    function addDuplicateAssetHandler() {
        const updateVal = [...validation];
        updateVal.map((el) => (el.hasError = false));
        setValidation(updateVal);
        dispatch(
            addTab({
                name: 'add',
                component: <AssetDetails assetId={asset.id} />,
                url: '/assets/add'
            })
        );
    }

    return (
        <Box sx={{ textAlign: 'left', display: 'flex', gap: '10px' }}>
            <IconButton
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                aria-label="more"
                id="long-button"
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={faEllipsisV} />
            </IconButton>
            <StyledMenu
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={anchorEl}
                id="demo-customized-menu"
                onClose={handleClose}
                open={open}
            >
                <MenuItem
                    disableRipple
                    onClick={(e) => {
                        addDuplicateAssetHandler();
                        setMode(Mode.ADD);
                        setCurrentTab('add');
                        handleClose(e);
                    }}
                >
                    <div className="actionsCellMenu">
                        <FontAwesomeIcon icon={faClone} />
                        <FormattedMessage id="clone" />
                    </div>
                </MenuItem>
            </StyledMenu>
        </Box>
    );
}
