import '@inovua/reactdatagrid-community/index.css';

import { addGridsPaginations } from '_global/Services/store/gridsPaginationSlice';
import { useAppDispatch, useAppSelector } from '_global/Services/store/hooks';
import gridsPaginationsUpdateGrid from '_global/Utils/gridsPaginations';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeComputedProps, TypeDataSource, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-community/types';
import { TypeColumn, TypeColWithNameProperty } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { Box } from '@mui/system';
import { AssetResponse } from 'models/asset/AssetResponse';
import { ASSET_STATE, ASSET_TYPE } from 'models/graphql-global-types';
import { SortInfoType } from 'models/sortInfo';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import DateCell from 'views/Events/grid/cells/DateCell';
import { GridHandleType } from 'views/Events/grid/filter/DataFilter';

import { defaultRowStyle } from '../../../_global/Components/base/grids/RowUtils';
import ActionsCell from './cells/ActionsCell';
import AssetStateCell from './cells/AssetStateCell';
import AssetTypeCell from './cells/AssetTypeCell';
import StatusCell from './cells/StatusCell';

export type AssetProps = {
    data: TypeDataSource;
    onRowPressed: (asset: AssetResponse) => void;
    pagination?: boolean;
    columns: TypeColumn[];
    loading: boolean;
    setGridRef: Dispatch<SetStateAction<((gridApiRef: MutableRefObject<TypeComputedProps | null>) => void) | undefined>>;
    sortInfo: SortInfoType;
    sortInfoC: TypeSortInfo;
};

export interface TypeColumnExtended extends TypeColWithNameProperty {
    sortable: boolean;
    columnSortAsc?: Record<string, Record<string, unknown>>;
    columnSortDesc?: Record<string, Record<string, unknown>>;
}

export const default_columns_assets: TypeColumnExtended[] = [
    {
        name: 'assetType',
        header: <FormattedMessage id={'assetType'} />,
        flex: 0.5,
        minWidth: 150,
        visible: true,
        sortable: true,
        render: ({ value }) => <AssetTypeCell type={value as ASSET_TYPE} />
    },
    {
        name: 'registryNumber',
        header: <FormattedMessage id={'registryNumber'} />,
        minWidth: 150,
        flex: 0.5,
        visible: true,
        sortable: true,
        render: ({ value }) => <FormattedMessage id={String(value)} />
    },
    {
        name: 'name',
        header: <FormattedMessage id={'name'} />,
        minWidth: 90,
        flex: 0.5,
        visible: true,
        sortable: true,
        render: ({ value }) => <FormattedMessage id={String(value)} />
    },
    {
        name: 'longitude',
        header: <FormattedMessage id={'longitude'} />,
        minWidth: 110,
        flex: 0.5,
        visible: false,
        sortable: true,
        render: ({ value }) => {
            if (value) return <FormattedMessage id={String(value)} />;
            else return <FormattedMessage id={'na'} />;
        }
    },
    {
        name: 'latitude',
        header: <FormattedMessage id={'latitude'} />,
        minWidth: 110,
        flex: 0.5,
        visible: false,
        sortable: true,
        render: ({ value }) => {
            if (value) return <FormattedMessage id={String(value)} />;
            else return <FormattedMessage id={'na'} />;
        }
    },
    {
        name: 'device',
        header: <FormattedMessage id={'device'} />,
        minWidth: 110,
        flex: 0.5,
        visible: false,
        columnSortAsc: { devices: { serialNumber: 'ASC' } },
        columnSortDesc: { devices: { serialNumber: 'DESC' } },
        sortable: false,
        render: ({ value }) => {
            if (value) return <FormattedMessage id={String(value)} />;
            else return <FormattedMessage id={'no-device'} />;
        }
    },
    {
        name: 'lastCommunication',
        header: <FormattedMessage id={'lastCommunication'} />,
        minWidth: 150,
        flex: 0.6,
        visible: false,
        columnSortAsc: { devices: { lastCommunication: 'ASC' } },
        columnSortDesc: { devices: { lastCommunication: 'DESC' } },
        sortable: false,
        render: ({ value }) => {
            const date = value as Date;
            if (date && date.getDate()) return <FormattedMessage id={String(value)} />;
            else return <FormattedMessage id={'na'} />;
        }
    },
    {
        name: 'operationalArea',
        header: <FormattedMessage id={'operationalArea'} />,
        minWidth: 150,
        flex: 0.5,
        visible: true,
        sortable: true,
        columnSortAsc: { operationalArea: { name: 'ASC' } },
        columnSortDesc: { operationalArea: { name: 'DESC' } },
        render: ({ value }) => {
            if (value) return <FormattedMessage id={String(value)} />;
            else return <FormattedMessage id={'na'} />;
        }
    },
    {
        name: 'region',
        header: <FormattedMessage id={'region'} />,
        minWidth: 90,
        flex: 0.5,
        visible: true,
        sortable: true,
        columnSortAsc: { region: { name: 'ASC' } },
        columnSortDesc: { region: { name: 'DESC' } },
        render: ({ value }) => {
            if (value) return <FormattedMessage id={String(value)} />;
            else return <FormattedMessage id={'na'} />;
        }
    },
    {
        name: 'alarmStandardName',
        header: <FormattedMessage id={'alarm_profile'} />,
        minWidth: 150,
        flex: 0.5,
        visible: true,
        sortable: true,
        render: ({ value }) => {
            if (value) return <FormattedMessage id={String(value)} />;
            else return <FormattedMessage id={'na'} />;
        }
    },
    {
        name: 'parentAssetName',
        header: <FormattedMessage id={'parentAssetName'} />,
        minWidth: 150,
        flex: 0.5,
        visible: false,
        sortable: true,
        render: ({ value }) => {
            if (value) return <FormattedMessage id={String(value)} />;
            else return <FormattedMessage id={'na'} />;
        }
    },
    {
        name: 'parentFeederName',
        header: <FormattedMessage id={'parentFeederName'} />,
        minWidth: 150,
        flex: 0.5,
        visible: false,
        sortable: true,
        render: ({ value }) => {
            if (value) return <FormattedMessage id={String(value)} />;
            else return <FormattedMessage id={'na'} />;
        }
    },
    {
        name: 'state',
        header: <FormattedMessage id={'state'} />,
        minWidth: 120,
        flex: 0.5,
        visible: true,
        sortable: true,
        render: ({ value }) => <AssetStateCell state={value as ASSET_STATE} />
    },
    {
        name: 'description',
        header: <FormattedMessage id={'description'} />,
        minWidth: 120,
        flex: 0.5,
        visible: false,
        sortable: true,
        render: ({ value }) => {
            if (value) return <FormattedMessage id={String(value)} />;
            else return <FormattedMessage id={'na'} />;
        }
    },
    {
        name: 'createdDate',
        header: <FormattedMessage id={'createdDate'} />,
        minWidth: 150,
        flex: 0.5,
        visible: false,
        sortable: true,
        render: ({ value }) => {
            const date = value as Date;
            if (date && date.getDate()) return <DateCell value={value as Date} />;
            else return <FormattedMessage id={'na'} />;
        }
    },
    {
        name: 'lastModifiedDate',
        header: <FormattedMessage id={'lastModifiedDate'} />,
        minWidth: 170,
        flex: 0.5,
        visible: false,
        sortable: true,
        render: ({ value }) => {
            const date = value as Date;
            if (date && date.getDate()) return <DateCell value={value as Date} />;
            else return <FormattedMessage id={'na'} />;
        }
    },
    {
        name: 'isEnabled',
        header: <FormattedMessage id={'isEnabled'} />,
        minWidth: 120,
        flex: 0.5,
        visible: false,
        sortable: true,
        render: ({ value }) => <StatusCell isEnable={value as boolean} />
    },
    {
        name: 'actions',
        header: <></>,
        flex: 0.5,
        visible: true,
        sortable: false,
        render: ({ data }) => <ActionsCell asset={data as AssetResponse} />
    }
];

const gridStyle = {
    flexGrow: 1,
    minHeight: '62vh',
    border: 'none'
};

export default function AssetsGrid({
    data,
    onRowPressed,
    pagination = true,
    columns,
    loading,
    setGridRef,
    sortInfo,
    sortInfoC
}: AssetProps) {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [gridSkip, setGridSkip] = useState<number | undefined>(undefined);
    const [gridSortInfo, setGridSortInfo] = useState<TypeSingleSortInfo | undefined>(undefined);
    const [gridColumnOrder, setGridColumnOrder] = useState<string[] | undefined>(undefined);
    const [gridLimit, setGridLimit] = useState<number | undefined>(undefined);

    const gridsPaginations = useAppSelector((state) => state.gridsPaginations.gridsPaginations);
    const firstRenderRef = useRef(true);

    useEffect(() => {
        gridsPaginationsUpdateGrid({
            gridName: 'AssetsGrid',
            gridsPaginations,
            columns: columns,
            sortInfo,
            setGridSkip,
            setGridSortInfo,
            setGridColumnOrder,
            setGridLimit,
            location
        });
    }, [gridsPaginations]);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
        } else {
            dispatch(
                addGridsPaginations({
                    url: location.pathname,
                    grid: { skip: gridSkip, sortInfo: gridSortInfo, columnOrder: gridColumnOrder, limit: gridLimit },
                    key: `AssetsGrid`
                })
            );
        }
    }, [gridSkip, gridSortInfo, gridColumnOrder, gridLimit]);

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <ReactDataGrid
                columnOrder={gridColumnOrder}
                columns={columns}
                dataSource={data}
                enableColumnAutosize
                handle={setGridRef as unknown as GridHandleType}
                idProperty="id"
                limit={gridLimit ? gridLimit : 10}
                loading={loading}
                onColumnOrderChange={(columnOrder) => {
                    setGridColumnOrder(columnOrder);
                }}
                onLimitChange={(limit) => {
                    setGridLimit(limit);
                }}
                onRowClick={(rowProps) => {
                    onRowPressed(rowProps.data as AssetResponse);
                }}
                onSkipChange={(skip) => {
                    setGridSkip(skip);
                }}
                onSortInfoChange={(column: TypeSortInfo) => {
                    if (!column) {
                        return sortInfo('', 0);
                    }
                    const section = columns.find((section) => section.name === ((column as TypeSingleSortInfo).id as string));
                    if ((section as TypeColumnExtended).columnSortAsc !== null && (section as TypeColumnExtended).columnSortDesc !== null) {
                        sortInfo(
                            (column as TypeSingleSortInfo).id as string,
                            (column as TypeSingleSortInfo).dir as number,
                            (column as TypeSingleSortInfo).type as string,
                            (section as TypeColumnExtended).columnSortAsc,
                            (section as TypeColumnExtended).columnSortDesc
                        );
                    } else {
                        sortInfo(
                            (column as TypeSingleSortInfo).id as string,
                            (column as TypeSingleSortInfo).dir as number,
                            (column as TypeSingleSortInfo).type as string
                        );
                    }
                    setGridSortInfo(column as TypeSingleSortInfo);
                }}
                pagination={pagination}
                resizable={true}
                rowHeight={50}
                rowStyle={defaultRowStyle}
                showCellBorders={'horizontal'}
                showColumnMenuTool={false}
                showEmptyRows={false}
                showZebraRows={false}
                skip={gridSkip}
                sortInfo={gridSortInfo ?? sortInfoC}
                style={gridStyle}
            />
        </Box>
    );
}
