import { COLORS } from '_global/Utils/Colors';
import { Grid, Typography } from '@mui/material';
import { ProcessedEvent } from 'models/events/ProcessedEvent';
import { EventSeverity } from 'models/graphql-global-types';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as HighVoltage } from '../../../../_global/Resources/assets/images/icons/high_voltage.svg';
import { ReactComponent as PowerOff } from '../../../../_global/Resources/assets/images/icons/power_off.svg';

export default function TypeCell(props: { data: ProcessedEvent }) {
    const { data } = props;

    const bgColor = useMemo(() => {
        switch (data.severity) {
            case EventSeverity.CRITICAL:
                return COLORS.ENEIDA_ALTERNATIVE_RED;
            case EventSeverity.WARNING:
                return COLORS.ENEIDA_ALTERNATIVE_YELLOW;
            case EventSeverity.INFO:
                return COLORS.ENEIDA_SIMPLE_BLUE;
            case EventSeverity.EMPTY:
                return COLORS.ENEIDA_GREY;
        }
    }, [data.severity]);

    return (
        <Grid container direction="row" spacing={1}>
            <Grid item>
                {String(data.type).toLowerCase().includes('voltage') ? (
                    <HighVoltage style={{ backgroundColor: bgColor, borderRadius: '50%' }} />
                ) : (
                    <PowerOff style={{ backgroundColor: bgColor, borderRadius: '50%' }} />
                )}
            </Grid>
            <Grid item>
                <Typography fontSize={14} sx={{ my: 1 }}>
                    <FormattedMessage id={String(data.type).toLowerCase()} />
                </Typography>
            </Grid>
        </Grid>
    );
}
