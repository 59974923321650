import { COLORS } from '_global/Utils/Colors';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export default function StatusCell(props: { isEnable: boolean }) {
    const { isEnable } = props;

    const backgroundColor = useMemo(() => {
        if (isEnable) return COLORS.ENEIDA_SIMPLE_LIGHT_GREEN;
        else return COLORS.ENEIDA_SIMPLE_LIGHT_RED;
    }, [isEnable]);

    const textColor = useMemo(() => {
        if (isEnable) return COLORS.ENEIDA_ALTERNATIVE_GREEN;
        else return COLORS.ENEIDA_ALTERNATIVE_RED;
    }, [isEnable]);

    const text = useMemo(() => {
        if (isEnable) return 'enable';
        else return 'disable';
    }, [isEnable]);

    return (
        <Box sx={{ textAlign: 'left' }}>
            <Typography
                sx={{
                    paddingX: '10px',
                    paddingY: '5px',
                    background: backgroundColor,
                    borderRadius: '25px',
                    maxWidth: '100px',
                    color: textColor
                }}
                variant="button"
            >
                <FormattedMessage id={text} />
            </Typography>
        </Box>
    );
}
