import { gql } from '@apollo/client';

export const ASSET_FEEDERS_BY_NAME = gql`
    query AssetFeedersByName($assetName: String) {
        assets(where: { name: { eq: $assetName } }) {
            nodes {
                id
                name
                feeders {
                    id
                    name
                }
            }
        }
    }
`;
