import { TypeColumn, TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import { Stack } from '@mui/material';
import { FilterType } from 'models/enums/FilterType';
import { columnsMap } from 'models/settings/RemoteConfiguration';
import { MutableRefObject, useState } from 'react';

import BarButtons from './BarButtons';

export type GridHandleType = (gridApiRef: MutableRefObject<TypeComputedProps | null>) => void;

export type DataFilterProps = {
    columns: TypeColumn[];
    onVisibilityChanged?: (columns: columnsMap) => void;
    onRefresh: () => void;
    enableFilter?: boolean;
    setEnableFilter?: (value: boolean) => void;
    numberActiveFilters?: number;
    saveSettings?: () => void;
    clearSettings?: () => void;
    gridRef: ((gridApiRef: MutableRefObject<TypeComputedProps | null>) => void) | undefined;
    userHasOptions: boolean;
};

export default function DataFilter(props: DataFilterProps) {
    const {
        onRefresh,
        onVisibilityChanged,
        columns,
        enableFilter,
        setEnableFilter,
        numberActiveFilters,
        saveSettings,
        clearSettings,
        gridRef,
        userHasOptions
    } = props;

    const [, setCurrentFilter] = useState(FilterType.NoFilter);

    return (
        <Stack direction="row" spacing={5}>
            <BarButtons
                initialColumns={columns}
                {...{ enableFilter, setEnableFilter, numberActiveFilters }}
                clearSettings={clearSettings}
                gridRef={gridRef}
                onRefresh={onRefresh}
                onSelection={(param) => {
                    setCurrentFilter(param);
                }}
                onVisibilityChanged={onVisibilityChanged}
                saveSettings={saveSettings}
                userHasOptions={userHasOptions}
            />
        </Stack>
    );
}
