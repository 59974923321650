import { Checkbox, FormControlLabel, FormGroup, List, ListItem, Paper } from '@mui/material';
import { columnsMap } from 'models/settings/RemoteConfiguration';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

type FilterFieldDropdownProps = {
    options: columnsMap;
    onOptionsChanged: (array: columnsMap) => void;
};

export default function FilterFieldDropdown(props: FilterFieldDropdownProps) {
    const { options, onOptionsChanged } = props;

    const checkedButtonsMemo = useMemo(() => {
        return [...options.entries()].filter((option) => option[1]).map((option) => option[0].name);
    }, [options]);

    const [internalOptions, setInternalOptions] = useState(options);
    const [checkedButtons, setCheckedButtons] = useState(checkedButtonsMemo);

    useEffect(() => {
        onOptionsChanged(internalOptions);
    }, [internalOptions, onOptionsChanged]);
    const intl = useIntl();

    return (
        <Paper>
            <List>
                {[...internalOptions.entries()]
                    .filter((option) => !option[0].name.includes('_'))
                    .map((option) => {
                        return (
                            <ListItem key={option[0].name}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checkedButtons.includes(option[0].name)}
                                                onChange={(event) => {
                                                    setInternalOptions(options.set(option[0], event.target.checked));

                                                    if (event.target.checked) {
                                                        const newArray = checkedButtons.filter((value) => value !== option[0].name);
                                                        newArray.push(option[0].name);

                                                        setCheckedButtons(newArray);
                                                    } else {
                                                        let newArray = checkedButtons;
                                                        newArray = newArray.filter((value) => value !== option[0].name);

                                                        setCheckedButtons(newArray);
                                                    }
                                                }}
                                            />
                                        }
                                        label={
                                            option[0].i18n !== ''
                                                ? intl.formatMessage({ id: option[0].i18n })
                                                : intl.formatMessage({ id: option[0].name })
                                        }
                                    />
                                    {}
                                </FormGroup>
                            </ListItem>
                        );
                    })}
            </List>
        </Paper>
    );
}
