import { modeAtomAssets, subTabAtomAssets } from '_global/Utils/hooks/jotai';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { useAtom } from 'jotai';
import { AssetPreviewTabValue } from 'models/asset/AssetPreview';
import { AssetResponse } from 'models/asset/AssetResponse';
import Mode from 'models/enums/ModeEnum';
import { ASSET_TYPE } from 'models/graphql-global-types';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import AssetDetails from '../AssetDetails';
import GlobalStateGrid from './global-states/GlobalStateGrid';
import HistoricalData from './historicaldata/HistoricalData';
import LastDataTable from './last-data/LastDataTable';
import Network from './network/Network';
import AssetOverview from './overview/AssetOverview';

enum ASSET_TYPE_TEMPORARY {
    LV_GROUND_SUBSTATION = 20,
    LV_POLE_MOUNTED = 21
}

export default function AssetContent(props: { asset: AssetResponse }) {
    const { asset } = props;
    const [mode] = useAtom(modeAtomAssets);
    const [, setCurrentSubTab] = useAtom(subTabAtomAssets);
    const typeAsset =
        asset.assetType === ASSET_TYPE.LV_GROUND_SUBSTATION ||
        asset.assetType === ASSET_TYPE.LV_POLE_MOUNTED ||
        Number(asset.assetType) === ASSET_TYPE_TEMPORARY.LV_GROUND_SUBSTATION ||
        Number(asset.assetType) === ASSET_TYPE_TEMPORARY.LV_POLE_MOUNTED;
    const [tabValue, setTabValue] = useState<string>(
        typeAsset ? AssetPreviewTabValue.OVERVIEW.toString() : AssetPreviewTabValue.DETAILS.toString()
    );
    const { pathname } = useLocation();

    const handleChange = useCallback((event: SyntheticEvent, tab: AssetPreviewTabValue) => {
        setTabValue(tab.toString());
    }, []);

    useEffect(() => {
        setCurrentSubTab(tabValue.toString());
    }, [tabValue]);

    useEffect(() => {
        if (pathname.split('/')[3] === 'edit' && tabValue !== AssetPreviewTabValue.DETAILS.toString()) {
            setTabValue(AssetPreviewTabValue.DETAILS.toString());
        }
    }, [pathname]);

    return (
        <TabContext value={tabValue.toString()}>
            {typeAsset ? (
                <TabList onChange={handleChange} value={tabValue.toString()}>
                    <Tab
                        disabled={mode === Mode.EDIT}
                        label={<FormattedMessage id={'overview'} />}
                        value={AssetPreviewTabValue.OVERVIEW.toString()}
                    />
                    <Tab
                        disabled={mode === Mode.EDIT}
                        label={<FormattedMessage id={'global-state'} />}
                        value={AssetPreviewTabValue.GLOBAL_STATE.toString()}
                    />
                    <Tab
                        disabled={mode === Mode.EDIT}
                        label={<FormattedMessage id={'last-data'} />}
                        value={AssetPreviewTabValue.LAST_DATA.toString()}
                    />
                    <Tab
                        disabled={mode === Mode.EDIT}
                        label={<FormattedMessage id={'historical-data'} />}
                        value={AssetPreviewTabValue.HISTORICAL_DATA.toString()}
                    />
                    <Tab
                        disabled={mode === Mode.EDIT}
                        label={<FormattedMessage id={'network'} />}
                        value={AssetPreviewTabValue.NETWORK.toString()}
                    />
                    <Tab
                        disabled={mode === Mode.EDIT}
                        label={<FormattedMessage id={'details'} />}
                        value={AssetPreviewTabValue.DETAILS.toString()}
                    />
                </TabList>
            ) : (
                <TabList onChange={handleChange} value={tabValue.toString()}>
                    <Tab label={<FormattedMessage id={'details'} />} value={AssetPreviewTabValue.DETAILS.toString()} />
                </TabList>
            )}
            <TabPanel value={AssetPreviewTabValue.OVERVIEW.toString()}>
                <AssetOverview asset={asset} />
            </TabPanel>
            <TabPanel value={AssetPreviewTabValue.GLOBAL_STATE.toString()}>
                <GlobalStateGrid />
            </TabPanel>
            <TabPanel value={AssetPreviewTabValue.LAST_DATA.toString()}>
                <LastDataTable id={asset.id} />
            </TabPanel>
            <TabPanel value={AssetPreviewTabValue.HISTORICAL_DATA.toString()}>
                <HistoricalData id={asset.id} />
            </TabPanel>
            <TabPanel value={AssetPreviewTabValue.NETWORK.toString()}>
                <Network id={asset.id} />
            </TabPanel>
            <TabPanel value={AssetPreviewTabValue.DETAILS.toString()}>
                <AssetDetails assetId={asset.id} />
            </TabPanel>
        </TabContext>
    );
}
