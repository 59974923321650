import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { AssetModel } from 'models/asset/AssetModel';
import { ASSET_TYPE } from 'models/graphql-global-types';
import { AssetType } from 'queries/Types/AssetType';
import { SubstationsFeedersByName } from 'queries/Types/SubstationsFeedersByName';
import { AssetInfoDataModel, AssetInfoImportDataModel, BasicDataModel } from 'views/Assets/utils/AssetModel';

export const reloadAssetTypeRequest = async (
    type: string,
    refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<AssetType>>,
    data: AssetType | undefined,
    setAssetInfoData?: React.Dispatch<React.SetStateAction<AssetInfoDataModel>>,
    setAssetInfoDataImport?: React.Dispatch<React.SetStateAction<AssetInfoImportDataModel>>
) => {
    let assetType = null;
    switch (type) {
        case ASSET_TYPE.DISTRIBUTION_BOX:
            assetType = {
                or: [
                    { assetType: { eq: 'DISTRIBUTION_BOX' } },
                    { assetType: { eq: 'LV_POLE_MOUNTED' } },
                    { assetType: { eq: 'LV_GROUND_SUBSTATION' } }
                ]
            };
            break;
        case ASSET_TYPE.EV_CHARGE_STATION:
            assetType = {
                or: [
                    { assetType: { eq: 'DISTRIBUTION_BOX' } },
                    { assetType: { eq: 'LV_POLE_MOUNTED' } },
                    { assetType: { eq: 'LV_GROUND_SUBSTATION' } }
                ]
            };
            break;
        case ASSET_TYPE.LV_POLE_MOUNTED:
            assetType = { and: [{ assetType: { eq: 'HV_SUBSTATION' } }] };
            break;
        case ASSET_TYPE.LV_GROUND_SUBSTATION:
            assetType = { and: [{ assetType: { eq: 'HV_SUBSTATION' } }] };
            break;
        case ASSET_TYPE.METER:
            assetType = {
                or: [
                    { assetType: { eq: 'DISTRIBUTION_BOX' } },
                    { assetType: { eq: 'LV_POLE_MOUNTED' } },
                    { assetType: { eq: 'LV_GROUND_SUBSTATION' } }
                ]
            };
            break;
        default:
            assetType = null;
    }

    if (assetType) {
        const response = await refetch({ assetType });
        const auxAssetParent: BasicDataModel[] = [];
        response?.data.assets?.edges?.map((el) => auxAssetParent.push(el.node));

        setAssetInfoData &&
            setAssetInfoData((_prevState) => ({
                ..._prevState,
                assetParent: auxAssetParent
            }));
        setAssetInfoDataImport &&
            setAssetInfoDataImport((_prevState) => ({
                ..._prevState,
                assetParent: auxAssetParent
            }));
        return;
    } else {
        setAssetInfoData && setAssetInfoData((old) => ({ ...old, assetParent: [] }));
        setAssetInfoDataImport && setAssetInfoDataImport((old) => ({ ...old, assetParent: [] }));
        return;
    }
};

export const reloadFeeders = async (
    assetDetails: AssetModel,
    feeders: SubstationsFeedersByName | undefined,
    refetchFeeders: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<SubstationsFeedersByName>>,
    setAssetInfoData: React.Dispatch<React.SetStateAction<AssetInfoDataModel>>
) => {
    await refetchFeeders({ feederName: assetDetails.parentAssetName });
    const auxFeeders: BasicDataModel[] = [];
    feeders?.assets?.nodes?.map((el) => auxFeeders.push(el));

    setAssetInfoData((_prevState) => ({
        ..._prevState,
        assetFeeder: auxFeeders
    }));
};
