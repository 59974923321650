import './Reports.scss';

import AlertRefreshBrowser from '_global/Components/base/AlertRefreshBrowser';
import SnackbarAlert from '_global/Components/base/extended/SnackbarAlert';
import AlertModal from '_global/Components/base/modals/AlertModal';
import PageHolder, { Action } from '_global/Components/base/page/PageHolder';
import { GenericTabHolder } from '_global/Components/base/tabs/GenericTabHolder';
import { useAppDispatch, useAppSelector } from '_global/Services/store/hooks';
import { addTab, removeTab } from '_global/Services/store/tabComponentSlice';
import { handleCurrenTab } from '_global/Utils/HandleCurrentTab';
import {
    filterAccessGroupsAtomReports,
    filterAssetAtomReports,
    filterCompaniesAtomReports,
    filterOpAreaAtomReports,
    filterRegionsAtomReports,
    modeAtomReports,
    setSelectedReportAtom,
    setSelectedReportAtomCustom,
    setSelectedReportAtomSystem,
    tabAtomCustomReports,
    tabAtomReports,
    tabAtomSystemReports
} from '_global/Utils/hooks/jotai';
import { IconToNode } from '_global/Utils/IconToNode';
import { useLazyQuery } from '@apollo/client';
import { faCheck, faFileChartPie, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { useAtom } from 'jotai';
import { Configuration } from 'logic/configuration/Configuration';
import { useConfiguration, useRemoteConfiguration } from 'logic/configuration/Hooks';
import MessageType from 'models/enums/MessageTypes';
import Mode from 'models/enums/ModeEnum';
import { IReportCreate, IReportDetails, TabValue } from 'models/report/ReportResponse';
import { IReport, IReportInstance, REPORT } from 'queries/Reports';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import ReportsPageInternal from 'views/Reports/ReportsPageInternal';

import ReportDetails from './ReportDetails';
import ReportsContent from './ReportsContent';
import ReportService from './utils/ReportService';

export enum EReportsPageType {
    ALL = 'all',
    SYSTEM = 'system',
    CUSTOM = 'custom'
}
interface ReportsPageProps {
    reportPageType?: EReportsPageType;
}

export default function Reports({ reportPageType = EReportsPageType.ALL }: ReportsPageProps) {
    const { pathname } = useLocation();
    const intl = useIntl();
    const [mode, setMode] = useAtom(modeAtomReports);
    const localConfig = useConfiguration();
    const [config] = useRemoteConfiguration();
    const dispatch = useAppDispatch();

    const setCurrentTabAtom = (reportPageType: EReportsPageType) => {
        switch (reportPageType) {
            case EReportsPageType.ALL:
                return tabAtomReports;
            case EReportsPageType.CUSTOM:
                return tabAtomCustomReports;
            case EReportsPageType.SYSTEM:
                return tabAtomSystemReports;
        }
    };

    const [currentTab, setCurrentTab] = useAtom(setCurrentTabAtom(reportPageType));
    const [currentSubTab, setCurrentSubTab] = useState<string>(TabValue.INFO.toString());
    const [stateModal, setStateModal] = useState({
        openModal: false,
        responseContent: '',
        responseTitle: '',
        type: MessageType.INFO
    });
    const { responseContent, responseTitle, openModal, type } = stateModal;

    const [stateToast, setStateToast] = useState({ openToast: false, toastMessage: '' });
    const { openToast, toastMessage } = stateToast;

    const setCustomSelectedReportAtom = (reportPageType: EReportsPageType) => {
        switch (reportPageType) {
            case EReportsPageType.ALL:
                return setSelectedReportAtom;
            case EReportsPageType.CUSTOM:
                return setSelectedReportAtomCustom;
            case EReportsPageType.SYSTEM:
                return setSelectedReportAtomSystem;
        }
    };
    const [selectedReport, setSelectedReport] = useAtom(setCustomSelectedReportAtom(reportPageType));
    const tabs = useAppSelector((state) => state.tabs.tabs);
    const [getReport] = useLazyQuery<IReport>(REPORT);

    const [, setFilterAssets] = useAtom(filterAssetAtomReports);
    const [, setFilterCompanies] = useAtom(filterCompaniesAtomReports);
    const [, setFilterRegions] = useAtom(filterRegionsAtomReports);
    const [, setFilterOpAreas] = useAtom(filterOpAreaAtomReports);
    const [, setFilterAccessGroups] = useAtom(filterAccessGroupsAtomReports);

    useEffect(() => {
        let existTab = false;
        tabs?.forEach((tab) => {
            if (tab.url === `/reports/${reportPageType}/add`) {
                existTab = true;
            }
        });
        if (!existTab) {
            setFilterAssets([{ name: '', operator: [], value: [] }]);
            setFilterCompanies([{ name: '', operator: [], value: [] }]);
            setFilterRegions([{ name: '', operator: [], value: [] }]);
            setFilterOpAreas([{ name: '', operator: [], value: [] }]);
            setFilterAccessGroups([{ name: '', operator: [], value: [] }]);
        }
    }, [tabs]);

    const loadState = () => {
        try {
            const serializedState = localStorage.getItem('reportDetails');
            if (serializedState === null) {
                return undefined;
            } else {
                return JSON.parse(serializedState) as IReportInstance | IReportCreate | IReportDetails;
            }
        } catch (err) {
            return undefined;
        }
    };

    useEffect(() => {
        if (mode !== Mode.EDIT && mode !== Mode.ADD) {
            localStorage.removeItem('reportDetails');
        } else {
            window.addEventListener('beforeunload', AlertRefreshBrowser);
            return () => {
                window.removeEventListener('beforeunload', AlertRefreshBrowser);
            };
        }
    }, [mode]);

    const onSubTabChange = useCallback((value: string) => {
        setCurrentSubTab(value);
    }, []);

    const submitReport = useCallback(async (report: IReportCreate, configuration: Configuration) => {
        const response = await ReportService.submitReport(report, configuration);
        if (response) {
            if (response.ok) {
                setStateToast({
                    openToast: true,
                    toastMessage: intl.formatMessage({ id: 'report_added_success' })
                });
                dispatch(removeTab({ removeName: 'add', removeUrl: undefined }));
                localStorage.removeItem('reportDetails');
                return Promise.resolve();
            } else {
                setStateModal({
                    openModal: true,
                    responseTitle: `${response.statusText} ${response.status}`,
                    responseContent: intl.formatMessage({ id: 'error_create_report' }),
                    type: MessageType.ERROR
                });
                return Promise.reject();
            }
        }
    }, []);

    const submitHandler = async () => {
        const report = loadState();
        if (localConfig && report) {
            const response = await submitReport(report, localConfig);
            return response;
        }
    };

    function addReportHandler() {
        dispatch(
            addTab({
                name: 'add',
                component: <ReportDetails onSubTabChange={(value) => onSubTabChange(value)} />,
                url: `/reports/${reportPageType}/add`
            })
        );
    }

    const getActions = useCallback(
        (mode: Mode): Action[] => {
            const routePermissions = config?.sidebar.routes.find((route) => route.path.includes('reports'));
            switch (mode) {
                case Mode.LIST:
                    if (routePermissions?.permissions.find((el) => el === 'Create')) {
                        return [
                            {
                                name: 'add_report',
                                icon: <IconToNode definition={faPlusCircle} />,
                                callback: () => {
                                    addReportHandler();
                                    setMode(Mode.ADD);
                                    setCurrentTab('add');
                                }
                            }
                        ];
                    }
                    return [];

                case Mode.ADD:
                    if (currentSubTab === TabValue.PREVIEW.toString() && routePermissions?.permissions.find((el) => el === 'Create')) {
                        return [{ name: 'submit', icon: <IconToNode definition={faCheck} />, callback: () => void submitHandler() }];
                    }
                    return [];

                default:
                    return [];
            }
        },
        [mode, currentSubTab, config]
    );

    useEffect(() => {
        handleCurrenTab({ tabs, setMode, currentTab });
    }, [currentTab, tabs]);

    useEffect(() => {
        if (selectedReport && selectedReport.name !== '') {
            const tab = Array.from(tabs).map(([name, value]) => ({ name: name, url: value.url ?? '' }));

            const existReport = tab.find((x) => x.name === selectedReport.name);

            if (existReport) {
                if (existReport.url.includes('edit')) {
                    setMode(Mode.EDIT);
                } else {
                    setMode(Mode.PREVIEW);
                }
            } else {
                dispatch(
                    addTab({
                        name: selectedReport.name,
                        component: <ReportsContent onSubTabChange={(value) => onSubTabChange(value)} reportId={selectedReport.id} />,
                        url: `/reports/${reportPageType}/${selectedReport.id}`
                    })
                );
                setMode(Mode.PREVIEW);
            }

            setCurrentTab(selectedReport.name);
            setSelectedReport(undefined);
        }
    }, [selectedReport]);

    const handleCloseModal = () => {
        setStateModal({ ...stateModal, openModal: false });
    };

    const handleCloseToast = () => {
        setStateToast({ ...stateToast, openToast: false });
    };

    const getPageHolderIcon = (reportsPageType: EReportsPageType) => {
        switch (reportsPageType) {
            case EReportsPageType.ALL:
                return faFileChartPie;
            default:
                return faFileChartPie;
        }
    };

    const showToast = (toastMessage: string) => {
        setStateToast({
            openToast: true,
            toastMessage: intl.formatMessage({ id: toastMessage })
        });
    };

    useEffect(() => {
        async function displayTab() {
            if (currentTab === 'List' && pathname.split('/').length > 2) {
                if (pathname.split('/')[3] === 'add') {
                    addReportHandler();
                } else if (pathname.split('/')[3] !== undefined) {
                    const { data: reportData, error: reportError } = await getReport({ variables: { reportId: pathname.split('/')[3] } });

                    if (reportData && reportData?.reports?.nodes) {
                        dispatch(
                            addTab({
                                name: reportData.reports.nodes[0].name,
                                component: (
                                    <ReportsContent onSubTabChange={(value) => onSubTabChange(value)} reportId={pathname.split('/')[3]} />
                                ),
                                url: `/reports/${pathname.split('/')[2]}/${pathname.split('/')[3]}${
                                    pathname.split('/')[4] === 'edit' ? `/${pathname.split('/')[4]}` : ''
                                }`
                            })
                        );
                        setCurrentTab(reportData.reports.nodes[0].name);
                    }

                    if (reportError) {
                        console.error(reportError);
                    }
                }
            }
        }
        void displayTab();
    }, []);

    useEffect(() => {
        const sessionTabs = sessionStorage.getItem('sessionTabs');

        if (sessionTabs) {
            const newSessionTabs = JSON.parse(sessionTabs).filter((url: string) => url.includes(`/${pathname.split('/')[1]}`));

            newSessionTabs.forEach(async (tabUrl: string) => {
                if (tabUrl.includes('add')) {
                    if (tabUrl.includes(reportPageType)) {
                        addReportHandler();
                    } else {
                        return;
                    }
                } else {
                    const idForEndpoint = tabUrl.split('/').pop();
                    if (tabUrl.split('/')[2] === reportPageType) {
                        const { data: reportData, error: reportError } = await getReport({ variables: { reportId: idForEndpoint } });

                        if (reportData && reportData?.reports?.nodes) {
                            dispatch(
                                addTab({
                                    name: reportData.reports.nodes[0].name,
                                    component: (
                                        <ReportsContent
                                            onSubTabChange={(value) => onSubTabChange(value)}
                                            reportId={pathname.split('/')[3]}
                                        />
                                    ),
                                    url: tabUrl
                                })
                            );
                        }

                        if (reportError) {
                            console.error(reportError);
                        }
                    }
                }
            });
        }
    }, []);

    return (
        <>
            <AlertModal isOpen={openModal} message={responseContent} onOk={handleCloseModal} title={responseTitle} type={type} />
            <SnackbarAlert message={toastMessage} onClose={handleCloseToast} open={openToast} type={MessageType.SUCCESS} />
            <PageHolder actions={getActions(mode)} icon={getPageHolderIcon(reportPageType)} name={intl.formatMessage({ id: 'reports' })}>
                <GenericTabHolder
                    currentTab={currentTab}
                    name={'List'}
                    onVisibilityChanged={(tab) => setCurrentTab(tab)}
                    setCurrentTab={setCurrentTab}
                    sx={{ backgroundColor: 'white' }}
                    url={`/reports/${reportPageType ?? 'all'}`}
                >
                    <ReportsPageInternal reportPageType={reportPageType} showToast={showToast} />
                </GenericTabHolder>
            </PageHolder>
        </>
    );
}
