import MainCard from '_global/Components/base/cards/MainCard';
import ModalMap from '_global/Components/base/map/modalMap/ModalMap';
import AlertModal from '_global/Components/base/modals/AlertModal';
import { getData } from '_global/Services/api/networkRequester';
import { RootState } from '_global/Services/store';
import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { useRemoteAccessGroupConfiguration } from 'logic/configuration/Hooks';
import { ASSET_TYPE_PROTECTED } from 'models/asset/AssetInfo';
import MessageType from 'models/enums/MessageTypes';
import { OperationalArea } from 'models/layout/OperationalArea';
import { ASSET_TYPE_QUERY } from 'queries/AssetType';
import { OPAREAS, OpAreas } from 'queries/OperationalAreas';
import { REGION } from 'queries/Region';
import { AssetType } from 'queries/Types/AssetType';
import { operationalAreas_operationalAreas_nodes } from 'queries/Types/operationalAreas';
import { RegionList } from 'queries/Types/RegionList';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import GeoProcessor from 'views/Settings/GeoProcessor';

import { AssetInfoDataModel, AssetProps, BasicDataModel } from '../utils/AssetModel';
import InfoDisplay from './AssetInfo/InfoDisplay';
import { reloadAssetTypeRequest } from './AssetInfo/InfoUtils';

const Info = (props: AssetProps) => {
    const { assetDetails, handleChange, handleOnChange, handleChangeNumber, handleChangeAssetType, handleChangeBool } = props;
    const features = useSelector((state: RootState) => state.features);

    const userAccessGroup = useRemoteAccessGroupConfiguration();

    const { data, refetch } = useQuery<AssetType>(ASSET_TYPE_QUERY);
    const { data: regionData } = useQuery<RegionList>(REGION, {
        variables: {
            filter: { id: { in: userAccessGroup.workingRegions ?? [] } },
            cursor: null,
            numberOfElements: 100
        }
    });
    const { data: opAreas } = useQuery<OpAreas>(OPAREAS, {
        variables: {
            filter: { id: { in: userAccessGroup.workingOPArea ?? [] } },
            cursor: null,
            numberOfElements: 100
        }
    });

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [assetInfoData, setAssetInfoData] = useState<AssetInfoDataModel>({
        assetType: [],
        assetParent: [],
        assetFeeder: [],
        optionsHvSubstation: [],
        optionsHvFeeder: [],
        optionsRegions: [],
        optionsOpArea: [],
        opAreaSelected: {
            initialPoint: {
                position: {
                    latitude: 40,
                    longitude: -8
                }
            },
            centerPoint: {
                position: {
                    latitude: 40,
                    longitude: -8
                }
            },
            operationalArea: null
        }
    });

    useEffect(() => {
        const opAreaSelected = opAreas?.operationalAreas?.nodes?.find(
            (opArea) => opArea && opArea.id !== 1 && opArea.id === assetDetails.operationalAreaId
        ) as OperationalArea;
        const initialLatitude: number = assetDetails.latitude;
        let centerLatitude: number;
        const initialLongitude: number = assetDetails.longitude;
        let centerLongitude: number;
        let opArea: OperationalArea;
        const centerPoints = GeoProcessor.centerPoint(opAreaSelected);

        if (centerPoints) {
            centerLatitude = centerPoints.geometry.coordinates[0];
            centerLongitude = centerPoints.geometry.coordinates[1];
            opArea = opAreaSelected;
            setAssetInfoData((_prevState) => ({
                ..._prevState,
                opAreaSelected: {
                    initialPoint: {
                        position: {
                            latitude: initialLatitude,
                            longitude: initialLongitude,
                            name: opAreaSelected.name
                        }
                    },
                    centerPoint: {
                        position: {
                            latitude: centerLatitude,
                            longitude: centerLongitude,
                            name: opAreaSelected.name
                        }
                    },
                    operationalArea: opArea
                }
            }));
        }
    }, [assetDetails]);

    const [stateModal, setStateModal] = useState({
        openModal: false,
        responseContent: '',
        responseTitle: '',
        type: MessageType.INFO
    });

    const { responseContent, responseTitle, openModal, type } = stateModal;

    const onClose = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (assetDetails.assetType !== 0) {
            let value = '';
            value = Object.entries(ASSET_TYPE_PROTECTED).find(([, val]) => val === assetDetails.assetType.toString())?.[0] ?? '';
            void reloadAssetTypeRequest(value, refetch, data, setAssetInfoData);
        }
    }, [assetDetails.assetType]);

    useEffect(() => {
        if (regionData && isEmpty(assetInfoData.optionsRegions)) {
            setAssetInfoData((_prevState) => ({
                ..._prevState,
                optionsRegions: regionData?.regions?.nodes
            }));
        }
    }, [assetInfoData, regionData]);

    const filterOpAreas = (nodes: (operationalAreas_operationalAreas_nodes | null)[] | null) => {
        const filteredOpAreas: (operationalAreas_operationalAreas_nodes | null)[] = [];
        nodes?.map((opArea) => {
            if (opArea?.regionId === assetDetails.regionId) {
                filteredOpAreas.push(opArea);
            }
        });

        return filteredOpAreas;
    };

    useEffect(() => {
        if (assetDetails.regionName !== '') {
            assetInfoData.optionsRegions?.find((region) => {
                if (region?.name === assetDetails.regionName) {
                    setAssetInfoData((_prevState) => ({
                        ..._prevState,
                        optionsOpArea: filterOpAreas(opAreas?.operationalAreas?.nodes ?? [])
                    }));
                }
            });
        }
    }, [assetDetails, opAreas, assetInfoData.optionsRegions]);

    useEffect(() => {
        const endpoint = `${features.configuration?.BaseUrl ?? ''}${'/equipments/api/Resource/asset_type'}`;

        if (endpoint !== undefined) {
            getData<BasicDataModel[]>(endpoint)
                .then((data) => {
                    setAssetInfoData((_prevState) => ({
                        ..._prevState,
                        assetType: data.filter((el) => el.id !== -1)
                    }));
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, []);

    function saveData(): void {
        localStorage.setItem('asset-info', JSON.stringify(assetInfoData));
    }

    const handleCloseModal = () => {
        setStateModal({ ...stateModal, openModal: false });
    };
    const handleCancelModal = () => {
        setStateModal({ ...stateModal, openModal: false });
    };
    const handleYesModal = () => {
        saveData();
        setStateModal({ ...stateModal, openModal: false });
    };

    return (
        <MainCard className="main-card" content={false} title={<FormattedMessage id={'details'} />}>
            <ModalMap assetInfoData={assetInfoData} handleChangeNumber={handleChangeNumber} isOpen={isOpen} onClose={onClose} />

            <AlertModal
                isOpen={openModal}
                message={responseContent}
                onCancel={handleCancelModal}
                onNo={handleYesModal}
                onOk={handleCloseModal}
                onYes={handleYesModal}
                title={responseTitle}
                type={type}
            />
            <InfoDisplay
                opAreas={(opAreas?.operationalAreas?.nodes as OperationalArea[]) ?? []}
                {...{
                    assetDetails,
                    assetInfoData,
                    handleChangeNumber,
                    handleOnChange,
                    handleChange,
                    isOpen,
                    setIsOpen,
                    handleChangeAssetType,
                    handleChangeBool
                }}
            />
        </MainCard>
    );
};

export default Info;
