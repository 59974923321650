import { Configuration } from 'logic/configuration/Configuration';
import { IReportCreate } from 'models/report/ReportResponse';

class ReportService {
    static async submitReport(report: IReportCreate, configuration: Configuration): Promise<Response | undefined> {
        try {
            const response = await fetch(`${configuration.BaseUrl}/api/report`, {
                method: 'POST',
                body: JSON.stringify(report),
                headers: { 'Content-Type': 'application/json' }
            });
            return response;
        } catch (e) {
            console.error;
        }
    }
    static async getAssetIds(
        accessGroupIds: number[],
        companiesIds: number[],
        regionsIds: number[],
        operationalAreasIds: number[],
        assetsIds: number[],
        configuration: Configuration
    ): Promise<Response | undefined> {
        try {
            const response = await fetch(`${configuration.BaseUrl}/layout/api/Asset`, {
                method: 'POST',
                body: JSON.stringify({
                    acessGroupsList: accessGroupIds,
                    companyList: companiesIds,
                    regionList: regionsIds,
                    opAreaList: operationalAreasIds,
                    assetList: assetsIds
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return response;
        } catch (e) {
            console.error;
        }
    }
}

export default ReportService;
