/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ALARM_GROUP_NAME {
  BROKEN_NEUTRAL = "BROKEN_NEUTRAL",
  BUSBAR_LINE_NULL_VOLTAGE = "BUSBAR_LINE_NULL_VOLTAGE",
  BUSBAR_OVER_VOLTAGE = "BUSBAR_OVER_VOLTAGE",
  BUSBAR_UNDER_VOLTAGE = "BUSBAR_UNDER_VOLTAGE",
  DEVICE_POWER_FAIL = "DEVICE_POWER_FAIL",
  FEEDER_LINE_FUSE_BLOWN = "FEEDER_LINE_FUSE_BLOWN",
  FEEDER_LINE_NULL_CURRENT = "FEEDER_LINE_NULL_CURRENT",
  FEEDER_LINE_OVERCURRENT = "FEEDER_LINE_OVERCURRENT",
  HV_LINE_DOWN = "HV_LINE_DOWN",
  HV_PHASE_LOSS = "HV_PHASE_LOSS",
  UINT = "UINT",
  VOLTAGE = "VOLTAGE",
  WFC = "WFC",
}

export enum ALARM_TYPE {
  BROKEN_NEUTRAL = "BROKEN_NEUTRAL",
  BUSBAR_LINE_NULL_VOLTAGE = "BUSBAR_LINE_NULL_VOLTAGE",
  BUSBAR_OVER_VOLTAGE = "BUSBAR_OVER_VOLTAGE",
  BUSBAR_UNDER_VOLTAGE = "BUSBAR_UNDER_VOLTAGE",
  DEVICE_POWER_FAIL = "DEVICE_POWER_FAIL",
  EMPTY = "EMPTY",
  FEEDER_LINE_FUSE_BLOWN = "FEEDER_LINE_FUSE_BLOWN",
  FEEDER_LINE_NULL_CURRENT = "FEEDER_LINE_NULL_CURRENT",
  FEEDER_LINE_OVERCURRENT = "FEEDER_LINE_OVERCURRENT",
  HV_LINE_DOWN = "HV_LINE_DOWN",
  HV_PHASE_LOSS = "HV_PHASE_LOSS",
  NEW_HV_LINE_DOWN = "NEW_HV_LINE_DOWN",
  UINT = "UINT",
  WFC = "WFC",
}

export enum ANCHOR_TYPE {
  DOUBLE = "DOUBLE",
  SINGLE = "SINGLE",
}

export enum APN_AUTH_TYPE {
  CHAP = "CHAP",
  NONE = "NONE",
  PAP = "PAP",
  PAP_OR_CHAP = "PAP_OR_CHAP",
}

export enum ASSET_STATE {
  ARCHIVED = "ARCHIVED",
  INSTALLED = "INSTALLED",
  NOT_INSTALLED = "NOT_INSTALLED",
}

export enum ASSET_TYPE {
  DISTRIBUTION_BOX = "DISTRIBUTION_BOX",
  ERROR = "ERROR",
  EV_CHARGE_STATION = "EV_CHARGE_STATION",
  HV_DISTRIBUTION_BOX = "HV_DISTRIBUTION_BOX",
  HV_POLE = "HV_POLE",
  HV_SUBSTATION = "HV_SUBSTATION",
  LV_GROUND_SUBSTATION = "LV_GROUND_SUBSTATION",
  LV_POLE_MOUNTED = "LV_POLE_MOUNTED",
  METER = "METER",
}

export enum CONNECTION_TYPE {
  LASHED_DOWN = "LASHED_DOWN",
  SUSPENDED = "SUSPENDED",
}

export enum CON_TEST_RESULT_TYPE {
  ERROR = "ERROR",
  NULL = "NULL",
  OK = "OK",
  UNKNOWN = "UNKNOWN",
  WAITING = "WAITING",
}

export enum DEVICE_TYPE {
  DTE = "DTE",
  DTVI4G = "DTVI4G",
  DTVI4GPMT = "DTVI4GPMT",
  DTVI4GPMT2 = "DTVI4GPMT2",
  DTVIG = "DTVIG",
  DTVIGPMT = "DTVIGPMT",
  DTVIGPMT2 = "DTVIGPMT2",
  DTVIM = "DTVIM",
  HVII = "HVII",
  INGRID90 = "INGRID90",
  INGRID91 = "INGRID91",
  INGRID92 = "INGRID92",
}

export enum ENTITY_TYPE {
  ASSET = "ASSET",
  ASSET_BUSBAR = "ASSET_BUSBAR",
  DEVICE = "DEVICE",
  FEEDER = "FEEDER",
}

export enum EQUIPMENT_TYPE {
  BASE_HV_POLE = "BASE_HV_POLE",
  DELIVERY_POINT = "DELIVERY_POINT",
  DISTRIBUTION_BOX = "DISTRIBUTION_BOX",
  HV_ISOLATOR = "HV_ISOLATOR",
  SUBSTATION = "SUBSTATION",
  TRANFORMER_HM = "TRANFORMER_HM",
  TRANSFORMER_ML = "TRANSFORMER_ML",
}

export enum EventDataStatus {
  RESOLVED = "RESOLVED",
  UNRESOLVED = "UNRESOLVED",
}

export enum EventEntityType {
  ASSET = "ASSET",
  ASSET_HV = "ASSET_HV",
  ASSET_HV_FEEDER = "ASSET_HV_FEEDER",
  ASSET_LV_BUSBAR = "ASSET_LV_BUSBAR",
  ASSET_LV_FEEDER = "ASSET_LV_FEEDER",
  ASSET_LV_LINE = "ASSET_LV_LINE",
  DEVICE = "DEVICE",
  EMPTY = "EMPTY",
}

export enum EventSeverity {
  CRITICAL = "CRITICAL",
  EMPTY = "EMPTY",
  INFO = "INFO",
  WARNING = "WARNING",
}

export enum EventStatus {
  ACKNOWLEDGE = "ACKNOWLEDGE",
  EMPTY = "EMPTY",
  RESOLVED = "RESOLVED",
  RESOLVING = "RESOLVING",
  UNRESOLVED = "UNRESOLVED",
}

export enum EventType {
  BROKEN_NEUTRAL = "BROKEN_NEUTRAL",
  BUSBAR_LINE_NULL_VOLTAGE = "BUSBAR_LINE_NULL_VOLTAGE",
  BUSBAR_OVER_VOLTAGE = "BUSBAR_OVER_VOLTAGE",
  BUSBAR_UNDER_VOLTAGE = "BUSBAR_UNDER_VOLTAGE",
  DEVICE_POWER_FAIL = "DEVICE_POWER_FAIL",
  DEVICE_REPORT = "DEVICE_REPORT",
  DEVICE_WITHOUT_COMMUNICATIONS = "DEVICE_WITHOUT_COMMUNICATIONS",
  DIP = "DIP",
  EMPTY = "EMPTY",
  FEEDER_LINE_CURRENT_LEVEL = "FEEDER_LINE_CURRENT_LEVEL",
  FEEDER_LINE_CURRENT_LEVEL_OPTIMIZATION = "FEEDER_LINE_CURRENT_LEVEL_OPTIMIZATION",
  FEEDER_LINE_FUSE_BLOWN = "FEEDER_LINE_FUSE_BLOWN",
  FEEDER_LINE_NULL_CURRENT = "FEEDER_LINE_NULL_CURRENT",
  FEEDER_LINE_OVERCURRENT = "FEEDER_LINE_OVERCURRENT",
  FEEDER_NEUTRAL_CURRENT_LEVEL = "FEEDER_NEUTRAL_CURRENT_LEVEL",
  HV_LINE_DOWN = "HV_LINE_DOWN",
  HV_PHASE_LOSS = "HV_PHASE_LOSS",
  NEW_HV_LINE_DOWN = "NEW_HV_LINE_DOWN",
  PLATFORM_FEEDER_LINE_NULL_CURRENT = "PLATFORM_FEEDER_LINE_NULL_CURRENT",
  RVC = "RVC",
  SDS_EARTHING_VOLTAGE_LEVEL = "SDS_EARTHING_VOLTAGE_LEVEL",
  SDS_MV_DEAD_SECTION = "SDS_MV_DEAD_SECTION",
  SDS_MV_DEAD_SECTION_VECT = "SDS_MV_DEAD_SECTION_VECT",
  SDS_PROTECTIVE_EARTH_LEVEL = "SDS_PROTECTIVE_EARTH_LEVEL",
  SDS_VOLTAGE_FREQ_VIOLATION = "SDS_VOLTAGE_FREQ_VIOLATION",
  SDS_VOLTAGE_PHASE_UNBALANCE = "SDS_VOLTAGE_PHASE_UNBALANCE",
  SDS_VOLTAGE_UNBALANCE = "SDS_VOLTAGE_UNBALANCE",
  SDS_VOLTAGE_VIOLATION = "SDS_VOLTAGE_VIOLATION",
  SWELL = "SWELL",
  UINT = "UINT",
  WFC = "WFC",
}

export enum ExpirationType {
  MONTH = "MONTH",
  NEVER = "NEVER",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

export enum FEEDER_TYPE {
  BUSBAR = "BUSBAR",
  EARTH_PROTECTION_LINE = "EARTH_PROTECTION_LINE",
  EARTH_SERVICE_LINE = "EARTH_SERVICE_LINE",
  HV_FEEDER = "HV_FEEDER",
  NEUTRAL_LINE = "NEUTRAL_LINE",
  POWERBUSLINE = "POWERBUSLINE",
}

export enum FIRMWARE_TASK_STATUS {
  CANCELED = "CANCELED",
  DONE = "DONE",
  ERROR = "ERROR",
  UPDATING = "UPDATING",
}

export enum INPUT_TYPE {
  ALL = "ALL",
  EARTH = "EARTH",
  EARTH_PROTECTION = "EARTH_PROTECTION",
  EARTH_SERVICE = "EARTH_SERVICE",
  L1 = "L1",
  L2 = "L2",
  L3 = "L3",
  NEUTRAL = "NEUTRAL",
  NOT_DEFINED = "NOT_DEFINED",
}

export enum INSTALLATION_TYPE {
  DOWNSTREAM = "DOWNSTREAM",
  UPSTREAM = "UPSTREAM",
  VERTICAL = "VERTICAL",
}

export enum INTERFACE_TYPE {
  BLUETOOTH = "BLUETOOTH",
  ETHERNET = "ETHERNET",
  GPRS = "GPRS",
  LO_RA = "LO_RA",
}

export enum LASTCONTACT_TYPE {
  ALARM = "ALARM",
  ALARM_SET = "ALARM_SET",
  ALARM_SETTINGS = "ALARM_SETTINGS",
  ALARM_STATUS = "ALARM_STATUS",
  DTVI_STATE_FIELD = "DTVI_STATE_FIELD",
  FIRMWARE = "FIRMWARE",
  LEGACY_STATE_FIELD = "LEGACY_STATE_FIELD",
  LOG_PARAMETER = "LOG_PARAMETER",
  RAW_DATA = "RAW_DATA",
  STATE_ALARM = "STATE_ALARM",
  STATE_ALARM_ACK = "STATE_ALARM_ACK",
  STATE_FIELD = "STATE_FIELD",
  STATE_FIELD_ACK = "STATE_FIELD_ACK",
  STATE_INSTALLATION = "STATE_INSTALLATION",
  STATE_PARAMETER = "STATE_PARAMETER",
  STATE_UNINSTALLATION = "STATE_UNINSTALLATION",
  TEST_RESULT = "TEST_RESULT",
  UNKNOWN = "UNKNOWN",
}

export enum MATERIAL_TYPE {
  COMPOSITE = "COMPOSITE",
  GLASS = "GLASS",
}

export enum MICRO_CONTROLLER_TYPE {
  COM = "COM",
  DOCKER = "DOCKER",
  ESP = "ESP",
  MEA = "MEA",
  MSP = "MSP",
  PIC = "PIC",
}

export enum MenuType {
  COLLAPSE = "COLLAPSE",
  ITEM = "ITEM",
}

export enum PHASE_ROTATION {
  ANTICLOCKWISE = "ANTICLOCKWISE",
  CLOCKWISE = "CLOCKWISE",
  UNDEFINED = "UNDEFINED",
}

export enum PHASE_TYPE {
  BIPHASIC = "BIPHASIC",
  MONOPHASIC = "MONOPHASIC",
  TRIPHASIC = "TRIPHASIC",
}

export enum PORT_TYPE {
  ENERGY_METER = "ENERGY_METER",
  ENERGY_METER_NEUTRAL = "ENERGY_METER_NEUTRAL",
  GENERIC = "GENERIC",
  IMPEDANCE_METER = "IMPEDANCE_METER",
}

export enum PROTOCOL_TYPE {
  BLUETOOTH = "BLUETOOTH",
  DNP3 = "DNP3",
  ENEIDA_LO_RA = "ENEIDA_LO_RA",
  HTTP = "HTTP",
  MQTT = "MQTT",
}

export enum REGION {
  EUROPE = "EUROPE",
}

export enum SEVERITY {
  CRITICAL = "CRITICAL",
  INFO = "INFO",
  WARNING = "WARNING",
}

export enum SIM_STATUS_TYPE {
  AVAILABLE = "AVAILABLE",
  DAMAGED = "DAMAGED",
  USED = "USED",
}

export enum SOURCE {
  DEEPGRID = "DEEPGRID",
  DEVICE = "DEVICE",
}

export enum SortEnumType {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TEMPLATE_MESSAGE_TYPE {
  HTML = "HTML",
  JSON = "JSON",
  NAME = "NAME",
  TEXT = "TEXT",
}

export enum TIME_SERVER_TYPE {
  DEEPGRID = "DEEPGRID",
  HTP = "HTP",
  NTP = "NTP",
}

export enum TRIGGER_TYPE {
  CONTROL = "CONTROL",
  EXPORT_SCADA = "EXPORT_SCADA",
  SEND_EMAIL = "SEND_EMAIL",
  SEND_SMS = "SEND_SMS",
  SEND_WEBHOOK = "SEND_WEBHOOK",
  SYSTEM_MESSAGE = "SYSTEM_MESSAGE",
}

export enum TokenType {
  APPLICATION = "APPLICATION",
  DEVICE = "DEVICE",
  USER = "USER",
}

export interface ALARM_GROUP_NAMEOperationFilterInput {
  eq?: ALARM_GROUP_NAME | null;
  neq?: ALARM_GROUP_NAME | null;
  in?: ALARM_GROUP_NAME[] | null;
  nin?: ALARM_GROUP_NAME[] | null;
}

export interface ALARM_TYPEOperationFilterInput {
  eq?: ALARM_TYPE | null;
  neq?: ALARM_TYPE | null;
  in?: ALARM_TYPE[] | null;
  nin?: ALARM_TYPE[] | null;
}

export interface APN_AUTH_TYPEOperationFilterInput {
  eq?: APN_AUTH_TYPE | null;
  neq?: APN_AUTH_TYPE | null;
  in?: APN_AUTH_TYPE[] | null;
  nin?: APN_AUTH_TYPE[] | null;
}

export interface ASSET_STATEOperationFilterInput {
  eq?: ASSET_STATE | null;
  neq?: ASSET_STATE | null;
  in?: ASSET_STATE[] | null;
  nin?: ASSET_STATE[] | null;
}

export interface ASSET_TYPEOperationFilterInput {
  eq?: ASSET_TYPE | null;
  neq?: ASSET_TYPE | null;
  in?: ASSET_TYPE[] | null;
  nin?: ASSET_TYPE[] | null;
}

export interface AccessGroupFilterInput {
  and?: AccessGroupFilterInput[] | null;
  or?: AccessGroupFilterInput[] | null;
  externalId?: ComparableInt64OperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  firmwareTasks?: ListFilterInputTypeOfFirmwareTaskFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
  isEnabled?: BooleanOperationFilterInput | null;
  isProtected?: BooleanOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  isDefault?: BooleanOperationFilterInput | null;
  regions?: ListFilterInputTypeOfRegionFilterInput | null;
  operationalAreas?: ListFilterInputTypeOfOperationalAreaFilterInput | null;
  workingOperationalAreas?: ListFilterInputTypeOfOperationalAreaFilterInput | null;
  companies?: ListFilterInputTypeOfCompanyFilterInput | null;
  assets?: ListFilterInputTypeOfAssetFilterInput | null;
  users?: ListFilterInputTypeOfUserFilterInput | null;
  enterprises?: ListFilterInputTypeOfEnterpriseFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
}

export interface AccessGroupSortInput {
  name?: SortEnumType | null;
  isEnabled?: SortEnumType | null;
  isProtected?: SortEnumType | null;
  description?: SortEnumType | null;
  isDefault?: SortEnumType | null;
  createdBy?: SortEnumType | null;
  createdDate?: SortEnumType | null;
  lastModifiedBy?: SortEnumType | null;
  lastModifiedDate?: SortEnumType | null;
  id?: SortEnumType | null;
}

export interface AlarmStandardDefinitionFilterInput {
  and?: AlarmStandardDefinitionFilterInput[] | null;
  or?: AlarmStandardDefinitionFilterInput[] | null;
  alarmStandardId?: ComparableNullableOfInt64OperationFilterInput | null;
  alarmTypeId?: ComparableNullableOfInt64OperationFilterInput | null;
  enable?: BooleanOperationFilterInput | null;
  alarmStandard?: AlarmStandardFilterInput | null;
  alarmType?: AlarmTypeFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface AlarmStandardFilterInput {
  and?: AlarmStandardFilterInput[] | null;
  or?: AlarmStandardFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  editable?: BooleanOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  alarmStandardDefinitions?: ListFilterInputTypeOfAlarmStandardDefinitionFilterInput | null;
  alarmStandardVariableDefinitions?: ListFilterInputTypeOfAlarmStandardVariableDefinitionFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
  externalId?: ComparableInt64OperationFilterInput | null;
  assets?: ListFilterInputTypeOfAssetFilterInput | null;
  feeders?: ListFilterInputTypeOfFeederFilterInput | null;
  device?: ListFilterInputTypeOfDeviceFilterInput | null;
}

export interface AlarmStandardSortInput {
  name?: SortEnumType | null;
  editable?: SortEnumType | null;
  description?: SortEnumType | null;
  createdBy?: SortEnumType | null;
  createdDate?: SortEnumType | null;
  lastModifiedBy?: SortEnumType | null;
  lastModifiedDate?: SortEnumType | null;
  id?: SortEnumType | null;
  externalId?: SortEnumType | null;
}

export interface AlarmStandardVariableDefinitionFilterInput {
  and?: AlarmStandardVariableDefinitionFilterInput[] | null;
  or?: AlarmStandardVariableDefinitionFilterInput[] | null;
  alarmStandardId?: ComparableNullableOfInt64OperationFilterInput | null;
  alarmVariableId?: ComparableNullableOfInt64OperationFilterInput | null;
  value?: StringOperationFilterInput | null;
  alarmStandard?: AlarmStandardFilterInput | null;
  alarmVariable?: AlarmVariableFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface AlarmTypeFilterInput {
  and?: AlarmTypeFilterInput[] | null;
  or?: AlarmTypeFilterInput[] | null;
  type?: ALARM_TYPEOperationFilterInput | null;
  source?: SOURCEOperationFilterInput | null;
  entityType?: ENTITY_TYPEOperationFilterInput | null;
  defaultStatus?: BooleanOperationFilterInput | null;
  alarmStandardDefinitions?: ListFilterInputTypeOfAlarmStandardDefinitionFilterInput | null;
  eventActions?: ListFilterInputTypeOfEventActionFilterInput | null;
  alarmVariables?: ListFilterInputTypeOfAlarmVariableFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface AlarmVariableFilterInput {
  and?: AlarmVariableFilterInput[] | null;
  or?: AlarmVariableFilterInput[] | null;
  alarmTypeId?: ComparableNullableOfInt64OperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  deviceName?: StringOperationFilterInput | null;
  type?: StringOperationFilterInput | null;
  defaultValue?: StringOperationFilterInput | null;
  jsonConstrains?: StringOperationFilterInput | null;
  groupName?: ALARM_GROUP_NAMEOperationFilterInput | null;
  region?: REGIONOperationFilterInput | null;
  alarmType?: AlarmTypeFilterInput | null;
  alarmStandardVariableDefinitions?: ListFilterInputTypeOfAlarmStandardVariableDefinitionFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface ApplicationFilterInput {
  and?: ApplicationFilterInput[] | null;
  or?: ApplicationFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  url?: StringOperationFilterInput | null;
  authorizationTokens?: ListFilterInputTypeOfAuthorizationTokenFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface AssetFilterInput {
  and?: AssetFilterInput[] | null;
  or?: AssetFilterInput[] | null;
  parentAssetId?: ComparableNullableOfInt64OperationFilterInput | null;
  parentFeederId?: ComparableNullableOfInt64OperationFilterInput | null;
  parentAssetName?: StringOperationFilterInput | null;
  parentFeederName?: StringOperationFilterInput | null;
  transformerId?: ComparableNullableOfInt64OperationFilterInput | null;
  operationalAreaId?: ComparableNullableOfInt64OperationFilterInput | null;
  regionId?: ComparableNullableOfInt64OperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  registryNumber?: StringOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  isEnabled?: BooleanOperationFilterInput | null;
  assetType?: ASSET_TYPEOperationFilterInput | null;
  state?: ASSET_STATEOperationFilterInput | null;
  alarmStandardId?: ComparableNullableOfInt64OperationFilterInput | null;
  alarmStandardName?: StringOperationFilterInput | null;
  latitude?: ComparableNullableOfDoubleOperationFilterInput | null;
  longitude?: ComparableNullableOfDoubleOperationFilterInput | null;
  alarmStandard?: AlarmStandardFilterInput | null;
  operationalArea?: OperationalAreaFilterInput | null;
  equipBulkConfiguration?: EquipBulkConfigurationFilterInput | null;
  region?: RegionFilterInput | null;
  equipBulkConfigurationId?: ComparableNullableOfInt64OperationFilterInput | null;
  equipBulkConfigurationName?: StringOperationFilterInput | null;
  hasAbscenceOfVoltage?: BooleanOperationFilterInput | null;
  hasHvLineDown?: BooleanOperationFilterInput | null;
  xGeometricPosition?: ComparableInt32OperationFilterInput | null;
  yGeometricPosition?: ComparableInt32OperationFilterInput | null;
  devices?: ListFilterInputTypeOfDeviceFilterInput | null;
  deviceLogs?: ListFilterInputTypeOfDeviceLogFilterInput | null;
  equipments?: ListFilterInputTypeOfEquipmentFilterInput | null;
  feeders?: ListFilterInputTypeOfFeederFilterInput | null;
  masterBusLines?: ListFilterInputTypeOfMasterBusLineFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
  externalAssetId?: ComparableInt64OperationFilterInput | null;
  registrationNumber?: StringOperationFilterInput | null;
  accessGroups?: ListFilterInputTypeOfAccessGroupFilterInput | null;
  gpsPoint?: GpsPointFilterInput | null;
}

export interface AssetSortInput {
  parentAssetId?: SortEnumType | null;
  parentFeederId?: SortEnumType | null;
  parentAssetName?: SortEnumType | null;
  parentFeederName?: SortEnumType | null;
  transformerId?: SortEnumType | null;
  operationalAreaId?: SortEnumType | null;
  regionId?: SortEnumType | null;
  name?: SortEnumType | null;
  registryNumber?: SortEnumType | null;
  description?: SortEnumType | null;
  isEnabled?: SortEnumType | null;
  assetType?: SortEnumType | null;
  state?: SortEnumType | null;
  alarmStandardId?: SortEnumType | null;
  alarmStandardName?: SortEnumType | null;
  latitude?: SortEnumType | null;
  longitude?: SortEnumType | null;
  alarmStandard?: AlarmStandardSortInput | null;
  operationalArea?: OperationalAreaSortInput | null;
  equipBulkConfiguration?: EquipBulkConfigurationSortInput | null;
  region?: RegionSortInput | null;
  equipBulkConfigurationId?: SortEnumType | null;
  equipBulkConfigurationName?: SortEnumType | null;
  hasAbscenceOfVoltage?: SortEnumType | null;
  hasHvLineDown?: SortEnumType | null;
  xGeometricPosition?: SortEnumType | null;
  yGeometricPosition?: SortEnumType | null;
  createdBy?: SortEnumType | null;
  createdDate?: SortEnumType | null;
  lastModifiedBy?: SortEnumType | null;
  lastModifiedDate?: SortEnumType | null;
  id?: SortEnumType | null;
  externalAssetId?: SortEnumType | null;
  registrationNumber?: SortEnumType | null;
  gpsPoint?: GpsPointSortInput | null;
}

export interface AuthorizationFilterInput {
  and?: AuthorizationFilterInput[] | null;
  or?: AuthorizationFilterInput[] | null;
  method?: StringOperationFilterInput | null;
  scope?: StringOperationFilterInput | null;
  roles?: ListFilterInputTypeOfRoleFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface AuthorizationTokenFilterInput {
  and?: AuthorizationTokenFilterInput[] | null;
  or?: AuthorizationTokenFilterInput[] | null;
  token?: StringOperationFilterInput | null;
  guid?: StringOperationFilterInput | null;
  key?: StringOperationFilterInput | null;
  expirationType?: ExpirationTypeOperationFilterInput | null;
  tokenType?: TokenTypeOperationFilterInput | null;
  expirationDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  userId?: ComparableNullableOfInt64OperationFilterInput | null;
  applicationId?: ComparableNullableOfInt64OperationFilterInput | null;
  user?: UserFilterInput | null;
  application?: ApplicationFilterInput | null;
  devices?: ListFilterInputTypeOfDeviceFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface BatchDeviceFilterInput {
  and?: BatchDeviceFilterInput[] | null;
  or?: BatchDeviceFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  batchIncludes?: ListFilterInputTypeOfBatchIncludeFilterInput | null;
  devices?: ListFilterInputTypeOfDeviceFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface BatchDeviceSortInput {
  name?: SortEnumType | null;
  createdBy?: SortEnumType | null;
  createdDate?: SortEnumType | null;
  lastModifiedBy?: SortEnumType | null;
  lastModifiedDate?: SortEnumType | null;
  id?: SortEnumType | null;
}

export interface BatchIncludeFilterInput {
  and?: BatchIncludeFilterInput[] | null;
  or?: BatchIncludeFilterInput[] | null;
  conTestId?: ComparableInt64OperationFilterInput | null;
  conTest?: ConTestFilterInput | null;
  batchSimId?: ComparableInt64OperationFilterInput | null;
  batchSim?: BatchSimFilterInput | null;
  batchDeviceId?: ComparableInt64OperationFilterInput | null;
  batchDevice?: BatchDeviceFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface BatchSimFilterInput {
  and?: BatchSimFilterInput[] | null;
  or?: BatchSimFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  simCards?: ListFilterInputTypeOfSimCardFilterInput | null;
  batchIncludes?: ListFilterInputTypeOfBatchIncludeFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface BatchSimSortInput {
  name?: SortEnumType | null;
  createdBy?: SortEnumType | null;
  createdDate?: SortEnumType | null;
  lastModifiedBy?: SortEnumType | null;
  lastModifiedDate?: SortEnumType | null;
  id?: SortEnumType | null;
}

export interface BooleanOperationFilterInput {
  eq?: boolean | null;
  neq?: boolean | null;
}

export interface CON_TEST_RESULT_TYPEOperationFilterInput {
  eq?: CON_TEST_RESULT_TYPE | null;
  neq?: CON_TEST_RESULT_TYPE | null;
  in?: CON_TEST_RESULT_TYPE[] | null;
  nin?: CON_TEST_RESULT_TYPE[] | null;
}

export interface CableTypeFilterInput {
  and?: CableTypeFilterInput[] | null;
  or?: CableTypeFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  coreSection?: ComparableSingleOperationFilterInput | null;
  cores?: ComparableInt32OperationFilterInput | null;
  color?: StringOperationFilterInput | null;
  fuseWay?: ComparableNullableOfSingleOperationFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface CableTypeSortInput {
  name?: SortEnumType | null;
  coreSection?: SortEnumType | null;
  cores?: SortEnumType | null;
  color?: SortEnumType | null;
  fuseWay?: SortEnumType | null;
  createdBy?: SortEnumType | null;
  createdDate?: SortEnumType | null;
  lastModifiedBy?: SortEnumType | null;
  lastModifiedDate?: SortEnumType | null;
  id?: SortEnumType | null;
}

export interface CompanyFilterInput {
  and?: CompanyFilterInput[] | null;
  or?: CompanyFilterInput[] | null;
  externalId?: ComparableInt64OperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  firmwareTasks?: ListFilterInputTypeOfFirmwareTaskFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  image?: StringOperationFilterInput | null;
  website?: StringOperationFilterInput | null;
  country?: StringOperationFilterInput | null;
  gpsPoints?: ListFilterInputTypeOfGpsPointFilterInput | null;
  regions?: ListFilterInputTypeOfRegionFilterInput | null;
  accessGroups?: ListFilterInputTypeOfAccessGroupFilterInput | null;
  users?: ListFilterInputTypeOfUserFilterInput | null;
  enterpriseId?: ComparableInt64OperationFilterInput | null;
  enterprise?: EnterpriseFilterInput | null;
}

export interface CompanySortInput {
  name?: SortEnumType | null;
  description?: SortEnumType | null;
  image?: SortEnumType | null;
  website?: SortEnumType | null;
  country?: SortEnumType | null;
  enterpriseId?: SortEnumType | null;
  enterprise?: EnterpriseSortInput | null;
  id?: SortEnumType | null;
}

export interface ComparableDateTimeOperationFilterInput {
  eq?: any | null;
  neq?: any | null;
  in?: any[] | null;
  nin?: any[] | null;
  gt?: any | null;
  ngt?: any | null;
  gte?: any | null;
  ngte?: any | null;
  lt?: any | null;
  nlt?: any | null;
  lte?: any | null;
  nlte?: any | null;
}

export interface ComparableDoubleOperationFilterInput {
  eq?: number | null;
  neq?: number | null;
  in?: number[] | null;
  nin?: number[] | null;
  gt?: number | null;
  ngt?: number | null;
  gte?: number | null;
  ngte?: number | null;
  lt?: number | null;
  nlt?: number | null;
  lte?: number | null;
  nlte?: number | null;
}

export interface ComparableInt32OperationFilterInput {
  eq?: number | null;
  neq?: number | null;
  in?: number[] | null;
  nin?: number[] | null;
  gt?: number | null;
  ngt?: number | null;
  gte?: number | null;
  ngte?: number | null;
  lt?: number | null;
  nlt?: number | null;
  lte?: number | null;
  nlte?: number | null;
}

export interface ComparableInt64OperationFilterInput {
  eq?: any | null;
  neq?: any | null;
  in?: any[] | null;
  nin?: any[] | null;
  gt?: any | null;
  ngt?: any | null;
  gte?: any | null;
  ngte?: any | null;
  lt?: any | null;
  nlt?: any | null;
  lte?: any | null;
  nlte?: any | null;
}

export interface ComparableNullableOfDateTimeOperationFilterInput {
  eq?: any | null;
  neq?: any | null;
  in?: (any | null)[] | null;
  nin?: (any | null)[] | null;
  gt?: any | null;
  ngt?: any | null;
  gte?: any | null;
  ngte?: any | null;
  lt?: any | null;
  nlt?: any | null;
  lte?: any | null;
  nlte?: any | null;
}

export interface ComparableNullableOfDoubleOperationFilterInput {
  eq?: number | null;
  neq?: number | null;
  in?: (number | null)[] | null;
  nin?: (number | null)[] | null;
  gt?: number | null;
  ngt?: number | null;
  gte?: number | null;
  ngte?: number | null;
  lt?: number | null;
  nlt?: number | null;
  lte?: number | null;
  nlte?: number | null;
}

export interface ComparableNullableOfInt32OperationFilterInput {
  eq?: number | null;
  neq?: number | null;
  in?: (number | null)[] | null;
  nin?: (number | null)[] | null;
  gt?: number | null;
  ngt?: number | null;
  gte?: number | null;
  ngte?: number | null;
  lt?: number | null;
  nlt?: number | null;
  lte?: number | null;
  nlte?: number | null;
}

export interface ComparableNullableOfInt64OperationFilterInput {
  eq?: any | null;
  neq?: any | null;
  in?: (any | null)[] | null;
  nin?: (any | null)[] | null;
  gt?: any | null;
  ngt?: any | null;
  gte?: any | null;
  ngte?: any | null;
  lt?: any | null;
  nlt?: any | null;
  lte?: any | null;
  nlte?: any | null;
}

export interface ComparableNullableOfSingleOperationFilterInput {
  eq?: number | null;
  neq?: number | null;
  in?: (number | null)[] | null;
  nin?: (number | null)[] | null;
  gt?: number | null;
  ngt?: number | null;
  gte?: number | null;
  ngte?: number | null;
  lt?: number | null;
  nlt?: number | null;
  lte?: number | null;
  nlte?: number | null;
}

export interface ComparableSingleOperationFilterInput {
  eq?: number | null;
  neq?: number | null;
  in?: number[] | null;
  nin?: number[] | null;
  gt?: number | null;
  ngt?: number | null;
  gte?: number | null;
  ngte?: number | null;
  lt?: number | null;
  nlt?: number | null;
  lte?: number | null;
  nlte?: number | null;
}

export interface ConTestDeviceResultFilterInput {
  and?: ConTestDeviceResultFilterInput[] | null;
  or?: ConTestDeviceResultFilterInput[] | null;
  conTestId?: ComparableInt64OperationFilterInput | null;
  conTest?: ConTestFilterInput | null;
  deviceId?: ComparableInt64OperationFilterInput | null;
  device?: DeviceFilterInput | null;
  result?: CON_TEST_RESULT_TYPEOperationFilterInput | null;
  resultLog?: StringOperationFilterInput | null;
  externalResult?: CON_TEST_RESULT_TYPEOperationFilterInput | null;
  externalResultTime?: ComparableNullableOfDateTimeOperationFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface ConTestExtendedFilterInput {
  and?: ConTestExtendedFilterInput[] | null;
  or?: ConTestExtendedFilterInput[] | null;
  testedDevices?: ComparableInt64OperationFilterInput | null;
  remainingDevices?: ComparableInt64OperationFilterInput | null;
  remainingSimCards?: ComparableInt64OperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  hasConnectivity?: BooleanOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  checkTimeOut?: ComparableNullableOfInt32OperationFilterInput | null;
  conTestDeviceResults?: ListFilterInputTypeOfConTestDeviceResultFilterInput | null;
  batchIncludes?: ListFilterInputTypeOfBatchIncludeFilterInput | null;
  deviceBulkConfiguration?: DeviceBulkConfigurationFilterInput | null;
  deviceBulkConfigurationId?: ComparableInt64OperationFilterInput | null;
  isDeleted?: BooleanOperationFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface ConTestExtendedSortInput {
  testedDevices?: SortEnumType | null;
  remainingDevices?: SortEnumType | null;
  remainingSimCards?: SortEnumType | null;
  name?: SortEnumType | null;
  hasConnectivity?: SortEnumType | null;
  description?: SortEnumType | null;
  checkTimeOut?: SortEnumType | null;
  deviceBulkConfiguration?: DeviceBulkConfigurationSortInput | null;
  deviceBulkConfigurationId?: SortEnumType | null;
  isDeleted?: SortEnumType | null;
  createdBy?: SortEnumType | null;
  createdDate?: SortEnumType | null;
  lastModifiedBy?: SortEnumType | null;
  lastModifiedDate?: SortEnumType | null;
  id?: SortEnumType | null;
}

export interface ConTestFilterInput {
  and?: ConTestFilterInput[] | null;
  or?: ConTestFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  hasConnectivity?: BooleanOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  checkTimeOut?: ComparableNullableOfInt32OperationFilterInput | null;
  conTestDeviceResults?: ListFilterInputTypeOfConTestDeviceResultFilterInput | null;
  batchIncludes?: ListFilterInputTypeOfBatchIncludeFilterInput | null;
  deviceBulkConfiguration?: DeviceBulkConfigurationFilterInput | null;
  deviceBulkConfigurationId?: ComparableInt64OperationFilterInput | null;
  isDeleted?: BooleanOperationFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface ConTestSortInput {
  name?: SortEnumType | null;
  hasConnectivity?: SortEnumType | null;
  description?: SortEnumType | null;
  checkTimeOut?: SortEnumType | null;
  deviceBulkConfiguration?: DeviceBulkConfigurationSortInput | null;
  deviceBulkConfigurationId?: SortEnumType | null;
  isDeleted?: SortEnumType | null;
  createdBy?: SortEnumType | null;
  createdDate?: SortEnumType | null;
  lastModifiedBy?: SortEnumType | null;
  lastModifiedDate?: SortEnumType | null;
  id?: SortEnumType | null;
}

export interface ContactFilterInput {
  and?: ContactFilterInput[] | null;
  or?: ContactFilterInput[] | null;
  firstName?: StringOperationFilterInput | null;
  lastName?: StringOperationFilterInput | null;
  email?: StringOperationFilterInput | null;
  phone?: StringOperationFilterInput | null;
  url?: StringOperationFilterInput | null;
  userId?: ComparableInt64OperationFilterInput | null;
  password?: StringOperationFilterInput | null;
  subscriptionList?: ListFilterInputTypeOfSubscriptionListFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface DEVICE_TYPEOperationFilterInput {
  eq?: DEVICE_TYPE | null;
  neq?: DEVICE_TYPE | null;
  in?: DEVICE_TYPE[] | null;
  nin?: DEVICE_TYPE[] | null;
}

export interface DeviceBulkConfigurationFilterInput {
  and?: DeviceBulkConfigurationFilterInput[] | null;
  or?: DeviceBulkConfigurationFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  aquisitionRate?: ComparableInt32OperationFilterInput | null;
  communicationRate?: ComparableInt32OperationFilterInput | null;
  timeServerUrl?: StringOperationFilterInput | null;
  timeServerPort?: ComparableInt32OperationFilterInput | null;
  timeServerProtocol?: StringOperationFilterInput | null;
  timeServerType?: TIME_SERVER_TYPEOperationFilterInput | null;
  isDefault?: BooleanOperationFilterInput | null;
  protectionEarthFrequencyF1?: ComparableInt32OperationFilterInput | null;
  protectionEarthFrequencyF2?: ComparableInt32OperationFilterInput | null;
  protectionEarthFrequencyF3?: ComparableInt32OperationFilterInput | null;
  serviceEarthFrequencyF1?: ComparableInt32OperationFilterInput | null;
  serviceEarthFrequencyF2?: ComparableInt32OperationFilterInput | null;
  serviceEarthFrequencyF3?: ComparableInt32OperationFilterInput | null;
  aquisitionRateEarth?: ComparableInt32OperationFilterInput | null;
  communicationRateEarth?: ComparableInt32OperationFilterInput | null;
  device?: ListFilterInputTypeOfDeviceFilterInput | null;
  deviceTypeConfigurations?: ListFilterInputTypeOfDeviceTypeConfigurationFilterInput | null;
  conTest?: ConTestFilterInput | null;
  serverUrl?: StringOperationFilterInput | null;
  mQTTServerUrl?: StringOperationFilterInput | null;
  mQTTUsername?: StringOperationFilterInput | null;
  mQTTPassword?: StringOperationFilterInput | null;
  mQTTCertificateUrl?: StringOperationFilterInput | null;
  mQTTClientId?: StringOperationFilterInput | null;
  mQTTGroupId?: StringOperationFilterInput | null;
  bluetoothTimeout?: ComparableInt32OperationFilterInput | null;
  proxyEnabled?: BooleanOperationFilterInput | null;
  proxyPort?: ComparableNullableOfInt32OperationFilterInput | null;
  proxyUrl?: StringOperationFilterInput | null;
  customDnsEnabled?: BooleanOperationFilterInput | null;
  dnsServerName1?: StringOperationFilterInput | null;
  dnsServerName2?: StringOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface DeviceBulkConfigurationSortInput {
  name?: SortEnumType | null;
  aquisitionRate?: SortEnumType | null;
  communicationRate?: SortEnumType | null;
  timeServerUrl?: SortEnumType | null;
  timeServerPort?: SortEnumType | null;
  timeServerProtocol?: SortEnumType | null;
  timeServerType?: SortEnumType | null;
  isDefault?: SortEnumType | null;
  protectionEarthFrequencyF1?: SortEnumType | null;
  protectionEarthFrequencyF2?: SortEnumType | null;
  protectionEarthFrequencyF3?: SortEnumType | null;
  serviceEarthFrequencyF1?: SortEnumType | null;
  serviceEarthFrequencyF2?: SortEnumType | null;
  serviceEarthFrequencyF3?: SortEnumType | null;
  aquisitionRateEarth?: SortEnumType | null;
  communicationRateEarth?: SortEnumType | null;
  conTest?: ConTestSortInput | null;
  serverUrl?: SortEnumType | null;
  mQTTServerUrl?: SortEnumType | null;
  mQTTUsername?: SortEnumType | null;
  mQTTPassword?: SortEnumType | null;
  mQTTCertificateUrl?: SortEnumType | null;
  mQTTClientId?: SortEnumType | null;
  mQTTGroupId?: SortEnumType | null;
  bluetoothTimeout?: SortEnumType | null;
  proxyEnabled?: SortEnumType | null;
  proxyPort?: SortEnumType | null;
  proxyUrl?: SortEnumType | null;
  customDnsEnabled?: SortEnumType | null;
  dnsServerName1?: SortEnumType | null;
  dnsServerName2?: SortEnumType | null;
  id?: SortEnumType | null;
}

export interface DeviceFilterInput {
  and?: DeviceFilterInput[] | null;
  or?: DeviceFilterInput[] | null;
  assetId?: ComparableInt64OperationFilterInput | null;
  externalId?: ComparableInt64OperationFilterInput | null;
  serialNumber?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  asset?: AssetFilterInput | null;
  deviceInputs?: ListFilterInputTypeOfDeviceInputNumberFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
  deviceType?: DEVICE_TYPEOperationFilterInput | null;
  bulkConfigurationId?: ComparableNullableOfInt64OperationFilterInput | null;
  alarmStandardId?: ComparableNullableOfInt64OperationFilterInput | null;
  deviceToken?: StringOperationFilterInput | null;
  isEnabled?: BooleanOperationFilterInput | null;
  isDeleted?: BooleanOperationFilterInput | null;
  acquisitionRate?: ComparableNullableOfInt32OperationFilterInput | null;
  communicationRate?: ComparableNullableOfInt32OperationFilterInput | null;
  lastCommunication?: ComparableNullableOfDateTimeOperationFilterInput | null;
  configRefreshInterval?: ComparableInt32OperationFilterInput | null;
  lastAddress?: StringOperationFilterInput | null;
  lastConfigTs?: ComparableNullableOfDateTimeOperationFilterInput | null;
  alarmLastConfigTs?: ComparableNullableOfDateTimeOperationFilterInput | null;
  deviceSpecificConfig?: StringOperationFilterInput | null;
  bulkConfigurationConfig?: StringOperationFilterInput | null;
  alarmsConfig?: StringOperationFilterInput | null;
  firmwareVersion?: StringOperationFilterInput | null;
  firmwareDeployDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  externalReference?: StringOperationFilterInput | null;
  batchDeviceId?: ComparableNullableOfInt64OperationFilterInput | null;
  batchDevice?: BatchDeviceFilterInput | null;
  simCardId?: ComparableNullableOfInt64OperationFilterInput | null;
  simCard?: SimCardFilterInput | null;
  conTestDeviceResults?: ListFilterInputTypeOfConTestDeviceResultFilterInput | null;
  bulkConfiguration?: DeviceBulkConfigurationFilterInput | null;
  alarmStandard?: AlarmStandardFilterInput | null;
  ports?: ListFilterInputTypeOfPortFilterInput | null;
  interfaces?: ListFilterInputTypeOfInterfaceFilterInput | null;
  lastContacts?: ListFilterInputTypeOfLastContactByTypeFilterInput | null;
  firmwareTasks?: ListFilterInputTypeOfFirmwareTaskFilterInput | null;
  firmwareTasksLogs?: ListFilterInputTypeOfFirmwareTaskLogFilterInput | null;
  deviceTags?: ListFilterInputTypeOfDeviceTagFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  authorizationTokens?: ListFilterInputTypeOfAuthorizationTokenFilterInput | null;
}

export interface DeviceInputNumberFilterInput {
  and?: DeviceInputNumberFilterInput[] | null;
  or?: DeviceInputNumberFilterInput[] | null;
  deviceId?: ComparableInt64OperationFilterInput | null;
  externalId?: ComparableInt64OperationFilterInput | null;
  deviceExternalId?: ComparableInt64OperationFilterInput | null;
  deviceSerialNumber?: StringOperationFilterInput | null;
  portNumber?: ComparableInt32OperationFilterInput | null;
  inputNumber?: ComparableInt32OperationFilterInput | null;
  typeId?: ComparableNullableOfInt32OperationFilterInput | null;
  devicePortId?: ComparableNullableOfInt64OperationFilterInput | null;
  isEnabled?: BooleanOperationFilterInput | null;
  inputType?: INPUT_TYPEOperationFilterInput | null;
  powerBusLineId?: ComparableNullableOfInt64OperationFilterInput | null;
  masterBusLineId?: ComparableNullableOfInt64OperationFilterInput | null;
  equipmentId?: ComparableNullableOfInt64OperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface DeviceLogFilterInput {
  and?: DeviceLogFilterInput[] | null;
  or?: DeviceLogFilterInput[] | null;
  assetId?: ComparableInt64OperationFilterInput | null;
  externalId?: ComparableInt64OperationFilterInput | null;
  serialNumber?: StringOperationFilterInput | null;
  comissionDate?: ComparableDateTimeOperationFilterInput | null;
  deComissionDate?: ComparableDateTimeOperationFilterInput | null;
  asset?: AssetFilterInput | null;
  deviceInputs?: ListFilterInputTypeOfDeviceInputNumberFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface DeviceSortInput {
  assetId?: SortEnumType | null;
  externalId?: SortEnumType | null;
  serialNumber?: SortEnumType | null;
  createdDate?: SortEnumType | null;
  asset?: AssetSortInput | null;
  id?: SortEnumType | null;
  deviceType?: SortEnumType | null;
  bulkConfigurationId?: SortEnumType | null;
  alarmStandardId?: SortEnumType | null;
  deviceToken?: SortEnumType | null;
  isEnabled?: SortEnumType | null;
  isDeleted?: SortEnumType | null;
  acquisitionRate?: SortEnumType | null;
  communicationRate?: SortEnumType | null;
  lastCommunication?: SortEnumType | null;
  configRefreshInterval?: SortEnumType | null;
  lastAddress?: SortEnumType | null;
  lastConfigTs?: SortEnumType | null;
  alarmLastConfigTs?: SortEnumType | null;
  deviceSpecificConfig?: SortEnumType | null;
  bulkConfigurationConfig?: SortEnumType | null;
  alarmsConfig?: SortEnumType | null;
  firmwareVersion?: SortEnumType | null;
  firmwareDeployDate?: SortEnumType | null;
  name?: SortEnumType | null;
  description?: SortEnumType | null;
  externalReference?: SortEnumType | null;
  batchDeviceId?: SortEnumType | null;
  batchDevice?: BatchDeviceSortInput | null;
  simCardId?: SortEnumType | null;
  simCard?: SimCardSortInput | null;
  bulkConfiguration?: DeviceBulkConfigurationSortInput | null;
  alarmStandard?: AlarmStandardSortInput | null;
  createdBy?: SortEnumType | null;
  lastModifiedBy?: SortEnumType | null;
  lastModifiedDate?: SortEnumType | null;
}

export interface DeviceTagFilterInput {
  and?: DeviceTagFilterInput[] | null;
  or?: DeviceTagFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  devices?: ListFilterInputTypeOfDeviceFilterInput | null;
  firmwareTasks?: ListFilterInputTypeOfFirmwareTaskFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface DeviceTypeConfigurationFilterInput {
  and?: DeviceTypeConfigurationFilterInput[] | null;
  or?: DeviceTypeConfigurationFilterInput[] | null;
  deviceType?: DEVICE_TYPEOperationFilterInput | null;
  bulkConfigurationId?: ComparableInt64OperationFilterInput | null;
  deviceBulkConfiguration?: DeviceBulkConfigurationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface ENTITY_TYPEOperationFilterInput {
  eq?: ENTITY_TYPE | null;
  neq?: ENTITY_TYPE | null;
  in?: ENTITY_TYPE[] | null;
  nin?: ENTITY_TYPE[] | null;
}

export interface EQUIPMENT_TYPEOperationFilterInput {
  eq?: EQUIPMENT_TYPE | null;
  neq?: EQUIPMENT_TYPE | null;
  in?: EQUIPMENT_TYPE[] | null;
  nin?: EQUIPMENT_TYPE[] | null;
}

export interface EnterpriseFilterInput {
  and?: EnterpriseFilterInput[] | null;
  or?: EnterpriseFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  companies?: ListFilterInputTypeOfCompanyFilterInput | null;
  accessGroups?: ListFilterInputTypeOfAccessGroupFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface EnterpriseSortInput {
  name?: SortEnumType | null;
  description?: SortEnumType | null;
  id?: SortEnumType | null;
}

export interface EquipBulkConfigurationFilterInput {
  and?: EquipBulkConfigurationFilterInput[] | null;
  or?: EquipBulkConfigurationFilterInput[] | null;
  externalId?: ComparableInt64OperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  assets?: ListFilterInputTypeOfAssetFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface EquipBulkConfigurationSortInput {
  externalId?: SortEnumType | null;
  name?: SortEnumType | null;
  id?: SortEnumType | null;
}

export interface EquipmentFilterInput {
  and?: EquipmentFilterInput[] | null;
  or?: EquipmentFilterInput[] | null;
  assetId?: ComparableNullableOfInt64OperationFilterInput | null;
  parentEquipmentId?: ComparableNullableOfInt64OperationFilterInput | null;
  parentEquipmentName?: StringOperationFilterInput | null;
  parentFeederId?: ComparableNullableOfInt64OperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  isMainTransformer?: BooleanOperationFilterInput | null;
  equipmentType?: EQUIPMENT_TYPEOperationFilterInput | null;
  brand?: StringOperationFilterInput | null;
  model?: StringOperationFilterInput | null;
  power?: ComparableSingleOperationFilterInput | null;
  manufactureDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  serviceEntryDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  phaseRotation?: PHASE_ROTATIONOperationFilterInput | null;
  phaseType?: PHASE_TYPEOperationFilterInput | null;
  parentEquipments?: ListFilterInputTypeOfEquipmentFilterInput | null;
  parentEquipment?: EquipmentFilterInput | null;
  parentFeeder?: FeederFilterInput | null;
  asset?: AssetFilterInput | null;
  installationType?: NullableOfINSTALLATION_TYPEOperationFilterInput | null;
  materialType?: NullableOfMATERIAL_TYPEOperationFilterInput | null;
  connectionType?: NullableOfCONNECTION_TYPEOperationFilterInput | null;
  anchorType?: NullableOfANCHOR_TYPEOperationFilterInput | null;
  inputType?: NullableOfINPUT_TYPEOperationFilterInput | null;
  isMain?: BooleanOperationFilterInput | null;
  feederName?: StringOperationFilterInput | null;
  feeders?: ListFilterInputTypeOfFeederFilterInput | null;
  masterBusLines?: ListFilterInputTypeOfMasterBusLineFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface EventActionFilterInput {
  and?: EventActionFilterInput[] | null;
  or?: EventActionFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  regionId?: ComparableNullableOfInt64OperationFilterInput | null;
  operationalAreaId?: ComparableNullableOfInt64OperationFilterInput | null;
  equipmentId?: ComparableNullableOfInt64OperationFilterInput | null;
  deviceId?: ComparableNullableOfInt64OperationFilterInput | null;
  severity?: SEVERITYOperationFilterInput | null;
  readonly?: BooleanOperationFilterInput | null;
  filter?: FilterFilterInput | null;
  filterId?: ComparableInt64OperationFilterInput | null;
  alarmType?: AlarmTypeFilterInput | null;
  alarmTypeId?: ComparableInt64OperationFilterInput | null;
  subscriptionLists?: ListFilterInputTypeOfSubscriptionListFilterInput | null;
  triggerActions?: ListFilterInputTypeOfTriggerActionsFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface EventDataFilterInput {
  and?: EventDataFilterInput[] | null;
  or?: EventDataFilterInput[] | null;
  eventId?: ComparableInt64OperationFilterInput | null;
  timeStamp?: ComparableNullableOfDateTimeOperationFilterInput | null;
  status?: EventStatusOperationFilterInput | null;
  deviceStatus?: NullableOfEventDataStatusOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  data?: StringOperationFilterInput | null;
  imax?: ComparableNullableOfInt32OperationFilterInput | null;
  iavg?: ComparableNullableOfInt32OperationFilterInput | null;
  imin?: ComparableNullableOfInt32OperationFilterInput | null;
  delta_T?: ComparableNullableOfInt32OperationFilterInput | null;
  notes?: StringOperationFilterInput | null;
  event?: EventFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface EventEntityTypeOperationFilterInput {
  eq?: EventEntityType | null;
  neq?: EventEntityType | null;
  in?: EventEntityType[] | null;
  nin?: EventEntityType[] | null;
}

export interface EventFilterInput {
  and?: EventFilterInput[] | null;
  or?: EventFilterInput[] | null;
  equipmentId?: ComparableNullableOfInt64OperationFilterInput | null;
  deviceId?: ComparableNullableOfInt64OperationFilterInput | null;
  feederId?: ComparableNullableOfInt64OperationFilterInput | null;
  line?: StringOperationFilterInput | null;
  portNumber?: ComparableNullableOfInt32OperationFilterInput | null;
  entityType?: EventEntityTypeOperationFilterInput | null;
  type?: EventTypeOperationFilterInput | null;
  status?: EventStatusOperationFilterInput | null;
  deviceStatus?: NullableOfEventDataStatusOperationFilterInput | null;
  severity?: EventSeverityOperationFilterInput | null;
  registrationNumber?: StringOperationFilterInput | null;
  deviceSerial?: StringOperationFilterInput | null;
  feederName?: StringOperationFilterInput | null;
  fuseRating?: ComparableNullableOfSingleOperationFilterInput | null;
  fuseWay?: ComparableNullableOfSingleOperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  sourceEntityType?: ENTITY_TYPEOperationFilterInput | null;
  source?: SOURCEOperationFilterInput | null;
  startDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  endDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  notes?: StringOperationFilterInput | null;
  hasWFC?: BooleanOperationFilterInput | null;
  eventData?: ListFilterInputTypeOfEventDataFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface EventSeverityOperationFilterInput {
  eq?: EventSeverity | null;
  neq?: EventSeverity | null;
  in?: EventSeverity[] | null;
  nin?: EventSeverity[] | null;
}

export interface EventSortInput {
  equipmentId?: SortEnumType | null;
  deviceId?: SortEnumType | null;
  feederId?: SortEnumType | null;
  line?: SortEnumType | null;
  portNumber?: SortEnumType | null;
  entityType?: SortEnumType | null;
  type?: SortEnumType | null;
  status?: SortEnumType | null;
  deviceStatus?: SortEnumType | null;
  severity?: SortEnumType | null;
  registrationNumber?: SortEnumType | null;
  deviceSerial?: SortEnumType | null;
  feederName?: SortEnumType | null;
  fuseRating?: SortEnumType | null;
  fuseWay?: SortEnumType | null;
  name?: SortEnumType | null;
  sourceEntityType?: SortEnumType | null;
  source?: SortEnumType | null;
  startDate?: SortEnumType | null;
  endDate?: SortEnumType | null;
  notes?: SortEnumType | null;
  hasWFC?: SortEnumType | null;
  createdBy?: SortEnumType | null;
  createdDate?: SortEnumType | null;
  lastModifiedBy?: SortEnumType | null;
  lastModifiedDate?: SortEnumType | null;
  id?: SortEnumType | null;
}

export interface EventStatusOperationFilterInput {
  eq?: EventStatus | null;
  neq?: EventStatus | null;
  in?: EventStatus[] | null;
  nin?: EventStatus[] | null;
}

export interface EventTypeOperationFilterInput {
  eq?: EventType | null;
  neq?: EventType | null;
  in?: EventType[] | null;
  nin?: EventType[] | null;
}

export interface ExpirationTypeOperationFilterInput {
  eq?: ExpirationType | null;
  neq?: ExpirationType | null;
  in?: ExpirationType[] | null;
  nin?: ExpirationType[] | null;
}

export interface FEEDER_TYPEOperationFilterInput {
  eq?: FEEDER_TYPE | null;
  neq?: FEEDER_TYPE | null;
  in?: FEEDER_TYPE[] | null;
  nin?: FEEDER_TYPE[] | null;
}

export interface FIRMWARE_TASK_STATUSOperationFilterInput {
  eq?: FIRMWARE_TASK_STATUS | null;
  neq?: FIRMWARE_TASK_STATUS | null;
  in?: FIRMWARE_TASK_STATUS[] | null;
  nin?: FIRMWARE_TASK_STATUS[] | null;
}

export interface FeederFilterInput {
  and?: FeederFilterInput[] | null;
  or?: FeederFilterInput[] | null;
  assetId?: ComparableInt64OperationFilterInput | null;
  equipmentId?: ComparableInt64OperationFilterInput | null;
  feederType?: FEEDER_TYPEOperationFilterInput | null;
  alarmStandardId?: ComparableNullableOfInt64OperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  number?: ComparableInt32OperationFilterInput | null;
  isActive?: BooleanOperationFilterInput | null;
  isReadOnly?: BooleanOperationFilterInput | null;
  isCommissioned?: BooleanOperationFilterInput | null;
  fuseRating?: ComparableSingleOperationFilterInput | null;
  cableTypeId?: ComparableNullableOfInt64OperationFilterInput | null;
  parentEquipments?: ListFilterInputTypeOfEquipmentFilterInput | null;
  alarmStandard?: AlarmStandardFilterInput | null;
  cableType?: CableTypeFilterInput | null;
  powerBusLines?: ListFilterInputTypeOfPowerBusLineFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface FilterFilterInput {
  and?: FilterFilterInput[] | null;
  or?: FilterFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  contains?: StringOperationFilterInput | null;
  formula?: StringOperationFilterInput | null;
  regex?: StringOperationFilterInput | null;
  eventActions?: ListFilterInputTypeOfEventActionFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface FirmwareDocumentFilterInput {
  and?: FirmwareDocumentFilterInput[] | null;
  or?: FirmwareDocumentFilterInput[] | null;
  externalId?: ComparableInt64OperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  version?: StringOperationFilterInput | null;
  microType?: MICRO_CONTROLLER_TYPEOperationFilterInput | null;
  deviceType?: DEVICE_TYPEOperationFilterInput | null;
  firmware?: FirmwareFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface FirmwareDocumentSortInput {
  externalId?: SortEnumType | null;
  name?: SortEnumType | null;
  version?: SortEnumType | null;
  microType?: SortEnumType | null;
  deviceType?: SortEnumType | null;
  firmware?: FirmwareSortInput | null;
  id?: SortEnumType | null;
}

export interface FirmwareFilterInput {
  and?: FirmwareFilterInput[] | null;
  or?: FirmwareFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  firmwareDocumentId?: ComparableInt64OperationFilterInput | null;
  firmwareDocument?: FirmwareDocumentFilterInput | null;
  firmwareTasks?: ListFilterInputTypeOfFirmwareTaskFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface FirmwareSortInput {
  name?: SortEnumType | null;
  firmwareDocumentId?: SortEnumType | null;
  firmwareDocument?: FirmwareDocumentSortInput | null;
  createdBy?: SortEnumType | null;
  createdDate?: SortEnumType | null;
  lastModifiedBy?: SortEnumType | null;
  lastModifiedDate?: SortEnumType | null;
  id?: SortEnumType | null;
}

export interface FirmwareTaskFilterInput {
  and?: FirmwareTaskFilterInput[] | null;
  or?: FirmwareTaskFilterInput[] | null;
  taskName?: StringOperationFilterInput | null;
  scheduleDate?: ComparableDateTimeOperationFilterInput | null;
  processedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  processed?: BooleanOperationFilterInput | null;
  downloadAttempts?: ComparableInt32OperationFilterInput | null;
  cancelDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  firmwareId?: ComparableInt64OperationFilterInput | null;
  firmware?: FirmwareFilterInput | null;
  devices?: ListFilterInputTypeOfDeviceFilterInput | null;
  accessGroups?: ListFilterInputTypeOfAccessGroupFilterInput | null;
  operationalAreas?: ListFilterInputTypeOfOperationalAreaFilterInput | null;
  regions?: ListFilterInputTypeOfRegionFilterInput | null;
  companies?: ListFilterInputTypeOfCompanyFilterInput | null;
  deviceTags?: ListFilterInputTypeOfDeviceTagFilterInput | null;
  firmwareTaskLogs?: ListFilterInputTypeOfFirmwareTaskLogFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface FirmwareTaskLogFilterInput {
  and?: FirmwareTaskLogFilterInput[] | null;
  or?: FirmwareTaskLogFilterInput[] | null;
  progress?: ComparableInt32OperationFilterInput | null;
  numberBlocks?: ComparableInt32OperationFilterInput | null;
  currentBlock?: ComparableInt32OperationFilterInput | null;
  downloadAttempts?: ComparableInt32OperationFilterInput | null;
  currentVersion?: StringOperationFilterInput | null;
  updatedVersion?: StringOperationFilterInput | null;
  startDate?: ComparableDateTimeOperationFilterInput | null;
  endDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  status?: FIRMWARE_TASK_STATUSOperationFilterInput | null;
  taskLog?: StringOperationFilterInput | null;
  deviceId?: ComparableInt64OperationFilterInput | null;
  device?: DeviceFilterInput | null;
  firmwareTaskId?: ComparableNullableOfInt64OperationFilterInput | null;
  firmwareTask?: FirmwareTaskFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface FirmwareTaskLogSortInput {
  progress?: SortEnumType | null;
  numberBlocks?: SortEnumType | null;
  currentBlock?: SortEnumType | null;
  downloadAttempts?: SortEnumType | null;
  currentVersion?: SortEnumType | null;
  updatedVersion?: SortEnumType | null;
  startDate?: SortEnumType | null;
  endDate?: SortEnumType | null;
  status?: SortEnumType | null;
  taskLog?: SortEnumType | null;
  deviceId?: SortEnumType | null;
  device?: DeviceSortInput | null;
  firmwareTaskId?: SortEnumType | null;
  firmwareTask?: FirmwareTaskSortInput | null;
  createdBy?: SortEnumType | null;
  createdDate?: SortEnumType | null;
  lastModifiedBy?: SortEnumType | null;
  lastModifiedDate?: SortEnumType | null;
  id?: SortEnumType | null;
}

export interface FirmwareTaskSortInput {
  taskName?: SortEnumType | null;
  scheduleDate?: SortEnumType | null;
  processedDate?: SortEnumType | null;
  processed?: SortEnumType | null;
  downloadAttempts?: SortEnumType | null;
  cancelDate?: SortEnumType | null;
  firmwareId?: SortEnumType | null;
  firmware?: FirmwareSortInput | null;
  createdBy?: SortEnumType | null;
  createdDate?: SortEnumType | null;
  lastModifiedBy?: SortEnumType | null;
  lastModifiedDate?: SortEnumType | null;
  id?: SortEnumType | null;
}

export interface GpsPointFilterInput {
  and?: GpsPointFilterInput[] | null;
  or?: GpsPointFilterInput[] | null;
  latitude?: ComparableDoubleOperationFilterInput | null;
  longitude?: ComparableDoubleOperationFilterInput | null;
  designOrder?: ComparableInt32OperationFilterInput | null;
  operationalAreaId?: ComparableNullableOfInt64OperationFilterInput | null;
  operationalArea?: OperationalAreaFilterInput | null;
  regionId?: ComparableNullableOfInt64OperationFilterInput | null;
  region?: RegionFilterInput | null;
  assetId?: ComparableNullableOfInt64OperationFilterInput | null;
  asset?: AssetFilterInput | null;
  companyId?: ComparableNullableOfInt64OperationFilterInput | null;
  company?: CompanyFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface GpsPointSortInput {
  latitude?: SortEnumType | null;
  longitude?: SortEnumType | null;
  designOrder?: SortEnumType | null;
  operationalAreaId?: SortEnumType | null;
  operationalArea?: OperationalAreaSortInput | null;
  regionId?: SortEnumType | null;
  region?: RegionSortInput | null;
  assetId?: SortEnumType | null;
  asset?: AssetSortInput | null;
  companyId?: SortEnumType | null;
  company?: CompanySortInput | null;
  id?: SortEnumType | null;
}

export interface INPUT_TYPEOperationFilterInput {
  eq?: INPUT_TYPE | null;
  neq?: INPUT_TYPE | null;
  in?: INPUT_TYPE[] | null;
  nin?: INPUT_TYPE[] | null;
}

export interface INTERFACE_TYPEOperationFilterInput {
  eq?: INTERFACE_TYPE | null;
  neq?: INTERFACE_TYPE | null;
  in?: INTERFACE_TYPE[] | null;
  nin?: INTERFACE_TYPE[] | null;
}

export interface InputFilterInput {
  and?: InputFilterInput[] | null;
  or?: InputFilterInput[] | null;
  inputType?: INPUT_TYPEOperationFilterInput | null;
  devicePortId?: ComparableInt64OperationFilterInput | null;
  number?: ComparableInt32OperationFilterInput | null;
  isEnabled?: BooleanOperationFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface InterfaceFilterInput {
  and?: InterfaceFilterInput[] | null;
  or?: InterfaceFilterInput[] | null;
  interfaceType?: INTERFACE_TYPEOperationFilterInput | null;
  deviceId?: ComparableInt64OperationFilterInput | null;
  protocols?: ListFilterInputTypeOfProtocolFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface LASTCONTACT_TYPEOperationFilterInput {
  eq?: LASTCONTACT_TYPE | null;
  neq?: LASTCONTACT_TYPE | null;
  in?: LASTCONTACT_TYPE[] | null;
  nin?: LASTCONTACT_TYPE[] | null;
}

export interface LastContactByTypeFilterInput {
  and?: LastContactByTypeFilterInput[] | null;
  or?: LastContactByTypeFilterInput[] | null;
  deviceId?: ComparableInt64OperationFilterInput | null;
  remoteIp?: StringOperationFilterInput | null;
  lastContact?: ComparableDateTimeOperationFilterInput | null;
  lastContactType?: LASTCONTACT_TYPEOperationFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface ListComparableInt32OperationFilterInput {
  all?: ComparableInt32OperationFilterInput | null;
  none?: ComparableInt32OperationFilterInput | null;
  some?: ComparableInt32OperationFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfAccessGroupFilterInput {
  all?: AccessGroupFilterInput | null;
  none?: AccessGroupFilterInput | null;
  some?: AccessGroupFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfAlarmStandardDefinitionFilterInput {
  all?: AlarmStandardDefinitionFilterInput | null;
  none?: AlarmStandardDefinitionFilterInput | null;
  some?: AlarmStandardDefinitionFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfAlarmStandardVariableDefinitionFilterInput {
  all?: AlarmStandardVariableDefinitionFilterInput | null;
  none?: AlarmStandardVariableDefinitionFilterInput | null;
  some?: AlarmStandardVariableDefinitionFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfAlarmVariableFilterInput {
  all?: AlarmVariableFilterInput | null;
  none?: AlarmVariableFilterInput | null;
  some?: AlarmVariableFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfAssetFilterInput {
  all?: AssetFilterInput | null;
  none?: AssetFilterInput | null;
  some?: AssetFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfAuthorizationFilterInput {
  all?: AuthorizationFilterInput | null;
  none?: AuthorizationFilterInput | null;
  some?: AuthorizationFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfAuthorizationTokenFilterInput {
  all?: AuthorizationTokenFilterInput | null;
  none?: AuthorizationTokenFilterInput | null;
  some?: AuthorizationTokenFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfBatchIncludeFilterInput {
  all?: BatchIncludeFilterInput | null;
  none?: BatchIncludeFilterInput | null;
  some?: BatchIncludeFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfCompanyFilterInput {
  all?: CompanyFilterInput | null;
  none?: CompanyFilterInput | null;
  some?: CompanyFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfConTestDeviceResultFilterInput {
  all?: ConTestDeviceResultFilterInput | null;
  none?: ConTestDeviceResultFilterInput | null;
  some?: ConTestDeviceResultFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfDeviceFilterInput {
  all?: DeviceFilterInput | null;
  none?: DeviceFilterInput | null;
  some?: DeviceFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfDeviceInputNumberFilterInput {
  all?: DeviceInputNumberFilterInput | null;
  none?: DeviceInputNumberFilterInput | null;
  some?: DeviceInputNumberFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfDeviceLogFilterInput {
  all?: DeviceLogFilterInput | null;
  none?: DeviceLogFilterInput | null;
  some?: DeviceLogFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfDeviceTagFilterInput {
  all?: DeviceTagFilterInput | null;
  none?: DeviceTagFilterInput | null;
  some?: DeviceTagFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfDeviceTypeConfigurationFilterInput {
  all?: DeviceTypeConfigurationFilterInput | null;
  none?: DeviceTypeConfigurationFilterInput | null;
  some?: DeviceTypeConfigurationFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfEnterpriseFilterInput {
  all?: EnterpriseFilterInput | null;
  none?: EnterpriseFilterInput | null;
  some?: EnterpriseFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfEquipmentFilterInput {
  all?: EquipmentFilterInput | null;
  none?: EquipmentFilterInput | null;
  some?: EquipmentFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfEventActionFilterInput {
  all?: EventActionFilterInput | null;
  none?: EventActionFilterInput | null;
  some?: EventActionFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfEventDataFilterInput {
  all?: EventDataFilterInput | null;
  none?: EventDataFilterInput | null;
  some?: EventDataFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfFeederFilterInput {
  all?: FeederFilterInput | null;
  none?: FeederFilterInput | null;
  some?: FeederFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfFirmwareTaskFilterInput {
  all?: FirmwareTaskFilterInput | null;
  none?: FirmwareTaskFilterInput | null;
  some?: FirmwareTaskFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfFirmwareTaskLogFilterInput {
  all?: FirmwareTaskLogFilterInput | null;
  none?: FirmwareTaskLogFilterInput | null;
  some?: FirmwareTaskLogFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfGpsPointFilterInput {
  all?: GpsPointFilterInput | null;
  none?: GpsPointFilterInput | null;
  some?: GpsPointFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfInputFilterInput {
  all?: InputFilterInput | null;
  none?: InputFilterInput | null;
  some?: InputFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfInterfaceFilterInput {
  all?: InterfaceFilterInput | null;
  none?: InterfaceFilterInput | null;
  some?: InterfaceFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfLastContactByTypeFilterInput {
  all?: LastContactByTypeFilterInput | null;
  none?: LastContactByTypeFilterInput | null;
  some?: LastContactByTypeFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfMasterBusLineFilterInput {
  all?: MasterBusLineFilterInput | null;
  none?: MasterBusLineFilterInput | null;
  some?: MasterBusLineFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfMenuFilterInput {
  all?: MenuFilterInput | null;
  none?: MenuFilterInput | null;
  some?: MenuFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfOperationalAreaFilterInput {
  all?: OperationalAreaFilterInput | null;
  none?: OperationalAreaFilterInput | null;
  some?: OperationalAreaFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfPortFilterInput {
  all?: PortFilterInput | null;
  none?: PortFilterInput | null;
  some?: PortFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfPowerBusLineFilterInput {
  all?: PowerBusLineFilterInput | null;
  none?: PowerBusLineFilterInput | null;
  some?: PowerBusLineFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfProtocolFilterInput {
  all?: ProtocolFilterInput | null;
  none?: ProtocolFilterInput | null;
  some?: ProtocolFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfRegionFilterInput {
  all?: RegionFilterInput | null;
  none?: RegionFilterInput | null;
  some?: RegionFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfRoleFilterInput {
  all?: RoleFilterInput | null;
  none?: RoleFilterInput | null;
  some?: RoleFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfRoleGraphQlFilterInput {
  all?: RoleGraphQlFilterInput | null;
  none?: RoleGraphQlFilterInput | null;
  some?: RoleGraphQlFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfSimCardFilterInput {
  all?: SimCardFilterInput | null;
  none?: SimCardFilterInput | null;
  some?: SimCardFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfSubscriptionListFilterInput {
  all?: SubscriptionListFilterInput | null;
  none?: SubscriptionListFilterInput | null;
  some?: SubscriptionListFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfSubscriptionListTriggerFilterInput {
  all?: SubscriptionListTriggerFilterInput | null;
  none?: SubscriptionListTriggerFilterInput | null;
  some?: SubscriptionListTriggerFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfTriggerActionsFilterInput {
  all?: TriggerActionsFilterInput | null;
  none?: TriggerActionsFilterInput | null;
  some?: TriggerActionsFilterInput | null;
  any?: boolean | null;
}

export interface ListFilterInputTypeOfUserFilterInput {
  all?: UserFilterInput | null;
  none?: UserFilterInput | null;
  some?: UserFilterInput | null;
  any?: boolean | null;
}

export interface MICRO_CONTROLLER_TYPEOperationFilterInput {
  eq?: MICRO_CONTROLLER_TYPE | null;
  neq?: MICRO_CONTROLLER_TYPE | null;
  in?: MICRO_CONTROLLER_TYPE[] | null;
  nin?: MICRO_CONTROLLER_TYPE[] | null;
}

export interface MasterBusLineFilterInput {
  and?: MasterBusLineFilterInput[] | null;
  or?: MasterBusLineFilterInput[] | null;
  feederId?: ComparableNullableOfInt64OperationFilterInput | null;
  assetId?: ComparableInt64OperationFilterInput | null;
  equipmentId?: ComparableInt64OperationFilterInput | null;
  inputType?: INPUT_TYPEOperationFilterInput | null;
  referenceAngle?: ComparableSingleOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface MenuFilterInput {
  and?: MenuFilterInput[] | null;
  or?: MenuFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  i18nKey?: StringOperationFilterInput | null;
  icon?: StringOperationFilterInput | null;
  url?: StringOperationFilterInput | null;
  externalUrl?: BooleanOperationFilterInput | null;
  path?: StringOperationFilterInput | null;
  element?: StringOperationFilterInput | null;
  type?: MenuTypeOperationFilterInput | null;
  breadcrumbs?: BooleanOperationFilterInput | null;
  parent?: MenuFilterInput | null;
  parentId?: ComparableNullableOfInt64OperationFilterInput | null;
  section?: SectionFilterInput | null;
  sectionId?: ComparableNullableOfInt64OperationFilterInput | null;
  children?: ListFilterInputTypeOfMenuFilterInput | null;
  roles?: ListFilterInputTypeOfRoleFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface MenuTypeOperationFilterInput {
  eq?: MenuType | null;
  neq?: MenuType | null;
  in?: MenuType[] | null;
  nin?: MenuType[] | null;
}

export interface NullableOfANCHOR_TYPEOperationFilterInput {
  eq?: ANCHOR_TYPE | null;
  neq?: ANCHOR_TYPE | null;
  in?: (ANCHOR_TYPE | null)[] | null;
  nin?: (ANCHOR_TYPE | null)[] | null;
}

export interface NullableOfCONNECTION_TYPEOperationFilterInput {
  eq?: CONNECTION_TYPE | null;
  neq?: CONNECTION_TYPE | null;
  in?: (CONNECTION_TYPE | null)[] | null;
  nin?: (CONNECTION_TYPE | null)[] | null;
}

export interface NullableOfEventDataStatusOperationFilterInput {
  eq?: EventDataStatus | null;
  neq?: EventDataStatus | null;
  in?: (EventDataStatus | null)[] | null;
  nin?: (EventDataStatus | null)[] | null;
}

export interface NullableOfINPUT_TYPEOperationFilterInput {
  eq?: INPUT_TYPE | null;
  neq?: INPUT_TYPE | null;
  in?: (INPUT_TYPE | null)[] | null;
  nin?: (INPUT_TYPE | null)[] | null;
}

export interface NullableOfINSTALLATION_TYPEOperationFilterInput {
  eq?: INSTALLATION_TYPE | null;
  neq?: INSTALLATION_TYPE | null;
  in?: (INSTALLATION_TYPE | null)[] | null;
  nin?: (INSTALLATION_TYPE | null)[] | null;
}

export interface NullableOfMATERIAL_TYPEOperationFilterInput {
  eq?: MATERIAL_TYPE | null;
  neq?: MATERIAL_TYPE | null;
  in?: (MATERIAL_TYPE | null)[] | null;
  nin?: (MATERIAL_TYPE | null)[] | null;
}

export interface OperationalAreaFilterInput {
  and?: OperationalAreaFilterInput[] | null;
  or?: OperationalAreaFilterInput[] | null;
  externalId?: ComparableInt64OperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  assets?: ListFilterInputTypeOfAssetFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
  firmwareTasks?: ListFilterInputTypeOfFirmwareTaskFilterInput | null;
  code?: StringOperationFilterInput | null;
  gpsPoints?: ListFilterInputTypeOfGpsPointFilterInput | null;
  accessGroupsWorkingOP?: ListFilterInputTypeOfAccessGroupFilterInput | null;
  accessGroups?: ListFilterInputTypeOfAccessGroupFilterInput | null;
  regionId?: ComparableInt64OperationFilterInput | null;
  region?: RegionFilterInput | null;
}

export interface OperationalAreaSortInput {
  externalId?: SortEnumType | null;
  name?: SortEnumType | null;
  id?: SortEnumType | null;
  code?: SortEnumType | null;
  regionId?: SortEnumType | null;
  region?: RegionSortInput | null;
}

export interface PHASE_ROTATIONOperationFilterInput {
  eq?: PHASE_ROTATION | null;
  neq?: PHASE_ROTATION | null;
  in?: PHASE_ROTATION[] | null;
  nin?: PHASE_ROTATION[] | null;
}

export interface PHASE_TYPEOperationFilterInput {
  eq?: PHASE_TYPE | null;
  neq?: PHASE_TYPE | null;
  in?: PHASE_TYPE[] | null;
  nin?: PHASE_TYPE[] | null;
}

export interface PORT_TYPEOperationFilterInput {
  eq?: PORT_TYPE | null;
  neq?: PORT_TYPE | null;
  in?: PORT_TYPE[] | null;
  nin?: PORT_TYPE[] | null;
}

export interface PROTOCOL_TYPEOperationFilterInput {
  eq?: PROTOCOL_TYPE | null;
  neq?: PROTOCOL_TYPE | null;
  in?: PROTOCOL_TYPE[] | null;
  nin?: PROTOCOL_TYPE[] | null;
}

export interface PortFilterInput {
  and?: PortFilterInput[] | null;
  or?: PortFilterInput[] | null;
  portType?: PORT_TYPEOperationFilterInput | null;
  deviceId?: ComparableInt64OperationFilterInput | null;
  portNumber?: ComparableInt32OperationFilterInput | null;
  isEnabled?: BooleanOperationFilterInput | null;
  inputs?: ListFilterInputTypeOfInputFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface PowerBusLineFilterInput {
  and?: PowerBusLineFilterInput[] | null;
  or?: PowerBusLineFilterInput[] | null;
  feederId?: ComparableInt64OperationFilterInput | null;
  masterBusLineId?: ComparableInt64OperationFilterInput | null;
  inputType?: INPUT_TYPEOperationFilterInput | null;
  masterBusLine?: MasterBusLineFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface ProtocolFilterInput {
  and?: ProtocolFilterInput[] | null;
  or?: ProtocolFilterInput[] | null;
  protocolType?: PROTOCOL_TYPEOperationFilterInput | null;
  interfaceId?: ComparableInt64OperationFilterInput | null;
  version?: StringOperationFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface REGIONOperationFilterInput {
  eq?: REGION | null;
  neq?: REGION | null;
  in?: REGION[] | null;
  nin?: REGION[] | null;
}

export interface RegionFilterInput {
  and?: RegionFilterInput[] | null;
  or?: RegionFilterInput[] | null;
  externalId?: ComparableInt64OperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  assets?: ListFilterInputTypeOfAssetFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
  firmwareTasks?: ListFilterInputTypeOfFirmwareTaskFilterInput | null;
  code?: StringOperationFilterInput | null;
  operationalAreas?: ListFilterInputTypeOfOperationalAreaFilterInput | null;
  gpsPoints?: ListFilterInputTypeOfGpsPointFilterInput | null;
  accessGroups?: ListFilterInputTypeOfAccessGroupFilterInput | null;
  companyId?: ComparableInt64OperationFilterInput | null;
  company?: CompanyFilterInput | null;
}

export interface RegionSortInput {
  externalId?: SortEnumType | null;
  name?: SortEnumType | null;
  id?: SortEnumType | null;
  code?: SortEnumType | null;
  companyId?: SortEnumType | null;
  company?: CompanySortInput | null;
}

export interface ReportDTOFilterInput {
  and?: ReportDTOFilterInput[] | null;
  or?: ReportDTOFilterInput[] | null;
  _id?: StringOperationFilterInput | null;
  name?: StringOperationFilterInput | null;
  title?: StringOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  period_start?: ComparableDateTimeOperationFilterInput | null;
  period_end?: ComparableDateTimeOperationFilterInput | null;
  created?: ComparableDateTimeOperationFilterInput | null;
  report_type?: StringOperationFilterInput | null;
  periodic?: BooleanOperationFilterInput | null;
  requested_by?: StringOperationFilterInput | null;
  equipments?: ListComparableInt32OperationFilterInput | null;
  size?: ComparableInt32OperationFilterInput | null;
  ready?: BooleanOperationFilterInput | null;
  errors?: StringOperationFilterInput | null;
}

export interface ReportDTOSortInput {
  _id?: SortEnumType | null;
  name?: SortEnumType | null;
  title?: SortEnumType | null;
  description?: SortEnumType | null;
  period_start?: SortEnumType | null;
  period_end?: SortEnumType | null;
  created?: SortEnumType | null;
  report_type?: SortEnumType | null;
  periodic?: SortEnumType | null;
  requested_by?: SortEnumType | null;
  size?: SortEnumType | null;
  ready?: SortEnumType | null;
  errors?: SortEnumType | null;
}

export interface RoleFilterInput {
  and?: RoleFilterInput[] | null;
  or?: RoleFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  authorizations?: ListFilterInputTypeOfAuthorizationFilterInput | null;
  users?: ListFilterInputTypeOfUserFilterInput | null;
  menus?: ListFilterInputTypeOfMenuFilterInput | null;
  keycloakId?: StringOperationFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface RoleGraphQlFilterInput {
  and?: RoleGraphQlFilterInput[] | null;
  or?: RoleGraphQlFilterInput[] | null;
  id?: ComparableNullableOfInt64OperationFilterInput | null;
  keycloakId?: StringOperationFilterInput | null;
  name?: StringOperationFilterInput | null;
}

export interface RoleGraphQlSortInput {
  id?: SortEnumType | null;
  keycloakId?: SortEnumType | null;
  name?: SortEnumType | null;
}

export interface SEVERITYOperationFilterInput {
  eq?: SEVERITY | null;
  neq?: SEVERITY | null;
  in?: SEVERITY[] | null;
  nin?: SEVERITY[] | null;
}

export interface SIM_STATUS_TYPEOperationFilterInput {
  eq?: SIM_STATUS_TYPE | null;
  neq?: SIM_STATUS_TYPE | null;
  in?: SIM_STATUS_TYPE[] | null;
  nin?: SIM_STATUS_TYPE[] | null;
}

export interface SOURCEOperationFilterInput {
  eq?: SOURCE | null;
  neq?: SOURCE | null;
  in?: SOURCE[] | null;
  nin?: SOURCE[] | null;
}

export interface SectionFilterInput {
  and?: SectionFilterInput[] | null;
  or?: SectionFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  i18nKey?: StringOperationFilterInput | null;
  menus?: ListFilterInputTypeOfMenuFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface SimCardFilterInput {
  and?: SimCardFilterInput[] | null;
  or?: SimCardFilterInput[] | null;
  simStatusType?: SIM_STATUS_TYPEOperationFilterInput | null;
  batchSimId?: ComparableInt64OperationFilterInput | null;
  batchSim?: BatchSimFilterInput | null;
  importDate?: ComparableDateTimeOperationFilterInput | null;
  filename?: StringOperationFilterInput | null;
  iccid?: StringOperationFilterInput | null;
  imsi?: StringOperationFilterInput | null;
  apnName?: StringOperationFilterInput | null;
  apnIp?: StringOperationFilterInput | null;
  apnUser?: StringOperationFilterInput | null;
  apnPwd?: StringOperationFilterInput | null;
  apnAuthType?: APN_AUTH_TYPEOperationFilterInput | null;
  simIp?: StringOperationFilterInput | null;
  associationSucess?: BooleanOperationFilterInput | null;
  associationDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  associationLog?: StringOperationFilterInput | null;
  device?: DeviceFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface SimCardSortInput {
  simStatusType?: SortEnumType | null;
  batchSimId?: SortEnumType | null;
  batchSim?: BatchSimSortInput | null;
  importDate?: SortEnumType | null;
  filename?: SortEnumType | null;
  iccid?: SortEnumType | null;
  imsi?: SortEnumType | null;
  apnName?: SortEnumType | null;
  apnIp?: SortEnumType | null;
  apnUser?: SortEnumType | null;
  apnPwd?: SortEnumType | null;
  apnAuthType?: SortEnumType | null;
  simIp?: SortEnumType | null;
  associationSucess?: SortEnumType | null;
  associationDate?: SortEnumType | null;
  associationLog?: SortEnumType | null;
  device?: DeviceSortInput | null;
  createdBy?: SortEnumType | null;
  createdDate?: SortEnumType | null;
  lastModifiedBy?: SortEnumType | null;
  lastModifiedDate?: SortEnumType | null;
  id?: SortEnumType | null;
}

export interface StringOperationFilterInput {
  and?: StringOperationFilterInput[] | null;
  or?: StringOperationFilterInput[] | null;
  eq?: string | null;
  neq?: string | null;
  contains?: string | null;
  ncontains?: string | null;
  in?: (string | null)[] | null;
  nin?: (string | null)[] | null;
  startsWith?: string | null;
  nstartsWith?: string | null;
  endsWith?: string | null;
  nendsWith?: string | null;
}

export interface SubscriptionListFilterInput {
  and?: SubscriptionListFilterInput[] | null;
  or?: SubscriptionListFilterInput[] | null;
  userId?: BooleanOperationFilterInput | null;
  email?: BooleanOperationFilterInput | null;
  mobile?: BooleanOperationFilterInput | null;
  webhook?: BooleanOperationFilterInput | null;
  contact?: ContactFilterInput | null;
  contactId?: ComparableInt64OperationFilterInput | null;
  eventActions?: ListFilterInputTypeOfEventActionFilterInput | null;
  subscriptionListTriggers?: ListFilterInputTypeOfSubscriptionListTriggerFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface SubscriptionListTriggerFilterInput {
  and?: SubscriptionListTriggerFilterInput[] | null;
  or?: SubscriptionListTriggerFilterInput[] | null;
  triggerType?: TRIGGER_TYPEOperationFilterInput | null;
  subscriptionList?: SubscriptionListFilterInput | null;
  subscriptionListId?: ComparableInt64OperationFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface TEMPLATE_MESSAGE_TYPEOperationFilterInput {
  eq?: TEMPLATE_MESSAGE_TYPE | null;
  neq?: TEMPLATE_MESSAGE_TYPE | null;
  in?: TEMPLATE_MESSAGE_TYPE[] | null;
  nin?: TEMPLATE_MESSAGE_TYPE[] | null;
}

export interface TIME_SERVER_TYPEOperationFilterInput {
  eq?: TIME_SERVER_TYPE | null;
  neq?: TIME_SERVER_TYPE | null;
  in?: TIME_SERVER_TYPE[] | null;
  nin?: TIME_SERVER_TYPE[] | null;
}

export interface TRIGGER_TYPEOperationFilterInput {
  eq?: TRIGGER_TYPE | null;
  neq?: TRIGGER_TYPE | null;
  in?: TRIGGER_TYPE[] | null;
  nin?: TRIGGER_TYPE[] | null;
}

export interface TemplateMessageFilterInput {
  and?: TemplateMessageFilterInput[] | null;
  or?: TemplateMessageFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  templateMessageType?: TEMPLATE_MESSAGE_TYPEOperationFilterInput | null;
  triggerType?: TRIGGER_TYPEOperationFilterInput | null;
  title?: StringOperationFilterInput | null;
  subject?: StringOperationFilterInput | null;
  message?: StringOperationFilterInput | null;
  readOnly?: BooleanOperationFilterInput | null;
  triggerActions?: ListFilterInputTypeOfTriggerActionsFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface TokenTypeOperationFilterInput {
  eq?: TokenType | null;
  neq?: TokenType | null;
  in?: TokenType[] | null;
  nin?: TokenType[] | null;
}

export interface TriggerActionsFilterInput {
  and?: TriggerActionsFilterInput[] | null;
  or?: TriggerActionsFilterInput[] | null;
  enable?: BooleanOperationFilterInput | null;
  eventAction?: EventActionFilterInput | null;
  eventActionId?: ComparableInt64OperationFilterInput | null;
  templateMessage?: TemplateMessageFilterInput | null;
  templateMessageId?: ComparableInt64OperationFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
}

export interface UserFilterInput {
  and?: UserFilterInput[] | null;
  or?: UserFilterInput[] | null;
  accessGroups?: ListFilterInputTypeOfAccessGroupFilterInput | null;
  companyId?: ComparableInt64OperationFilterInput | null;
  company?: CompanyFilterInput | null;
  id?: ComparableInt64OperationFilterInput | null;
  userSettings?: StringOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  roles?: ListFilterInputTypeOfRoleFilterInput | null;
  authorizationTokens?: ListFilterInputTypeOfAuthorizationTokenFilterInput | null;
  keycloakId?: StringOperationFilterInput | null;
  createdBy?: StringOperationFilterInput | null;
  createdDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
  lastModifiedBy?: StringOperationFilterInput | null;
  lastModifiedDate?: ComparableNullableOfDateTimeOperationFilterInput | null;
}

export interface UserGraphQlFilterInput {
  and?: UserGraphQlFilterInput[] | null;
  or?: UserGraphQlFilterInput[] | null;
  id?: ComparableNullableOfInt64OperationFilterInput | null;
  keycloakId?: StringOperationFilterInput | null;
  userSettings?: StringOperationFilterInput | null;
  userName?: StringOperationFilterInput | null;
  firstName?: StringOperationFilterInput | null;
  lastName?: StringOperationFilterInput | null;
  email?: StringOperationFilterInput | null;
  roles?: ListFilterInputTypeOfRoleGraphQlFilterInput | null;
  description?: StringOperationFilterInput | null;
}

export interface UserGraphQlSortInput {
  id?: SortEnumType | null;
  keycloakId?: SortEnumType | null;
  userSettings?: SortEnumType | null;
  userName?: SortEnumType | null;
  firstName?: SortEnumType | null;
  lastName?: SortEnumType | null;
  email?: SortEnumType | null;
  description?: SortEnumType | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
