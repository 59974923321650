import { DependencyList, useEffect, useState } from 'react';

import cache from './cache';

export function useCachedFunction<T>(callback: () => T) {
    const [cachedValue, setCachedValue] = useState<T | undefined>(undefined);

    useEffect(() => setCachedValue(callback()), []);

    return cachedValue;
}

export function runFunctionWithCache<T>(callback: () => T, dependencies: DependencyList): T {
    const hash = JSON.stringify(dependencies);

    let response: T;

    if (cache.get(hash) !== undefined) {
        response = cache.get(hash) as T;
    } else {
        response = callback();
        cache.set(hash, response);
    }

    return response;
}

export async function runFunctionWithCacheAsync<T>(callback: () => Promise<T>, dependencies: DependencyList): Promise<T> {
    const hash = JSON.stringify(dependencies);

    let response: T;

    if (cache.get(hash) !== undefined) {
        response = cache.get(hash) as T;
    } else {
        response = await callback();
        cache.set(hash, response);
    }

    return response;
}
