import MainCard from '_global/Components/base/cards/MainCard';
import { modeAtomEvents } from '_global/Utils/hooks/jotai';
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useAtom } from 'jotai';
import Mode from 'models/enums/ModeEnum';
import { AlarmStatus, ProcessedEvent } from 'models/events/ProcessedEvent';
import { EventStatus } from 'models/graphql-global-types';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import EventInfoTable from './EventInfoTable/EventInfoTable';

const formControlStyle = {
    width: '100%'
};

const dividerStyle = {
    width: '100%',
    mt: 3,
    mb: 3
};
export interface EventProps {
    mode?: Mode;
    alarm: ProcessedEvent;
}

export default function EventDetails(props: EventProps) {
    const { alarm } = props;
    const [mode] = useAtom(modeAtomEvents);
    const [alarmDetails, setAlarmDetails] = useState<ProcessedEvent>(alarm);

    const saveState = (state: ProcessedEvent) => {
        try {
            const serializedState = JSON.stringify(state);
            localStorage.setItem('alarmDetails', serializedState);
        } catch (e) {
            console.error({ e });
        }
    };

    useEffect(() => {
        if (mode === Mode.EDIT) {
            saveState(alarmDetails);
        }
    }, [mode, alarmDetails]);

    const handleChangeSelect = (event: SelectChangeEvent) => {
        setAlarmDetails((old) => ({ ...old, status: Number(event.target.value) }));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlarmDetails((old) => ({ ...old, notes: event.target.value }));
    };

    return (
        <MainCard content={false}>
            <Box sx={{ p: 3 }}>
                <EventInfoTable alarm={alarm} />

                {mode === Mode.EDIT && (
                    <Box>
                        <Divider sx={dividerStyle} />
                        <Grid container item spacing={1}>
                            <Grid item xs={2}>
                                <FormControl sx={formControlStyle}>
                                    <InputLabel>{<FormattedMessage id={'status'} />}</InputLabel>
                                    <Select
                                        label={<FormattedMessage id={'status'} />}
                                        onChange={handleChangeSelect}
                                        value={alarmDetails.status as string}
                                    >
                                        <MenuItem value={AlarmStatus.UNRESOLVED}>
                                            <FormattedMessage id={EventStatus.UNRESOLVED.toLowerCase()} />
                                        </MenuItem>
                                        <MenuItem value={AlarmStatus.RESOLVING}>
                                            <FormattedMessage id={EventStatus.RESOLVING.toLowerCase()} />
                                        </MenuItem>
                                        <MenuItem value={AlarmStatus.RESOLVED}>
                                            <FormattedMessage id={EventStatus.RESOLVED.toLowerCase()} />
                                        </MenuItem>
                                        <MenuItem value={AlarmStatus.ACKNOWLEDGE}>
                                            <FormattedMessage id={EventStatus.ACKNOWLEDGE.toLowerCase()} />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={10}>
                                <FormControl sx={formControlStyle}>
                                    <TextField
                                        defaultValue={alarmDetails.notes}
                                        label={<FormattedMessage id={'notes'} />}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Box>
        </MainCard>
    );
}
