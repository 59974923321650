import '@inovua/reactdatagrid-community/index.css';

import { addGridsPaginations } from '_global/Services/store/gridsPaginationSlice';
import { useAppDispatch, useAppSelector } from '_global/Services/store/hooks';
import gridsPaginationsUpdateGrid from '_global/Utils/gridsPaginations';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeComputedProps, TypeDataSource, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-community/types';
import { TypeColumn } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { Box } from '@mui/system';
import { ProcessedEvent } from 'models/events/ProcessedEvent';
import { SortInfoType } from 'models/sortInfo';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TypeColumnExtended } from 'views/Assets/grid/AssetsGrid';
import { GridHandleType } from 'views/Events/grid/filter/DataFilter';

import { defaultRowStyle } from '../../../_global/Components/base/grids/RowUtils';

export type EventsProps = {
    data: TypeDataSource;
    onRowPressed: (event: ProcessedEvent) => void;
    pagination?: boolean;
    columns: TypeColumn[];
    loading?: boolean;
    setGridRef: Dispatch<SetStateAction<((gridApiRef: MutableRefObject<TypeComputedProps | null>) => void) | undefined>>;
    sortInfo?: SortInfoType;
    minHeight?: string;
    sortInfoC: TypeSortInfo;
};

export default function EventsGrid({
    data,
    onRowPressed,
    pagination = true,
    columns,
    loading,
    setGridRef,
    sortInfo,
    minHeight,
    sortInfoC
}: EventsProps) {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [gridSkip, setGridSkip] = useState<number | undefined>(undefined);
    const [gridSortInfo, setGridSortInfo] = useState<TypeSingleSortInfo | undefined>(undefined);
    const [gridColumnOrder, setGridColumnOrder] = useState<string[] | undefined>(undefined);
    const [gridLimit, setGridLimit] = useState<number | undefined>(undefined);
    const gridsPaginations = useAppSelector((state) => state.gridsPaginations.gridsPaginations);
    const firstRenderRef = useRef(true);

    useEffect(() => {
        gridsPaginationsUpdateGrid({
            gridName: 'EventsGrid',
            gridsPaginations,
            columns: columns,
            sortInfo,
            setGridSkip,
            setGridSortInfo,
            setGridColumnOrder,
            setGridLimit,
            location
        });
    }, [gridsPaginations]);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
        } else {
            dispatch(
                addGridsPaginations({
                    url: location.pathname,
                    grid: { skip: gridSkip, sortInfo: gridSortInfo, columnOrder: gridColumnOrder, limit: gridLimit },
                    key: `EventsGrid`
                })
            );
        }
    }, [gridSkip, gridSortInfo, gridColumnOrder, gridLimit]);

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <ReactDataGrid
                columnOrder={gridColumnOrder}
                columns={columns}
                dataSource={data}
                enableColumnAutosize
                handle={setGridRef as unknown as GridHandleType}
                limit={gridLimit ? gridLimit : 10}
                loading={loading}
                onColumnOrderChange={(columnOrder) => {
                    setGridColumnOrder(columnOrder);
                }}
                onLimitChange={(limit) => {
                    setGridLimit(limit);
                }}
                onRowClick={(rowProps) => {
                    onRowPressed(rowProps.data as ProcessedEvent);
                }}
                onSkipChange={(skip) => {
                    setGridSkip(skip);
                }}
                onSortInfoChange={(column: TypeSortInfo) => {
                    if (!sortInfo) return;
                    if (!column) {
                        return sortInfo('', 0);
                    }
                    const section = columns.find((section) => section.name === ((column as TypeSingleSortInfo).id as string));
                    if ((section as TypeColumnExtended).columnSortAsc !== null && (section as TypeColumnExtended).columnSortDesc !== null) {
                        sortInfo(
                            (column as TypeSingleSortInfo).id as string,
                            (column as TypeSingleSortInfo).dir as number,
                            (column as TypeSingleSortInfo).type as string,
                            (section as TypeColumnExtended).columnSortAsc,
                            (section as TypeColumnExtended).columnSortDesc
                        );
                    } else {
                        sortInfo(
                            (column as TypeSingleSortInfo).id as string,
                            (column as TypeSingleSortInfo).dir as number,
                            (column as TypeSingleSortInfo).type as string
                        );
                    }
                    setGridSortInfo(column as TypeSingleSortInfo);
                }}
                pagination={pagination}
                resizable={true}
                rowHeight={50}
                rowStyle={defaultRowStyle}
                showCellBorders={'horizontal'}
                showColumnMenuTool={false}
                showEmptyRows={false}
                showZebraRows={false}
                skip={gridSkip}
                sortInfo={gridSortInfo ?? sortInfoC}
                style={{
                    flexGrow: 1,
                    minHeight: minHeight ?? '62vh',
                    border: 'none'
                }}
            />
        </Box>
    );
}
