import { requestUrlIncludes, stringIncludes } from '_global/Utils/ArrayUtils';
import { useKeycloak } from '@react-keycloak/web';
import { wrapUseRoutes } from '@sentry/react';
import fetchIntercept from 'fetch-intercept';
import { useEffect, useMemo } from 'react';
import * as React from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';

import { Configuration } from '../../logic/configuration/Configuration';
import { InterceptorRequest } from '../../models/authorization/InterceptorRequest';
import LoginRoutes from './LoginRoutes';
import mainRoutes from './MainRoutes';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useSentryRoutes = wrapUseRoutes(useRoutes);

export default function AppRoutes(props: { configuration: Configuration }): React.ReactElement | null {
    const { configuration } = props;
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();
    const location = useLocation();

    const isLoggedIn = useMemo(() => keycloak.authenticated, [keycloak, keycloak.authenticated]);

    useEffect(() => {
        if ((isLoggedIn && location.pathname === '/login') || location.pathname === '/') {
            navigate('/dashboard/overview');
        }
    }, [keycloak.authenticated]);

    useEffect(() => {
        fetchIntercept.register({
            request: function (url: Request | string, config: InterceptorRequest) {
                let address: string;

                if (url instanceof Request) address = url.url;
                else address = url;

                if (configuration && configuration.EnabledAuthentication && !stringIncludes(address, configuration.ExternalRequests)) {
                    if (requestUrlIncludes(address)) {
                        return Promise.reject();
                    }

                    if (config.body instanceof FormData) {
                        return [url, config];
                    }

                    if (keycloak.idToken != null && config) {
                        config.headers.Authorization = `Bearer ${keycloak.idToken}`;

                        return [address, config];
                    } else {
                        return Promise.reject();
                    }
                } else {
                    return [address, config];
                }
            },

            requestError: function (error) {
                return Promise.reject(error);
            },

            response: function (response) {
                return response;
            },

            responseError: function (error) {
                return Promise.reject(error);
            }
        });
    }, [configuration]);

    if (configuration.EnabledAuthentication) {
        return useSentryRoutes([mainRoutes() ?? LoginRoutes]);
    } else {
        return useSentryRoutes([LoginRoutes]);
    }
}
