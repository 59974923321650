import './BarButtons.scss';

import RoundedCornersButton from '_global/Components/base/button/RoundedCornersButton';
import AlertModal from '_global/Components/base/modals/AlertModal';
import { RootState } from '_global/Services/store';
import { COLORS } from '_global/Utils/Colors';
import { IconToNode } from '_global/Utils/IconToNode';
import { faFloppyDisk, faFloppyDiskCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { TypeColumn, TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import { Download, FilterList, Refresh, Visibility } from '@mui/icons-material';
import { Badge, Popover, Stack } from '@mui/material';
import { FilterType } from 'models/enums/FilterType';
import MessageType from 'models/enums/MessageTypes';
import { MouseEventHandler, MutableRefObject, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import FilterFieldDropdown from './FilterFieldDropdown';

const SEPARATOR = ',';

const downloadBlob = (blob: Blob | MediaSource, fileName = 'DataGridExport.csv') => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.position = 'absolute';
    link.style.visibility = 'hidden';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
};

const exportCSV = (gridRef: ((gridApiRef: MutableRefObject<TypeComputedProps | null>) => void) | any | undefined) => {
    if (gridRef) {
        const columns: TypeColumn[] = gridRef.current.visibleColumns;

        const header: string = columns.map((c) => c.name).join(SEPARATOR);
        const rows = gridRef.current.data.map((data: any) => columns.map((c) => data[c.id!]).join(SEPARATOR));

        const contents = [header].concat(rows).join('\n');
        const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

        downloadBlob(blob);
    }
};

export default function BarButtons(props: {
    onSelection: (arg0: FilterType) => void;
    onRefresh: () => void;
    enableFilter?: boolean;
    setEnableFilter?: (value: boolean) => void;
    numberActiveFilters?: number;
    onVisibilityChanged?: (columns: Map<{ name: string; i18n: string }, boolean>) => void;
    initialColumns: TypeColumn[];
    saveSettings?: () => void;
    clearSettings?: () => void;
    gridRef: ((gridApiRef: MutableRefObject<TypeComputedProps | null>) => void) | undefined;
    userHasOptions: boolean;
}) {
    const {
        onRefresh,
        enableFilter,
        setEnableFilter,
        numberActiveFilters,
        onVisibilityChanged,
        initialColumns,
        saveSettings,
        clearSettings,
        gridRef,
        userHasOptions
    } = props;

    const exportState = useSelector((state: RootState) => state.exporter);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const intl = useIntl();
    const [stateModal, setStateModal] = useState({
        openModal: false,
        responseContent: '',
        responseTitle: '',
        type: MessageType.INFO
    });
    const { responseContent, responseTitle, openModal, type } = stateModal;

    const showModal = (responseTitle: string, responseContent: string, type: MessageType) => {
        setStateModal({
            openModal: true,
            responseTitle: intl.formatMessage({ id: responseTitle }),
            responseContent: intl.formatMessage({ id: responseContent }),
            type: type
        });
    };
    const [currentVisibilityOptions, setCurrentVisibilityOptions] = useState(
        new Map(
            initialColumns.map((obj) => {
                return [{ name: obj.name ?? '', i18n: (obj.header?.props.id as string) ?? '' }, obj.visible ?? true];
            })
        )
    );

    const buttonSX = {
        backgroundColor: COLORS.ENEIDA_SIMPLE_LIGHT_RED,
        color: COLORS.ENEIDA_RED,
        '&:hover': {
            backgroundColor: COLORS.ENEIDA_RED,
            color: COLORS.ENEIDA_SIMPLE_LIGHT_RED
        }
    };

    const handlePopoverOpen: MouseEventHandler<HTMLElement> = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (anchorEl === null) {
            onVisibilityChanged && onVisibilityChanged(currentVisibilityOptions);
        }
    }, [anchorEl]);

    useEffect(() => {
        if (initialColumns) {
            setCurrentVisibilityOptions(
                new Map(
                    initialColumns.map((obj) => {
                        return [{ name: obj.name ?? '', i18n: (obj.header?.props.id as string) ?? '' }, obj.visible ?? true];
                    })
                )
            );
        }
    }, [initialColumns]);

    const handleCloseModal = () => {
        setStateModal({ ...stateModal, openModal: false });
    };
    const handleCancelModal = () => {
        setStateModal({ ...stateModal, openModal: false });
    };
    const handleYesModal = () => {
        if (clearSettings) clearSettings();
        setStateModal({ ...stateModal, openModal: false });
    };

    return (
        <Stack direction="row" justifyContent="center" spacing={1} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <AlertModal
                isOpen={openModal}
                message={responseContent}
                onNo={handleCloseModal}
                onYes={handleYesModal}
                title={responseTitle}
                type={type}
            />
            <RoundedCornersButton onClicked={() => onRefresh()}>
                <Refresh />
            </RoundedCornersButton>

            {onVisibilityChanged && (
                <RoundedCornersButton onClicked={handlePopoverOpen}>
                    <Visibility />
                </RoundedCornersButton>
            )}

            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                onClose={handlePopoverClose}
                open={open}
            >
                <FilterFieldDropdown
                    onOptionsChanged={(options) => {
                        setCurrentVisibilityOptions(options);
                    }}
                    options={currentVisibilityOptions}
                />
            </Popover>

            <RoundedCornersButton
                onClicked={() => {
                    if (saveSettings) saveSettings();
                }}
                sx={{
                    background: userHasOptions ? COLORS.ENEIDA_ALTERNATIVE_BLUE : COLORS.ENDEIDA_SIMPLE_LIGHT_BLUE,
                    color: userHasOptions ? COLORS.ENDEIDA_SIMPLE_LIGHT_BLUE : COLORS.ENEIDA_ALTERNATIVE_BLUE
                }}
            >
                <IconToNode definition={faFloppyDisk} />
            </RoundedCornersButton>

            {userHasOptions ? (
                <RoundedCornersButton
                    onClicked={() => {
                        showModal('warning', 'are-you-sure-clear-settings-this-table', MessageType.INFO);
                    }}
                    sx={buttonSX}
                >
                    <IconToNode definition={faFloppyDiskCircleXmark} />
                </RoundedCornersButton>
            ) : (
                <></>
            )}

            <RoundedCornersButton onClicked={() => exportCSV(gridRef)}>
                <Download />
            </RoundedCornersButton>
            <Badge
                badgeContent={numberActiveFilters}
                color="primary"
                onClick={() => setEnableFilter && setEnableFilter(!enableFilter)}
                sx={{
                    ml: '8px',
                    p: '8px',
                    background: numberActiveFilters ? COLORS.ENEIDA_ALTERNATIVE_BLUE : COLORS.ENDEIDA_SIMPLE_LIGHT_BLUE,
                    color: numberActiveFilters ? COLORS.ENDEIDA_SIMPLE_LIGHT_BLUE : COLORS.ENEIDA_ALTERNATIVE_BLUE,
                    borderRadius: '8px',
                    cursor: 'pointer'
                }}
            >
                <FilterList />
            </Badge>
        </Stack>
    );
}
