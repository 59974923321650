import { TableCell, TableHead, TableRow } from '@mui/material';
import { GenericFeederType } from 'models/asset/AssetModel';
import Mode from 'models/enums/ModeEnum';
import { FormattedMessage } from 'react-intl';
import { IFeederTableHead } from 'views/Assets/utils/AssetModel';

export default function FeederTableHead(props: IFeederTableHead) {
    const position = 'left';
    const { mode, feederType } = props;
    return (
        <TableHead>
            <TableRow>
                <TableCell align={position} key={'feeder_name'}>
                    <FormattedMessage id={'feeder_name'} />
                </TableCell>
                <TableCell align={position} key={'feeder_status'}>
                    <FormattedMessage id={'feeder_status'} />
                </TableCell>
                <TableCell align={position} key={'alarm_profile'}>
                    <FormattedMessage id={'alarm_profile'} />
                </TableCell>
                {feederType === GenericFeederType.FEEDER && (
                    <>
                        <TableCell align={position} key={'cable_type'}>
                            <FormattedMessage id={'cable_type'} />
                        </TableCell>
                        <TableCell align={position} key={'fuse_rating'}>
                            <FormattedMessage id={'fuse_rating'} />
                        </TableCell>
                        {(mode === Mode.EDIT || mode === Mode.ADD) && (
                            <TableCell align={position} key={'feeeder_actions'}>
                                <FormattedMessage id={'feeeder_actions'} />
                            </TableCell>
                        )}
                    </>
                )}
            </TableRow>
        </TableHead>
    );
}
