import { typeFlagRefetch } from '_global/Components/base/filter/FilterTypes';
import { getActiveFilters, getSubmitFilterWithOperators, refactorFiltersToBeQuery } from '_global/Components/base/filter/FilterUtils';
import FilterView from '_global/Components/base/filter/FilterView';
import AlertModal from '_global/Components/base/modals/AlertModal';
import { addGridsPaginations, Pagination, removeGridPagination } from '_global/Services/store/gridsPaginationSlice';
import { useAppDispatch, useAppSelector } from '_global/Services/store/hooks';
import gridsPaginationsUpdateGrid from '_global/Utils/gridsPaginations';
import { gridUpdate } from '_global/Utils/GridUtils';
import { filterAssetAtomReports } from '_global/Utils/hooks/jotai';
import { useQuery } from '@apollo/client';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import {
    TypeColumn,
    TypeComputedProps,
    TypeDataSource,
    TypePaginationProps,
    TypeSingleSortInfo,
    TypeSortInfo
} from '@inovua/reactdatagrid-community/types';
import { Box } from '@mui/system';
import { useAtom } from 'jotai';
import { useRemoteAccessGroupConfiguration } from 'logic/configuration/Hooks';
import { AssetResponse, assetToRow } from 'models/asset/AssetResponse';
import MessageType from 'models/enums/MessageTypes';
import { OrderDirection } from 'models/enums/OrderDirection';
import { ASSET_STATE, ASSET_TYPE } from 'models/graphql-global-types';
import { columnsMap } from 'models/settings/RemoteConfiguration';
import { ASSETS } from 'queries/Assets';
import { Assets } from 'queries/Types/Assets';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TypeColumnExtended } from 'views/Assets/grid/AssetsGrid';
import DataFilter, { GridHandleType } from 'views/Events/grid/filter/DataFilter';

import { defaultRowStyle } from '../../../_global/Components/base/grids/RowUtils';
import { AssetsTabProps } from '../Tabs/AssetsTab';
import { renderColumnsAssets } from './utils';

const gridStyle = {
    flexGrow: 1,
    border: 'none',
    width: '100%',
    height: '100%'
};

const filtersValue = [
    { name: 'registryNumber', type: 'string', i18n: 'registryNumber' },
    { name: 'device', type: 'string', i18n: 'device' },
    { name: 'latitude', type: 'number', i18n: 'latitude' },
    { name: 'longitude', type: 'number', i18n: 'longitude' },
    { name: 'name', type: 'string', i18n: 'name' },
    { name: 'description', type: 'string', i18n: 'description' },
    { name: 'region', type: 'string', i18n: 'region' },
    { name: 'parentFeederName', type: 'string', i18n: 'parentFeederName' },
    { name: 'parentAssetName', type: 'string', i18n: 'parentAssetName' },
    { name: 'alarmStandardName', type: 'string', i18n: 'alarmStandardName' },
    { name: 'lastCommunication', type: 'date', i18n: 'lastCommunication' },
    { name: 'assetType', type: 'enum', i18n: 'assetType', options: ASSET_TYPE },
    { name: 'state', type: 'enum', i18n: 'state', options: ASSET_STATE },
    {
        name: 'operationalArea',
        type: 'string',
        i18n: 'operationalArea'
    },
    {
        name: 'isEnabled',
        type: 'boolean',
        i18n: 'isEnabled'
    },
    {
        name: 'lastModifiedDate',
        type: 'date',
        i18n: 'lastModifiedDate'
    },
    {
        name: 'createdDate',
        type: 'date',
        i18n: 'createdDate'
    }
];

export default function AssetsGrid({ report, handleChangeAll }: AssetsTabProps) {
    const { data: assetsData, loading, error, fetchMore } = useQuery<Assets>(ASSETS);
    const [gridRef, setGridRef] = useState<((gridApiRef: MutableRefObject<TypeComputedProps | null>) => void) | undefined>(undefined);
    const [enableFilter, setEnableFilter] = useState(false);
    const columns_assets: TypeColumn[] = renderColumnsAssets(report, handleChangeAll);
    const [columns, setColumns] = useState<TypeColumn[]>(columns_assets.slice(1, columns_assets.length));
    const [numberActiveFilters, setNumberActiveFilters] = useState(0);
    const [filters, setFilters] = useAtom(filterAssetAtomReports);
    const [flagRefetch, setFlagRefetch] = useState<typeFlagRefetch>(null);
    const [showingError, setShowingError] = useState(false);
    const [sortField, setSortFieldId] = useState<string | Record<string, string | Record<string, unknown>>>({ id: 'DESC' });

    const location = useLocation();
    const dispatch = useAppDispatch();
    const [gridSkip, setGridSkip] = useState<number | undefined>(undefined);
    const [gridSortInfo, setGridSortInfo] = useState<TypeSingleSortInfo | undefined>(undefined);
    const [gridColumnOrder, setGridColumnOrder] = useState<string[] | undefined>(undefined);
    const [gridLimit, setGridLimit] = useState<number | undefined>(undefined);
    const gridsPaginations = useAppSelector((state) => state.gridsPaginations.gridsPaginations);
    const firstRenderRef = useRef(true);

    const sortInfo = useCallback(
        (
            sortBy: string,
            dir: number,
            type?: string,
            columnSortAsc?: Record<string, Record<string, unknown>>,
            columnSortDesc?: Record<string, Record<string, unknown>>
        ) => {
            if (dir === OrderDirection.ASC) {
                if (columnSortAsc) {
                    setSortFieldId(columnSortAsc);
                } else {
                    setSortFieldId({ [sortBy]: 'ASC' });
                }
            } else {
                if (columnSortDesc) {
                    setSortFieldId(columnSortDesc);
                } else {
                    setSortFieldId({ [sortBy]: 'DESC' });
                }
            }
            if (sortBy === '') {
                setSortFieldId({ id: 'DESC' });
            }
        },
        []
    );

    useEffect(() => {
        gridsPaginationsUpdateGrid({
            gridName: 'AssetsGrid',
            gridsPaginations,
            columns: columns,
            sortInfo,
            setGridSkip,
            setGridSortInfo,
            setGridColumnOrder,
            setGridLimit,
            location
        });
    }, [gridsPaginations]);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
        } else {
            dispatch(
                addGridsPaginations({
                    url: location.pathname,
                    grid: { skip: gridSkip, sortInfo: gridSortInfo, columnOrder: gridColumnOrder, limit: gridLimit },
                    key: `AssetsGrid`
                })
            );
        }
    }, [gridSkip, gridSortInfo, gridColumnOrder, gridLimit]);

    const refresh = () => {
        dispatch(
            removeGridPagination({
                key: `AssetsGrid`,
                url: location?.pathname
            })
        );
        gridUpdate();
    };

    const nodes = useMemo(() => assetsData?.assets?.nodes, [assetsData?.assets?.nodes]);

    const pageInfo = useMemo(() => assetsData?.assets?.pageInfo, [assetsData?.assets?.pageInfo]);

    const userAccessGroup = useRemoteAccessGroupConfiguration();

    const initialFlagRefetch = useMemo(
        () => ({ id: { nin: [1, 2, 3] }, and: { id: { in: userAccessGroup.assets } } }),
        [userAccessGroup.assets]
    );

    const specialFilters: Record<string, Record<string, string | number | number[]>>[] = [
        { id: { nin: [1, 2, 3] } },
        { id: { in: userAccessGroup.assets } }
    ];

    const loadData: TypeDataSource = useCallback(
        async (props: TypePaginationProps) => {
            const response = await fetchMore({
                variables: {
                    first: props.skip + props.limit,
                    order: sortField,
                    filter: flagRefetch ?? initialFlagRefetch
                }
            });

            const assets = response?.data?.assets?.nodes?.map((asset) => assetToRow(asset)) ?? [];
            return {
                data: assets.slice(props.skip, assets.length),
                count: response.data.assets?.totalCount ?? 0
            };
        },
        [pageInfo, pageInfo?.endCursor, nodes, flagRefetch, initialFlagRefetch]
    );

    const updateVisibility = useCallback((columnsMapping: columnsMap) => {
        setColumns((columns) => {
            const newColumns: TypeColumn[] = [];

            columnsMapping.forEach((value, key) => {
                const index = columns.findIndex((nc) => nc.name === key.name);
                newColumns.push({ ...columns[index], visible: value });
            });

            return newColumns;
        });
    }, []);

    const rowClassName = (asset: AssetResponse) => {
        if (report.assets.some((ast) => ast.externalAssetId === asset.id)) {
            return 'selected';
        }

        return undefined;
    };

    const handleDeleteAll = () => {
        if (numberActiveFilters <= 0) {
            return;
        }
        setFilters([{ name: '', operator: [], value: [] }]);
        setFlagRefetch(null);
    };

    //This 1, 2 and 3 are the ids or the defaults Assets, created on database. Those should not be shown on list.
    const handleSubmitFilter = () => {
        const { filterWithoutOperators, andFilter, orFilter } = refactorFiltersToBeQuery(filters, filtersValue);
        const newAndFilter = andFilter.concat(specialFilters);

        if (Object.keys(filterWithoutOperators).length > 0) {
            setFlagRefetch(getSubmitFilterWithOperators(filters, filterWithoutOperators, newAndFilter, orFilter));
        } else {
            setFlagRefetch(null);
        }
    };

    useEffect(() => {
        setNumberActiveFilters(getActiveFilters(filters));
    }, [filters]);

    useEffect(() => {
        if (filters.length > 0 && filters[0].name !== '') {
            setEnableFilter(true);
            const { filterWithoutOperators, andFilter, orFilter } = refactorFiltersToBeQuery(filters, filtersValue);
            const newAndFilter = andFilter.concat(specialFilters);
            if (Object.keys(filterWithoutOperators).length > 0) {
                setFlagRefetch(getSubmitFilterWithOperators(filters, filterWithoutOperators, newAndFilter, orFilter));
            } else {
                setFlagRefetch(null);
            }
        } else {
            setEnableFilter(false);
        }
    }, []);

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <DataFilter
                columns={columns}
                onRefresh={refresh}
                onVisibilityChanged={updateVisibility}
                userHasOptions={false}
                {...{ enableFilter, setEnableFilter, numberActiveFilters }}
                gridRef={gridRef}
            />
            {enableFilter && <FilterView {...{ filters, setFilters, filtersValue, handleSubmitFilter, handleDeleteAll, setFlagRefetch }} />}
            <Box sx={{ mt: 2, height: 600 }}>
                <ReactDataGrid
                    columnOrder={gridColumnOrder}
                    columns={[columns_assets[0]].concat(columns)}
                    dataSource={loadData}
                    handle={setGridRef as unknown as GridHandleType}
                    idProperty="id"
                    limit={gridLimit ? gridLimit : 10}
                    loading={loading}
                    onColumnOrderChange={(columnOrder) => {
                        setGridColumnOrder(columnOrder);
                    }}
                    onLimitChange={(limit) => {
                        setGridLimit(limit);
                    }}
                    onSkipChange={(skip) => {
                        setGridSkip(skip);
                    }}
                    onSortInfoChange={(column: TypeSortInfo) => {
                        if (!column) {
                            return sortInfo('', 0);
                        }
                        const section = columns.find((section) => section.name === ((column as TypeSingleSortInfo).id as string));
                        if ((section as TypeColumnExtended)?.columnSortAsc && (section as TypeColumnExtended)?.columnSortDesc) {
                            sortInfo(
                                (column as TypeSingleSortInfo).id as string,
                                (column as TypeSingleSortInfo).dir as number,
                                (column as TypeSingleSortInfo).type as string,
                                (section as TypeColumnExtended).columnSortAsc,
                                (section as TypeColumnExtended).columnSortDesc
                            );
                        } else {
                            sortInfo(
                                (column as TypeSingleSortInfo).id as string,
                                (column as TypeSingleSortInfo).dir as number,
                                (column as TypeSingleSortInfo).type as string
                            );
                        }
                        setGridSortInfo(column as TypeSingleSortInfo);
                    }}
                    pagination={true}
                    rowClassName={({ data }) => rowClassName(data as AssetResponse)}
                    rowHeight={50}
                    rowStyle={defaultRowStyle}
                    showCellBorders={'horizontal'}
                    showColumnMenuTool={false}
                    showZebraRows={false}
                    skip={gridSkip}
                    sortInfo={gridSortInfo}
                    style={gridStyle}
                />
            </Box>
            {error && (
                <AlertModal
                    isOpen={showingError}
                    message={JSON.stringify(error)}
                    onOk={() => setShowingError(false)}
                    type={MessageType.ERROR}
                />
            )}
        </Box>
    );
}
