import { COLORS } from '_global/Utils/Colors';
import { IconToNode } from '_global/Utils/IconToNode';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery } from '@mui/material';
import { useConfiguration, useRemoteConfiguration } from 'logic/configuration/Hooks';
import QRCodeStyling from 'qr-code-styling';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

type ModalProps = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
};

interface QrCodeResp {
    baseApi: string;
    contactSupport: string;
    features: string[];
    id?: number;
    initialMapLocation: { longitude: number; latitude: number };
    keycloakClientId: string;
    keycloakEndpoint: string;
    logo: string;
    name: string;
    oldAppPackageId: string;
    quickGuide: string;
    regions: null;
}

interface QrCode {
    baseApi: string;
    contactSupport: string;
    features: string[];
    id?: number;
    initialMapLocation: { Longitude: number; Latitude: number };
    keycloakClientId: string;
    keycloakEndpoint: string;
    logo: string;
    name: string;
    oldAppPackageId: string;
    quickGuide: string;
    regions: null;
}

const qrCode = new QRCodeStyling({
    width: 600,
    height: 600,
    image: 'https://codaio.imgix.net/docs/iFaEgRVAVL/blobs/bl-Mxj0qJudVi/26b0e9faf5dfb82b1e1037b616adf6fa9fe0d2a13ecd72dd151393bef054e682836633f59d23439a9417c1208ecb24ab5c7f3f342f61ae70334c34ebd710958169ad277c448129b98cb08c7e542a39219f281a9b6ff12135e4505327acc69d34e9b95e92?auto=format%2Ccompress&fit=max',
    dotsOptions: {
        color: '#2C4153',
        type: 'rounded'
    },
    imageOptions: {
        crossOrigin: 'anonymous',
        margin: 20
    },
    qrOptions: {
        errorCorrectionLevel: 'H'
    }
});

const QrCodeView = (props: { qr: QRCodeStyling; changeQr: boolean }) => {
    const { qr, changeQr } = props;
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current) qr.append(ref.current);
    }, []);

    useEffect(() => {
        if (changeQr) {
            qr.update({
                image: undefined,
                dotsOptions: {
                    color: '#000'
                }
            });
        } else {
            qr.update({
                image: 'https://codaio.imgix.net/docs/iFaEgRVAVL/blobs/bl-Mxj0qJudVi/26b0e9faf5dfb82b1e1037b616adf6fa9fe0d2a13ecd72dd151393bef054e682836633f59d23439a9417c1208ecb24ab5c7f3f342f61ae70334c34ebd710958169ad277c448129b98cb08c7e542a39219f281a9b6ff12135e4505327acc69d34e9b95e92?auto=format%2Ccompress&fit=max',
                dotsOptions: {
                    color: '#2C4153'
                }
            });
        }
    }, [changeQr]);

    return <div ref={ref} style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }} />;
};

const ModalQrCode = (props: ModalProps) => {
    const { isOpen, setIsOpen } = props;
    const [config] = useRemoteConfiguration();
    const configuration = useConfiguration();
    const matchesPhone = useMediaQuery('(max-width:600px)');
    const [changeQr, setChangeQr] = useState(false);

    async function getDataQrcode() {
        if (!configuration) return;
        try {
            const response = await fetch(`${configuration.BaseUrl}/authorization/api/QRCode/get-by-name`, {
                method: 'POST',
                body: JSON.stringify({ name: 'Generic' }),
                headers: { 'Content-Type': 'application/json' }
            });

            return response;
        } catch (e) {
            console.error(e);
        }
    }

    const fetchData = async () => {
        const data = await getDataQrcode();

        if (data) {
            const response = (await data.json()) as QrCodeResp;
            delete response.id;

            const correctObj = { ...response } as unknown as QrCode;

            correctObj.initialMapLocation = {
                Longitude: response.initialMapLocation.longitude,
                Latitude: response.initialMapLocation.latitude
            };

            qrCode.update({
                data: JSON.stringify(correctObj),
                width: matchesPhone ? 300 : 600,
                height: matchesPhone ? 300 : 600
            });
        }
    };

    useEffect(() => {
        if (isOpen && config !== undefined) {
            void fetchData();
        }
    }, [isOpen, config]);

    return (
        <Dialog
            PaperProps={{
                style: {
                    boxShadow: 'none',
                    minWidth: matchesPhone ? '300px' : '650px'
                }
            }}
            fullWidth
            open={isOpen}
            scroll={'body'}
            slotProps={{ backdrop: { style: { background: 'rgba(0,0,0,0.9)' } } }}
        >
            <Card>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                    <Typography variant={matchesPhone ? 'h4' : 'h2'}> eneida EdgeForce</Typography>
                    <div
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                        style={{ cursor: 'pointer', position: 'absolute', top: 10, right: 25 }}
                    >
                        <IconToNode definition={faClose} size={matchesPhone ? 'sm' : 'lg'} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <QrCodeView changeQr={changeQr} qr={qrCode} />
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={(event) => {
                            event.preventDefault();
                            setChangeQr(!changeQr);
                        }}
                        size="medium"
                        sx={{ color: 'white', background: COLORS.ENEIDA_ALTERNATIVE_BLUE }}
                        variant={'contained'}
                    >
                        <FormattedMessage id={'alt_qrcode'} />
                    </Button>
                </DialogActions>
            </Card>
        </Dialog>
    );
};

export default ModalQrCode;
