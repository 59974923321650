import { IconToNode } from '_global/Utils/IconToNode';
import {
    faCheckDouble,
    faCircleExclamationCheck,
    faTriangleExclamation,
    faUserHelmetSafety,
    faXmarkLarge
} from '@fortawesome/pro-solid-svg-icons';
import { Box, Grid, Typography } from '@mui/material';
import { EventStatus } from 'models/graphql-global-types';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export default function StatusCell(props: { status: EventStatus }) {
    const { status } = props;

    const statusStyle = useMemo(() => {
        switch (status) {
            case EventStatus.EMPTY:
                return { bgColor: '#f5f5f5', txtColor: '#616161', icon: faXmarkLarge };
            case EventStatus.UNRESOLVED:
                return { bgColor: '#ffebee', txtColor: '#d32f2f', icon: faTriangleExclamation };
            case EventStatus.RESOLVING:
                return { bgColor: '#fffde7', txtColor: '#fbc02d', icon: faUserHelmetSafety };
            case EventStatus.RESOLVED:
                return { bgColor: '#e8f5e9', txtColor: '#388e3c', icon: faCircleExclamationCheck };
            case EventStatus.ACKNOWLEDGE:
                return { bgColor: '#e3f2fd', txtColor: '#1976d2', icon: faCheckDouble };
        }
    }, [status]);

    return (
        <Box
            sx={{
                borderRadius: 2,
                width: 130,
                height: '35px',
                backgroundColor: statusStyle.bgColor,
                my: 1
            }}
        >
            <Grid container direction="row" spacing={1} sx={{ mx: 0, my: 0 }}>
                <Grid item>
                    {statusStyle.icon && <IconToNode definition={statusStyle.icon} sx={{ fontSize: 17, color: statusStyle.txtColor }} />}
                </Grid>
                <Grid item>
                    <Typography color={statusStyle.txtColor} fontSize={14} sx={{ fontWeight: 'bold', my: 0.2 }}>
                        <FormattedMessage id={status.toLowerCase()} />
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
