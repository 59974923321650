import '_global/Resources/assets/scss/style.scss';

import ConfigLoader from '_global/Components/base/ConfigLoader';
import { store } from '_global/Services/store';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import App from 'App';
import { enableMapSet } from 'immer';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as serviceWorker from 'serviceWorker';

// use it in sentry init
Sentry.init({
    dsn: 'https://0707b2e1c76b4e099de15e3d8ac5fca5@sentry.deepgrid.io/4',
    integrations: [
        new Sentry.Integrations.Breadcrumbs({ console: false }),
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                matchRoutes
            )
        }),
        new ExtraErrorDataIntegration()
    ],
    tracesSampleRate: 0.3,
    normalizeDepth: 10,
    environment: import.meta.env.VITE_CONFIG_NAME as string
});

window.global ||= window;

enableMapSet();
setUseWhatChange(process.env.NODE_ENV === 'development');

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <Provider store={store}>
        <ConfigLoader />
        <App />
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
