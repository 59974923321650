import DeepgridLoader from '_global/Components/base/DeepgridLoader';
import Loader from '_global/Components/base/Loader';
import { drawerWidth } from '_global/Services/store/constant';
import { removeAllGridsPagination } from '_global/Services/store/gridsPaginationSlice';
import { useAppDispatch } from '_global/Services/store/hooks';
import { removeAllTabs } from '_global/Services/store/tabComponentSlice';
import useIdle from '_global/Utils/hooks/IdleTimerHook';
import { reRenderMapAtomDashboard } from '_global/Utils/hooks/jotai';
import { AppBar, Box, Toolbar, useMediaQuery } from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Header from './Header';
import Sidebar from './Sidebar';

interface MainStyleProps {
    theme: Theme;
    open: boolean;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    height: '100%',
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        padding: '10px',
        marginRight: 0,
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 10),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '10px',
        marginRight: 0,
        [theme.breakpoints.down('md')]: {
            marginLeft: '10px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Handle left drawer
    const [leftDrawerOpened, setLeftDrawerOpened] = useState(!matchDownMd);

    //Interceptor Loading Component
    const [showLoading, setShowLoading] = useState(false);
    const [showSpecialLoading, setShowSpecialLoading] = useState(false);
    const { fetch: originalFetch } = window;
    const { keycloak, initialized } = useKeycloak();
    const { isIdle } = useIdle({ idleTime: 30 });
    const [renderMap, setRenderMap] = useAtom(reRenderMapAtomDashboard);

    useEffect(() => {
        if (keycloak && initialized) {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            keycloak.onAuthRefreshError = async () => {
                try {
                    dispatch(removeAllGridsPagination());
                    dispatch(removeAllTabs());
                    await keycloak.logout();
                } catch (err) {
                    console.error(err);
                }
            };
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            keycloak.onTokenExpired = async () => {
                try {
                    dispatch(removeAllGridsPagination());
                    dispatch(removeAllTabs());
                    await keycloak.logout();
                } catch (err) {
                    console.error(err);
                }
            };
        }
        return () => {
            if (keycloak) {
                keycloak.onTokenExpired = () => {};
                keycloak.onAuthRefreshError = () => {};
            }
        };
    }, [initialized, keycloak]);

    useEffect(() => {
        async function isUserInactive() {
            try {
                dispatch(removeAllGridsPagination());
                dispatch(removeAllTabs());
                await keycloak.logout();
            } catch (err) {
                console.error(err);
            }
        }

        if (isIdle) {
            void isUserInactive();
        }
    }, [isIdle]);

    useEffect(() => {
        window.fetch = async (...args) => {
            const [resource, config] = args;
            if (
                (config?.method === 'POST' || config?.method === 'PUT') &&
                !resource.toString().includes('graphql') &&
                !resource.toString().includes('getusersetting')
            ) {
                setShowSpecialLoading(true);
            } else {
                setShowLoading(true);
            }

            const response = await originalFetch(resource, config);

            // Error Interceptor
            if (!response.ok && response.status === 404) {
                // 404 Error Handling
                setShowLoading(false);
                setShowSpecialLoading(false);
                return Promise.reject(response);
            }

            // Response interceptor
            const json = () => response.clone().json();

            response.json = json;
            setShowLoading(false);
            setShowSpecialLoading(false);
            return response;
        };
    }, []);

    return (
        <>
            {showLoading && <Loader />}
            <DeepgridLoader open={showSpecialLoading} />

            <Box sx={{ display: 'flex', margin: 0, overflow: 'hidden', height: '100vh' }}>
                {/* header */}
                <AppBar
                    color="inherit"
                    elevation={0}
                    enableColorOnDark
                    position="fixed"
                    sx={{
                        bgcolor: theme.palette.background.default,
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                    }}
                >
                    <Toolbar>
                        <Header
                            handleLeftDrawerToggle={() => {
                                setRenderMap(!renderMap);
                                setLeftDrawerOpened(!leftDrawerOpened);
                            }}
                        />
                    </Toolbar>
                </AppBar>
                {/* drawer */}
                <Sidebar
                    drawerOpen={leftDrawerOpened}
                    drawerToggle={() => {
                        setLeftDrawerOpened(!leftDrawerOpened);
                    }}
                />
                {/* main content */}
                <Main open={leftDrawerOpened} theme={theme}>
                    <Outlet />
                </Main>
            </Box>
        </>
    );
};

export default MainLayout;
