export const getSessionHeaders = (contentType = 'application/json') => {
    return {
        'Content-type': contentType
    };
};

export async function getData<T>(endpoint: string): Promise<T> {
    const response = await fetch(endpoint, {
        method: 'GET',
        headers: getSessionHeaders()
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return (await response.json()) as Promise<T>;
}

export async function deleteData<T>(endpoint: string): Promise<T> {
    const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: getSessionHeaders()
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return (await response.json()) as Promise<T>;
}
