export enum DevicesModel {
    DTVIg = 100,
    DTVIm = 110,
    DTVI4g = 120,
    DTVIgPMT2 = 87,
    DTVI4gPMT2 = 88,
    DTVIgPMT = 94,
    DTVI4gPMT = 95,
    DTE = 72,
    INGRID90 = 90,
    INGRID91 = 91,
    INGRID92 = 92
}

export const backgroundColor = (deviceType: DevicesModel) => {
    switch (deviceType) {
        case DevicesModel.DTVIg:
            return '#495967';
        case DevicesModel.DTVIm:
            return '#77878B';
        case DevicesModel.INGRID90 || DevicesModel.INGRID91 || DevicesModel.INGRID92:
            return '#B7D5D4';
        case DevicesModel.DTVI4g:
            return '#4F7C84';
        case DevicesModel.DTVIgPMT:
            return '#E4C35F';
        case DevicesModel.DTVI4gPMT2:
            return '#E4C35F';
        case DevicesModel.DTVI4gPMT:
            return '#F3D886';
        case DevicesModel.DTVIgPMT2:
            return '#F3D886';
        case DevicesModel.DTE:
            return '#5D4149';
        default:
            return '#f5d676';
    }
};

export const textColor = (deviceType: DevicesModel) => {
    switch (deviceType) {
        case DevicesModel.DTVIg:
            return '#ffffff';
        case DevicesModel.DTVIm:
            return '#ffffff';
        case DevicesModel.DTE:
            return '#ffffff';
        case DevicesModel.DTVI4g:
            return '#ffffff';
        case DevicesModel.INGRID90 || DevicesModel.INGRID91 || DevicesModel.INGRID92:
            return '#000000';
        default:
            return '#000000';
    }
};
