import CollapseCard from '_global/Components/base/cards/CollapseCard';
import { useQuery } from '@apollo/client';
import { TypeComputedProps, TypeDataSource } from '@inovua/reactdatagrid-community/types';
import { Button } from '@mui/material';
import { eventToRow, ProcessedEvent } from 'models/events/ProcessedEvent';
import { EVENTS_BY_EQUIPMENT_ID } from 'queries/EventsQuery';
import { Events_By_Equipment_Id } from 'queries/Types/Events_By_Equipment_Id';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { default_columns_event } from 'views/Events/AlarmsPageInternal';
import EventsGrid from 'views/Events/grid/EventsGrid';

import { runFunctionWithCacheAsync } from '../../../../../logic/cache/useCachedFunction';

const MAX_NUMBER_OF_EVENTS = 5;

const EventsPreview = (props: { id: number }) => {
    const { id } = props;
    const navigate = useNavigate();
    const [selectedEvent, setSelectedEvent] = useState<ProcessedEvent | undefined>(undefined);
    const [, setGridRef] = useState<((gridApiRef: MutableRefObject<TypeComputedProps | null>) => void) | undefined>(undefined);
    undefined;
    useEffect(() => {
        if (selectedEvent) {
            navigate(`/alarms/${selectedEvent.id}`);
        }
    }, [selectedEvent]);

    const { fetchMore } = useQuery<Events_By_Equipment_Id>(EVENTS_BY_EQUIPMENT_ID, {
        variables: {
            equipmentId: id
        }
    });

    const loadData: TypeDataSource = async () => {
        const response = await runFunctionWithCacheAsync(
            async () =>
                await fetchMore({
                    variables: {
                        first: MAX_NUMBER_OF_EVENTS
                    }
                }),
            [MAX_NUMBER_OF_EVENTS]
        );

        const events = response?.data?.events?.nodes?.map((event) => eventToRow(event)) ?? [];

        return {
            data: events,
            count: response.data.events?.totalCount ?? 0
        };
    };

    const goToEventsPage = () => {
        navigate('/alarms');
    };

    const showAlarms = useCallback(() => {
        return (
            <CollapseCard startExpanded={true} title={<FormattedMessage id={'alarms'} />}>
                <EventsGrid
                    columns={default_columns_event}
                    data={loadData}
                    minHeight={'30vh'}
                    onRowPressed={(event) => setSelectedEvent(event)}
                    pagination={false}
                    setGridRef={setGridRef}
                />
                <Button className="headers" onClick={() => goToEventsPage()} style={{ marginTop: '8px' }}>
                    <FormattedMessage id={'view-all-alarms'} />
                </Button>
            </CollapseCard>
        );
    }, [props]);

    return showAlarms();
};

export default EventsPreview;
