import { ListItem, ListItemText, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage } from 'react-intl';

export const GlobalNoListItems = () => {
    return (
        <Box>
            <ListItem>
                <ListItemText
                    primary={
                        <Typography sx={{ fontSize: 12, color: 'black', mx: 1 }}>
                            <FormattedMessage id="no_items" />
                        </Typography>
                    }
                />
            </ListItem>
        </Box>
    );
};
