/*eslint-disable*/
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useControl } from 'react-map-gl';

import type { MapRef, ControlPosition } from 'react-map-gl';
import { Feature } from 'geojson';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
    position?: ControlPosition;
    onDrawGotRef?: (ref: MapboxDraw) => void;

    onCreate?: (evt: { features: Feature[] }) => void;
    onUpdate?: (evt: { features: Feature[]; action: string }) => void;
    onDelete?: (evt: { features: Feature[] }) => void;
    onSelect?: (evt: { features: Feature[] }) => void;
};

async function updateAfterRender(delay: number, callback: Function) {
    await new Promise((r) => setTimeout(r, delay));
    callback();
}

export default function DrawControl(props: DrawControlProps) {
    // @ts-ignore
    const ref = useControl<MapboxDraw>(
        ({ map }: { map: MapRef }) => {
            // @ts-ignore
            map.on('draw.create', props.onCreate);
            // @ts-ignore
            map.on('draw.update', props.onUpdate);
            // @ts-ignore
            map.on('draw.delete', props.onDelete);
            // @ts-ignore
            map.on('draw.selectionchange', props.onSelect);
            return new MapboxDraw(props);
        },
        ({ map }: { map: MapRef }) => {
            // @ts-ignore
            map.off('draw.create', props.onCreate);
            // @ts-ignore
            map.off('draw.update', props.onUpdate);
            // @ts-ignore
            map.off('draw.delete', props.onDelete);
            // @ts-ignore
            map.off('draw.selectionchange', props.onSelect);
        },
        {
            position: props.position
        }
    );

    updateAfterRender(50, () => {
        if (props.onDrawGotRef !== undefined) props.onDrawGotRef(ref);
    }).catch(console.error);

    return null;
}

DrawControl.defaultProps = {
    onCreate: () => {},
    onUpdate: () => {},
    onDelete: () => {}
};
