import MainCard from '_global/Components/base/cards/MainCard';
import { getData } from '_global/Services/api/networkRequester';
import { RootState } from '_global/Services/store';
import { hasLastDataSet } from '_global/Utils/hooks/jotai';
import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { Box, Divider, Grid } from '@mui/material';
import { useAtom } from 'jotai';
import { CATEGORY } from 'models/asset/AssetHistoricalData';
import { AssetModel } from 'models/asset/AssetModel';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import ButtonComponent from '../../../../_global/Components/base/button/Button';
import Category from './Category';
import HistoricalDataFilters from './HistoricalDataFilters';
import HistoricalDataGraphs from './HistoricalDataGraphs';

export default function HistoricalData(props: { id: number }) {
    const { id } = props;
    const features = useSelector((state: RootState) => state.features);
    const [assetModel, setAssetModel] = useState<AssetModel>();

    const [shouldLoadGraphs, setShowLoadGraphs] = useState<boolean>(false);
    const [hasDefaultFilters, setHasDefaultFilters] = useState<boolean>(true);
    const [grafanaFilters, setGrafanaFilters] = useState<string>('');
    const [hasLastData] = useAtom(hasLastDataSet);
    const [category, setCategory] = useState<CATEGORY>(hasLastData ? CATEGORY.MEASURE : CATEGORY.EARTH_SYSTEM);

    useEffect(() => {
        const url = '/equipments/api/equipment/Asset';
        const endpoint = `${features.configuration?.BaseUrl ?? ''}${url}/${id ?? ''}`;

        getData<AssetModel>(endpoint)
            .then((data) => {
                setAssetModel(data);
            })
            .catch((e) => {
                setAssetModel(undefined);
                console.error(e);
            });
    }, [props]);

    const onCategoryChange = useCallback((value: CATEGORY) => {
        setCategory(value);
        setGrafanaFilters('');
    }, []);

    const onClearFilters = useCallback(() => {
        setShowLoadGraphs(false);
        setHasDefaultFilters(true);
        setGrafanaFilters('');
    }, []);

    const onFiltersChange = useCallback(() => {
        setHasDefaultFilters(false);
    }, []);

    const onConfirmFilters = useCallback(() => {
        setGrafanaFilters('');
        setShowLoadGraphs(true);
    }, []);

    const onSaveGrafanaFilter = useCallback((filters: string) => {
        setGrafanaFilters(filters);
        setShowLoadGraphs(false);
    }, []);

    return (
        <Box>
            {!hasLastData && !assetModel?.hasEarth ? (
                <Grid alignItems="center" container direction="column">
                    <Grid item sx={{ mt: '10%', mb: '5%' }}>
                        <FormattedMessage id={'no-data'} />
                    </Grid>
                </Grid>
            ) : (
                <MainCard className="main-card" content={false} style={{ paddingBottom: '10px' }}>
                    <Grid container spacing={2} sx={{ p: 4 }}>
                        <Category
                            assetModel={assetModel}
                            category={category}
                            hasLastData={hasLastData}
                            onChange={(value) => onCategoryChange(value)}
                        />

                        {assetModel && (
                            <HistoricalDataFilters
                                asset={assetModel}
                                category={category}
                                clearFilter={hasDefaultFilters}
                                onConfirmPressed={shouldLoadGraphs}
                                onFiltersChange={onFiltersChange}
                                onSaveGrafanaFilter={onSaveGrafanaFilter}
                            />
                        )}
                    </Grid>

                    <Divider sx={{ mx: 2 }} />

                    <Grid container>
                        <Grid item xs={8}></Grid>
                        <Grid item sx={{ padding: '8px' }} xs>
                            <ButtonComponent
                                color="inherit"
                                disabled={hasDefaultFilters}
                                icon={<ClearAllIcon />}
                                onClick={() => {
                                    onClearFilters();
                                }}
                                title="clear"
                            />
                        </Grid>

                        <Grid item sx={{ padding: '8px' }} xs>
                            <ButtonComponent
                                disabled={shouldLoadGraphs}
                                icon={<CheckIcon />}
                                onClick={() => onConfirmFilters()}
                                title="confirm"
                            />
                        </Grid>
                    </Grid>
                    {grafanaFilters !== '' ? <HistoricalDataGraphs assetId={id} category={category} filter={grafanaFilters} /> : <></>}
                </MainCard>
            )}
        </Box>
    );
}
