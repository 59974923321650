import MainCard from '_global/Components/base/cards/MainCard';
import Transitions from '_global/Components/base/extended/Transitions';
import ModalQrCode from '_global/Components/base/modals/ModalQrCode';
import { getData } from '_global/Services/api/networkRequester';
import { removeAllGridsPagination } from '_global/Services/store/gridsPaginationSlice';
import { removeAllTabs } from '_global/Services/store/tabComponentSlice';
import { faArrowRightFromBracket, faCircleQuestion, faQrcode } from '@fortawesome/pro-light-svg-icons';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import {
    Avatar,
    Box,
    ButtonBase,
    ClickAwayListener,
    Divider,
    Grid,
    Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';
import { DefaultRootStateProps } from 'models';
import { Profile } from 'models/layout/Profile';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../../../_global/Services/store/hooks';
import { IconToNode } from '../../../../../_global/Utils/IconToNode';
import { useConfiguration, useRemoteConfiguration } from '../../../../../logic/configuration/Hooks';

const ProfileSection = () => {
    const theme = useTheme();

    const customization = useSelector((state: DefaultRootStateProps) => state.customization);
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);

    const { keycloak } = useKeycloak();
    const configuration = useConfiguration();
    const dispatch = useAppDispatch();
    const [profile, setProfile] = useState<Profile>();
    const [config] = useRemoteConfiguration();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (config !== undefined) {
            const endpoint = `${configuration?.BaseUrl ?? ''}/authorization/api/User/${config.id}`;
            getData<Profile>(endpoint)
                .then((data) => {
                    setProfile(data);
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    }, [config]);

    /**
     * anchorRef is used on different ReactComponents and specifying one type leads to other ReactComponents throwing an error
     * */
    // eslint-disable-next-line
    const anchorRef = React.useRef<any>(null);

    const handleLogout = async () => {
        try {
            dispatch(removeAllGridsPagination());
            dispatch(removeAllTabs());
            await keycloak.logout();
        } catch (err) {
            console.error(err);
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        // eslint-disable-next-line
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleListItemClick = (event: MouseEvent, index: number, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current && !open) {
            // eslint-disable-next-line
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <ModalQrCode {...{ isOpen, setIsOpen }} />
            <Box>
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleToggle}
                        ref={anchorRef}
                        sx={{
                            border: '1px solid',
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            transition: 'all .2s ease-in-out',
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                borderColor: theme.palette.secondary.main,
                                background: theme.palette.secondary.main,
                                color: theme.palette.primary.light
                            }
                        }}
                        variant="rounded"
                    >
                        <IconToNode definition={faUser} />
                    </Avatar>
                </ButtonBase>
            </Box>
            <Popper
                disablePortal
                placement="bottom-end"
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
                role={undefined}
                transition
                open={open}
                /* eslint-disable-next-line */
                anchorEl={anchorRef.current}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={true} boxShadow content={false} elevation={16} shadow={theme.shadows[16]}>
                                    <Box sx={{ ml: 2.5, mt: 1.5 }}>
                                        <Stack>
                                            <Stack alignItems="center" direction="row" marginBottom={1} marginTop={2}>
                                                <Typography marginRight={0.75} variant="h4">
                                                    <FormattedMessage id="welcome" />
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    <i>{profile?.firstName}</i>
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Divider />
                                    </Box>
                                    <List
                                        component="nav"
                                        sx={{
                                            width: '100%',
                                            maxWidth: 350,
                                            minWidth: 250,
                                            backgroundColor: theme.palette.background.paper,
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <ListItemButton
                                            onClick={(event: MouseEvent) => handleListItemClick(event, 0, '/user/profile')}
                                            selected={selectedIndex === 0}
                                            sx={{ borderRadius: `${customization.borderRadius ?? 0}px` }}
                                        >
                                            <ListItemIcon>
                                                <IconToNode definition={faUser} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Grid container justifyContent="space-between" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="body2">
                                                                <FormattedMessage id="profile" />
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        </ListItemButton>

                                        <ListItemButton
                                            onClick={(event) => {
                                                handleClose(event);
                                                setIsOpen(!isOpen);
                                            }}
                                            selected={selectedIndex === 1}
                                            sx={{ borderRadius: `${customization.borderRadius ?? 0}px` }}
                                        >
                                            <ListItemIcon>
                                                <IconToNode definition={faQrcode} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Grid container justifyContent="space-between" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="body2">
                                                                <FormattedMessage id="qrcode" />
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        </ListItemButton>

                                        <Link href={configuration?.HelpLink ?? ''} style={{ textDecoration: 'none' }} target="_blank">
                                            <ListItemButton
                                                selected={selectedIndex === 2}
                                                sx={{ borderRadius: `${customization.borderRadius ?? 0}px` }}
                                            >
                                                <ListItemIcon>
                                                    <IconToNode definition={faCircleQuestion} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Grid container justifyContent="space-between" spacing={1}>
                                                            <Grid item>
                                                                <Typography variant="body2">
                                                                    <FormattedMessage id="help" />
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                />
                                            </ListItemButton>
                                        </Link>

                                        <ListItemButton
                                            onClick={() => {
                                                handleLogout().catch(console.error);
                                            }}
                                            selected={selectedIndex === 3}
                                            sx={{ borderRadius: `${customization.borderRadius ?? 0}px` }}
                                        >
                                            <ListItemIcon>
                                                <IconToNode definition={faArrowRightFromBracket} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body2">
                                                        <FormattedMessage id="logout" />
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </List>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
