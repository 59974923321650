import { Box, Divider, Paper, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NoMatch = () => {
    const location = useLocation();

    useEffect(() => {
        console.error(`${location.pathname} is not a valid route.`);
    }, []);

    return (
        <Box sx={{ width: '100%', paddingY: '10px' }}>
            <Paper sx={{ maxWidth: '400px', padding: '8px', margin: '0 auto' }}>
                <Typography sx={{ textAlign: 'center' }} variant="h2">
                    Route not found
                </Typography>

                <Divider sx={{ marginY: '10px' }} />

                <Typography sx={{ textAlign: 'center' }} variant="h4">{`${location.pathname} is not a valid route.`}</Typography>
            </Paper>
        </Box>
    );
};

export default NoMatch;
