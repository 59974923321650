import { IconToNode } from '_global/Utils/IconToNode';
import { faCircleNodes, faHorizontalRule, faMicrochip, faTimeline } from '@fortawesome/pro-solid-svg-icons';
import { Box, Grid, Typography } from '@mui/material';
import { ENTITY_TYPE } from 'models/graphql-global-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export default function EntityCell(props: { entityType: ENTITY_TYPE }) {
    const { entityType } = props;
    const intl = useIntl();
    const iconStyle = { fontSize: 17, color: 'white' };
    const txtStyle = { fontWeight: 'bold', my: 0.2, color: 'white' };

    const entityStyle = useMemo(() => {
        switch (entityType) {
            case ENTITY_TYPE.ASSET:
                return { bgColor: '#7e57c2', icon: faCircleNodes };
            case ENTITY_TYPE.ASSET_BUSBAR:
                return { bgColor: '#5c6bc0', icon: faHorizontalRule };
            case ENTITY_TYPE.DEVICE:
                return { bgColor: '#1e88e5', icon: faMicrochip };
            case ENTITY_TYPE.FEEDER:
                return { bgColor: '#757575', icon: faTimeline };
        }
    }, [entityType]);

    return (
        <Box
            sx={{
                borderRadius: 2,
                width: 130,
                height: '35px',
                backgroundColor: entityStyle.bgColor,
                my: 1,
                px: 1
            }}
        >
            <Grid container direction="row" spacing={1}>
                <Grid item>{entityStyle.icon && <IconToNode definition={entityStyle.icon} sx={iconStyle} />}</Grid>
                <Grid item>
                    <Typography fontSize={14} sx={txtStyle}>
                        {intl.formatMessage({ id: (entityType as string).toLowerCase() })}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
