import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

import { Group } from '../../../logic/misc/MapExtensions';

export type Tab = { component: ReactNode; url?: string };

export type Tabs = Group<string, Tab>;

export interface TabState {
    tabs: Tabs;
}

const initialState: TabState = {
    tabs: new Group()
};

export const tabsSlice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        setTabs: (state, action: PayloadAction<Tabs>) => {
            state.tabs = action.payload;
        },
        updateTab: (state, action: PayloadAction<{ name: string; component: ReactNode; url?: string }>) => {
            state.tabs.set(action.payload.name, { component: action.payload.component, url: action.payload.url });
        },
        addTab: (state, action: PayloadAction<{ name: string; component: ReactNode; url?: string }>) => {
            const newMap = new Group(state.tabs);
            newMap.set(action.payload.name, { component: action.payload.component, url: action.payload.url });

            const sessionTabs = sessionStorage.getItem('sessionTabs');

            if (sessionTabs) {
                const newSessionTabs = [...new Set([...JSON.parse(sessionTabs), action.payload.url])];
                sessionStorage.setItem('sessionTabs', JSON.stringify(newSessionTabs));
            } else {
                sessionStorage.setItem('sessionTabs', JSON.stringify([action.payload.url]));
            }

            state.tabs = newMap;
        },
        removeTab: (state, action: PayloadAction<{ removeName: string; removeUrl?: string }>) => {
            const newMap = new Group(state.tabs);
            newMap.delete(action.payload.removeName);

            const sessionTabs = sessionStorage.getItem('sessionTabs');

            if (sessionTabs) {
                const newSessionTabs: string[] = JSON.parse(sessionTabs).filter((tabUrl: string) => tabUrl !== action.payload.removeUrl);
                sessionStorage.setItem('sessionTabs', JSON.stringify(newSessionTabs));
            } else {
                sessionStorage.setItem('sessionTabs', JSON.stringify([]));
            }

            state.tabs = newMap;
        },
        removeAllTabs: () => {
            sessionStorage.removeItem('sessionTabs');
        }
    }
});

// Action creators are generated for each case reducer function
export const { setTabs, updateTab, addTab, removeTab, removeAllTabs } = tabsSlice.actions;

export default tabsSlice.reducer;
