import CollapseCard from '_global/Components/base/cards/CollapseCard';
import { Grid } from '@mui/material';
import { IAssetReportDetails, IItemReportDetails, IReportDetails } from 'models/report/ReportResponse';
import { FormattedMessage } from 'react-intl';

import { AssetsListButton } from '../AssetsList/AssetsListButton';
import AccessGroupsGrid from '../Grid/AccessGroupsGrid';
import AssetsGrid from '../Grid/AssetsGrid';
import CompaniesGrid from '../Grid/CompaniesGrid';
import OpAreasGrid from '../Grid/OpAreasGrid';
import RegionsGrid from '../Grid/RegionsGrid';

export interface AssetsTabProps {
    report: IReportDetails;
    handleChangeAll: (id: string, items: IItemReportDetails[] | IAssetReportDetails[]) => void;
}

export const AssetsTab = (props: AssetsTabProps) => {
    const { report, handleChangeAll } = props;

    return (
        <Grid container spacing={2}>
            <Grid item sx={{ mt: 2 }} xs={9}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CollapseCard title={<FormattedMessage id={'accessGroups'} />}>
                            <AccessGroupsGrid handleChangeAll={handleChangeAll} report={report} />
                        </CollapseCard>
                    </Grid>
                    <Grid item xs={12}>
                        <CollapseCard title={<FormattedMessage id={'companies'} />}>
                            <CompaniesGrid handleChangeAll={handleChangeAll} report={report} />
                        </CollapseCard>
                    </Grid>
                    <Grid item xs={12}>
                        <CollapseCard title={<FormattedMessage id={'regions'} />}>
                            <RegionsGrid handleChangeAll={handleChangeAll} report={report} />
                        </CollapseCard>
                    </Grid>
                    <Grid item xs={12}>
                        <CollapseCard title={<FormattedMessage id={'operational-areas'} />}>
                            <OpAreasGrid handleChangeAll={handleChangeAll} report={report} />
                        </CollapseCard>
                    </Grid>
                    <Grid item xs={12}>
                        <CollapseCard title={<FormattedMessage id={'assets'} />}>
                            <AssetsGrid handleChangeAll={handleChangeAll} report={report} />
                        </CollapseCard>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{ mt: 2 }} xs={3}>
                <AssetsListButton handleChangeAll={handleChangeAll} report={report} />
            </Grid>
        </Grid>
    );
};
