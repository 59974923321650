import MainCard from '_global/Components/base/cards/MainCard';
import GrafanaHolder from '_global/Components/base/grafana/GrafanaHolder';
import { RootState } from '_global/Services/store';
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EventProps } from './EventDetails';

const PATH = '/d/IgY7c1f7z/wfc?&var-measurement=WaveFormData&var-event_id=';
const THEME = '&theme=light';

export const EVENT_GRAPHS_VIEW_PANEL = {
    wfc: [8, 3]
};

export default function EventGraphs(props: EventProps) {
    const { alarm } = props;
    const features = useSelector((state: RootState) => state.features);

    const baseURL = features.configuration?.BaseUrl ?? '';
    const grafana = features.configuration?.Grafana.url ?? '';

    const [grafanaUrlGraphs, setGrafanaUrlGraphs] = useState<string[]>([]);

    useEffect(() => {
        const viewPanels = Object.entries(EVENT_GRAPHS_VIEW_PANEL).filter(([key]) => key);

        if (viewPanels) {
            const grafanaGraphs: string[] = [];
            viewPanels[0][1].map((viewPanel) => {
                grafanaGraphs.push(`${baseURL}${grafana}${PATH}${alarm.id}${THEME}&kiosk&viewPanel=${viewPanel}`);
            });

            setGrafanaUrlGraphs(grafanaGraphs);
        }
    }, [alarm]);

    return (
        <Box>
            {alarm?.eventData && (
                <MainCard content={false}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ p: 3 }}>
                        {grafanaUrlGraphs.map((url) => {
                            return (
                                <Grid item xs={12} key={url}>
                                    <GrafanaHolder url={url} {...props} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </MainCard>
            )}
        </Box>
    );
}
