import '@inovua/reactdatagrid-community/index.css';

import { TableCell, TableRow, Typography } from '@mui/material';
import { AlarmStatus, EventData } from 'models/events/ProcessedEvent';
import { EventStatus } from 'models/graphql-global-types';
import { FormattedMessage } from 'react-intl';

import StatusCell from '../grid/cells/StatusCell';

type EventHistoryTableRowProps = {
    data: EventData;
};
export default function EventHistoryTableRow(props: EventHistoryTableRowProps) {
    const { data: eventData } = props;
    const position = 'left';
    const status = Object.entries(AlarmStatus).find(([, val]) => val === eventData.status)?.[0] ?? '';

    return (
        <TableRow hover>
            <TableCell align={position} sx={{ width: '10%' }}>
                <Typography>
                    {eventData.createdDate ? new Date(eventData.createdDate).toLocaleString() : <FormattedMessage id={'na'} />}
                </Typography>
            </TableCell>
            <TableCell align={position} sx={{ width: '10%' }}>
                <StatusCell status={status as EventStatus} />
            </TableCell>
            <TableCell align={position} sx={{ width: '60%' }}>
                <Typography>{eventData.notes ? eventData.notes : <FormattedMessage id={'na'} />}</Typography>
            </TableCell>
            <TableCell align={position} sx={{ width: '20%' }}>
                <Typography>{eventData.createdBy ? eventData.createdBy : <FormattedMessage id={'na'} />}</Typography>
            </TableCell>
        </TableRow>
    );
}
