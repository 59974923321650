import { Avatar, ButtonBase } from '@mui/material';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import React, { MouseEventHandler } from 'react';

export type RoundedCornersButtonProps<T> = {
    sx?: SxProps<Theme>;
    onClicked?: MouseEventHandler<T>;
    children?: React.ReactNode;
};

export default function RoundedCornersButton(props: RoundedCornersButtonProps<HTMLElement>) {
    const { sx, onClicked, children } = props;

    const theme = useTheme();

    return (
        <ButtonBase sx={{ overflow: 'hidden' }} onClick={onClicked}>
            <Avatar
                variant="rounded"
                sx={[
                    {
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        '&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                        }
                    },
                    ...(Array.isArray(sx) ? sx : [sx])
                ]}
                color="inherit"
            >
                {children}
            </Avatar>
        </ButtonBase>
    );
}
