import 'mapbox-gl/dist/mapbox-gl.css';

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Alert, AlertTitle, Box, Stack } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { bbox } from '@turf/turf';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Map, {
    FullscreenControl,
    GeoJSONSource,
    GeolocateControl,
    Layer,
    LngLatBoundsLike,
    MapRef,
    Marker,
    NavigationControl,
    Popup,
    ScaleControl,
    Source,
    ViewState,
    ViewStateChangeEvent
} from 'react-map-gl';

import { Configuration } from '../../../../logic/configuration/Configuration';
import { useConfiguration } from '../../../../logic/configuration/Hooks';
import { auxDataLayer, clusterCountLayer, clusterLayer, dataLayer, unclusteredPointLayer } from './LayerStyle';
import DrawControl from './logic/DrawControl';
import MapPolygonsHandler from './logic/MapPolygonsHandler';
import { MapLocation, MapMarker, MapPopup } from './logic/MapTypes';
import { randomString } from './logic/MapUtils';
import MapFeaturesControl from './MapFeaturesControl';

export type MapComponentProps = {
    initialPosition?: MapLocation;
    initialZoom?: number;
    showHelpBox?: boolean;
    markers?: MapMarker[];
    onMapMove?: (state: ViewState) => void;
    debounceTime?: number;
    popup?: MapPopup;
    polygonHandler?: MapPolygonsHandler;
    data?: Feature;
    dragPan?: boolean;
    minZoom?: number;
    maxZoom?: number;
    sx?: SxProps<Theme>;
    auxData?: Feature;
    flagClearDraw?: boolean;
    setFlagClearDraw?: (value: boolean) => void;
    readOnly?: boolean;
};

export default function MapComponent({
    initialPosition,
    initialZoom,
    onMapMove,
    debounceTime,
    markers,
    popup,
    polygonHandler,
    data,
    sx,
    showHelpBox = true,
    dragPan,
    minZoom,
    maxZoom,
    auxData,
    flagClearDraw,
    setFlagClearDraw,
    readOnly
}: MapComponentProps) {
    const configuration = useConfiguration();

    const [mapDraw, setMapDraw] = useState<MapboxDraw | undefined>(undefined);

    useEffect(() => {
        if (flagClearDraw && mapDraw) {
            setMapDraw(undefined);
            setFlagClearDraw && setFlagClearDraw(false);
        }
    }, [flagClearDraw, mapDraw]);

    const debouncedCallback = useMemo(() => {
        if (onMapMove !== undefined) {
            return debounce(onMapMove, debounceTime ?? 300);
        } else {
            return undefined;
        }
    }, [onMapMove, debounceTime]);

    const [mapRef, setMapRef] = useState<MapRef | null>(null);

    useEffect(() => {
        if (mapRef && !readOnly) {
            mapRef?.setZoom(initialZoom ?? 11);
            mapRef?.setCenter({
                lat: initialPosition?.latitude ?? 40,
                lng: initialPosition?.longitude ?? -8
            });
        }
    }, [initialPosition, mapRef]);

    const points: Feature<Geometry, GeoJsonProperties>[] = useMemo(() => {
        if (markers && readOnly) {
            return markers.map((marker, markerIndex) => {
                return {
                    type: 'Feature',
                    properties: { id: `${markerIndex}-${marker.name ? marker.name : ''}` },
                    geometry: {
                        type: 'Point',
                        coordinates: [marker.position.longitude, marker.position.latitude]
                    }
                };
            });
        }
        return [];
    }, [markers, readOnly]);

    const pointsCollection: FeatureCollection<Geometry, GeoJsonProperties> | undefined = useMemo(() => {
        if (!readOnly) {
            return;
        }

        if (points) {
            return {
                type: 'FeatureCollection',
                crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
                features: points
            };
        }

        return {
            type: 'FeatureCollection',
            crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
            features: []
        };
    }, [points, readOnly]);

    useEffect(() => {
        if (mapRef && pointsCollection?.features.length !== 0 && readOnly) {
            mapRef.fitBounds(bbox(pointsCollection) as LngLatBoundsLike, { padding: 50 });
        }
    }, [pointsCollection, mapRef]);

    const onClick = useCallback(
        (event: mapboxgl.MapLayerMouseEvent) => {
            if (!mapRef || !pointsCollection || !readOnly) return;
            if (!event.features) return;

            const feature = event.features[0];

            const clusterId: number = feature?.properties?.cluster_id;

            const mapboxSource = mapRef?.getSource('markers') as GeoJSONSource;

            if (clusterId) {
                mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
                    if (err) {
                        return;
                    }

                    if (mapRef) {
                        mapRef.easeTo({
                            center: feature.geometry.coordinates,
                            zoom,
                            duration: 500
                        });
                    }
                });
            }
        },
        [mapRef, pointsCollection, readOnly]
    );

    const mapInternal = useCallback(
        (internalConfiguration: Configuration) => {
            return (
                <>
                    <Map
                        ref={(ref) => setMapRef(ref)}
                        reuseMaps
                        cooperativeGestures
                        style={{ width: '100%', height: '100%', borderRadius: '8px' }}
                        initialViewState={
                            !readOnly
                                ? {
                                      longitude: -8,
                                      latitude: 40,
                                      zoom: 10
                                  }
                                : undefined
                        }
                        mapboxAccessToken={internalConfiguration.MapboxToken}
                        mapStyle="mapbox://styles/sweneida/clgnl9c6400ej01pjgm6f5laj"
                        onMove={(evt: ViewStateChangeEvent) => {
                            if (debouncedCallback !== undefined) {
                                debouncedCallback(evt.viewState);
                            }
                        }}
                        interactiveLayerIds={clusterLayer?.id ? ['clusters'] : undefined}
                        onClick={onClick}
                        dragPan={dragPan}
                        minZoom={minZoom}
                        maxZoom={maxZoom}
                        renderWorldCopies={false}
                    >
                        <NavigationControl />
                        <ScaleControl />
                        <FullscreenControl />
                        <GeolocateControl />

                        {markers && !readOnly ? (
                            markers.map((marker) => (
                                <Marker
                                    key={marker.name ?? randomString(20)}
                                    longitude={marker.position.longitude}
                                    latitude={marker.position.latitude}
                                    color={marker.color ?? '#3FB1CE'}
                                    draggable={marker.onDragEnd !== undefined}
                                    onClick={() => {
                                        if (marker.onClick !== undefined) {
                                            marker.onClick(marker);
                                        }
                                    }}
                                    onDragEnd={(e) => {
                                        if (marker.onDragEnd !== undefined) {
                                            marker.onDragEnd(e);
                                        }
                                    }}
                                />
                            ))
                        ) : (
                            <></>
                        )}

                        {popup && (
                            <Popup longitude={popup.position.longitude} latitude={popup.position.latitude} onClose={popup.onClick}>
                                {popup.content}
                            </Popup>
                        )}

                        {polygonHandler && (
                            <DrawControl
                                position="top-left"
                                displayControlsDefault={false}
                                onCreate={(e) => polygonHandler?.onCreate(e)}
                                onUpdate={(e) => polygonHandler?.onUpdate(e)}
                                onDelete={(e) => polygonHandler?.onDelete(e)}
                                onSelect={(e) => polygonHandler?.onSelect(e)}
                                onDrawGotRef={(ref) => setMapDraw(ref)}
                            />
                        )}

                        {data && !polygonHandler && (
                            <Source type="geojson" data={data}>
                                <Layer {...dataLayer} />
                            </Source>
                        )}

                        {auxData && (
                            <Source type="geojson" data={auxData}>
                                <Layer {...auxDataLayer} />
                            </Source>
                        )}

                        {pointsCollection && readOnly && (
                            <Source
                                id="markers"
                                type="geojson"
                                data={pointsCollection}
                                cluster={true}
                                clusterMaxZoom={14}
                                clusterRadius={50}
                            >
                                <Layer {...clusterLayer} />
                                <Layer {...clusterCountLayer} />
                                <Layer {...unclusteredPointLayer} />
                            </Source>
                        )}
                    </Map>

                    {mapDraw && polygonHandler && (
                        <MapFeaturesControl mapDraw={mapDraw} polygonHandler={polygonHandler} topMargin={showHelpBox ? 100 : undefined} />
                    )}
                </>
            );
        },
        [markers, polygonHandler, popup, data, mapDraw, initialPosition, initialZoom, configuration, points, mapRef]
    );

    return (
        <Stack direction="column" sx={{ width: '100%', height: '100%' }}>
            {mapDraw && polygonHandler && showHelpBox && (
                <Alert severity="info" sx={{ marginY: '8px' }}>
                    <AlertTitle>Info</AlertTitle>
                    <FormattedMessage id={'map_finish_edit'} />
                </Alert>
            )}

            {sx !== undefined ? (
                <>{configuration?.MapboxToken && <Box sx={sx}>{configuration && mapInternal(configuration)}</Box>}</>
            ) : (
                <>{configuration?.MapboxToken && <>{configuration && mapInternal(configuration)}</>}</>
            )}
        </Stack>
    );
}
