import 'dayjs/locale/pt';
import 'dayjs/locale/de';
import 'dayjs/locale/en-nz';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-gb';

import { availableLanguages, userLocale } from '_global/Utils/GetCurrentLocale';
import { Grid, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

export default function WindowFilter(props: {
    onStartWindowChange: (value: Dayjs | null) => void;
    onEndWindowChange: (value: Dayjs | null) => void;
    startDate: Dayjs | null;
    endDate: Dayjs | null;
}) {
    const { onStartWindowChange, onEndWindowChange, startDate, endDate } = props;
    const [locale, setLocale] = useState('en-gb');

    useEffect(() => {
        function loadData() {
            const langExist = availableLanguages.indexOf(userLocale.toLowerCase());
            if (langExist === -1) {
                const split = userLocale.split('-');
                const exist = availableLanguages.indexOf(split[0].toLowerCase());
                if (exist !== -1) {
                    setLocale(availableLanguages[exist]);
                }
            } else {
                setLocale(availableLanguages[langExist]);
            }
        }
        if (userLocale) {
            void loadData();
        }
    }, [userLocale]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
            <Grid item xs={2} sx={{ width: '100%', pl: 0 }}>
                <DateTimePicker
                    disableFuture
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => {
                        onStartWindowChange(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Grid>
            <Grid item xs={2} sx={{ width: '100%', pl: 0 }}>
                <DateTimePicker
                    disableFuture
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => {
                        onEndWindowChange(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Grid>
        </LocalizationProvider>
    );
}
