import { Filter } from '_global/Components/base/filter/FilterTypes';
import { ComponentType } from 'models/layout/General';
import { Section, Visibility } from 'models/settings/RemoteConfiguration';
import { Option } from 'models/settings/RemoteConfiguration';
import { RemoteUserSettings } from 'models/settings/RemoteUserSettings';

export const saveUserFilters = (
    path: string,
    updateUserSettings: (sections: Section[]) => void,
    filters: Filter[],
    userVisibility: Visibility[],
    userConfig?: RemoteUserSettings
) => {
    if (userConfig !== undefined) {
        const configAux = { ...userConfig.userSettings };
        const newSection = {
            path: path,
            options: [{ id: '', type: ComponentType.Grid, filters: filters, visibility: userVisibility }]
        };

        if (configAux.sections.length > 0) {
            // (3) Existing path, filters updated!
            let hasSection = false;
            configAux.sections.map((section) => {
                if (section.path === path) {
                    hasSection = true;
                    section.options = newSection.options;

                    updateUserSettings(configAux.sections);
                    return;
                }
            });
            // (2) New path added, filters updated!
            if (!hasSection) {
                configAux.sections.push(newSection);
                updateUserSettings(configAux.sections);
                return;
            }
        } else {
            // (1) Empty array, filters added!
            configAux.sections.push(newSection);
            updateUserSettings(configAux.sections);
            return;
        }
    }
};

export const clearUserFilters = (updateUserSettings: (sections: Section[]) => void, userConfig?: RemoteUserSettings) => {
    if (userConfig !== undefined) {
        const configAux = { ...userConfig.userSettings };

        configAux.sections = [];

        updateUserSettings(configAux.sections);
        return;
    }
};

export const clearUserSectionFilters = (
    sectionName: string,
    updateUserSettings: (sections: Section[]) => void,
    userConfig?: RemoteUserSettings
) => {
    if (userConfig !== undefined) {
        const configAux = { ...userConfig.userSettings };
        configAux.sections.map((section, i) => {
            if (section.path === sectionName) {
                configAux.sections.splice(i);
            }
        });

        updateUserSettings(configAux.sections);
        return;
    }
};

export function getUserConfig(path: string, userConfig: RemoteUserSettings | undefined): Option[] | undefined {
    let options;

    userConfig?.userSettings.sections.map((section) => {
        if (section.path === path) {
            options = section.options;
        }
    });
    return options;
}

export const gridUpdate = () => {
    const updateButton = document.getElementsByClassName(
        'inovua-react-pagination-toolbar__icon inovua-react-pagination-toolbar__icon--named--REFRESH inovua-react-toolkit-button inovua-react-toolkit-button--theme-default-light inovua-react-toolkit-button--ltr inovua-react-toolkit-button--ellipsis inovua-react-toolkit-button--align-center inovua-react-toolkit-button--no-children inovua-react-toolkit-button--vertical-align-middle inovua-react-toolkit-button--has-icon inovua-react-toolkit-button--icon-position-start inovua-react-toolkit-button--icon-first'
    );
    updateButton[0].click();
};
