import { gql } from '@apollo/client';

import { Company_By_Id, Company_By_Id_companys_nodes } from './Types/Company_By_Id';
import { companys_companys_nodes } from './Types/companys';

export type CompanyById = Company_By_Id;
export type CompanyByIDInstance = Company_By_Id_companys_nodes;
export type CompanyInstance = companys_companys_nodes;

export const COMPANYS = gql`
    query companys($cursor: String, $numberOfElements: Int, $filter: CompanyFilterInput) {
        companys(where: $filter, order: { id: ASC }, first: $numberOfElements, after: $cursor) {
            totalCount
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            nodes {
                country
                description
                id
                name
                website
                enterpriseId
                image
            }
        }
    }
`;

export const COMPANY_BY_ID = gql`
    query Company_By_Id($cursor: String, $first: Int, $companyId: Long) {
        companys(first: $first, after: $cursor, where: { id: { eq: $companyId } }) {
            nodes {
                id
                name
                country
                description
                website
                image
                enterpriseId
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;
