class LocalStorageManager {
    static clearAllStoredInfo() {
        localStorage.clear();
        sessionStorage.clear();

        const cookies = document.cookie;

        for (const myCookie of cookies.split(';')) {
            const pos = myCookie.indexOf('=');
            const name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }

        console.warn(
            '%c ! ALL DATA PURGED !',
            'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)'
        );
    }

    static checkIfNeedsToBeCleared() {
        const currentVersion = localStorage.getItem('APP_VERSION');

        if (currentVersion === null) {
            localStorage.setItem('APP_VERSION', import.meta.env.PACKAGE_VERSION as string);
        } else {
            if (import.meta.env.PACKAGE_VERSION !== currentVersion) {
                console.warn('Different version running, clearing data...');

                this.clearAllStoredInfo();
                localStorage.setItem('APP_VERSION', import.meta.env.PACKAGE_VERSION as string);
            } else {
                // eslint-disable-next-line no-console
                console.log('No version mismatch detected, all good !');
            }
        }
    }
}

export default LocalStorageManager;
