import { SnackBarActionProps } from '_global/Services/store/snackbarReducer';
import { updateTab } from '_global/Services/store/tabComponentSlice';
import { AnyAction } from '@reduxjs/toolkit';
import * as turf from '@turf/turf';
import { Feature, GeoJsonProperties, Polygon } from 'geojson';
import { Group } from 'logic/misc/MapExtensions';
import { Dispatch } from 'react';
import { NavigateFunction } from 'react-router-dom';

export const updateNavigation = (props: {
    dispatch: Dispatch<AnyAction | SnackBarActionProps>;
    navigate: NavigateFunction;
    tabs: Group<
        string,
        {
            component: React.ReactNode;
            url?: string | undefined;
        }
    >;
}) => {
    const { dispatch, navigate, tabs } = props;

    const currentTab = Array.from(tabs).find((el) => {
        return el[1].url === window.location.pathname;
    });

    const path = currentTab?.[1].url?.split('/');
    if (path !== undefined && path.length > 0) {
        const newPath = path.slice(0, -1).join('/');
        dispatch(
            updateTab({
                name: currentTab?.[0] ?? '',
                component: currentTab?.[1].component,
                url: `${newPath}`
            })
        );
        navigate(`${newPath}`);
    }
};

export const updateNavigationEdit = (props: {
    dispatch: Dispatch<AnyAction | SnackBarActionProps>;
    navigate: NavigateFunction;
    tabs: Group<
        string,
        {
            component: React.ReactNode;
            url?: string | undefined;
        }
    >;
}) => {
    const { dispatch, navigate, tabs } = props;
    const currentTab = Array.from(tabs).find((el) => el[1].url === window.location.pathname);
    const path = currentTab?.[1].url?.split('/');
    if (path !== undefined && path.length > 0) {
        const newPath = `${path.join('/')}/edit`;
        dispatch(
            updateTab({
                name: currentTab?.[0] ?? '',
                component: currentTab?.[1].component,
                url: `${newPath}`
            })
        );
        navigate(`${newPath}`);
    }
};

export const areaZoom = (areaFeature?: Feature<Polygon, GeoJsonProperties>) => {
    if (!areaFeature) return;
    const area = turf.area(areaFeature);

    if (area < 10000000) {
        return 13;
    } else if (area >= 10000000 && area < 100000000) {
        return 11.5;
    } else if (area >= 100000000 && area <= 300000000) {
        return 10.5;
    } else if (area > 300000000 && area <= 700000000) {
        return 10;
    } else if (area > 700000000 && area <= 7000000000) {
        return 8;
    } else {
        return 5;
    }
};
