import { COLORS } from '_global/Utils/Colors';
import { TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { ProtectionEarth, ServiceEarth } from 'models/dataStorage/NewSimpleLastData';
import { FormattedMessage } from 'react-intl';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: COLORS.ENEIDA_BLUE,
        color: theme.palette.common.white,
        maxWidth: '100px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        marginTop: 10,
        border: 0
    }
}));

export default function ProtectionEarthTableRow(props: { lastData: ProtectionEarth | ServiceEarth }) {
    const { lastData } = props;
    const lines = ['frequency (hz)', 'impedance (ohm)', 'resistance (ohm)'];

    return (
        <>
            <StyledTableRow hover>
                <StyledTableCell align="left" variant="head">
                    <Typography variant="body1">
                        <FormattedMessage id={lines[0]} />
                    </Typography>
                </StyledTableCell>
                {lastData.impedance.map((imp) => {
                    if (imp.frequence !== 0)
                        return (
                            <StyledTableCell sx={{ textAlign: 'center' }}>
                                <Typography>{imp.frequence}</Typography>
                            </StyledTableCell>
                        );
                })}
            </StyledTableRow>
            <StyledTableRow hover>
                <StyledTableCell align="left" variant="head">
                    <Typography variant="body1">
                        <FormattedMessage id={lines[1]} />
                    </Typography>
                </StyledTableCell>
                {lastData.impedance.map((imp) => {
                    if (imp.value && imp.value !== 0)
                        return (
                            <StyledTableCell sx={{ textAlign: 'center' }}>
                                <Typography>{imp.value.toFixed(2)}</Typography>
                            </StyledTableCell>
                        );
                })}
            </StyledTableRow>
            <br />
            <StyledTableRow hover>
                <StyledTableCell align="left" variant="head">
                    <Typography variant="body1">
                        <FormattedMessage id={lines[2]} />
                    </Typography>
                </StyledTableCell>

                <StyledTableCell colSpan={lastData.impedance.length} sx={{ textAlign: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold' }}>{lastData?.resistence?.toFixed(2) ?? '---'}</Typography>
                </StyledTableCell>
            </StyledTableRow>
        </>
    );
}
