import DeepgridLoader from '_global/Components/base/DeepgridLoader';
import { getData } from '_global/Services/api/networkRequester';
import { modeAtomEvents } from '_global/Utils/hooks/jotai';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { Box } from '@mui/system';
import { useAtom } from 'jotai';
import Mode from 'models/enums/ModeEnum';
import { ProcessedEvent } from 'models/events/ProcessedEvent';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useConfiguration } from '../../logic/configuration/Hooks';
import EventDetails from './EventDetails';
import EventGraphs from './EventGraphs';
import EventHistory from './EventHistory';

enum TabValue {
    DETAILS = 0,
    HISTORY = 1,
    WAVEFORM = 2
}

function EventsContent(props: { alarmId: number }) {
    const { alarmId } = props;
    const [alarm, setAlarm] = useState<ProcessedEvent>();
    const configuration = useConfiguration();
    const [isLoading, setIsLoading] = useState(true);
    const [mode] = useAtom(modeAtomEvents);
    const [tabValue, setTabValue] = useState<string>(TabValue.DETAILS.toString());

    const fetchAlarm = useCallback(() => {
        if (configuration) {
            setIsLoading(true);
            const alarmUrl = '/events/api/Event/';
            const endpoint = `${configuration.BaseUrl}${alarmUrl}${alarmId}`;
            getData<ProcessedEvent>(endpoint)
                .then((data) => {
                    setAlarm(data);
                    setIsLoading(false);
                })
                .catch((e) => {
                    console.error(e);
                    setIsLoading(false);
                });
        }
    }, [configuration]);

    useEffect(() => {
        if (mode === Mode.PREVIEW || mode === Mode.EDIT) {
            fetchAlarm();
        }
        if (mode === Mode.EDIT) {
            setTabValue(TabValue.DETAILS.toString());
        }
    }, [mode, props]);

    const renderWaveFormCapture = () => {
        if (alarm?.hasWFC === true) {
            return (
                <Tab
                    disabled={mode === Mode.EDIT}
                    label={<FormattedMessage id={'wave_form_capture'} />}
                    value={TabValue.WAVEFORM.toString()}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <Box>
            <DeepgridLoader open={isLoading} />

            {alarm && (
                <TabContext value={tabValue.toString()}>
                    <TabList onChange={(_, tab: number) => setTabValue(tab.toString())} value={tabValue.toString()}>
                        <Tab label={<FormattedMessage id={'details'} />} value={TabValue.DETAILS.toString()} />
                        <Tab
                            disabled={mode === Mode.EDIT}
                            label={<FormattedMessage id={'history'} />}
                            value={TabValue.HISTORY.toString()}
                        />
                        {renderWaveFormCapture()}
                    </TabList>

                    <TabPanel value={TabValue.DETAILS.toString()}>
                        <EventDetails alarm={alarm} />
                    </TabPanel>

                    <TabPanel value={TabValue.HISTORY.toString()}>
                        <EventHistory alarm={alarm} />
                    </TabPanel>

                    <TabPanel value={TabValue.WAVEFORM.toString()}>
                        <EventGraphs alarm={alarm} />
                    </TabPanel>
                </TabContext>
            )}
        </Box>
    );
}

export default EventsContent;
