import { Configuration, loadDefaultConfig } from 'logic/configuration/Configuration';
import { LngLat } from 'mapbox-gl';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { MapLocation } from './logic/MapTypes';
import MapImage from './MapImage';
import MapStyle from './MapStyle';

const MapImageComponent = (props: MapLocation) => {
    const { latitude, longitude } = props;
    const [configuration, setConfiguration] = useState<Configuration | undefined>(undefined);
    const imgRef = useRef<HTMLImageElement>(null);
    const [width, setWidth] = useState<number>(0);
    const [heigth, setHeigth] = useState<number>(0);

    useEffect(() => {
        loadDefaultConfig()
            .then((config) => {
                setConfiguration(config);
            })
            .catch(console.error);
    }, []);

    useLayoutEffect(() => {
        setWidth(imgRef.current?.clientWidth ?? 0);
        setHeigth(imgRef.current?.clientHeight ?? 0);
    }, [imgRef]);

    const mapGenerated = useMemo(() => {
        if (!configuration) return undefined;

        return new MapImage(configuration?.MapboxToken ?? '');
    }, [configuration]);

    return (
        <img
            ref={imgRef}
            src={
                mapGenerated?.generate(MapStyle.Light, new LngLat(longitude ?? 0, latitude ?? 0), 17, width, heigth, {
                    name: '',
                    color: '009933',
                    position: {
                        longitude: longitude ?? 0,
                        latitude: latitude ?? 0
                    }
                }) ?? ''
            }
        />
    );
};

export default MapImageComponent;
