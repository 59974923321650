import { TableBody, TableCell, TableRow } from '@mui/material';
import { Feeder } from 'models/asset/AssetModel';
import { FormattedMessage } from 'react-intl';
import { IFeederTableBody } from 'views/Assets/utils/AssetModel';

import FeederTableRow from './FeederTableRow';

export default function FeederTableBody(props: IFeederTableBody) {
    const {
        feeders,
        cableTypeOptions,
        setFeederToDelete: handleDeleteFeeder,
        handleChange,
        handleOnChange,
        handleChangeSelect,
        handleEraseCableTypeAndFuseRating,
        standardsOption,
        setOpenModal,
        mode
    } = props;

    return (
        <TableBody>
            {feeders.length === 0 ? (
                <TableRow hover>
                    <TableCell align={'left'}>
                        <FormattedMessage id={'no_records_available'} />
                    </TableCell>
                </TableRow>
            ) : (
                <>
                    {feeders
                        .sort((a, b) => a.number - b.number)
                        .map((feeder: Feeder, index: number) => {
                            return (
                                <FeederTableRow
                                    key={index}
                                    {...{
                                        cableTypeOptions,
                                        setFeederToDelete: handleDeleteFeeder,
                                        feeder,
                                        handleChange,
                                        handleOnChange,
                                        handleChangeSelect,
                                        handleEraseCableTypeAndFuseRating,
                                        standardsOption,
                                        setOpenModal,
                                        mode
                                    }}
                                />
                            );
                        })}
                </>
            )}
        </TableBody>
    );
}
