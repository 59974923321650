import { gql } from '@apollo/client';

export const ASSET_TYPE_QUERY = gql`
    query AssetType($assetType: AssetFilterInput) {
        assets(where: $assetType) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;
