import MainCard from '_global/Components/base/cards/MainCard';
import { getData } from '_global/Services/api/networkRequester';
import { RootState } from '_global/Services/store';
import { Table, TableContainer } from '@mui/material';
import { Device } from 'models/asset/AssetDevice';
import { AssetModel } from 'models/asset/AssetModel';
import Mode from 'models/enums/ModeEnum';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { DevicesTableProps } from 'views/Assets/utils/AssetModel';

import DevicesTableBody from './DevicesTableBody';
import DevicesTableHead from './DevicesTableHead';

export default function DevicesTable(props: DevicesTableProps) {
    const { assetDetails, mode, fetchAsset } = props;
    const [devices, setDevices] = useState<Device[]>([]);
    const features = useSelector((state: RootState) => state.features);

    const createdDateCheck = (asset: AssetModel, device: Device, index: number): string => {
        if (asset.devices[index].externalId === device.id) {
            return asset.devices[index].createdDate;
        } else {
            asset.devices.map((item) => {
                if (item.externalId === device.id) return item.createdDate;
            });
        }
        return '';
    };

    const fetchDevices = useCallback(async () => {
        const allDevices = await Promise.all(
            assetDetails.devices.map((device, index) => {
                const endpoint = `${features.configuration?.BaseUrl ?? ''}/devicesinfo/api/Device/${device.externalId}`;
                return getData<Device>(endpoint)
                    .then((data) => {
                        return {
                            deviceType: data.deviceType,
                            deviceTypeName: data.deviceTypeName,
                            id: data.id,
                            serialNumber: data.serialNumber,
                            equipmentId: data.equipmentId,
                            createdBy: data.createdBy,
                            createdDate: createdDateCheck(assetDetails, data, index),
                            lastModifiedBy: data.lastModifiedBy,
                            lastModifiedDate: data.lastModifiedDate,
                            deviceBulkConfiguration: data.deviceBulkConfiguration
                        };
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            })
        );

        setDevices(allDevices as Device[]);
    }, [assetDetails.devices]);

    useEffect(() => {
        if (mode === Mode.PREVIEW || mode === Mode.EDIT) {
            void fetchDevices();
        }
    }, [mode, assetDetails.devices]);

    return (
        <MainCard content={false} title={<FormattedMessage id={'devices'} />}>
            <TableContainer sx={{ px: 2 }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <DevicesTableHead />

                    <DevicesTableBody data={devices} fetchAsset={fetchAsset} />
                </Table>
            </TableContainer>
        </MainCard>
    );
}
