import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { IconToNode } from '../../../Utils/IconToNode';
import ButtonComponent from '../button/Button';
import Spacer from '../Spacer';

export type Action = {
    name: string;
    isOutlined?: boolean;
    isError?: boolean;
    icon?: ReactNode;
    disabled?: boolean;
    callback: () => void;
};

export type PageHolderProps = {
    name?: string;
    icon?: IconDefinition;
    actions?: Action[];
    children: ReactNode;
};

export default function PageHolder({ name, actions, children, icon }: PageHolderProps) {
    return (
        <>
            {name ? (
                <Stack sx={{ height: '100%' }}>
                    <Stack
                        direction="row"
                        sx={{ minHeight: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {icon && <IconToNode definition={icon} sx={{ paddingLeft: '8px', paddingRight: '8px', color: 'black' }} />}

                            <Typography sx={{ textAlign: 'center' }} variant="h3">
                                {name}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                            {actions !== undefined &&
                                actions.map((action) => (
                                    <Box key={action.name} sx={{ pb: '0.3rem' }}>
                                        <ButtonComponent
                                            color={action.isError ? 'error' : undefined}
                                            disabled={action.disabled ?? false}
                                            icon={action.icon}
                                            onClick={action.callback}
                                            title={action.name}
                                            variant={action.isOutlined ? 'outlined' : 'contained'}
                                        />
                                    </Box>
                                ))}
                        </Box>
                    </Stack>
                    {children}
                </Stack>
            ) : (
                <>{children}</>
            )}
        </>
    );
}
