import { OperationalArea } from 'models/layout/OperationalArea';
import { OpAreaById } from 'queries/OperationalAreas';

export function opAreaToRow(event: OpAreaById): OperationalArea {
    return {
        id: Number(event?.id),
        name: String(event?.name),
        code: String(event?.code),
        regionId: Number(event?.regionId),
        gpsPoints: event?.gpsPoints ?? []
    };
}
