import MainCard from '_global/Components/base/cards/MainCard';
import { Box, Grid } from '@mui/material';

import { EventProps } from './EventDetails';
import EventHistoryTable from './EventHistoryTable/EventHistoryTable';

export default function EventHistory(props: EventProps) {
    const { alarm } = props;
    return (
        <Box>
            {alarm?.eventData && (
                <MainCard content={false}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ p: 3 }}>
                        <EventHistoryTable data={alarm.eventData} />
                    </Grid>
                </MainCard>
            )}
        </Box>
    );
}
