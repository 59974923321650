import '../../../../_global/Components/base/map/MapImage.css';

import MainCard from '_global/Components/base/cards/MainCard';
import MapImageComponent from '_global/Components/base/map/MapImageComponent';
import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const PreviewLocation = (props: { latitude: number; longitude: number; opAreaName: string; regionName: string }) => {
    const { latitude, longitude, opAreaName, regionName } = props;

    const intl = useIntl();

    const showDetailsHeaders = useCallback(() => {
        return (
            <>
                <Grid item xs={3}>
                    <label className="headers">
                        <FormattedMessage id={'latitude'} />
                    </label>
                </Grid>
                <Grid item xs={3}>
                    <label className="headers">
                        <FormattedMessage id={'longitude'} />
                    </label>
                </Grid>
                <Grid item xs={3}>
                    <label className="headers">
                        <FormattedMessage id={'region'} />
                    </label>
                </Grid>
                <Grid item xs={3}>
                    <label className="headers">
                        <FormattedMessage id={'operationalArea'} />
                    </label>
                </Grid>
            </>
        );
    }, [props]);

    const showDetailsValues = useCallback(() => {
        return (
            <>
                <Grid item xs={3}>
                    <label className="values">{latitude ?? 0}</label>
                </Grid>
                <Grid item xs={3}>
                    <label className="values">{longitude ?? 0}</label>
                </Grid>
                <Grid item xs={3}>
                    <label className="values">{regionName ?? intl.formatMessage({ id: 'na' })}</label>
                </Grid>
                <Grid item xs={3}>
                    <label className="values">{opAreaName ?? intl.formatMessage({ id: 'na' })}</label>
                </Grid>
            </>
        );
    }, [props]);

    const showLocationDetails = useCallback(() => {
        return (
            <Grid container rowSpacing={1} spacing={9} sx={{ mt: 0.5, px: 2 }}>
                {showDetailsHeaders()}
                {showDetailsValues()}
            </Grid>
        );
    }, [props]);

    return (
        <MainCard title={<FormattedMessage id={'location'} />}>
            <MapImageComponent {...{ latitude: latitude, longitude: longitude }} />
            {showLocationDetails()}
        </MainCard>
    );
};
export default PreviewLocation;
