import { Box } from '@mui/material';

export type SpacerProps = {
    growSize?: number;
};

export default function Spacer(props: SpacerProps) {
    const { growSize } = props;
    return <Box sx={{ flexGrow: growSize !== undefined ? growSize : 1 }} />;
}
