import './styles/app.scss';

import Locales from '_global/Components/base/Locales';
import themes from '_global/Resources/themes';
import AppRoutes from '_global/Routes';
import { setGridsPaginations } from '_global/Services/store/gridsPaginationSlice';
import { useAppDispatch } from '_global/Services/store/hooks';
import { ApolloProvider } from '@apollo/client';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import * as Sentry from '@sentry/react';
import NavigationScroll from 'controllers/layout/NavigationScroll';
import Keycloak from 'keycloak-js';
import { DefaultRootStateProps } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import ErrorComponent from './_global/Components/base/ErrorComponent';
import { graphQLClient } from './_global/Services/network/endpoint';
import config from './config';
import { useConfiguration } from './logic/configuration/Hooks';
import LocalStorageManager from './logic/local/LocalStorageManager';

function onKeycloakEvent(eventType: unknown, error?: unknown) {
    if (error === undefined) {
        console.warn('onKeycloakEvent', eventType);
    } else {
        console.error('onKeycloakEvent', eventType, error);
    }
}

const App = () => {
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);
    const configuration = useConfiguration();
    const dispatch = useAppDispatch();

    const [configurationShown, setConfigurationShown] = useState(false);
    const keycloakConfig = useMemo(() => new Keycloak(configuration?.KeycloakConfiguration), [configuration]);

    const graphQLCLient = useMemo(() => {
        return graphQLClient(configuration);
    }, [configuration]);

    useEffect(() => {
        if (configuration !== undefined && configuration !== null && !configurationShown) {
            console.warn(`Using configuration: ${configuration.Name}`);
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            console.warn(`Version: ${import.meta.env.VITE_VERSION ?? import.meta.env.PACKAGE_VERSION}`);

            setConfigurationShown(true);
        }
    }, [configuration]);

    useEffect(() => {
        LocalStorageManager.checkIfNeedsToBeCleared();
    }, []);

    const getGridsPaginationsFromSessionStorage = () => {
        try {
            const gridsPaginations = sessionStorage.getItem('gridsPaginations');
            if (gridsPaginations) {
                return JSON.parse(gridsPaginations);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const gridsPaginations = getGridsPaginationsFromSessionStorage();

    if (gridsPaginations) {
        dispatch(setGridsPaginations(gridsPaginations));
    }

    return (
        <>
            <ReactKeycloakProvider authClient={keycloakConfig} initOptions={{ checkLoginIframe: false }} onEvent={onKeycloakEvent}>
                <BrowserRouter basename={config.basename}>
                    <ApolloProvider client={graphQLCLient}>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={themes(customization)}>
                                <CssBaseline />
                                <Locales>
                                    <Sentry.ErrorBoundary fallback={<ErrorComponent />} showDialog={false}>
                                        <NavigationScroll>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DndProvider backend={HTML5Backend}>
                                                    {configuration && <AppRoutes configuration={configuration} />}
                                                </DndProvider>
                                            </LocalizationProvider>
                                        </NavigationScroll>
                                    </Sentry.ErrorBoundary>
                                </Locales>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </ApolloProvider>
                </BrowserRouter>
            </ReactKeycloakProvider>
        </>
    );
};

export default Sentry.withProfiler(App);
