import './Stats.scss';

import MainCard from '_global/Components/base/cards/MainCard';
import { getData } from '_global/Services/api/networkRequester';
import { RootState } from '_global/Services/store';
import { Autocomplete, Grid, Table, TableBody, TableCell, TableRow, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Field, KPIResponse, KPIsProps, Periods } from './StatsModels';

export default function KPIGrid({ locale, title, requestErrors, assetID, trigger, hasLastData }: KPIsProps) {
    const features = useSelector((state: RootState) => state.features);
    const [periods, setPeriods] = useState<Periods>();
    const [selectedPeriods, setSelectedPeriods] = useState<string[]>([]);
    const [kpiList, setKPIList] = useState<KPIResponse>();

    const minPeriodsToReload = 2;
    const firstColumn = 0;
    const secondColumn = 1;

    const urls = {
        periods: '/api/GetKPIPeriods',
        variables: '/api/GetKPIVariables',
        kpi: '/api/GetKPI'
    };

    useEffect(() => {
        const auxSelectedPeriods: string[] = [];
        periods?.Selected?.map((el) => {
            auxSelectedPeriods.push(el.Value);
        });
        setSelectedPeriods(auxSelectedPeriods);
    }, [periods]);

    function buildEndPoint(url: string, language: string, fields?: Field[], sds?: number, region?: string, opArea?: string): string {
        let endpoint = `${features.configuration?.BaseUrl ?? ''}${url}?lang=${language}`;
        let fieldString = '';

        if (selectedPeriods) {
            if (selectedPeriods[0] !== '') {
                endpoint = `${endpoint}&column=${selectedPeriods[0]}`;
            }

            if (selectedPeriods[1] !== '') {
                endpoint = `${endpoint}&column=${selectedPeriods[1]}`;
            }
        }

        if (fields) {
            fields.map((el) => {
                fieldString = `${fieldString}&field=${el.Value}`;
            });
        }

        if (sds) {
            endpoint = `${endpoint}&sds=${sds}`;
        }

        if (region) {
            endpoint = `${endpoint}&region=${region}`;
        }

        if (opArea) {
            endpoint = `${opArea}&opArea=${opArea}`;
        }

        return endpoint;
    }

    function getPeriods(doNextRequest: boolean, language: string) {
        getData<Periods | string>(buildEndPoint(urls.periods, language))
            .then((data) => {
                if (data === 'Unavailable language' && doNextRequest) {
                    getPeriods(false, 'en-GB');
                } else {
                    setPeriods(data as Periods);
                }
            })
            .catch(() => {
                setPeriods(undefined);
            });
    }

    function getKPI(doNextRequest: boolean, language: string, fields?: Field[], sds?: number, region?: string, opArea?: string) {
        getData<KPIResponse | string>(buildEndPoint(urls.kpi, language, fields, sds, region, opArea))
            .then((data) => {
                if (data === requestErrors.language && doNextRequest) {
                    getKPI(false, language, fields, sds, region, opArea);
                } else {
                    setKPIList(data as KPIResponse);
                }
            })
            .catch(() => {
                setKPIList(undefined);
            });
    }

    useEffect(() => {
        getPeriods(true, locale);
    }, []);

    useEffect(() => {
        if (trigger) {
            getPeriods(true, locale);
        }
    }, [trigger]);

    useEffect(() => {
        if (selectedPeriods.length > 1) {
            if (assetID) {
                getKPI(true, locale, undefined, assetID);
            } else {
                getKPI(true, locale);
            }
        }
    }, [selectedPeriods]);

    const handleOnChange = (newValue: string | null, column: number) => {
        if (newValue !== null) {
            const auxPeriods = selectedPeriods;
            auxPeriods[column] = newValue;
            setSelectedPeriods(auxPeriods);
            if (selectedPeriods.length > 1 && periods?.Selected) {
                if (assetID) {
                    getKPI(true, locale, undefined, assetID);
                } else {
                    getKPI(true, locale);
                }
            }
        }
    };

    const kpiPeriods = useCallback(
        (column: number) => {
            if (!periods || selectedPeriods.length < minPeriodsToReload) return <></>;
            return (
                <Autocomplete
                    defaultValue={selectedPeriods[column]}
                    disableClearable
                    disabled={hasLastData !== undefined ? !hasLastData : false}
                    getOptionLabel={(option) => {
                        const found = periods.filtersdata[column].find((el) => el.Value === option);
                        if (found) return found.name;
                        return '';
                    }}
                    isOptionEqualToValue={(option, val) => {
                        if (val === '') {
                            return false;
                        }

                        return option?.toString() === val?.toString();
                    }}
                    key={column}
                    onChange={(event, newValue) => handleOnChange(newValue, column)}
                    options={periods.filtersdata[column].map((period) => period.Value)}
                    renderInput={(params) => <TextField {...params} label={<FormattedMessage id={'period'} />} />}
                    sx={{ maxHeight: '51px' }}
                    value={selectedPeriods[column]}
                />
            );
        },
        [periods, selectedPeriods]
    );

    const kpiPeriodsContainer = () => {
        return (
            <Grid alignItems="center" container direction="row" justifyContent="center" spacing={1}>
                <Grid item xs={6}>
                    {kpiPeriods(firstColumn)}
                </Grid>
                <Grid item xs={6}>
                    {kpiPeriods(secondColumn)}
                </Grid>
            </Grid>
        );
    };

    const getKPIBody = useCallback(() => {
        if (kpiList) {
            return kpiList.data.map((el, index) => {
                return (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row" style={{ width: '40%' }}>
                            <b>{el[0]}</b>
                        </TableCell>
                        <TableCell align="left" style={{ width: '30%' }}>
                            {el[1]}
                        </TableCell>
                        <TableCell align="left" style={{ width: '30%' }}>
                            {el[2]}
                        </TableCell>
                    </TableRow>
                );
            });
        } else {
            return <></>;
        }
    }, [kpiList]);

    return (
        <MainCard content={false} secondary={kpiPeriodsContainer()} sx={{ padding: 1 }} title={<FormattedMessage id={title} />}>
            <Table aria-labelledby="tableTitle" size={'small'}>
                <TableBody>
                    {assetID && getKPIBody()}

                    {!assetID && (
                        <TableRow>
                            <TableCell>
                                <Grid alignItems="center" container direction="column">
                                    <Grid item sx={{ mt: '25%', mb: '35%' }}>
                                        <FormattedMessage id={'no-data'} />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </MainCard>
    );
}
