import SnackbarAlert from '_global/Components/base/extended/SnackbarAlert';
import AlertModal from '_global/Components/base/modals/AlertModal';
import { RootState } from '_global/Services/store';
import { COLORS } from '_global/Utils/Colors';
import { availableLanguages, userLocale } from '_global/Utils/GetCurrentLocale';
import { IconToNode } from '_global/Utils/IconToNode';
import { faEllipsisV, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { faBrainCircuit } from '@fortawesome/pro-light-svg-icons';
import {
    faCircleWaveformLines,
    faEthernet,
    faSimCard,
    faSimCards,
    faUtilityPole as faUtilityPoleRegular
} from '@fortawesome/pro-regular-svg-icons';
import { faUtilityPole as faUtilityPoleSolid, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Grid, IconButton, MenuItem, Stack, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import MessageType from 'models/enums/MessageTypes';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DevicesTableRowProps } from 'views/Assets/utils/AssetModel';
import { StyledMenu } from 'views/Reports/Grid/Cells/ActionsCell';

import { backgroundColor, DevicesModel, textColor } from './DevicesUtils';

const loadIcon = (deviceType: DevicesModel, color: string) => {
    let icon: IconDefinition = faSimCard;
    switch (deviceType) {
        case DevicesModel.DTVIg:
            icon = faSimCard;
            break;
        case DevicesModel.DTVIm:
            icon = faEthernet;
            break;
        case DevicesModel.DTE:
            icon = faCircleWaveformLines;
            break;
        case DevicesModel.DTVI4g:
            icon = faSimCards;
            break;
        case DevicesModel.DTVIgPMT:
            icon = faUtilityPoleRegular;
            break;
        case DevicesModel.DTVI4gPMT:
            icon = faUtilityPoleSolid;
            break;
        case DevicesModel.DTVIgPMT2:
            icon = faUtilityPoleSolid;
            break;
        case DevicesModel.DTVI4gPMT2:
            icon = faUtilityPoleSolid;
            break;
        case DevicesModel.INGRID90 || DevicesModel.INGRID91 || DevicesModel.INGRID92:
            icon = faBrainCircuit;
            break;
    }
    return <IconToNode definition={icon} size="1x" sx={{ paddingLeft: '4px', paddingRight: '4px', color: color }} />;
};

export default function DevicesTableRow(props: DevicesTableRowProps) {
    const { device, fetchAsset } = props;
    const [typeBgColor, setTypeBgColor] = useState<string>('');
    const [typeTextColor, setTypeTextColor] = useState<string>('');

    useEffect(() => {
        setTypeBgColor(backgroundColor(Number(device?.deviceType) as DevicesModel));
        setTypeTextColor(textColor(Number(device?.deviceType) as DevicesModel));
    }, [device.deviceType]);

    const [stateModal, setStateModal] = useState({
        openModal: false,
        responseContent: '',
        responseTitle: '',
        type: MessageType.INFO
    });

    const { responseContent, responseTitle, openModal, type } = stateModal;

    const [stateToast, setStateToast] = useState({ openToast: false, toastMessage: '' });

    const { openToast, toastMessage } = stateToast;

    const features = useSelector((state: RootState) => state.features);
    const intl = useIntl();
    const [locale, setLocale] = useState('en-gb');
    const navigate = useNavigate();

    useEffect(() => {
        function loadData() {
            const langExist = availableLanguages.indexOf(userLocale.toLowerCase());
            if (langExist === -1) {
                const split = userLocale.split('-');
                const exist = availableLanguages.indexOf(split[0].toLowerCase());
                if (exist !== -1) {
                    setLocale(availableLanguages[exist]);
                }
            } else {
                setLocale(availableLanguages[langExist]);
            }
        }
        if (userLocale) {
            void loadData();
        }
    }, [userLocale]);

    const decommisionDevice = useCallback(async () => {
        if (device && features.configuration) {
            const response = await fetch(`${features.configuration?.BaseUrl}/devicesinfo/api/Device/decommission`, {
                method: 'POST',
                body: JSON.stringify([device.id]),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                void fetchAsset();
                setStateToast({
                    openToast: true,
                    toastMessage: response.statusText
                });

                return Promise.resolve();
            } else {
                setStateModal({
                    openModal: true,
                    responseTitle: `${response.statusText} ${response.status}`,
                    responseContent: intl.formatMessage({ id: 'error_decommission_device' }),
                    type: MessageType.ERROR
                });

                return Promise.reject();
            }
        }
    }, [device]);

    const handleClickModal = () => {
        setStateModal({
            openModal: true,
            responseTitle: intl.formatMessage({ id: 'decommision_device' }),
            responseContent: intl.formatMessage({ id: 'irreversible_action' }),
            type: MessageType.WARNING
        });
    };

    const handleCloseModal = () => {
        setStateModal({ ...stateModal, openModal: false });
    };
    const handleOkModal = () => {
        void decommisionDevice();
        handleCloseModal();
    };

    const handleCloseToast = () => {
        setStateToast({ ...stateToast, openToast: false });
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const checkIngridDeviceType = (deviceType: string) => {
        if (deviceType.toLowerCase() === 'ingrid90' || deviceType.toLowerCase() === 'ingrid91' || deviceType.toLowerCase() === 'ingrid92') {
            return 'InGrid';
        }

        return deviceType;
    };

    return (
        <TableBody>
            <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDayjs}>
                <AlertModal
                    isOpen={openModal}
                    message={responseContent}
                    onCancel={type === MessageType.WARNING ? handleCloseModal : undefined}
                    onOk={type === MessageType.WARNING ? handleOkModal : handleCloseModal}
                    title={responseTitle}
                    type={type}
                />
                <SnackbarAlert message={toastMessage} onClose={handleCloseToast} open={openToast} type={MessageType.SUCCESS} />
                <TableRow hover onClick={() => navigate(`/devices/${device?.id}`)} role="checkbox" sx={{ cursor: 'pointer' }}>
                    <TableCell align={'left'} sx={{ width: '15%' }}>
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="center"
                            sx={{
                                paddingX: '10px',
                                paddingY: '2px',
                                background: typeBgColor,
                                borderRadius: '25px',
                                maxWidth: '130px'
                            }}
                        >
                            <Typography sx={{ color: typeTextColor, fontWeight: 'bold' }}>
                                {loadIcon(Number(device?.deviceType) as DevicesModel, typeTextColor)}
                                {intl.formatMessage({ id: checkIngridDeviceType(device?.deviceTypeName) ?? 'NA' })}
                            </Typography>
                        </Stack>
                    </TableCell>
                    <TableCell align={'left'} sx={{ width: '15%' }}>
                        <Typography>{device?.serialNumber}</Typography>
                    </TableCell>
                    <TableCell align={'left'} sx={{ width: '15%' }}>
                        <Typography>{dayjs(device?.lastModifiedDate).toString()}</Typography>
                    </TableCell>
                    <TableCell align={'left'} sx={{ width: '15%' }}>
                        <Typography>{device.deviceBulkConfiguration?.name}</Typography>
                    </TableCell>
                    <TableCell align={'left'} sx={{ width: '15%' }}>
                        <Typography>{dayjs(device?.createdDate).toString()}</Typography>
                    </TableCell>
                    <TableCell
                        align={'left'}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        sx={{ width: '15%', cursor: 'context-menu' }}
                    >
                        <Box>
                            <Grid container spacing={1}>
                                <IconButton
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    aria-label="more"
                                    id="long-button"
                                    onClick={handleClick}
                                >
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                </IconButton>
                                <StyledMenu
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button'
                                    }}
                                    anchorEl={anchorEl}
                                    id="demo-customized-menu"
                                    onClose={handleClose}
                                    open={open}
                                >
                                    <MenuItem
                                        disableRipple
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClickModal();
                                            handleClose(e);
                                        }}
                                    >
                                        <div className="actionsCellMenu">
                                            <Avatar
                                                sx={{ bgcolor: COLORS.ENEIDA_SIMPLE_LIGHT_RED, width: 30, height: 30 }}
                                                variant="rounded"
                                            >
                                                <FontAwesomeIcon color="#f44336" icon={faPowerOff} />
                                            </Avatar>
                                            <FormattedMessage id={'decommision_device'} />
                                        </div>
                                    </MenuItem>
                                </StyledMenu>
                            </Grid>
                        </Box>
                    </TableCell>
                </TableRow>
            </LocalizationProvider>
        </TableBody>
    );
}
