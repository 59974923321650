import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { HISTORICAL_DATA_TYPE } from 'models/asset/AssetHistoricalData';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export default function TypeFilter(props: { onChange: (value: HISTORICAL_DATA_TYPE) => void; selectedType: HISTORICAL_DATA_TYPE }) {
    const { onChange, selectedType } = props;

    const onTypeChange = useCallback(
        (event: SelectChangeEvent<HISTORICAL_DATA_TYPE>) => {
            onChange(event.target.value as HISTORICAL_DATA_TYPE);
        },
        [selectedType]
    );

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="type">
                <FormattedMessage id={'type'} />
            </InputLabel>
            <Select id="type" value={selectedType} onChange={(e) => onTypeChange(e)} label={<FormattedMessage id={'type'} />}>
                {Object.values(HISTORICAL_DATA_TYPE).map((value, index) => {
                    return (
                        <MenuItem key={index} value={value}>
                            <FormattedMessage id={value} />
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
