import { gql } from '@apollo/client';

import { Reports, Reports_reports_nodes } from './Types/Reports';

export type IReportInstance = Reports_reports_nodes;
export type IReport = Reports;

export const REPORTS = gql`
    query Reports($first: Int, $filter: ReportDTOFilterInput, $order: [ReportDTOSortInput!]) {
        reports(where: $filter, order: $order, first: $first) {
            nodes {
                created
                description
                equipments
                errors
                name
                period_end
                period_start
                periodic
                ready
                report_type
                requested_by
                size
                title
                _id
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
            totalCount
        }
    }
`;

export const REPORT = gql`
    query Report($reportId: String) {
        reports(where: { _id: { eq: $reportId } }, order: { created: DESC }) {
            nodes {
                created
                description
                equipments
                errors
                name
                period_end
                period_start
                periodic
                ready
                report_type
                requested_by
                size
                title
                _id
            }
        }
    }
`;
