import { LazyExoticComponent, Suspense } from 'react';

import Loader, { LoaderProps } from './Loader';

const Loadable = (Component: LazyExoticComponent<(props: any) => JSX.Element>, ComponentProps?: any) => (props: LoaderProps) =>
    (
        <Suspense fallback={<Loader />}>
            <Component {...props} {...ComponentProps} />
        </Suspense>
    );

export default Loadable;
