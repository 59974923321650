import { Autocomplete, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';

import { Filter, FilterValue } from './FilterTypes';

export type ObjConcat =
    | Record<string, Record<string, string | number | boolean | number[] | string[]>>
    | Record<string, Record<string, Record<string, string | number | boolean | number[]>>>
    | Record<
          string,
          Record<
              string,
              | Record<string, Record<string, string | number | boolean | number[]>>
              | Record<string, Record<string, Record<string, string | number | boolean | number[]>>>
          >
      >;

export const typeOptions = [
    { name: 'Equals', value: 'eq', type: ['string', 'number', 'enum', 'boolean'] },
    { name: 'Between', value: ['gte', 'lte'], type: ['number', 'date'] },
    { name: 'Contains', value: 'contains', type: ['string'] },
    { name: 'EndsWith', value: 'endsWith', type: ['string'] },
    { name: 'Starts With', value: 'startsWith', type: ['string'] },
    { name: 'Not Contains', value: 'ncontains', type: ['string'] },
    { name: 'Not Ends With', value: 'nendsWith', type: ['string'] },
    { name: 'Not Starts With', value: 'nstartsWith', type: ['string'] },
    { name: 'Match', value: 'in', type: ['string', 'number'] },
    { name: 'Not Match', value: 'nin', type: ['string', 'number'] },
    { name: 'Not Equal', value: 'neq', type: ['string', 'boolean', 'enum'] },
    { name: 'Greater Than', value: 'gt', type: ['date', 'number'] },
    { name: 'Lower Than', value: 'lt', type: ['date', 'number'] },
    { name: 'Greater Or Equal Than', value: 'gte', type: ['date', 'number'] },
    { name: 'Lower Or Equal Than', value: 'lte', type: ['date', 'number'] }
];

export const andOrOptions = [
    { name: 'And', value: 'and' },
    { name: 'Or', value: 'or' }
];

//function to render the correct field. It uses the type of the current filterValue to know which type to render
export function RenderValueComponent(
    type: string,
    value: string | number | boolean,
    i: number,
    between: number | undefined,
    handleOnChangeValue: (newValue: string | number | boolean, i: number, between: number | undefined) => void,
    disabled: boolean,
    filtersValue?: FilterValue[],
    el?: Filter
) {
    const intl = useIntl();

    switch (type) {
        case 'boolean':
            return (
                <Autocomplete
                    disableClearable
                    disabled={disabled}
                    getOptionLabel={(option) => {
                        return option.toString() !== '' ? intl.formatMessage({ id: option.toString() }) : '';
                    }}
                    isOptionEqualToValue={(option, val) => {
                        if (val.toString() === '') return true;
                        return option === val;
                    }}
                    onChange={(event, newValue) => {
                        handleOnChangeValue(newValue, i, between);
                    }}
                    options={[false, true]}
                    renderInput={(params) => <TextField {...params} label={<FormattedMessage id={'Value'} />} />}
                    value={(value as boolean) ?? ''}
                />
            );
        case 'enum':
            return (
                <Autocomplete
                    disableClearable
                    disabled={disabled}
                    getOptionLabel={(option) => {
                        return option ? intl.formatMessage({ id: option.toLowerCase() }) : '';
                    }}
                    isOptionEqualToValue={(option, val) => {
                        if (val === '') return true;
                        return option === val;
                    }}
                    onChange={(event, newValue) => {
                        handleOnChangeValue(newValue, i, between);
                    }}
                    options={
                        Object.entries(
                            filtersValue?.find((filter) => filter.name === el?.name)?.options as { [s: string]: string } | ArrayLike<string>
                        )
                            .filter((filter) => filter[0] !== 'NEW_HV_LINE_DOWN')
                            .map(([, value]) => value) ?? []
                    }
                    renderInput={(params) => <TextField {...params} label={<FormattedMessage id={'Value'} />} />}
                    value={
                        Object.entries(
                            filtersValue?.find((filter) => filter.name === el?.name)?.options as { [s: string]: string } | ArrayLike<string>
                        )
                            .filter((filter) => filter[0] !== 'NEW_HV_LINE_DOWN')
                            .map(([, value]) => value)
                            .find((test) => test === el?.value[0]) ?? ''
                    }
                />
            );
        case 'string':
            return (
                <TextField
                    disabled={disabled}
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChangeValue(event.target.value, i, between)}
                    placeholder={'Value'}
                    title={'Value'}
                    type={'text'}
                    value={value !== undefined ? value : ''}
                />
            );
        case 'number':
            return (
                <TextField
                    disabled={disabled}
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChangeValue(event.target.value, i, between)}
                    title={'Value'}
                    type={'number'}
                    value={value !== undefined ? value : ''}
                />
            );
        case 'date':
            return (
                <DatePicker
                    disabled={disabled}
                    label={<FormattedMessage id={'Date'} />}
                    onChange={(newValue: Dayjs | null) => {
                        newValue && handleOnChangeValue(newValue.toString(), i, between);
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                    value={disabled || value === undefined ? null : value}
                />
            );
    }
}

//build the final filter to pass to the query
export const getSubmitFilterWithOperators = (
    filters: Filter[],
    filterWithoutOperators: ObjConcat,
    andFilter: ObjConcat[],
    orFilter: ObjConcat[]
) => {
    const filterWithOperators: Record<
        string,
        (
            | Record<string, Record<string, string | number | boolean | number[] | string[]>>
            | Record<string, Record<string, Record<string, string | number | boolean | number[]>>>
            | Record<
                  string,
                  Record<
                      string,
                      | Record<string, Record<string, string | number | boolean | number[]>>
                      | Record<string, Record<string, Record<string, string | number | boolean | number[]>>>
                  >
              >
        )[]
    > = {};
    if (filters.length > 1 || andFilter.length > 0) {
        if (andFilter.length > 0 && orFilter.length > 0) {
            filterWithOperators['and'] = [];
            filterWithOperators['or'] = [];
            filterWithOperators['or'].push(filterWithoutOperators);

            andFilter.forEach((andItem) => {
                filterWithOperators['and'].push(andItem);
            });
            orFilter.forEach((orItem) => {
                filterWithOperators['or'].push(orItem);
            });
        } else if (andFilter.length > 0) {
            filterWithOperators['and'] = [];
            filterWithOperators['and'].push(filterWithoutOperators);
            andFilter.forEach((andItem) => {
                filterWithOperators['and'].push(andItem);
            });
        } else if (orFilter.length > 0) {
            filterWithOperators['or'] = [];
            filterWithOperators['or'].push(filterWithoutOperators);
            orFilter.forEach((orItem) => {
                filterWithOperators['or'].push(orItem);
            });
        } else {
            return filterWithoutOperators;
        }
    } else {
        return filterWithoutOperators;
    }
    return filterWithOperators;
};

//refactor of the filters choosen by the user to be used in the query
export const refactorFiltersToBeQuery = (filters: Filter[], filtersValue: FilterValue[]) => {
    const filterWithoutOperators: ObjConcat = {};
    const andFilter: ObjConcat[] = [];
    const orFilter: ObjConcat[] = [];

    let needToIncludeIngridTypes = new Boolean(false);
    let needToExcludeIngridTypes = new Boolean(false);

    let needToIncludeDtvigPmtTypes = new Boolean(false);
    let needToExcludeDtvigPmtTypes = new Boolean(false);

    let needToIncludeDtvig4gPmtTypes = new Boolean(false);
    let needToExcludeDtvig4gPmtTypes = new Boolean(false);

    let needToIncludeHvLineDownTypes = new Boolean(false);
    let needToExcludeHvLineDownTypes = new Boolean(false);

    filters.map((filterItem) => {
        if (filterItem.name === 'deviceType' && filterItem.value.toString() === 'InGrid') {
            if (filterItem.operator.toString() === 'eq') {
                needToIncludeIngridTypes = true;
            } else {
                needToExcludeIngridTypes = true;
            }
        }

        if (filterItem.name === 'deviceType' && filterItem.value.toString() === 'DTVIGPMT') {
            if (filterItem.operator.toString() === 'eq') {
                needToIncludeDtvigPmtTypes = true;
            } else {
                needToExcludeDtvigPmtTypes = true;
            }
        }

        if (filterItem.name === 'deviceType' && filterItem.value.toString() === 'DTVI4GPMT') {
            if (filterItem.operator.toString() === 'eq') {
                needToIncludeDtvig4gPmtTypes = true;
            } else {
                needToExcludeDtvig4gPmtTypes = true;
            }
        }

        if (filterItem.name === 'type' && filterItem.value.toString() === 'HV_LINE_DOWN') {
            if (filterItem.operator.toString() === 'eq') {
                needToIncludeHvLineDownTypes = true;
            } else {
                needToExcludeHvLineDownTypes = true;
            }
        }
    });

    filters.forEach((filter, index) => {
        const type = filtersValue.find((el) => el.name === filter.name)?.type ?? 'string';

        if (Object.keys(filter).length > 0) {
            if (index === 0) {
                if (filter.operator.length > 1) {
                    filterWithoutOperators[`${filter.name}`] = {
                        [filter.operator[0]]: type === 'number' ? Number(filter.value[0]) : filter.value[0].toString(),
                        [filter.operator[1]]: type === 'number' ? Number(filter.value[1]) : filter.value[1].toString()
                    };
                } else {
                    if (filter.name === 'operationalArea' || filter.name === 'region') {
                        filterWithoutOperators[`${filter.name}`] = {
                            name: {
                                [filter.operator[0]]:
                                    type === 'number'
                                        ? Number(filter.value)
                                        : type === 'boolean'
                                        ? filter.value[0]
                                        : filter.value.toString()
                            }
                        };
                    } else {
                        filterWithoutOperators[`${filter.name}`] = {
                            [filter.operator[0]]:
                                type === 'number' ? Number(filter.value) : type === 'boolean' ? filter.value[0] : filter.value.toString()
                        };
                    }
                }
            } else if (filter.andOr === 'and' || filter.andOr === undefined) {
                if (filter.operator.length > 1) {
                    const objConcat: ObjConcat = {};
                    if (filter.name === 'operationalArea' || filter.name === 'region') {
                        objConcat[`${filter.name}`] = {
                            name: {
                                [filter.operator[0]]:
                                    type === 'number'
                                        ? Number(filter.value)
                                        : type === 'boolean'
                                        ? filter.value[0]
                                        : filter.value.toString()
                            }
                        };
                    } else {
                        objConcat[`${filter.name}`] = {
                            [filter.operator[0]]: type === 'number' ? Number(filter.value[0]) : filter.value[0],
                            [filter.operator[1]]: type === 'number' ? Number(filter.value[1]) : filter.value[1]
                        };
                    }
                    andFilter.push(objConcat);
                } else {
                    const objConcat: ObjConcat = {};
                    if (filter.name === 'operationalArea' || filter.name === 'region') {
                        objConcat[`${filter.name}`] = {
                            name: {
                                [filter.operator[0]]:
                                    type === 'number'
                                        ? Number(filter.value)
                                        : type === 'boolean'
                                        ? filter.value[0]
                                        : filter.value.toString()
                            }
                        };
                    } else {
                        objConcat[`${filter.name}`] = {
                            [filter.operator[0]]:
                                type === 'number' ? Number(filter.value) : type === 'boolean' ? filter.value[0] : filter.value.toString()
                        };
                    }
                    andFilter.push(objConcat);
                }
            } else if (filter.andOr === 'or') {
                if (filter.operator.length > 1) {
                    const objConcat: ObjConcat = {};
                    if (filter.name === 'operationalArea' || filter.name === 'region') {
                        objConcat[`${filter.name}`] = {
                            name: {
                                [filter.operator[0]]:
                                    type === 'number'
                                        ? Number(filter.value)
                                        : type === 'boolean'
                                        ? filter.value[0]
                                        : filter.value.toString()
                            }
                        };
                    } else {
                        objConcat[`${filter.name}`] = {
                            [filter.operator[0]]: type === 'number' ? Number(filter.value[0]) : filter.value[0],
                            [filter.operator[1]]: type === 'number' ? Number(filter.value[1]) : filter.value[1]
                        };
                    }
                    orFilter.push(objConcat);
                } else {
                    const objConcat: ObjConcat = {};
                    if (filter.name === 'operationalArea' || filter.name === 'region') {
                        objConcat[`${filter.name}`] = {
                            name: {
                                [filter.operator[0]]:
                                    type === 'number'
                                        ? Number(filter.value)
                                        : type === 'boolean'
                                        ? filter.value[0]
                                        : filter.value.toString()
                            }
                        };
                    } else {
                        objConcat[`${filter.name}`] = {
                            [filter.operator[0]]:
                                type === 'number' ? Number(filter.value) : type === 'boolean' ? filter.value[0] : filter.value.toString()
                        };
                    }
                    orFilter.push(objConcat);
                }
            }
        }
    });

    if (needToIncludeIngridTypes === true) {
        filterWithoutOperators.deviceType = { in: ['INGRID90', 'INGRID91', 'INGRID92'] };
    } else if (needToExcludeIngridTypes === true) {
        filterWithoutOperators.deviceType = { nin: ['INGRID90', 'INGRID91', 'INGRID92'] };
    }

    if (needToIncludeDtvigPmtTypes === true) {
        filterWithoutOperators.deviceType = { in: ['DTVIGPMT', 'DTVIGPMT2'] };
    } else if (needToExcludeDtvigPmtTypes === true) {
        filterWithoutOperators.deviceType = { nin: ['DTVIGPMT', 'DTVIGPMT2'] };
    }

    if (needToIncludeDtvig4gPmtTypes === true) {
        filterWithoutOperators.deviceType = { in: ['DTVI4GPMT', 'DTVI4GPMT2'] };
    } else if (needToExcludeDtvig4gPmtTypes === true) {
        filterWithoutOperators.deviceType = { nin: ['DTVI4GPMT', 'DTVI4GPMT2'] };
    }
    if (needToIncludeHvLineDownTypes === true) {
        filterWithoutOperators.type = { in: ['HV_LINE_DOWN', 'NEW_HV_LINE_DOWN'] };
    } else if (needToExcludeHvLineDownTypes === true) {
        filterWithoutOperators.type = { nin: ['HV_LINE_DOWN', 'NEW_HV_LINE_DOWN'] };
    }

    return { filterWithoutOperators, andFilter, orFilter };
};

export const getActiveFilters = (filters: Filter[]) => {
    let activeFilters = 0;
    filters.forEach((filter) => {
        if (Object.keys(filter).length > 0 && filters[0].name !== '') {
            activeFilters += 1;
        }
    });
    return activeFilters;
};
