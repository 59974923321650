const enum Mode {
    ADD = 'add',
    EDIT = 'edit',
    IMPORT = 'import',
    LIST = 'list',
    PREVIEW = 'preview',
    NULL = 'null'
}

export default Mode;
