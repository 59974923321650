import { gql } from '@apollo/client';

export const BULK_LIST = gql`
    query DeviceBulkConfigurationsList($cursor: String, $numberOfElements: Int!, $filter: DeviceBulkConfigurationFilterInput) {
        deviceBulkConfigurations(where: $filter, order: { id: ASC }, first: $numberOfElements, after: $cursor) {
            totalCount
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            nodes {
                id
                name
                communicationRate
                aquisitionRate
                serverUrl
                timeServerPort
                timeServerProtocol
                timeServerType
                timeServerUrl
                isDefault
            }
        }
    }
`;
