import MainCard from '_global/Components/base/cards/MainCard';
import DeepgridLoader from '_global/Components/base/DeepgridLoader';
import { getData } from '_global/Services/api/networkRequester';
import { RootState } from '_global/Services/store';
import { modeAtomAssets, validationSetAssets } from '_global/Utils/hooks/jotai';
import { IconToNode } from '_global/Utils/IconToNode';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Grid, Tab } from '@mui/material';
import { useAtom } from 'jotai';
import { useConfiguration } from 'logic/configuration/Hooks';
import { ASSET_TYPE_PROTECTED } from 'models/asset/AssetInfo';
import { AssetModel, Feeder, GenericFeederType } from 'models/asset/AssetModel';
import Mode from 'models/enums/ModeEnum';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Configuration from './info/Configuration';
import DevicesTable from './info/DevicesTable/DevicesTable';
import FeederTable from './info/FeederTable';
import Info from './info/Info';
import TransformerInfo, { optionsPhaseRotation, optionsPhaseType } from './info/TransformerInfo';
import { AssetDetailsProps } from './utils/AssetModel';
export enum TabValue {
    Feeders = 0,
    Busbar = 1,
    Earths = 2
}

const AssetDetails = (props: AssetDetailsProps) => {
    const [mode] = useAtom(modeAtomAssets);
    const [tabValue, setTabValue] = useState<string>(mode !== Mode.ADD ? TabValue.Busbar.toString() : TabValue.Feeders.toString());
    const [validation, setValidation] = useAtom(validationSetAssets);
    const { assetId } = props;
    const intl = useIntl();
    const configuration = useConfiguration();
    const defaultLatitude = useMemo(() => configuration?.InitialMapPosition?.position?.latitude ?? 40, [configuration]);
    const defaultLongitude = useMemo(() => configuration?.InitialMapPosition?.position?.longitude ?? -8, [configuration]);

    const defaultStateAsset = {
        assetId: 0,
        name: '',
        registryNumber: '',
        isEnabled: false,
        state: 'NOT_INSTALLED',
        assetType: 0,
        latitude: defaultLatitude,
        longitude: defaultLongitude,
        description: '',
        transformer: {
            id: 0,
            assetId: 0,
            name: '',
            description: '',
            isMainTranformer: false,
            equipmentType: '',
            brand: '',
            model: '',
            power: 0,
            manufactureDate: '',
            serviceEntryDate: '',
            phaseRotation: optionsPhaseRotation[0].value.toString(),
            phaseType: optionsPhaseType[2].value,
            createdBy: '',
            lastModifiedBy: ''
        },
        transformerId: 0,
        alarmStandardId: null,
        alarmStandardName: null,
        regionId: 0,
        regionName: '',
        operationalAreaId: 0,
        operationalAreaName: '',
        devices: [],
        equipBulkConfigurationId: null,
        equipBulkConfigurationName: null,
        equipments: [],
        feeders: [],
        masterBusLines: [],
        createdBy: '',
        createdDate: '',
        lastModifiedBy: '',
        lastModifiedDate: '',
        validRegion_And_OperationalArea: false,
        hasEarth: false
    };

    const [assetDetails, setAssetDetails] = useState<AssetModel>(defaultStateAsset);
    const features = useSelector((state: RootState) => state.features);
    const saveState = (state: AssetModel) => {
        try {
            const serializedState = JSON.stringify(state);
            localStorage.setItem('assetDetails', serializedState);
        } catch {
            // ignore write errors
        }
    };

    useEffect(() => {
        saveState(assetDetails);
    }, [assetDetails]);

    useEffect(() => {
        if (mode === Mode.PREVIEW) {
            const updateVal = [...validation];
            updateVal.map((el) => (el.hasError = false));
            setValidation(updateVal);
        }
    }, [mode]);

    const [isLoading, setIsLoading] = useState(false);

    const fetchAsset = useCallback(() => {
        setIsLoading(true);

        if (assetId) {
            const endpoint = `${features.configuration?.BaseUrl ?? ''}/equipments/api/equipment/Asset/${assetId}`;
            getData<AssetModel>(endpoint)
                .then((data) => {
                    setAssetDetails(data);

                    if (mode === Mode.ADD) {
                        setAssetDetails((old) => ({
                            ...old,
                            assetId: 0,
                            name: '',
                            registryNumber: '',
                            devices: [],
                            equipments: [],
                            transformerId: 0,
                            transformer: { ...old.transformer, id: 0, assetId: 0 },
                            masterBusLines: [],
                            feeders: old.feeders
                                .map((item) => ({ ...item, id: 0, assetId: 0, equipmentId: 0, powerBusLines: [] }))
                                .sort((a, b) => a.number - b.number)
                        }));
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
        }
        setIsLoading(false);
    }, [features.configuration, mode]);

    useEffect(() => {
        if (mode === Mode.PREVIEW || mode === Mode.EDIT || (mode === Mode.ADD && assetId)) {
            fetchAsset();
        }
    }, [mode, props]);

    const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
        setAssetDetails((old) => ({ ...old, [id]: event.target.value }));
    }, []);

    const handleOnChangeTransfomerInfo = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
        setAssetDetails((old) => ({ ...old, transformer: { ...old.transformer, [id]: event.target.value } }));
    }, []);

    const handleChangeTransfomerInfo = useCallback((newValue: string, id: string) => {
        setAssetDetails((old) => ({ ...old, transformer: { ...old.transformer, [id]: newValue } }));
    }, []);

    const handleChange = useCallback((newValue: string, id: string) => {
        setAssetDetails((old) => ({ ...old, [id]: newValue }));
    }, []);

    const handleChangeBool = useCallback((newValue: boolean, id: string) => {
        setAssetDetails((old) => ({ ...old, [id]: newValue }));
    }, []);

    const handleChangeAlarm = useCallback((alarmStandardId: number | null, alarmStandardName: string | null) => {
        setAssetDetails((old) => ({ ...old, alarmStandardId, alarmStandardName }));
    }, []);

    const handleChangeBulk = useCallback((equipBulkConfigurationId: number | null, equipBulkConfigurationName: string | null) => {
        setAssetDetails((old) => ({ ...old, equipBulkConfigurationId, equipBulkConfigurationName }));
    }, []);

    const handleChangeNumber = useCallback((newValue: number, id: string) => {
        setAssetDetails((old) => ({ ...old, [id]: newValue }));
    }, []);

    const updateFeeders = useCallback((feeders: Feeder[]) => {
        setAssetDetails((old) => ({ ...old, feeders: feeders }));
    }, []);

    const handleChangeAssetType = useCallback((newValue: ASSET_TYPE_PROTECTED) => {
        const val = Object.entries(ASSET_TYPE_PROTECTED).find(([key]) => key === newValue)?.[1];
        setAssetDetails((old) => ({ ...old, assetType: Number(val) }));
    }, []);

    const handleChangeTab = useCallback((event: SyntheticEvent, tab: TabValue) => {
        setTabValue(tab.toString());
    }, []);

    const handleAddFeeder = useCallback(() => {
        const lastFeederNumberId = assetDetails.feeders.sort((a, b) => a.number - b.number).findLast((x) => x)?.number ?? 0;

        const newArray: Feeder[] = [...assetDetails.feeders];

        newArray.push({
            id: 0,
            name: '',
            equipmentId: 0,
            description: '',
            isActive: false,
            fuseRating: 0,
            cableTypeId: null,
            cableTypeName: null,
            alarmStandardId: null,
            alarmStandardName: null,
            createdDate: '',
            assetId: 0,
            number: Number(lastFeederNumberId) + 1,
            createdBy: '',
            lastModifiedBy: '',
            lastModifiedDate: '',
            powerBusLines: [],
            feederType: 2
        });

        updateFeeders(newArray);
    }, [assetDetails.feeders]);

    return (
        <Box>
            <DeepgridLoader open={isLoading} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Info
                        {...{ handleChangeNumber, assetDetails, handleChange, handleOnChange, handleChangeAssetType, handleChangeBool }}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TransformerInfo
                        {...{
                            handleChangeNumber,
                            assetDetails,
                            handleChange,
                            handleOnChange,
                            handleOnChangeTransfomerInfo,
                            handleChangeTransfomerInfo
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Configuration {...{ assetDetails, handleChangeBulk, handleChangeAlarm }} />
                </Grid>
                <Grid item xs={12}>
                    <MainCard>
                        <Box sx={{ borderColor: 'divider', width: '100%', position: 'relative' }}>
                            <Box sx={{ position: 'absolute', right: 0, top: 0, bottom: 0, margin: 'auto', zIndex: 10 }}>
                                {tabValue === TabValue.Feeders.toString() && (mode === Mode.EDIT || mode === Mode.ADD) && (
                                    <div>
                                        <Button
                                            color="secondary"
                                            key={'add'}
                                            onClick={() => handleAddFeeder()}
                                            sx={{ ml: 2 }}
                                            variant="contained"
                                        >
                                            <IconToNode definition={faCirclePlus} size={'lg'} sx={{ paddingRight: 3 }} />
                                            {intl.formatMessage({ id: 'add_feeder' })}
                                        </Button>
                                    </div>
                                )}
                            </Box>
                            <TabContext value={tabValue.toString()}>
                                <TabList onChange={handleChangeTab} value={tabValue.toString()}>
                                    {mode !== Mode.ADD && (
                                        <Tab label={<FormattedMessage id={'busbar'} />} value={TabValue.Busbar.toString()} />
                                    )}
                                    <Tab label={<FormattedMessage id={'feeders'} />} value={TabValue.Feeders.toString()} />
                                    {assetDetails.hasEarth && mode !== Mode.ADD && (
                                        <Tab label={<FormattedMessage id={'earth'} />} value={TabValue.Earths.toString()} />
                                    )}
                                </TabList>

                                <TabPanel value={TabValue.Feeders.toString()}>
                                    <FeederTable
                                        feederType={GenericFeederType.FEEDER}
                                        feeders={assetDetails.feeders}
                                        updateFeeders={updateFeeders}
                                    />
                                </TabPanel>
                                {mode !== Mode.ADD && (
                                    <TabPanel value={TabValue.Busbar.toString()}>
                                        <FeederTable
                                            feederType={GenericFeederType.BUSBAR}
                                            feeders={assetDetails.feeders}
                                            updateFeeders={updateFeeders}
                                        />
                                    </TabPanel>
                                )}
                                {mode !== Mode.ADD && (
                                    <TabPanel value={TabValue.Earths.toString()}>
                                        <FeederTable
                                            feederType={GenericFeederType.EARTH}
                                            feeders={assetDetails.feeders}
                                            updateFeeders={updateFeeders}
                                        />
                                    </TabPanel>
                                )}
                            </TabContext>
                        </Box>
                    </MainCard>
                </Grid>
                {mode !== Mode.ADD && (
                    <Grid item xs={12}>
                        <DevicesTable {...{ assetDetails, mode, fetchAsset }} />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default AssetDetails;
