import { COLORS } from '_global/Utils/Colors';
import { ConditionalLink } from '_global/Utils/conditionalLink';
import { IconToNode } from '_global/Utils/IconToNode';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faEraser } from '@fortawesome/pro-regular-svg-icons';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Autocomplete, Box, FormControl, IconButton, MenuItem, Select, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { FeederType } from 'models/asset/AssetModel';
import Mode from 'models/enums/ModeEnum';
import Type from 'models/enums/TypeEnum';
import { FormattedMessage, useIntl } from 'react-intl';
import { IFeederTableRow } from 'views/Assets/utils/AssetModel';

export default function FeederTableRow(props: IFeederTableRow) {
    const intl = useIntl();
    const {
        feeder,
        cableTypeOptions,
        setFeederToDelete,
        handleOnChange,
        handleChange,
        handleChangeSelect,
        handleEraseCableTypeAndFuseRating,
        standardsOption,
        setOpenModal,
        mode
    } = props;

    const align = 'left';
    const iconTrash: IconDefinition = faTrash;
    const iconEraser: IconDefinition = faEraser;

    return (
        <TableRow hover>
            <TableCell align={align} sx={{ width: '15%' }}>
                {mode === Mode.PREVIEW ? (
                    <Box sx={{ width: '100%' }}>
                        <Typography sx={{ width: '100%' }}>{feeder.name}</Typography>
                    </Box>
                ) : (
                    <TextField
                        disabled={
                            feeder.feederType === FeederType.BUSBAR ||
                            feeder.feederType === FeederType.EARTH_PROTECTION_LINE ||
                            feeder.feederType === FeederType.EARTH_SERVICE_LINE
                        }
                        fullWidth
                        onChange={(event) => handleOnChange(event, 'name', feeder.number, Type.STRING)}
                        value={feeder.name}
                        variant="standard"
                    />
                )}
            </TableCell>
            <TableCell align={align} sx={{ width: '10%' }}>
                {mode === Mode.PREVIEW ? (
                    <Typography
                        sx={{
                            paddingX: '10px',
                            paddingY: '5px',
                            background: feeder.isActive === true ? COLORS.ENEIDA_SIMPLE_LIGHT_GREEN : COLORS.ENEIDA_SIMPLE_LIGHT_RED,
                            borderRadius: '25px',
                            maxWidth: '100px',
                            color: feeder.isActive === true ? COLORS.ENEIDA_ALTERNATIVE_GREEN : COLORS.ENEIDA_ALTERNATIVE_RED
                        }}
                        variant="button"
                    >
                        {feeder.isActive === true
                            ? intl.formatMessage({ id: 'feeder_active' })
                            : intl.formatMessage({ id: 'feeder_disabled' })}
                    </Typography>
                ) : (
                    <FormControl fullWidth variant="standard">
                        <Select
                            disabled={
                                feeder.feederType === FeederType.BUSBAR ||
                                feeder.feederType === FeederType.EARTH_PROTECTION_LINE ||
                                feeder.feederType === FeederType.EARTH_SERVICE_LINE
                            }
                            onChange={(event) => handleChangeSelect(event, 'isActive', feeder.number, Type.BOOLEAN)}
                            value={feeder.isActive.toString()}
                        >
                            <MenuItem value={'true'}>{intl.formatMessage({ id: 'feeder_enabled' })}</MenuItem>
                            <MenuItem value={'false'}>{intl.formatMessage({ id: 'feeder_disabled' })}</MenuItem>
                        </Select>
                    </FormControl>
                )}
            </TableCell>

            <TableCell align={align} sx={{ width: '15%' }}>
                {mode === Mode.PREVIEW ? (
                    <ConditionalLink condition={feeder.alarmStandardId} to={`/alarmProfiles/${feeder.alarmStandardId ?? ''}`}>
                        <Box sx={{ width: '100%', cursor: feeder.alarmStandardId ? 'pointer' : 'context-menu' }}>
                            <Typography>{feeder.alarmStandardId ? feeder.alarmStandardName : <FormattedMessage id={'none'} />}</Typography>
                        </Box>
                    </ConditionalLink>
                ) : (
                    <Autocomplete
                        fullWidth
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        key={feeder.alarmStandardId}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                handleChange(newValue.id, 'alarmStandardId', feeder.number);
                            } else {
                                handleChange(newValue, 'alarmStandardId', feeder.number);
                            }
                        }}
                        options={standardsOption}
                        renderInput={(params) => (
                            <TextField variant="standard" {...params} placeholder={intl.formatMessage({ id: 'search_to_add' })} />
                        )}
                        value={feeder.alarmStandardId ? standardsOption.find((standard) => standard.id === feeder.alarmStandardId) : null}
                    />
                )}
            </TableCell>
            {feeder.feederType !== FeederType.BUSBAR &&
                feeder.feederType !== FeederType.EARTH_PROTECTION_LINE &&
                feeder.feederType !== FeederType.EARTH_SERVICE_LINE && (
                    <>
                        <TableCell align={align} sx={{ width: '15%' }}>
                            {mode === Mode.PREVIEW ? (
                                <ConditionalLink condition={feeder.cableTypeId} to={`/cableTypes/${feeder.cableTypeId ?? ''}`}>
                                    <Box sx={{ width: '100%', cursor: feeder.cableTypeId ? 'pointer' : 'context-menu' }}>
                                        <Typography>
                                            {feeder.cableTypeId ? feeder.cableTypeName : <FormattedMessage id={'none'} />}
                                        </Typography>
                                    </Box>
                                </ConditionalLink>
                            ) : (
                                <Autocomplete
                                    disabled={feeder.fuseRating !== 0}
                                    fullWidth
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    key={feeder.cableTypeId}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            handleChange(newValue.id, 'cableTypeId', feeder.number);
                                        } else {
                                            handleChange(newValue, 'cableTypeId', feeder.number);
                                        }
                                    }}
                                    options={cableTypeOptions}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={intl.formatMessage({ id: 'search_to_add' })}
                                            variant="standard"
                                        />
                                    )}
                                    value={
                                        feeder.cableTypeId
                                            ? cableTypeOptions.find((cableType) => cableType.id === feeder.cableTypeId)
                                            : null
                                    }
                                />
                            )}
                        </TableCell>
                        <TableCell align={align} sx={{ width: '5%' }}>
                            {mode === Mode.PREVIEW ? (
                                <Box sx={{ width: '100%' }}>
                                    <Typography>{feeder.fuseRating}</Typography>
                                </Box>
                            ) : (
                                <TextField
                                    disabled={feeder.cableTypeId !== null}
                                    fullWidth
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={(event) => handleOnChange(event, 'fuseRating', feeder.number, Type.NUMBER)}
                                    type="number"
                                    value={feeder.fuseRating}
                                    variant="standard"
                                />
                            )}
                        </TableCell>
                        {mode !== Mode.PREVIEW && (
                            <TableCell align={align} sx={{ width: '10%' }}>
                                {feeder.feederType === FeederType.POWERBUSLINE && (
                                    <Box>
                                        <IconButton
                                            disabled={feeder.cableTypeId === null && feeder.fuseRating === 0}
                                            key="erase"
                                            onClick={() => {
                                                handleEraseCableTypeAndFuseRating(feeder.number);
                                            }}
                                            sx={{ opacity: feeder.cableTypeId === null && feeder.fuseRating === 0 ? 0.6 : null }}
                                        >
                                            <IconToNode
                                                definition={iconEraser}
                                                size="1x"
                                                sx={{
                                                    paddingLeft: '4px',
                                                    paddingRight: '4px',
                                                    color: 'black'
                                                }}
                                            />
                                        </IconButton>
                                        <IconButton
                                            key="delete"
                                            onClick={() => {
                                                setOpenModal(true);
                                                setFeederToDelete(feeder.number);
                                            }}
                                        >
                                            <IconToNode
                                                definition={iconTrash}
                                                size="1x"
                                                sx={{ paddingLeft: '4px', paddingRight: '4px', color: 'black' }}
                                            />
                                        </IconButton>
                                    </Box>
                                )}
                            </TableCell>
                        )}
                    </>
                )}
        </TableRow>
    );
}
