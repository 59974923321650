import { CableTypesInstance } from 'queries/CableTypes';

export interface CableTypeResponse {
    id: number;
    name: string;
    coreSection: number;
    color: string;
    cores: number;
    fuseWay: number | null;
    createdBy: string | null;
    createdDate: Date | null;
    lastModifiedBy: string | null;
    lastModifiedDate: Date | null;
}

export function cableTypeToRow(cableType: CableTypesInstance): CableTypeResponse {
    return {
        id: Number(cableType.id),
        name: cableType.name,
        coreSection: Number(cableType.coreSection),
        color: cableType.color,
        cores: Number(cableType.cores),
        fuseWay: cableType.fuseWay,
        createdBy: cableType.createdBy,
        createdDate: new Date(String(cableType.createdDate)),
        lastModifiedBy: cableType?.lastModifiedBy,
        lastModifiedDate: new Date(String(cableType.lastModifiedDate))
    };
}
