import { Filter } from '_global/Components/base/filter/FilterTypes';
import { RTAlarmEnum } from '_global/Components/base/forms/RtAlarmEnum';
import { atom } from 'jotai';
import { ConTestConfigTabValue } from 'models/conTest/ConTestConfiguration';
import { defaultDeviceValidationForm } from 'models/device/Device';
import Mode from 'models/enums/ModeEnum';
import { ItemAccessGroup } from 'models/layout/AccessGroup';
import { Country } from 'models/layout/Country';
import { RemoteUserSettings } from 'models/settings/RemoteUserSettings';
import { IValidation } from 'models/validation/validation';
import { defaultImportAssetValidation, defaultValidationFormAssets, IImportValidationAssets } from 'views/Assets/utils/AssetModel';
import { defaultValidationConfigurationProfile } from 'views/BulkConfiguration/utils/ConfigurationProfilesModels';
import { defaultCompanyValidation } from 'views/Company/utils/CompanyModels';
import { defaultConTestConfigValidation } from 'views/ConTestConfiguration/utils/ConTestConfigurationModels';
import { defaultDeviceValidation } from 'views/Devices/utils/DeviceModels';
import { defaultOpAreaValidation } from 'views/OpAreas/utils/OpAreaModels';
import { defaultRegionValidation } from 'views/Regions/utils/RegionModels';
import { defaultValidationUsers, IValidations } from 'views/Users/utils/UsersModels';

//------ GLOBAL USER PROPS --------//
const userAccessGroupAtom = atom<ItemAccessGroup>({ id: 0, name: '' });
const userLanguageAtom = atom<string>('');
const flagShouldUpdateAtom = atom<RemoteUserSettings | undefined>(undefined);

//------ DASHBOARD --------//
const subTabAtomDashboard = atom<string>('Overview');
const reRenderMapAtomDashboard = atom(false);

//------ ACCESS GROUPS --------//
const modeAtomAccessGroups = atom<Mode>(Mode.LIST);
const filterAtomAccessGroups = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAssetAtomAccessGroups = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterCompaniesAtomAccessGroups = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterRegionsAtomAccessGroups = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterOpAreaAtomAccessGroups = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterUsersAtomAccessGroups = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomAccessGroups = atom<string>('List');

//------ ASSETS --------//
const modeAtomAssets = atom<Mode>(Mode.LIST);
const tabAtomAssets = atom<string>('List');
const subTabAtomAssets = atom<string>('');
const validationAtomAssets = atom<IValidation[]>([...defaultValidationFormAssets]);
const validationSetAssets = atom(
    (get) => get(validationAtomAssets),
    (_get, set, newValidationSet: IValidation[]) => set(validationAtomAssets, newValidationSet)
);
const importAssetsValidationAtomAssets = atom<IImportValidationAssets>(defaultImportAssetValidation);
const importAssetsValidationSet = atom(
    (get) => get(importAssetsValidationAtomAssets),
    (_get, set, newAssetsValidationSet: IImportValidationAssets) => set(importAssetsValidationAtomAssets, newAssetsValidationSet)
);
const hasLastDataAtomAssets = atom<boolean>(false);
const hasLastDataSet = atom(
    (get) => get(hasLastDataAtomAssets),
    (_get, set, newSelected: boolean) => set(hasLastDataAtomAssets, newSelected)
);
const filterAtomAssets = atom<Filter[]>([{ name: '', operator: [], value: [] }]);

//------ CONFIGURATION PROFILE --------//
const modeAtomConfigurationProfile = atom<Mode>(Mode.LIST);
const validationAtomConfigurationProfile = atom<IValidations[]>(defaultValidationConfigurationProfile);
const validationConfigProfileSet = atom(
    (get) => get(validationAtomConfigurationProfile),
    (_get, set, newValidationSet: IValidations[]) => set(validationAtomConfigurationProfile, newValidationSet)
);
const filterAtomConfigurationProfile = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomConfigurationProfile = atom<string>('List');

//------ CABLE TYPES --------//
const modeAtomCableTypes = atom<Mode>(Mode.LIST);
const filterAtomCableTypes = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomCableTypes = atom<string>('List');

//------ COMPANY --------//
const modeAtomCompany = atom<Mode>(Mode.LIST);
const countryAtomCompany = atom<Country[]>([]);
const validationAtomCompany = atom<IValidations[]>(defaultCompanyValidation);
const validationCompanySet = atom(
    (get) => get(validationAtomCompany),
    (_get, set, newValidationSet: IValidations[]) => set(validationAtomCompany, newValidationSet)
);
const filterAtomCompany = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomCompany = atom<string>('List');

//------ DEVICES --------//
const modeAtomDevices = atom<Mode>(Mode.LIST);
const validationAtomDevices = atom<IValidations[]>(defaultDeviceValidation);
const validationDeviceSet = atom(
    (get) => get(validationAtomDevices),
    (_get, set, newValidationSet: IValidations[]) => set(validationAtomDevices, newValidationSet)
);
const filterAtomDevices = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomDevices = atom<string>('List');

//------ EVENTS --------//
const modeAtomEvents = atom<Mode>(Mode.LIST);
const filterAtomEvents = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomEvents = atom<string>('List');

//------ FIRMWARE --------//
const modeAtomFirmware = atom<Mode>(Mode.LIST);
const validationAtomFirmware = atom<IValidation[]>([...defaultDeviceValidationForm]);
const validationSetFirmware = atom(
    (get) => get(validationAtomFirmware),
    (_get, set, newValidationSet: IValidation[]) => set(validationAtomFirmware, newValidationSet)
);
const filterAtomFirmware = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomFirmware = atom<string>('List');

//------ FIRMWARE TASKS--------//
const modeAtomFirmwareTasks = atom<Mode>(Mode.LIST);
const validationAtomFirmwareTasks = atom<IValidation[]>([...defaultDeviceValidationForm]);
const validationSetFirmwareTasks = atom(
    (get) => get(validationAtomFirmwareTasks),
    (_get, set, newValidationSet: IValidation[]) => set(validationAtomFirmwareTasks, newValidationSet)
);
const tabAtomFirmwareTasks = atom<string>('List');
const editButtonAtomFirmwareTasks = atom<boolean>(false);
const filterAtomFirmwareTasks = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAssetAtomFirmwareTasks = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterCompaniesAtomFirmwareTasks = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterRegionsAtomFirmwareTasks = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterOpAreaAtomFirmwareTasks = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterDevicesAtomFirmwareTasks = atom<Filter[]>([{ name: '', operator: [], value: [] }]);

//------ OPERATIONAL AREAS--------//
const modeAtomOperationalAreas = atom<Mode>(Mode.LIST);
const validationAtomOperationalAreas = atom<IValidations[]>(defaultOpAreaValidation);
const validationOpAreaSet = atom(
    (get) => get(validationAtomOperationalAreas),
    (_get, set, newValidationSet: IValidations[]) => set(validationAtomOperationalAreas, newValidationSet)
);
const filterAtomOperationalAreas = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomOperationalAreas = atom<string>('List');

//------ ALARM PROFILES --------//
const modeAtomAlarmProfiles = atom<Mode>(Mode.LIST);
const filterAtomAlarmProfiles = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomAlarmProfiles = atom<string>('List');
const fetchEnumsAtomAlarmProfiles = atom<RTAlarmEnum | undefined>(undefined);

//------ REGIONS --------//
const modeAtomRegions = atom<Mode>(Mode.LIST);
const countryAtomRegions = atom<Country[]>([]);
const validationAtomRegions = atom<IValidations[]>(defaultRegionValidation);
const validationRegionSet = atom(
    (get) => get(validationAtomRegions),
    (_get, set, newValidationSet: IValidations[]) => set(validationAtomRegions, newValidationSet)
);
const filterAtomRegions = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomRegions = atom<string>('List');

//------ REPORTS --------//
const modeAtomReports = atom<Mode>(Mode.LIST);
const tabAtomReports = atom<string>('List');
const tabAtomSystemReports = atom<string>('List');
const tabAtomCustomReports = atom<string>('List');
const selectedReportAtom = atom<{ name: string; id: string } | undefined>(undefined);
const setSelectedReportAtom = atom(
    (get) => get(selectedReportAtom),
    (_get, set, newSelectedReport: { name: string; id: string } | undefined) => set(selectedReportAtom, newSelectedReport)
);
const selectedReportAtomSystem = atom<{ name: string; id: string } | undefined>(undefined);
const setSelectedReportAtomSystem = atom(
    (get) => get(selectedReportAtomSystem),
    (_get, set, newSelectedReport: { name: string; id: string } | undefined) => set(selectedReportAtomSystem, newSelectedReport)
);
const selectedReportAtomCustom = atom<{ name: string; id: string } | undefined>(undefined);
const setSelectedReportAtomCustom = atom(
    (get) => get(selectedReportAtomCustom),
    (_get, set, newSelectedReport: { name: string; id: string } | undefined) => set(selectedReportAtomCustom, newSelectedReport)
);
const updateReportsAtom = atom<boolean>(false);
const setUpdateReportAtom = atom(
    (get) => get(updateReportsAtom),
    (_get, set, updateReport: boolean) => set(updateReportsAtom, updateReport)
);
const updateReportsAtomSystem = atom<boolean>(false);
const setUpdateReportAtomSystem = atom(
    (get) => get(updateReportsAtomSystem),
    (_get, set, updateReport: boolean) => set(updateReportsAtomSystem, updateReport)
);
const updateReportsAtomCustom = atom<boolean>(false);
const setUpdateReportAtomCustom = atom(
    (get) => get(updateReportsAtomCustom),
    (_get, set, updateReport: boolean) => set(updateReportsAtomCustom, updateReport)
);
const filterAtomAllReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAtomSystemReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAtomCustomReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAssetAtomReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterCompaniesAtomReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterRegionsAtomReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterOpAreaAtomReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAccessGroupsAtomReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);

//------ USER PROFILE --------//
const modeAtomUserProfile = atom<Mode>(Mode.PREVIEW);
const lastUserSettingsAtom = atom<RemoteUserSettings | undefined>(undefined);

//------ USER ROLES --------//
const modeAtomUserRoles = atom<Mode>(Mode.LIST);
const filterAtomUserRoles = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomUserRoles = atom<string>('List');

//------ USERS --------//
const modeAtomUsers = atom<Mode>(Mode.LIST);
const validationAtomUsers = atom<IValidations[]>(defaultValidationUsers);
const validationUserSet = atom(
    (get) => get(validationAtomUsers),
    (_get, set, newValidationSet: IValidations[]) => set(validationAtomUsers, newValidationSet)
);
const filterAtomUsers = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomUsers = atom<string>('List');

//------ CONTEST CONFIGURATIONS --------//
const modeAtomConTestConfig = atom<Mode>(Mode.LIST);
const filterAtomConTestConfig = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAtomBatchDevices = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAtomBatchSIMs = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomConTestConfig = atom<string>('List');
const subTabAtomConTestConfig = atom<ConTestConfigTabValue>(ConTestConfigTabValue.INFO);
const validationAtomConTestConfig = atom<IValidations[]>(defaultConTestConfigValidation);
const validationConTestConfigSet = atom(
    (get) => get(validationAtomConTestConfig),
    (_get, set, newValidationSet: IValidations[]) => set(validationAtomConTestConfig, newValidationSet)
);
const ConTestConfigQRCodeAtom = atom<boolean>(false);

export {
    ConTestConfigQRCodeAtom,
    countryAtomCompany,
    countryAtomRegions,
    editButtonAtomFirmwareTasks,
    fetchEnumsAtomAlarmProfiles,
    filterAccessGroupsAtomReports,
    filterAssetAtomAccessGroups,
    filterAssetAtomFirmwareTasks,
    filterAssetAtomReports,
    filterAtomAccessGroups,
    filterAtomAlarmProfiles,
    filterAtomAllReports,
    filterAtomAssets,
    filterAtomBatchDevices,
    filterAtomBatchSIMs,
    filterAtomCableTypes,
    filterAtomCompany,
    filterAtomConfigurationProfile,
    filterAtomConTestConfig,
    filterAtomCustomReports,
    filterAtomDevices,
    filterAtomEvents,
    filterAtomFirmware,
    filterAtomFirmwareTasks,
    filterAtomOperationalAreas,
    filterAtomRegions,
    filterAtomSystemReports,
    filterAtomUserRoles,
    filterAtomUsers,
    filterCompaniesAtomAccessGroups,
    filterCompaniesAtomFirmwareTasks,
    filterCompaniesAtomReports,
    filterDevicesAtomFirmwareTasks,
    filterOpAreaAtomAccessGroups,
    filterOpAreaAtomFirmwareTasks,
    filterOpAreaAtomReports,
    filterRegionsAtomAccessGroups,
    filterRegionsAtomFirmwareTasks,
    filterRegionsAtomReports,
    filterUsersAtomAccessGroups,
    flagShouldUpdateAtom,
    hasLastDataSet,
    importAssetsValidationSet,
    lastUserSettingsAtom,
    modeAtomAccessGroups,
    modeAtomAlarmProfiles,
    modeAtomAssets,
    modeAtomCableTypes,
    modeAtomCompany,
    modeAtomConfigurationProfile,
    modeAtomConTestConfig,
    modeAtomDevices,
    modeAtomEvents,
    modeAtomFirmware,
    modeAtomFirmwareTasks,
    modeAtomOperationalAreas,
    modeAtomRegions,
    modeAtomReports,
    modeAtomUserProfile,
    modeAtomUserRoles,
    modeAtomUsers,
    reRenderMapAtomDashboard,
    selectedReportAtom,
    setSelectedReportAtom,
    setSelectedReportAtomCustom,
    setSelectedReportAtomSystem,
    setUpdateReportAtom,
    setUpdateReportAtomCustom,
    setUpdateReportAtomSystem,
    subTabAtomAssets,
    subTabAtomConTestConfig,
    subTabAtomDashboard,
    tabAtomAccessGroups,
    tabAtomAlarmProfiles,
    tabAtomAssets,
    tabAtomCableTypes,
    tabAtomCompany,
    tabAtomConfigurationProfile,
    tabAtomConTestConfig,
    tabAtomCustomReports,
    tabAtomDevices,
    tabAtomEvents,
    tabAtomFirmware,
    tabAtomFirmwareTasks,
    tabAtomOperationalAreas,
    tabAtomRegions,
    tabAtomReports,
    tabAtomSystemReports,
    tabAtomUserRoles,
    tabAtomUsers,
    userAccessGroupAtom,
    userLanguageAtom,
    validationCompanySet,
    validationConfigProfileSet,
    validationConTestConfigSet,
    validationDeviceSet,
    validationOpAreaSet,
    validationRegionSet,
    validationSetAssets,
    validationSetFirmware,
    validationSetFirmwareTasks,
    validationUserSet
};
