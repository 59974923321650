import '@inovua/reactdatagrid-community/index.css';

import { defaultRowStyle } from '_global/Components/base/grids/RowUtils';
import { addGridsPaginations, Pagination } from '_global/Services/store/gridsPaginationSlice';
import { useAppDispatch, useAppSelector } from '_global/Services/store/hooks';
import { userLocale } from '_global/Utils/GetCurrentLocale';
import gridsPaginationsUpdateGrid from '_global/Utils/gridsPaginations';
import { setSelectedReportAtom, setSelectedReportAtomCustom, setSelectedReportAtomSystem } from '_global/Utils/hooks/jotai';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeColumn, TypeComputedProps, TypeDataSource, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-community/types';
import { Box } from '@mui/material';
import { useAtom } from 'jotai';
import { SortInfoType } from 'models/sortInfo';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { TypeColumnExtended } from 'views/Assets/grid/AssetsGrid';
import { GridHandleType } from 'views/Events/grid/filter/DataFilter';

import { EReportsPageType } from '../ReportsPage';
import ActionsCell from './Cells/ActionsCell';
import DateCell from './Cells/DateCell';

export type ReportsGridProps = {
    data: TypeDataSource;
    pagination?: boolean;
    columns: TypeColumn[];
    loading: boolean;
    setGridRef: Dispatch<SetStateAction<((gridApiRef: MutableRefObject<TypeComputedProps | null>) => void) | undefined>>;
    reportPageType: EReportsPageType;
    sortInfo: SortInfoType;
    sortInfoC: TypeSortInfo;
};

const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

export const default_columns_reports: TypeColumn[] = [
    {
        name: 'created',
        header: <FormattedMessage id={'date'} />,
        flex: 1,
        visible: true,
        sortable: true,
        render: ({ data: { ready, errors }, value }) => (
            <DateCell date={value as string} errors={errors as string} ready={ready as boolean} />
        )
    },
    {
        name: 'description',
        header: <FormattedMessage id={'description'} />,
        flex: 0.5,
        visible: false,
        sortable: true,
        render: ({ value }) => String(value)
    },
    {
        name: 'equipments',
        header: <FormattedMessage id={'equipments'} />,
        flex: 0.5,
        visible: false,
        sortable: true,
        render: ({ value }) => (value as number[]).map((item: number, index) => <FormattedMessage id={String(item)} key={index} />)
    },
    {
        name: 'errors',
        header: <FormattedMessage id={'errors'} />,
        flex: 0.5,
        visible: false,
        sortable: true,
        render: ({ value }) => String(value)
    },
    {
        name: 'name',
        header: <FormattedMessage id={'name'} />,
        flex: 0.5,
        visible: true,
        sortable: true,
        render: ({ value }) => <FormattedMessage id={String(value)} />
    },
    {
        name: 'title',
        header: <FormattedMessage id={'type'} />,
        flex: 0.5,
        minWidth: 150,
        visible: true,
        sortable: true
    },
    {
        name: 'requested_by',
        header: <FormattedMessage id={'requestedBy'} />,
        flex: 0.5,
        visible: true,
        sortable: true,
        render: ({ value }) => <FormattedMessage id={String(value)} />
    },
    {
        name: 'period_start',
        header: <FormattedMessage id={'from'} />,
        flex: 0.5,
        visible: true,
        sortable: true,
        render: ({ value }) => <FormattedMessage id={new Date(value as string).toLocaleString(userLocale, dateOptions)} />
    },
    {
        name: 'period_end',
        header: <FormattedMessage id={'to'} />,
        flex: 0.5,
        visible: true,
        sortable: true,
        render: ({ value }) => <FormattedMessage id={new Date(value as string).toLocaleString(userLocale, dateOptions)} />
    },
    {
        name: 'periodic',
        header: <FormattedMessage id={'periodic'} />,
        flex: 0.5,
        visible: false,
        sortable: true,
        render: ({ value }) => <FormattedMessage id={String(value)} />
    },
    {
        name: 'ready',
        header: <FormattedMessage id={'ready'} />,
        flex: 0.5,
        visible: false,
        sortable: true,
        render: ({ value }) => <FormattedMessage id={String(value)} />
    },
    {
        name: 'size',
        header: <FormattedMessage id={'fileSize'} />,
        flex: 0.5,
        visible: true,
        sortable: true,
        render: ({ value }) => <FormattedMessage id={String(`${value as string} kb`)} />
    },
    {
        name: 'actions',
        header: <></>,
        flex: 0.5,
        visible: true,
        sortable: false,
        render: ({ data: { ready, _id, errors } }) => <ActionsCell errors={errors as string} id={_id as string} ready={ready as boolean} />
    }
];

export default function ReportsGrid({
    data,
    pagination = true,
    columns,
    loading,
    setGridRef,
    reportPageType,
    sortInfo,
    sortInfoC
}: ReportsGridProps) {
    const setCustomSelectedReportAtom = (reportPageType: EReportsPageType) => {
        switch (reportPageType) {
            case EReportsPageType.ALL:
                return setSelectedReportAtom;
            case EReportsPageType.CUSTOM:
                return setSelectedReportAtomCustom;
            case EReportsPageType.SYSTEM:
                return setSelectedReportAtomSystem;
        }
    };
    const [_, setSelectedReport] = useAtom(setCustomSelectedReportAtom(reportPageType));

    const location = useLocation();
    const dispatch = useAppDispatch();
    const [gridSkip, setGridSkip] = useState<number | undefined>(undefined);
    const [gridSortInfo, setGridSortInfo] = useState<TypeSingleSortInfo | undefined>(undefined);
    const [gridColumnOrder, setGridColumnOrder] = useState<string[] | undefined>(undefined);
    const [gridLimit, setGridLimit] = useState<number | undefined>(undefined);
    const gridsPaginations = useAppSelector((state) => state.gridsPaginations.gridsPaginations);
    const firstRenderRef = useRef(true);

    useEffect(() => {
        gridsPaginationsUpdateGrid({
            gridName: 'ReportsGrid',
            gridsPaginations,
            columns: columns,
            sortInfo,
            setGridSkip,
            setGridSortInfo,
            setGridColumnOrder,
            setGridLimit,
            location
        });
    }, [gridsPaginations]);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
        } else {
            dispatch(
                addGridsPaginations({
                    url: location.pathname,
                    grid: { skip: gridSkip, sortInfo: gridSortInfo, columnOrder: gridColumnOrder, limit: gridLimit },
                    key: `ReportsGrid`
                })
            );
        }
    }, [gridSkip, gridSortInfo, gridColumnOrder, gridLimit]);

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <ReactDataGrid
                columnOrder={gridColumnOrder}
                columns={columns}
                dataSource={data}
                enableColumnAutosize
                handle={setGridRef as unknown as GridHandleType}
                idProperty="_id"
                limit={gridLimit ? gridLimit : 10}
                loading={loading}
                onColumnOrderChange={(columnOrder) => {
                    setGridColumnOrder(columnOrder);
                }}
                onLimitChange={(limit) => {
                    setGridLimit(limit);
                }}
                onRowClick={(rowProps) => {
                    setSelectedReport({ id: rowProps.data._id, name: rowProps.data.name });
                }}
                onSkipChange={(skip) => {
                    setGridSkip(skip);
                }}
                onSortInfoChange={(column: TypeSortInfo) => {
                    if (!column) {
                        return sortInfo('', 0);
                    }
                    const section = columns.find((section) => {
                        return section.name === ((column as TypeSingleSortInfo).id as string);
                    });
                    if ((section as TypeColumnExtended).columnSortAsc !== null && (section as TypeColumnExtended).columnSortDesc !== null) {
                        sortInfo(
                            (column as TypeSingleSortInfo).id as string,
                            (column as TypeSingleSortInfo).dir as number,
                            (column as TypeSingleSortInfo).type as string,
                            (section as TypeColumnExtended).columnSortAsc,
                            (section as TypeColumnExtended).columnSortDesc
                        );
                    } else {
                        sortInfo(
                            (column as TypeSingleSortInfo).id as string,
                            (column as TypeSingleSortInfo).dir as number,
                            (column as TypeSingleSortInfo).type as string,
                            (section as TypeColumnExtended).columnSortAsc
                        );
                    }
                    setGridSortInfo(column as TypeSingleSortInfo);
                }}
                pagination={pagination}
                resizable={false}
                rowHeight={50}
                rowStyle={defaultRowStyle}
                showCellBorders={'horizontal'}
                showColumnMenuTool={false}
                showEmptyRows={false}
                showZebraRows={false}
                skip={gridSkip}
                sortInfo={gridSortInfo ?? sortInfoC}
                style={{
                    flexGrow: 1,
                    minHeight: '62vh',
                    border: 'none'
                }}
            />
        </Box>
    );
}
