import '@inovua/reactdatagrid-community/index.css';

import { IconToNode } from '_global/Utils/IconToNode';
import { faBrowser, faTimer, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { Grid, Typography } from '@mui/material';
import { AlarmSource } from 'models/events/ProcessedEvent';
import { useIntl } from 'react-intl';

type EventSourceTitleProps = {
    source: number;
};

function SourceTitle(title: string, icon: IconDefinition) {
    const iconInfoStyle = { fontSize: 30, color: '#0d47a1' };

    return (
        <Grid container direction="row" spacing={1}>
            <Grid item>
                <Typography>
                    <IconToNode definition={icon} sx={iconInfoStyle} />
                </Typography>
            </Grid>
            <Grid item>
                <Typography sx={{ mt: 1 }} variant="h4">
                    {title}
                </Typography>
            </Grid>
        </Grid>
    );
}
export default function EventSourceTitle(props: EventSourceTitleProps) {
    const { source } = props;
    const intl = useIntl();

    return source !== AlarmSource.DEEPGRID
        ? SourceTitle(intl.formatMessage({ id: 'real_time' }), faTimer)
        : SourceTitle(intl.formatMessage({ id: 'platform' }), faBrowser);
}
