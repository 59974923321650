import Spacer from '_global/Components/base/Spacer';
import { IconToNode } from '_global/Utils/IconToNode';
import { faPedestal, faTransformerBolt } from '@fortawesome/pro-regular-svg-icons';
import { faChargingStation, faIndustryWindows, faMeterBolt, faUtilityPole, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { Box, Stack, Typography } from '@mui/material';
import { ASSET_TYPE } from 'models/graphql-global-types';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

function IconForAssetType(props: { type: ASSET_TYPE; color: string }) {
    const { type, color } = props;

    let icon: IconDefinition = faIndustryWindows;
    switch (type) {
        case ASSET_TYPE.HV_SUBSTATION:
            icon = faIndustryWindows;
            break;
        case ASSET_TYPE.LV_GROUND_SUBSTATION:
            icon = faTransformerBolt;
            break;
        case ASSET_TYPE.LV_POLE_MOUNTED:
            icon = faUtilityPole;
            break;
        case ASSET_TYPE.DISTRIBUTION_BOX:
            icon = faPedestal;
            break;
        case ASSET_TYPE.EV_CHARGE_STATION:
            icon = faChargingStation;
            break;
        case ASSET_TYPE.METER:
            icon = faMeterBolt;
            break;
    }
    return <IconToNode definition={icon} size="1x" sx={{ paddingLeft: '4px', paddingRight: '4px', color: color }} />;
}

export default function StateCell(props: { type: ASSET_TYPE }) {
    const { type } = props;

    const backgroundColor = useMemo(() => {
        switch (type) {
            case ASSET_TYPE.HV_SUBSTATION:
                return '#9FE2BF';
            case ASSET_TYPE.LV_GROUND_SUBSTATION:
                return '#A7C7E7';
            case ASSET_TYPE.LV_POLE_MOUNTED:
                return '#CCCCFF';
            case ASSET_TYPE.DISTRIBUTION_BOX:
                return '#7DF9FF';
            case ASSET_TYPE.EV_CHARGE_STATION:
                return '#6495ED';
            case ASSET_TYPE.METER:
                return '#7393B3';
        }
    }, [type]);

    const textColor = useMemo(() => {
        switch (type) {
            case ASSET_TYPE.HV_SUBSTATION:
                return '#008080';
            case ASSET_TYPE.LV_GROUND_SUBSTATION:
                return '#4682B4';
            case ASSET_TYPE.LV_POLE_MOUNTED:
                return '#5D3FD3';
            case ASSET_TYPE.DISTRIBUTION_BOX:
                return '#6082B6';
            case ASSET_TYPE.EV_CHARGE_STATION:
                return '#00008B';
            case ASSET_TYPE.METER:
                return '#0047AB';
            default:
                return '#008080';
        }
    }, [type]);

    return (
        <Stack direction="row">
            <Spacer />
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                sx={{ paddingX: '10px', paddingY: '2px', background: backgroundColor, borderRadius: '25px', maxWidth: '130px' }}
            >
                <Box sx={{ paddingX: '4px' }}>
                    <IconForAssetType color={textColor} type={type} />
                </Box>

                <Typography sx={{ color: textColor }} variant="button">
                    <FormattedMessage id={type.toLowerCase()} />
                </Typography>
            </Stack>
            <Spacer />
        </Stack>
    );
}
