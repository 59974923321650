import { EventInstance } from 'queries/EventsQuery';

import { ENTITY_TYPE, EventDataStatus, EventEntityType, EventSeverity, EventStatus, EventType, SOURCE } from '../graphql-global-types';

export type EventData = {
    createdBy: string | null;
    createdDate: Date | null;
    data: string;
    deviceStatus: EventDataStatus | number;
    eventId: number | null;
    iavg: number | null;
    id: number;
    imax: number | null;
    imin: number | null;
    lastModifiedBy: string | null;
    lastModifiedDate: Date;
    notes: string | null;
    timeStamp: Date;
    status: EventStatus | number;
    delta_T: number | null;
    description: string;
};

export type ProcessedEvent = {
    id: number;
    equipmentId: number;
    endDate: Date | null;
    hasWFC: boolean | null;
    eventData?: EventData[] | null;
    createdBy: string | null;
    createdDate: Date | null;
    lastModifiedBy: string | null;
    lastModifiedDate: Date | null;
    deviceId: number | null;
    feederId: number | null;
    line: string | null;
    portNumber: number | null;
    entityType: EventEntityType | number;
    type: EventType | number;
    status: EventStatus | number;
    severity: EventSeverity | number;
    startDate: Date;
    notes: string | null;
    name: string;
    feederName: string | null;
    registrationNumber: string | null;
    deviceSerial: string | null;
    source: SOURCE | number;
    sourceEntityType: ENTITY_TYPE | number;
};

export function eventToRow(event: EventInstance): ProcessedEvent {
    return {
        id: Number(event.id),
        equipmentId: Number(event.equipmentId),
        endDate: event.endDate ? new Date(String(event.endDate)) : null,
        createdBy: event.createdBy,
        createdDate: event.createdDate ? new Date(String(event.createdDate)) : null,
        lastModifiedBy: event.lastModifiedBy,
        lastModifiedDate: event.lastModifiedDate ? new Date(String(event.lastModifiedDate)) : null,
        deviceId: Number(event.deviceId),
        feederId: Number(event.feederId),
        line: event.line,
        portNumber: Number(event.portNumber),
        entityType: event.entityType,
        type: event.type,
        status: event.status,
        severity: event.severity,
        startDate: new Date(String(event.startDate)),
        notes: event.notes,
        name: event.name,
        feederName: event.feederName,
        registrationNumber: event.registrationNumber,
        deviceSerial: event.deviceSerial,
        source: event.source,
        sourceEntityType: event.sourceEntityType,
        hasWFC: event.hasWFC
    };
}
export enum AlarmSeverity {
    INFO = 1,
    WARNING = 5,
    CRITICAL = 10
}

export enum AlarmStatus {
    EMPTY,
    UNRESOLVED,
    RESOLVING,
    RESOLVED,
    ACKNOWLEDGE
}

export enum AlarmSource {
    DEVICE = 10,
    DEEPGRID = 20
}
export enum AlarmEntityType {
    FEEDER = 10,
    ASSET = 20,
    ASSET_BUSBAR = 21,
    DEVICE = 30
}

export enum DeviceStatus {
    RESOLVED = 0,
    UNRESOLVED = 1
}

export enum AlarmType {
    EMPTY = 0,
    BUSBAR_LINE_NULL_VOLTAGE = 1,
    BUSBAR_UNDER_VOLTAGE = 2,
    BUSBAR_OVER_VOLTAGE = 3,
    FEEDER_LINE_NULL_CURRENT = 4,
    FEEDER_LINE_OVERCURRENT = 5,
    FEEDER_LINE_FUSE_BLOWN = 6,
    HV_LINE_DOWN = 7,
    BROKEN_NEUTRAL = 8,
    WFC = 9,
    HV_PHASE_LOSS = 10,
    NEW_HV_LINE_DOWN = 11,
    UINT = 100,
    DEVICE_POWER_FAIL = 101,
    DEVICE_WITHOUT_COMMUNICATIONS = 102,
    DIP = 103,
    SWELL = 104,
    RVC = 105,
    SDS_VOLTAGE_VIOLATION = 200,
    SDS_VOLTAGE_FREQ_VIOLATION = 201,
    SDS_VOLTAGE_UNBALANCE = 202,
    FEEDER_LINE_CURRENT_LEVEL = 203,
    FEEDER_LINE_CURRENT_LEVEL_OPTIMIZATION = 204,
    FEEDER_NEUTRAL_CURRENT_LEVEL = 205,
    PLATFORM_FEEDER_LINE_NULL_CURRENT = 206,
    SDS_MV_DEAD_SECTION = 207,
    SDS_MV_DEAD_SECTION_VECT = 208,
    SDS_VOLTAGE_PHASE_UNBALANCE = 209,
    SDS_PROTECTIVE_EARTH_LEVEL = 300,
    SDS_EARTHING_VOLTAGE_LEVEL = 301,
    DEVICE_REPORT = 400
}
