import { useKeycloak } from '@react-keycloak/web';
import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

export default function useIdle({ idleTime = 1 }: { idleTime: number }) {
    const [isIdle, setIsIdle] = useState(false);
    const { keycloak } = useKeycloak();

    const handleOnIdle = () => {
        setIsIdle(true);
    };

    const handleOnAction = () => {
        setIsIdle(false);
    };

    const idleTimer = useIdleTimer({
        timeout: 1000 * 60 * idleTime,
        onIdle: handleOnIdle,
        onAction: () => void handleOnAction(),
        debounce: 500
    });

    return {
        idleTimer,
        isIdle
    };
}
