import { AlarmStandardsInstance } from 'queries/Standards';

export type AlarmStandardList = {
    name: string;
    id: number;
    editable: boolean;
    createdBy: string | null;
    createdDate: Date;
    lastModifiedBy: string | null;
    lastModifiedDate: Date;
};

export interface AlarmVariable {
    id: number;
    alarmTypeId: number;
    name: string;
    deviceName: string;
    type: Type;
    defaultValue: string;
    jsonConstrains: null | string;
    groupName: number;
    region: number;
}

export enum Type {
    INT = 'int',
    SEVERIRY = 'SEVERITY',
    BIT = 'BIT',
    DOUBLE = 'double',
    SOURCE = 'SOURCE',
    WFC_MODE = 'WFC_MODE',
    ENTITY_TYPE = 'ENTITY_TYPE',
    REGION = 'REGION',
    ALARM_TYPE = 'ALARM_TYPE',
    ALARM_GROUP_NAME = 'ALARM_GROUP_NAME'
}

export interface FormModel {
    id: number;
    standardDefinitionId: number;
    defaultStatus: boolean;
    entityType: number;
    name: string;
    type: number;
    source: number;
    alarmVariables: AlarmVariable[];
}

export type AlarmStandard = {
    id: number;
    name: string;
    description: string;
    editable: boolean;
    alarms: FormModel[];
};

export function alarmStandardToRow(alarmStandard: AlarmStandardsInstance): AlarmStandardList {
    return {
        id: Number(alarmStandard.id),
        name: alarmStandard.name,
        editable: Boolean(alarmStandard.editable),
        createdBy: alarmStandard.createdBy,
        createdDate: new Date(alarmStandard.createdDate as string),
        lastModifiedBy: alarmStandard.lastModifiedBy,
        lastModifiedDate: new Date(alarmStandard.lastModifiedDate as string)
    };
}

export enum TabValue {
    ProfileSettings = 0,
    BusbarAlarms = 1,
    FeederAlarms = 2,
    AssetAlarms = 3,
    DeviceAlarms = 4
}

export interface AlarmStandardToSend {
    id: number;
    name: string;
    description: string;
    alarmStandardVariableDefinitions: VariableToSend[];
    alarmStandardDefinitions: DefinitionToSend[];
}

export interface VariableToSend {
    alarmVariableId: number;
    value: string | number;
}
export interface DefinitionToSend {
    id: number;
    enable: boolean;
}
