import { AccessGroupInstance } from 'queries/AccessGroups';

export interface ItemAccessGroup {
    id: number;
    name: string;
}

export interface AssetAccessGroup {
    id: number;
    name: string;
    registryNumber: string;
    externalAssetId: number;
}

export interface AccessGroup {
    id: number;
    name: string;
    description: string | null;
    isEnabled: boolean;
    isProtected: boolean;
    isDefault: boolean;
    createdBy?: string | null;
    createdDate?: Date;
    lastModifiedBy?: string | null;
    lastModifiedDate?: Date;
    workingOperationalAreas: ItemAccessGroup[];
    operationalAreas: ItemAccessGroup[];
    companies: ItemAccessGroup[];
    regions: ItemAccessGroup[];
    assets: AssetAccessGroup[];
    users: ItemAccessGroup[];
}

export interface AccessGroupList {
    id: number;
    name: string;
    description: string | null;
    isEnabled: boolean;
    isProtected: boolean;
    isDefault: boolean;
    createdBy: string | null;
    createdDate: Date | null;
    lastModifiedBy: string | null;
    lastModifiedDate: Date | null;
}
export function accessGroupToRow(accessGroup: AccessGroupInstance): AccessGroupList {
    return {
        id: Number(accessGroup?.id),
        name: String(accessGroup?.name),
        description: accessGroup?.description,
        isEnabled: Boolean(accessGroup?.isEnabled),
        isProtected: Boolean(accessGroup?.isProtected),
        isDefault: Boolean(accessGroup?.isDefault),
        createdBy: accessGroup?.createdBy,
        createdDate: new Date(String(accessGroup.createdDate)),
        lastModifiedBy: accessGroup?.lastModifiedBy,
        lastModifiedDate: new Date(String(accessGroup.lastModifiedDate))
    };
}

export enum TabValue {
    INFO = 0,
    ASSETS = 1,
    USERS = 2
}

export enum AccessGroupSections {
    COMPANIES = 'companies',
    ASSETS = 'assets',
    REGIONS = 'regions',
    OPAREAS = 'opareas',
    USERS = 'users'
}
