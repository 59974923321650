class CsvExporter {
    static exportBlobToCSV(blob: Blob) {
        this.saveFile(blob, 'export.csv');
    }

    private static saveFile(blob: Blob | MediaSource, filename: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);

        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;

        a.click();

        // Old Edge hack
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }, 0);
    }
}

export default CsvExporter;
