import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { HISTORICAL_DATA_AGGREGATION_UNIT } from 'models/asset/AssetHistoricalData';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export default function AggregationUnitFilter(props: {
    onChange: (value: HISTORICAL_DATA_AGGREGATION_UNIT) => void;
    selectedAggregationUnit: HISTORICAL_DATA_AGGREGATION_UNIT;
}) {
    const { onChange, selectedAggregationUnit } = props;

    const onTypeChange = useCallback(
        (event: SelectChangeEvent<HISTORICAL_DATA_AGGREGATION_UNIT>) => {
            onChange(event.target.value as HISTORICAL_DATA_AGGREGATION_UNIT);
        },
        [selectedAggregationUnit]
    );

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="aggregation-unit">
                <FormattedMessage id={'aggregation-unit'} />
            </InputLabel>
            <Select
                id="aggregation-unit"
                value={selectedAggregationUnit}
                onChange={(e) => onTypeChange(e)}
                label={<FormattedMessage id={'aggregation-unit'} />}
            >
                {Object.values(HISTORICAL_DATA_AGGREGATION_UNIT).map((value, index) => {
                    return (
                        <MenuItem key={index} value={value}>
                            <FormattedMessage id={value} />
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
