import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { HISTORICAL_DATA_MINUTES } from 'models/asset/AssetHistoricalData';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export default function MinutesFilter(props: {
    onChange: (value: HISTORICAL_DATA_MINUTES) => void;
    selectedMinutes: HISTORICAL_DATA_MINUTES;
}) {
    const { onChange, selectedMinutes } = props;

    const onMinutesChange = useCallback(
        (event: SelectChangeEvent<HISTORICAL_DATA_MINUTES>) => {
            onChange(event.target.value as HISTORICAL_DATA_MINUTES);
        },
        [selectedMinutes]
    );

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="minutes">
                <FormattedMessage id={'minutes'} />
            </InputLabel>
            <Select id="minutes" value={selectedMinutes} onChange={(e) => onMinutesChange(e)} label={<FormattedMessage id={'minutes'} />}>
                {Object.values(HISTORICAL_DATA_MINUTES).map((min, index) => {
                    return (
                        <MenuItem key={index} value={min}>
                            <FormattedMessage id={min} />
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
