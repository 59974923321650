import { IAssetReportDetails, IItemReportDetails } from 'models/report/ReportResponse';

export function numberOfCheckedAccessGroups(accessGroups: IItemReportDetails[], checkedAccessGroups: IItemReportDetails[]): number {
    const aux = checkedAccessGroups.filter((value) => accessGroups.indexOf(value) !== -1);
    return aux.length;
}

export function numberOfCheckedCompanies(companies: IItemReportDetails[], checkedCompanies: IItemReportDetails[]): number {
    const aux = checkedCompanies.filter((value) => companies.indexOf(value) !== -1);
    return aux.length;
}

export function numberOfCheckedRegions(regions: IItemReportDetails[], checkedRegions: IItemReportDetails[]): number {
    const aux = checkedRegions.filter((value) => regions.indexOf(value) !== -1);
    return aux.length;
}

export function numberOfCheckedOpAreas(operationalAreas: IItemReportDetails[], checkedOpAreas: IItemReportDetails[]): number {
    const aux = checkedOpAreas.filter((value) => operationalAreas.indexOf(value) !== -1);
    return aux.length;
}
export function numberOfCheckedAssets(assets: IAssetReportDetails[], checkedAssets: IAssetReportDetails[]): number {
    const aux = checkedAssets.filter((value) => assets.indexOf(value) !== -1);
    return aux.length;
}

export function numberOfCheckedUsers(users: IItemReportDetails[], checkedUsers: IItemReportDetails[]): number {
    const aux = checkedUsers.filter((value) => users.indexOf(value) !== -1);
    return aux.length;
}
