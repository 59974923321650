import { availableLanguages, userLocale } from '_global/Utils/GetCurrentLocale';

export interface LanguageAndRegion {
    lang: string;
    lang_region: string;
}

export function loadData(setLocale: React.Dispatch<React.SetStateAction<string>>) {
    const langExist = availableLanguages.indexOf(userLocale.toLowerCase());
    if (langExist === -1) {
        const split = userLocale.split('-');
        const exist = availableLanguages.indexOf(split[0].toLowerCase());
        if (exist !== -1) {
            setLocale(availableLanguages[exist]);
        }
    } else {
        setLocale(availableLanguages[langExist]);
    }
}

export function getDefaultLanguage(): LanguageAndRegion {
    return { lang: 'en', lang_region: 'en-GB' };
}
