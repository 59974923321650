import { useKeycloak } from '@react-keycloak/web';
import { useCallback, useEffect } from 'react';

const KeycloakLogin = () => {
    const { keycloak, initialized } = useKeycloak();

    const login = useCallback(() => {
        keycloak.login().catch(console.error);
    }, [keycloak]);

    useEffect(() => {
        if (!keycloak.authenticated && initialized) {
            login();
        }
    }, [initialized, keycloak.authenticated]);

    return <></>;
};

export default KeycloakLogin;
