import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { HISTORICAL_DATA_LINE } from 'models/asset/AssetHistoricalData';
import { MasterBusLine } from 'models/asset/AssetModel';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export default function LineFilter(props: {
    onChange: (value: HISTORICAL_DATA_LINE) => void;
    masterBusLines: MasterBusLine[];
    selectedLine: HISTORICAL_DATA_LINE;
}) {
    const { onChange, masterBusLines, selectedLine } = props;

    const onMBLChange = useCallback(
        (event: SelectChangeEvent<HISTORICAL_DATA_LINE>) => {
            onChange(event.target.value as HISTORICAL_DATA_LINE);
        },
        [selectedLine]
    );

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="line">
                <FormattedMessage id={'line'} />
            </InputLabel>
            <Select id="line" value={selectedLine} onChange={(e) => onMBLChange(e)} label={<FormattedMessage id={'line'} />}>
                {masterBusLines.map((mbl, index) => {
                    return (
                        <MenuItem key={index} value={mbl.inputType}>
                            <FormattedMessage id={mbl.inputType} />
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
