import SnackbarAlert from '_global/Components/base/extended/SnackbarAlert';
import AlertModal from '_global/Components/base/modals/AlertModal';
import { userAccessGroupAtom } from '_global/Utils/hooks/jotai';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useConfiguration, useRemoteConfiguration } from 'logic/configuration/Hooks';
import LocalStorageManager from 'logic/local/LocalStorageManager';
import MessageType from 'models/enums/MessageTypes';
import { ItemAccessGroup } from 'models/layout/AccessGroup';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const AccessGroupSection = () => {
    const theme = useTheme();
    const localConfig = useConfiguration();
    const [userConfig, setUserConfig] = useRemoteConfiguration();

    const [stateModal, setStateModal] = useState({
        openModal: false,
        responseContent: '',
        responseTitle: '',
        type: MessageType.INFO
    });
    const { responseContent, responseTitle, openModal, type } = stateModal;
    const [stateToast, setStateToast] = useState({ openToast: false, toastMessage: '' });
    const { openToast, toastMessage } = stateToast;

    const [accessGroupSelected, setAccessGroupSelected] = useState<ItemAccessGroup>();
    const intl = useIntl();
    const [, setUserAccessGroup] = useAtom(userAccessGroupAtom);
    const navigate = useNavigate();

    useEffect(() => {
        if (localConfig && userConfig) {
            setUserAccessGroup(
                userConfig?.userSettings?.selectedAccessGroup
                    ? userConfig.userSettings.selectedAccessGroup
                    : userConfig?.accessGroups
                    ? userConfig?.accessGroups[0]
                    : { id: 0, name: '' }
            );
        }
    }, [userConfig?.userSettings, localConfig]);

    const setUserSettings = useCallback(
        (ag: ItemAccessGroup) => {
            if (localConfig && userConfig) {
                setUserConfig({
                    ...userConfig,
                    userSettings: {
                        ...userConfig?.userSettings,
                        selectedAccessGroup: ag ? ag : null
                    }
                });
            }
        },
        [userConfig, localConfig]
    );

    const handleCloseToast = () => {
        setStateToast({ ...stateToast, openToast: false });
    };

    const handleYesModal = useCallback(() => {
        setStateModal({ ...stateModal, openModal: false });
        if (accessGroupSelected) {
            void setUserSettings(accessGroupSelected);
            setUserAccessGroup(accessGroupSelected);
        }

        LocalStorageManager.clearAllStoredInfo();
        navigate('/dashboard/overview');
        setStateToast({ ...stateToast, toastMessage: intl.formatMessage({ id: 'user_session_restarted' }), openToast: true });
    }, [accessGroupSelected, localConfig, userConfig]);

    const handleNoModal = () => {
        setStateModal({ ...stateModal, openModal: false });
    };

    const handleChange = () => {
        setStateModal({
            openModal: true,
            responseTitle: intl.formatMessage({ id: 'response_title_change_access group' }),
            responseContent: intl.formatMessage({ id: 'response_body_user_restarted' }),
            type: MessageType.INFO
        });
    };

    return (
        <Box>
            <SnackbarAlert message={toastMessage} onClose={handleCloseToast} open={openToast} type={MessageType.SUCCESS} />
            <AlertModal
                isOpen={openModal}
                message={responseContent}
                onNo={handleNoModal}
                onYes={handleYesModal}
                title={responseTitle}
                type={type}
            />

            <Autocomplete
                disableClearable
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                    setAccessGroupSelected(newValue);
                    handleChange();
                }}
                options={userConfig?.accessGroups ?? []}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{
                            style: {
                                color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                                fontWeight: 800
                            }
                        }}
                        label={intl.formatMessage({ id: 'access_group' })}
                        placeholder={intl.formatMessage({ id: 'search_to_add' })}
                        sx={{
                            '& label.Mui-focused': {
                                color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                                },
                                '&:hover fieldset': {
                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                                },
                                '.MuiInputBase-input': {
                                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                                }
                            }
                        }}
                    />
                )}
                size="small"
                sx={{
                    width: 200,
                    '.MuiAutocomplete-inputRoot': {
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                    },
                    '.MuiSvgIcon-root': {
                        color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                    }
                }}
                value={
                    userConfig?.userSettings?.selectedAccessGroup
                        ? userConfig.userSettings.selectedAccessGroup
                        : userConfig?.accessGroups
                        ? userConfig?.accessGroups[0]
                        : { id: 0, name: '' }
                }
            />
        </Box>
    );
};

export default AccessGroupSection;
