import { COLORS } from '_global/Utils/Colors';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Grid, Typography } from '@mui/material';
import { EventSeverity } from 'models/graphql-global-types';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export default function SeverityCell(props: { severity: EventSeverity }) {
    const { severity } = props;

    const severityStyle = useMemo(() => {
        switch (severity) {
            case EventSeverity.CRITICAL:
                return { txtColor: '#d32f2f', icon: <ErrorOutlineIcon sx={{ color: '#d32f2f' }} /> };
            case EventSeverity.WARNING:
                return {
                    txtColor: COLORS.ENEIDA_ALTERNATIVE_YELLOW,
                    icon: <WarningAmberIcon sx={{ color: COLORS.ENEIDA_ALTERNATIVE_YELLOW }} />
                };
            case EventSeverity.INFO:
                return { txtColor: COLORS.ENEIDA_SIMPLE_BLUE, icon: <InfoOutlinedIcon sx={{ color: COLORS.ENEIDA_SIMPLE_BLUE }} /> };
            case EventSeverity.EMPTY:
                return { txtColor: 'Transparent', icon: undefined };
            default:
                return { txtColor: 'Transparent', icon: undefined };
        }
    }, [severity]);

    return (
        <Grid container direction="row" spacing={1}>
            <Grid item>{severity !== EventSeverity.EMPTY && severityStyle.icon}</Grid>
            <Grid item>
                <Typography color={severityStyle.txtColor} sx={{ fontWeight: 'bold', mt: 0.5 }}>
                    <FormattedMessage id={severity.toLowerCase()} />
                </Typography>
            </Grid>
        </Grid>
    );
}
