import ButtonComponent from '_global/Components/base/button/Button';
import MainCard from '_global/Components/base/cards/MainCard';
import { getData } from '_global/Services/api/networkRequester';
import { RootState } from '_global/Services/store';
import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { CATEGORY } from 'models/asset/AssetHistoricalData';
import { AssetDevice, AssetModel } from 'models/asset/AssetModel';
import LASTCONTACT_TYPE from 'models/enums/LastContactType';
import { Device } from 'models/layout/Device';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import HistoricalDataFilters from '../historicaldata/HistoricalDataFilters';
import HistoricalDataGraphs from '../historicaldata/HistoricalDataGraphs';

function Network(props: { id: number }) {
    const { id } = props;
    const features = useSelector((state: RootState) => state.features);
    const [assetModel, setAssetModel] = useState<AssetModel>();
    const [selectedDevice, setSelectedDevice] = useState<AssetDevice>();
    const [device, setDevice] = useState<Device>();
    const [grafanaFilters, setGrafanaFilters] = useState<string>('');
    const [shouldLoadGraphs, setShowLoadGraphs] = useState<boolean>(false);
    const [hasDefaultFilters, setHasDefaultFilters] = useState<boolean>(true);
    const intl = useIntl();

    useEffect(() => {
        const url = '/equipments/api/equipment/Asset';

        const endpoint = `${features.configuration?.BaseUrl ?? ''}${url}/${id ?? ''}`;

        getData<AssetModel>(endpoint)
            .then((data) => {
                setAssetModel(data);
            })
            .catch((e) => {
                setAssetModel(undefined);
                console.error(e);
            });
    }, [props]);

    useEffect(() => {
        if (selectedDevice) {
            const endpoint = `${features.configuration?.BaseUrl ?? ''}${'/devicesinfo/api/Device'}`;

            if (endpoint === undefined) return;

            getData<Device>(`${endpoint}/${String(selectedDevice.externalId)}`)
                .then((data) => {
                    setDevice(data);
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    }, [selectedDevice]);

    const onDeviceChange = useCallback((value: AssetDevice) => {
        setSelectedDevice(value);
        setGrafanaFilters('');
    }, []);

    const onClearFilters = useCallback(() => {
        setShowLoadGraphs(false);
        setHasDefaultFilters(true);
        setGrafanaFilters('');
    }, []);

    const onFiltersChange = useCallback(() => {
        setHasDefaultFilters(false);
    }, []);

    const onConfirmFilters = useCallback(() => {
        setGrafanaFilters('');
        setShowLoadGraphs(true);
    }, []);

    const onSaveGrafanaFilter = useCallback((filters: string) => {
        setGrafanaFilters(filters);
        setShowLoadGraphs(false);
    }, []);

    return (
        <div>
            <Grid columnSpacing={2} container sx={{ p: 2 }}>
                <Grid item sm={4} xs={12}>
                    <Autocomplete
                        disableClearable
                        disabled={assetModel?.devices && assetModel?.devices.length <= 0}
                        getOptionLabel={(option) => {
                            return option?.serialNumber ? option?.serialNumber : '';
                        }}
                        key={
                            assetModel?.devices?.find((device) => `${device?.serialNumber}` === selectedDevice?.serialNumber)?.serialNumber
                        }
                        onChange={(event, newValue) => {
                            onDeviceChange(newValue);
                        }}
                        options={assetModel?.devices ?? []}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={
                                    <FormattedMessage
                                        id={assetModel?.devices && assetModel?.devices.length <= 0 ? 'no_devices' : 'devices'}
                                    />
                                }
                                required={true}
                            />
                        )}
                        sx={{ maxHeight: '51px' }}
                        value={assetModel?.devices?.find((device) => `${device?.serialNumber}` === selectedDevice?.serialNumber)}
                    />
                </Grid>
            </Grid>
            {device && (
                <>
                    <MainCard className="main-card" content={false} sx={{ p: 2, m: 2 }} title={<FormattedMessage id={'last_state'} />}>
                        <Grid container spacing={2} sx={{ p: 4 }}>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    label={<FormattedMessage id={'remote_ip'} />}
                                    value={device?.lastContactByType?.remoteIp ?? ''}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    label={<FormattedMessage id={'last_contact'} />}
                                    variant="outlined"
                                    value={new Date(device?.lastContactByType?.lastContact ?? '').toString() ?? ''}
                                    // TODO in the future we must be using localstring dinamicly
                                    // value={new Date(device?.lastContactByType?.lastContact ?? '').toLocaleString('pt-pt').toString() ?? ''}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    label={<FormattedMessage id={'last_contact_by_type'} />}
                                    value={Object.values(LASTCONTACT_TYPE)
                                        .filter((value) => value === device?.lastContactByType?.lastContactType)
                                        .map((el) => {
                                            switch (el) {
                                                case 0:
                                                case 1:
                                                case 2:
                                                case 3:
                                                    return intl.formatMessage({ id: 'field_configuration' });

                                                case 4:
                                                case 5:
                                                case 7:
                                                    return intl.formatMessage({ id: 'alarm_configuration' });
                                                case 6:
                                                    return intl.formatMessage({ id: 'raw_data' });
                                                default:
                                                    return '';
                                            }
                                        })}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </MainCard>
                    {assetModel && (
                        <MainCard
                            className="main-card"
                            content={false}
                            sx={{ p: 2, m: 2 }}
                            title={<FormattedMessage id={'signal_quality'} />}
                        >
                            <Grid container spacing={2} sx={{ p: 4 }}>
                                <HistoricalDataFilters
                                    asset={assetModel}
                                    category={CATEGORY.NETWORK}
                                    clearFilter={hasDefaultFilters}
                                    onConfirmPressed={shouldLoadGraphs}
                                    onFiltersChange={onFiltersChange}
                                    onSaveGrafanaFilter={onSaveGrafanaFilter}
                                />
                            </Grid>
                            {grafanaFilters !== '' ? (
                                <HistoricalDataGraphs
                                    assetId={id}
                                    category={CATEGORY.NETWORK}
                                    deviceId={device.id}
                                    filter={grafanaFilters}
                                />
                            ) : (
                                <></>
                            )}
                        </MainCard>
                    )}
                    <Grid container>
                        <Grid item xs={8}></Grid>
                        <Grid item sx={{ padding: '8px' }} xs>
                            <ButtonComponent
                                color="inherit"
                                disabled={hasDefaultFilters}
                                icon={<ClearAllIcon />}
                                onClick={() => {
                                    onClearFilters();
                                }}
                                title="clear"
                            />
                        </Grid>

                        <Grid item sx={{ padding: '8px' }} xs>
                            <ButtonComponent
                                disabled={shouldLoadGraphs}
                                icon={<CheckIcon />}
                                onClick={() => onConfirmFilters()}
                                title="confirm"
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
}

export default Network;
