import '@inovua/reactdatagrid-community/index.css';

import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { EventData } from 'models/events/ProcessedEvent';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { EventHistoryTableProps } from './EventHistoryTable';
import EventHistoryTableRow from './EventHistoryTableRow';

export default function EventHistoryTableBody(props: EventHistoryTableProps) {
    const { data } = props;

    const dataSorted = useMemo(() => {
        return data.sort((a, b) => b.id - a.id);
    }, [data]);

    return (
        <TableBody>
            {dataSorted.length === 0 ? (
                <TableRow>
                    <TableCell>
                        <Typography sx={{ mt: 3 }}>
                            <FormattedMessage id={'no_records_available'} />
                        </Typography>
                    </TableCell>
                </TableRow>
            ) : (
                dataSorted.map((eventData: EventData, i: number) => {
                    return <EventHistoryTableRow key={i} data={eventData} />;
                })
            )}
        </TableBody>
    );
}
