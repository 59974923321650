import '@inovua/reactdatagrid-community/index.css';

import { TableCell, TableRow, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type MeasurementsTableRowProps = {
    column: string;
    value?: string | number | null;
};

export default function MeasurementsTableRow(props: MeasurementsTableRowProps) {
    const { value, column } = props;
    let valueFormatted = value?.toString() ?? '';

    switch (column) {
        case 'delta_T':
            valueFormatted = `${valueFormatted} ms`;
            break;
        case 'imax':
            valueFormatted = `${valueFormatted} A`;
            break;
        case 'imin':
            valueFormatted = `${valueFormatted} A`;
            break;
        case 'iavg':
            valueFormatted = `${valueFormatted} A`;
            break;
        default:
            valueFormatted = `${valueFormatted}`;
            break;
    }

    if (value) {
        return (
            <TableRow hover>
                <TableCell sx={{ borderBottom: 'none' }} variant="head">
                    <Typography fontSize={12} sx={{ color: 'gray' }}>
                        <FormattedMessage id={column} />
                    </Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>
                    <Typography color={'black'}>{valueFormatted}</Typography>
                </TableCell>
            </TableRow>
        );
    }
    return <></>;
}
