import { gql } from '@apollo/client';

import { Events_events_nodes } from './Types/Events';
import { Events_By_Equipment_Id_events_nodes } from './Types/Events_By_Equipment_Id';

export type EventInstance = Events_events_nodes;
export type EventDataInstance = Events_By_Equipment_Id_events_nodes;

export const EVENTS = gql`
    query Events($cursor: String, $first: Int, $filter: EventFilterInput, $order: [EventSortInput!]) {
        events(where: $filter, first: $first, after: $cursor, order: $order) {
            nodes {
                id
                equipmentId
                endDate
                hasWFC
                createdBy
                createdDate
                lastModifiedBy
                lastModifiedDate
                deviceId
                feederId
                line
                portNumber
                entityType
                status
                severity
                startDate
                notes
                feederName
                source
                sourceEntityType
                registrationNumber
                name
                deviceSerial
                type
            }
            totalCount
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`;

export const EVENTS_BY_EQUIPMENT_ID = gql`
    query Events_By_Equipment_Id($cursor: String, $first: Int, $equipmentId: Long) {
        events(
            first: $first
            after: $cursor
            where: { equipmentId: { eq: $equipmentId }, status: { eq: UNRESOLVED } }
            order: { startDate: DESC }
        ) {
            nodes {
                id
                equipmentId
                endDate
                createdBy
                createdDate
                lastModifiedBy
                lastModifiedDate
                deviceId
                feederId
                line
                portNumber
                entityType
                status
                severity
                startDate
                notes
                feederName
                source
                sourceEntityType
                registrationNumber
                name
                deviceSerial
                type
            }
            totalCount
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`;
