import { TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type GridState = { skip?: number; sortInfo?: TypeSingleSortInfo; columnOrder?: string[]; limit?: number };

export type Pagination = { key: string; url: string; grid: GridState };

export interface GridsPaginationsState {
    gridsPaginations: Pagination[];
}

const initialState: GridsPaginationsState = {
    gridsPaginations: []
};

export const tabsSlice = createSlice({
    name: 'gridsPaginations',
    initialState,
    reducers: {
        setGridsPaginations: (state, action: PayloadAction<Pagination[]>) => {
            state.gridsPaginations = action.payload;
        },
        addGridsPaginations: (state, action: PayloadAction<Pagination>) => {
            const newSessionGridsPaginations: Pagination[] = [...state.gridsPaginations];
            if (
                newSessionGridsPaginations.filter(
                    (gridPagination: Pagination) =>
                        gridPagination?.url === action.payload.url && gridPagination?.key === action.payload?.key
                ).length > 0
            ) {
                newSessionGridsPaginations.forEach((gridPagination: Pagination) => {
                    if (gridPagination?.url === action.payload?.url && gridPagination?.key === action.payload?.key) {
                        gridPagination.grid = action.payload.grid;
                    }
                });

                state.gridsPaginations = newSessionGridsPaginations;
            } else {
                state.gridsPaginations = [...state.gridsPaginations, action.payload];
            }
        },
        removeGridPagination: (state, action: PayloadAction<{ key?: string; url: string }>) => {
            const newSessionGridsPaginations: Pagination[] = [...state.gridsPaginations];
            if (action.payload?.key) {
                newSessionGridsPaginations.forEach((gridPagination: Pagination) => {
                    if (gridPagination?.url === action.payload?.url && gridPagination?.key === action.payload?.key) {
                        gridPagination.grid = {};
                    }
                });
                state.gridsPaginations = newSessionGridsPaginations;
            } else {
                newSessionGridsPaginations.forEach((gridPagination: Pagination) => {
                    if (gridPagination?.url === action.payload?.url) {
                        gridPagination.grid = {};
                    }
                });
                state.gridsPaginations = newSessionGridsPaginations;
            }
        },
        removeAllGridsPagination: (state) => {
            state.gridsPaginations = [];
        }
    }
});

export const { setGridsPaginations, addGridsPaginations, removeGridPagination, removeAllGridsPagination } = tabsSlice.actions;

export default tabsSlice.reducer;
