import { flagShouldUpdateAtom, lastUserSettingsAtom, userAccessGroupAtom } from '_global/Utils/hooks/jotai';
import { useKeycloak } from '@react-keycloak/web';
import { useAtom } from 'jotai';
import _ from 'lodash';
import { ItemAccessGroup } from 'models/layout/AccessGroup';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { RemoteUserSettings } from '../../models/settings/RemoteUserSettings';
import { AccessGroupsGridsResponse, Configuration, loadDefaultConfig } from './Configuration';
import SettingsFetcher from './SettingsFetcher';

let currentConfiguration: Configuration | undefined = undefined;

export function useConfiguration() {
    const [configuration, setConfiguration] = useState<Configuration | undefined>(currentConfiguration);

    useEffect(() => {
        if (configuration === undefined) {
            loadDefaultConfig()
                .then((config) => {
                    currentConfiguration = config;
                    setConfiguration(config);
                })
                .catch(console.error);
        }
    }, []);

    return configuration;
}

export function useRemoteConfiguration(): [RemoteUserSettings | undefined, Dispatch<SetStateAction<RemoteUserSettings | undefined>>] {
    const [lastUserSettings, setLastUserSettings] = useAtom(lastUserSettingsAtom);
    const [shouldUpdate, setShouldUpdate] = useAtom(flagShouldUpdateAtom);

    const localConfig = useConfiguration();

    const keycloak = useKeycloak();
    const token = useMemo(() => keycloak.keycloak.token ?? '', [keycloak, keycloak.keycloak, keycloak.keycloak.token]);

    const fetchGlobal = useCallback(async () => {
        if (localConfig !== undefined) {
            return await SettingsFetcher.getUserSettings(token, localConfig);
        }
    }, [token, localConfig]);

    const submitGlobal = useCallback(async () => {
        if (lastUserSettings !== undefined && localConfig !== undefined) {
            await SettingsFetcher.postUserSettings({ ...lastUserSettings, idToken: token }, localConfig);
        }
    }, [token, lastUserSettings, localConfig]);

    useEffect(() => {
        if (token === '') {
            console.error('JWT token is invalid, cannot fetch remote configuration');
        }
    }, [token]);

    useEffect(() => {
        if (token !== '' && localConfig !== undefined && lastUserSettings === undefined) {
            fetchGlobal()
                .then((result) => {
                    setLastUserSettings(result);
                    setShouldUpdate(JSON.parse(JSON.stringify(result)) as RemoteUserSettings);
                })
                .catch(console.error);
        }
    }, [token, localConfig, lastUserSettings]);

    useEffect(() => {
        if (
            lastUserSettings !== undefined &&
            shouldUpdate !== undefined &&
            !_.isEqual(lastUserSettings.userSettings, shouldUpdate?.userSettings)
        ) {
            submitGlobal()
                .then(() => {
                    setShouldUpdate(JSON.parse(JSON.stringify(lastUserSettings)) as RemoteUserSettings);
                })
                .catch(console.error);
        }
    }, [lastUserSettings, shouldUpdate]);

    return [lastUserSettings, setLastUserSettings];
}

export function useRemoteAccessGroupConfiguration() {
    const defaultResponse = {
        assets: [],
        workingOPArea: [],
        workingRegions: [],
        devices: []
    };
    const localConfig = useConfiguration();
    const [userConfig] = useRemoteConfiguration();
    const [userAccessGroupResponse, setUserAccessGroupResponse] = useState<AccessGroupsGridsResponse>(defaultResponse);
    const [userAccessGroup] = useAtom(userAccessGroupAtom);
    const getAssets = useCallback(
        async (accessGroup: ItemAccessGroup) => {
            if (localConfig && userConfig) {
                const response = await SettingsFetcher.GetUserAccessGroupGrids({
                    configuration: localConfig,
                    accessGroupIds: [accessGroup.id]
                });
                setUserAccessGroupResponse(response ?? defaultResponse);
            }
        },
        [userConfig, localConfig]
    );
    useEffect(() => {
        if (localConfig && userConfig) {
            void getAssets(userAccessGroup);
        }
    }, [userConfig?.userSettings, localConfig, userAccessGroup]);

    return userAccessGroupResponse;
}
