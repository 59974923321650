import { modeAtomReports } from '_global/Utils/hooks/jotai';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Tab } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useAtom } from 'jotai';
import Mode from 'models/enums/ModeEnum';
import { IAssetReportDetails, IItemReportDetails, IReportCreate, IReportDetails, TabValue } from 'models/report/ReportResponse';
import { IReportInstance } from 'queries/Reports';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { AssetsTab } from './Tabs/AssetsTab';
import { DetailsTab } from './Tabs/DetailsTab';
import InfoTab from './Tabs/InfoTab';
import { PreviewTab } from './Tabs/PreviewTab';

export interface ReportTabProps {
    onSubTabChange: (value: string) => void;
    report?: IReportDetails;
}

export const saveStateReport = (state: IReportDetails | IReportInstance | IReportCreate) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('reportDetails', serializedState);
    } catch (e) {
        console.error({ e });
    }
};

const ReportDetails = (props: ReportTabProps) => {
    const { onSubTabChange, report } = props;
    const [mode] = useAtom(modeAtomReports);
    const [tabValue, setTabValue] = useState<string>(TabValue.INFO.toString());

    const [canProgress, setCanProgress] = useState<boolean[]>([false, false, false]);
    const [reportDetails, setReportDetails] = useState<IReportDetails>({
        __typename: 'ReportDTO',
        created: new Date(),
        description: '',
        equipments: [],
        errors: '',
        name: '',
        period_end: new Date().toString(),
        period_start: new Date().toString(),
        periodic: false,
        ready: false,
        report_type: '',
        requested_by: '',
        size: 0,
        title: '',
        _id: '',
        accessGroups: [],
        operationalAreas: [],
        companies: [],
        regions: [],
        assets: [],
        users: []
    });

    useEffect(() => {
        if (report) {
            setReportDetails(report);
        }
    }, [report]);

    const updateTabValue = (tab: TabValue) => {
        switch (Number(tab)) {
            case TabValue.INFO:
                setTabValue(tab.toString());
                break;
            case TabValue.ASSETS:
                if (canProgress[TabValue.INFO]) {
                    setTabValue(tab.toString());
                }
                break;
            case TabValue.PREVIEW:
                if (canProgress[TabValue.ASSETS]) {
                    setTabValue(tab.toString());
                }
                break;
            default:
                break;
        }
    };

    const handleContinueSubmit = useCallback(
        (tab: TabValue) => {
            updateTabValue(tab);
        },
        [canProgress]
    );

    const handleChange = useCallback(
        (event: SyntheticEvent, tab: TabValue) => {
            updateTabValue(tab);
        },
        [canProgress, mode]
    );

    useEffect(() => {
        onSubTabChange(tabValue.toString());
    }, [tabValue]);

    useEffect(() => {
        if (mode === Mode.EDIT) {
            setTabValue(TabValue.INFO.toString());
        }
    }, [mode]);

    const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string, id: string) => {
        if (typeof event === 'string') {
            setReportDetails((old) => ({ ...old, [id]: event }));
        } else {
            setReportDetails((old) => ({ ...old, [id]: event.target.value }));
        }
    }, []);

    const handleOnChangeDate = useCallback((event: Dayjs | null, id: string) => {
        setReportDetails((old) => ({ ...old, [id]: event }));
    }, []);

    const handleChangeAll = useCallback((id: string, items: IItemReportDetails[] | IAssetReportDetails[]) => {
        setReportDetails((old) => ({ ...old, [id]: items }));
    }, []);

    useEffect(() => {
        switch (tabValue) {
            case TabValue.INFO.toString():
                if (mode !== Mode.PREVIEW) {
                    if (reportDetails.name !== '' && reportDetails.report_type !== '') {
                        setCanProgress([true, false, false]);
                    } else {
                        setCanProgress([false, false, false]);
                    }
                } else {
                    setCanProgress([true, false, false]);
                }

                break;
            case TabValue.ASSETS.toString():
                if (mode !== Mode.PREVIEW) {
                    if (
                        reportDetails.accessGroups.length > 0 ||
                        reportDetails.companies.length > 0 ||
                        reportDetails.regions.length > 0 ||
                        reportDetails.operationalAreas.length > 0 ||
                        reportDetails.assets.length > 0
                    ) {
                        setCanProgress([true, true, false]);
                    } else {
                        setCanProgress([true, false, false]);
                    }
                } else {
                    setCanProgress([true, true, false]);
                }

                break;
            case TabValue.PREVIEW.toString():
                if (mode !== Mode.PREVIEW) {
                    if (reportDetails.users.length > 0) {
                        setCanProgress([true, true, true]);
                    } else {
                        setCanProgress([true, true, false]);
                    }
                } else {
                    setCanProgress([true, true, true]);
                }

                break;
        }
    }, [tabValue, reportDetails, mode]);

    useEffect(() => {
        if (mode === Mode.EDIT || mode === Mode.ADD) {
            saveStateReport(reportDetails);
        }
    }, [mode]);

    return (
        <Box sx={{ borderColor: 'divider', width: '100%', position: 'relative' }}>
            {mode !== Mode.PREVIEW ? (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            mr: 3,
                            zIndex: 10
                        }}
                    >
                        {tabValue !== TabValue.PREVIEW.toString() && (
                            <Button
                                color="secondary"
                                disabled={tabValue === TabValue.INFO.toString() ? !canProgress[0] : !canProgress[1]}
                                onClick={() => handleContinueSubmit(Number(tabValue) + 1)}
                                variant={'contained'}
                            >
                                <FormattedMessage id={'continue'} />
                            </Button>
                        )}
                    </Box>

                    <TabContext value={tabValue.toString()}>
                        <TabList onChange={handleChange} value={tabValue.toString()}>
                            <Tab label={<FormattedMessage id={'info'} />} value={TabValue.INFO.toString()} />
                            <Tab label={<FormattedMessage id={'assets'} />} value={TabValue.ASSETS.toString()} />
                            <Tab label={<FormattedMessage id={'preview'} />} value={TabValue.PREVIEW.toString()} />
                        </TabList>

                        <TabPanel value={TabValue.INFO.toString()}>
                            <InfoTab handleOnChange={handleOnChange} handleOnChangeDate={handleOnChangeDate} report={reportDetails} />
                        </TabPanel>

                        <TabPanel value={TabValue.ASSETS.toString()}>
                            <AssetsTab handleChangeAll={handleChangeAll} report={reportDetails} />
                        </TabPanel>

                        <TabPanel value={TabValue.PREVIEW.toString()}>
                            <PreviewTab report={reportDetails} />
                        </TabPanel>
                    </TabContext>
                </>
            ) : (
                <DetailsTab report={reportDetails} />
            )}
        </Box>
    );
};

export default ReportDetails;
