import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loadDefaultConfig } from '../../../logic/configuration/Configuration';
import Logger from '../../Services/logger/Logger';
import { setConfiguration } from '../../Services/store/featureSlice';

export default function ConfigLoader() {
    const dispatch = useDispatch();

    useEffect(() => {
        loadDefaultConfig()
            .then((config) => {
                if (config === undefined) {
                    Logger.LogBigError('Configuration not found !');
                    throw new Error('I crashed!');
                } else {
                    dispatch(setConfiguration(config));
                }
            })
            .catch(console.error);
    }, []);

    return <></>;
}
