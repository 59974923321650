import dayjs from 'dayjs';
import {
    CATEGORY,
    HISTORICAL_DATA_AGGREGATION_UNIT,
    HISTORICAL_DATA_LINE,
    HISTORICAL_DATA_MINUTES,
    HISTORICAL_DATA_TIME_FRAME,
    HISTORICAL_DATA_TYPE
} from 'models/asset/AssetHistoricalData';

function getFeederQuery(feederId: number | undefined): string {
    if (feederId) return `&var-feeder_id=${feederId}`;
    else return '';
}

function getLineQuery(line: HISTORICAL_DATA_LINE): string {
    return `&var-line=${line}`;
}

function getTypeQuery(type: HISTORICAL_DATA_TYPE): string {
    return `&var-type=${type}`;
}

function getTimeFrameQuery(timeframe: HISTORICAL_DATA_TIME_FRAME, startDate: string, endDate: string): string {
    const endOfToday = dayjs().unix().toString();
    switch (timeframe) {
        case HISTORICAL_DATA_TIME_FRAME.TODAY:
            return `&var-time_start=${dayjs().startOf('day').unix().toString()}&var-time_end=${endOfToday}`;
        case HISTORICAL_DATA_TIME_FRAME.LAST_WEEK:
            return `&var-time_start=${dayjs().subtract(7, 'days').unix().toString()}&var-time_end=${endOfToday}`;
        case HISTORICAL_DATA_TIME_FRAME.LAST_MONTH:
            return `&var-time_start=${dayjs().subtract(1, 'months').unix().toString()}&var-time_end=${endOfToday}`;
        case HISTORICAL_DATA_TIME_FRAME.LAST_THREE_MONTH:
            return `&var-time_start=${dayjs().subtract(3, 'months').unix().toString()}&var-time_end=${endOfToday}`;
        case HISTORICAL_DATA_TIME_FRAME.LAST_SIX_MONTH:
            return `&var-time_start=${dayjs().subtract(6, 'months').unix().toString()}&var-time_end=${endOfToday}`;
        case HISTORICAL_DATA_TIME_FRAME.WINDOW:
            return `&var-time_start=${startDate}&var-time_end=${endDate}`;
    }
}

function getAggregationQuery(aggregationUnit: HISTORICAL_DATA_AGGREGATION_UNIT, time: HISTORICAL_DATA_MINUTES): string {
    if (aggregationUnit === HISTORICAL_DATA_AGGREGATION_UNIT.NONE) return `&var-aggregate=false`;
    else {
        if (aggregationUnit === HISTORICAL_DATA_AGGREGATION_UNIT.MINUTES) {
            return `&var-aggregation_value=${time}&var-aggregate=true`;
        }

        return `&var-aggregation_value=${aggregationUnit}&var-aggregate=true`;
    }
}

export function getFilters(
    category: CATEGORY,
    feederId: number | undefined,
    feederEarthId: number | undefined,
    line: HISTORICAL_DATA_LINE,
    type: HISTORICAL_DATA_TYPE,
    timeFrame: HISTORICAL_DATA_TIME_FRAME,
    startDate: string,
    endDate: string,
    aggregationUnit: HISTORICAL_DATA_AGGREGATION_UNIT,
    time: HISTORICAL_DATA_MINUTES
): string {
    let finalFilter = '';
    switch (category) {
        case CATEGORY.MEASURE:
            finalFilter += getFeederQuery(feederId);
            finalFilter += getTypeQuery(type);
            break;
        case CATEGORY.POWER:
        case CATEGORY.ENERGY:
            finalFilter += getFeederQuery(feederId);
            finalFilter += getLineQuery(line);
            break;
        case CATEGORY.THD:
            finalFilter += getFeederQuery(feederId);
            break;
        case CATEGORY.EARTH_SYSTEM:
            finalFilter += getFeederQuery(feederEarthId);
            break;
    }

    finalFilter += getTimeFrameQuery(timeFrame, startDate, endDate);
    if (category !== CATEGORY.EARTH_SYSTEM) finalFilter += getAggregationQuery(aggregationUnit, time);
    return finalFilter;
}
