import { RegionInstance } from 'queries/Region';

import { InstallationPoint } from './InstallationPoint';
import { OperationalArea } from './OperationalArea';

export interface RegionDetails {
    id?: number;
    companyId: number;
    name: string;
    gpsPoints?: InstallationPoint[];
    code: string;
    operationalAreas?: OperationalArea[];
}

export function regionToRow(event: RegionInstance): RegionDetails {
    return {
        id: Number(event?.id),
        companyId: Number(event?.companyId),
        name: String(event?.name),
        code: String(event?.code)
    };
}

export function regionFromGraphQL(region: unknown): RegionDetails {
    return region as RegionDetails;
}
