import { RegionDetails } from 'models/layout/Region';
import { Dispatch, SetStateAction } from 'react';
import { IValidations } from 'views/Users/utils/UsersModels';

export interface IRegion {
    selectedRegion?: RegionDetails;
}

export interface IRegionsPageInternal {
    setStateToast: Dispatch<
        SetStateAction<{
            openToast: boolean;
            toastMessage: string;
        }>
    >;
}

export const defaultRegionValidation = [
    { name: 'name', error: false, message: '' },
    { name: 'code', error: false, message: '' },
    { name: 'companyId', error: false, message: '' },
    { name: 'gpsPoints', error: false, message: '' }
] as IValidations[];
