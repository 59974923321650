import GrafanaHolder from '_global/Components/base/grafana/GrafanaHolder';
import { RootState } from '_global/Services/store';
import { CATEGORY, HISTORICAL_DATA_VIEW_PANEL } from 'models/asset/AssetHistoricalData';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const PATH = '/d/hDyfgwSjd/historical-plots?var-measurement=powerBusLine&var-equipment_id=';
const PATH_EARTH = '/d/TLD7nAa4z/earth?';
const THEME = '&theme=light';

export default function HistoricalDataGraphs(props: { assetId: number; deviceId?: number; category: CATEGORY; filter: string }) {
    const { assetId, category, filter, deviceId } = props;
    const features = useSelector((state: RootState) => state.features);

    const baseURL = features.configuration?.BaseUrl ?? '';
    const grafana = features.configuration?.Grafana.url ?? '';

    const [grafanaUrlGraphs, setGrafanaUrlGraphs] = useState<string[]>([]);

    useEffect(() => {
        const viewPanels = Object.entries(HISTORICAL_DATA_VIEW_PANEL).filter(([key]) => key === category.toString());

        if (viewPanels) {
            const grafanaGraphs: string[] = [];
            viewPanels[0][1].map((viewPanel) => {
                switch (category) {
                    case CATEGORY.EARTH_SYSTEM:
                        return grafanaGraphs.push(
                            `${baseURL}${grafana}${PATH_EARTH}${assetId}${filter}${THEME}&kiosk&viewPanel=${viewPanel}`
                        );
                    case CATEGORY.NETWORK:
                        return grafanaGraphs.push(
                            `${baseURL}${grafana}${PATH}${filter}${THEME}&orgId=1&kiosk&viewPanel=${viewPanel}&var-device_id=${
                                deviceId ?? ''
                            }`
                        );
                    default:
                        return grafanaGraphs.push(`${baseURL}${grafana}${PATH}${assetId}${filter}${THEME}&kiosk&viewPanel=${viewPanel}`);
                }
            });

            setGrafanaUrlGraphs(grafanaGraphs);
        }
    }, [category]);

    return (
        <>
            {grafanaUrlGraphs.map((url) => {
                return <GrafanaHolder url={url} {...props} key={url} />;
            })}
        </>
    );
}
