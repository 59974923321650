import Spacer from '_global/Components/base/Spacer';
import { COLORS } from '_global/Utils/Colors';
import { Stack, Typography } from '@mui/material';
import { ASSET_STATE } from 'models/graphql-global-types';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export default function StateCell(props: { state: ASSET_STATE }) {
    const { state } = props;

    const backgroundColor = useMemo(() => {
        switch (state) {
            case ASSET_STATE.ARCHIVED:
                return COLORS.ENDEIDA_SIMPLE_LIGHT_BLUE;
            case ASSET_STATE.INSTALLED:
                return COLORS.ENEIDA_SIMPLE_LIGHT_GREEN;
            case ASSET_STATE.NOT_INSTALLED:
                return COLORS.ENEIDA_SIMPLE_LIGHT_RED;
        }
    }, [state]);

    const textColor = useMemo(() => {
        switch (state) {
            case ASSET_STATE.ARCHIVED:
                return COLORS.ENEIDA_SOLID_BLUE;
            case ASSET_STATE.INSTALLED:
                return COLORS.ENEIDA_ALTERNATIVE_GREEN;
            case ASSET_STATE.NOT_INSTALLED:
                return COLORS.ENEIDA_ALTERNATIVE_RED;
        }
    }, [state]);

    const text = useMemo(() => {
        switch (state) {
            case ASSET_STATE.ARCHIVED:
                return 'archived';
            case ASSET_STATE.INSTALLED:
                return 'installed';
            case ASSET_STATE.NOT_INSTALLED:
                return 'not-installed';
        }
    }, [state]);

    return (
        <Stack direction="row">
            <Spacer />
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                sx={{ paddingX: '10px', paddingY: '2px', background: backgroundColor, borderRadius: '25px', maxWidth: '130px' }}
            >
                <Typography sx={{ color: textColor }} variant="button">
                    <FormattedMessage id={text} />
                </Typography>
            </Stack>
            <Spacer />
        </Stack>
    );
}
