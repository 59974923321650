import { IValidations } from 'views/Users/utils/UsersModels';

export const defaultDeviceValidation = [
    { name: 'serialNumber', error: false, message: '' },
    { name: 'bulkConfigurationId', error: false, message: '' },
    { name: 'apnName', error: false, message: '' },
    { name: 'apnUser', error: false, message: '' },
    { name: 'apnAuthType', error: false, message: '' },
    { name: 'apnPwd', error: false, message: '' },
    { name: 'username', error: false, message: '' },
    { name: 'password', error: false, message: '' },
    { name: 'certificateUrl', error: false, message: '' },
    { name: 'serverAddress', error: false, message: '' },
    { name: 'serverPort', error: false, message: '' },
    { name: 'communicationRate', error: false, message: '' }
] as IValidations[];
