import { COLORS } from '_global/Utils/Colors';
import EVENT_SEVERITY_BY_NUMBER from 'models/enums/EventSeverityByNumber';

export function getAlarmSeverityColor(alarmSeverity?: EVENT_SEVERITY_BY_NUMBER) {
    switch (alarmSeverity) {
        case EVENT_SEVERITY_BY_NUMBER.CRITICAL:
            return COLORS.ENEIDA_ALTERNATIVE_RED;
        case EVENT_SEVERITY_BY_NUMBER.WARNING:
            return COLORS.ENEIDA_ALTERNATIVE_YELLOW;
        case EVENT_SEVERITY_BY_NUMBER.INFO:
            return COLORS.ENEIDA_SIMPLE_BLUE;
        default:
            return COLORS.ENEIDA_ALTERNATIVE_GREEN;
    }
}

export function getPowerOccupationColor(powerOccupation: number): string {
    if (powerOccupation >= 80 && powerOccupation < 100) {
        return COLORS.ENEIDA_ALTERNATIVE_YELLOW;
    } else if (powerOccupation >= 100) {
        return COLORS.ENEIDA_ALTERNATIVE_RED;
    } else {
        return COLORS.ENEIDA_ALTERNATIVE_GREEN;
    }
}
