import { AccessGroupList, ItemAccessGroup } from 'models/layout/AccessGroup';
import { UserRolesResponse } from 'models/userRoles/ProcessedUserRoles';
import { UserResponse } from 'models/users/UserResponse';
import { ChangeEvent } from 'react';

export interface IUser {
    user?: UserResponse;
}
export interface IUserDetailsContent {
    userDetails: UserResponse;
    handleOnChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => void;
    handleChangeRoles: (role: UserRolesResponse | null) => void;
    userRoles: UserRolesResponse[];
    handleChangeAccessGroups: (accessGroups: ItemAccessGroup[]) => void;
    userAccessGroups: AccessGroupList[];
}

export interface IValidations {
    name: string;
    error: boolean;
    message: string;
}

export const defaultValidationUsers = [
    { name: 'firstName', error: false, message: '' },
    { name: 'lastName', error: false, message: '' },
    { name: 'email', error: false, message: '' },
    { name: 'roles', error: false, message: '' },
    { name: 'accessGroups', error: false, message: '' }
] as IValidations[];
