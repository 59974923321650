import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import reducer from './reducer';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
});

const persistConfig = {
    key: 'root',
    storage
};

const store = configureStore({
    reducer: reducer,
    middleware: [thunk],
    enhancers: [sentryReduxEnhancer]
});

store.subscribe(() => {
    const { gridsPaginations } = store.getState();

    sessionStorage.setItem('gridsPaginations', JSON.stringify(gridsPaginations.gridsPaginations));
});

const persister = persistReducer(persistConfig, reducer);

export { persister, store };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
