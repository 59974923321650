import { getData } from '_global/Services/api/networkRequester';
import { Box, Typography } from '@mui/material';
import { useConfiguration } from 'logic/configuration/Hooks';
import { Country } from 'models/layout/Country';
import { useEffect, useState } from 'react';

export default function CountryCell(props: { country: string }) {
    const { country } = props;

    const configuration = useConfiguration();
    const [countries, setCountries] = useState<Country[]>();

    useEffect(() => {
        if (configuration) {
            const countryUrl = '/layout/api/StaticProvider/GetAllCountries';
            const endpoint = `${configuration.BaseUrl}${countryUrl}`;
            getData<Country[]>(endpoint)
                .then((data) => {
                    data?.sort(function (firstCountry, secondCountry) {
                        return firstCountry.name.common.localeCompare(secondCountry.name.common);
                    });
                    setCountries(data);
                })
                .catch(() => {
                    setCountries([]);
                });
        }
    }, [configuration]);

    return (
        <Box sx={{ textAlign: 'left' }}>
            <Typography>{countries?.find((el) => el.ccn3 === country)?.name.common ?? ''}</Typography>
        </Box>
    );
}
