import { KeycloakConfig } from 'keycloak-js';

export type AccessGroupsGridsResponse = {
    assets: number[];
    workingOPArea: number[];
    workingRegions: number[];
    devices: number[];
};

type GrafanaConfig = {
    url: string;
};

type MapInitialPosition = {
    position: {
        latitude: number;
        longitude: number;
    };
};

type Company = {
    NoImageUrl: string;
};

type App = {
    Company: Company;
};

export type Configuration = {
    Name: string;
    App: App;
    BaseUrl: string;
    ExternalRequests: string[];
    CountriesUrl: string;
    MapboxToken?: string;
    KeycloakConfiguration: KeycloakConfig | string;
    Grafana: GrafanaConfig;
    AvailableFeatures?: Feature[];
    EnabledAuthentication: boolean;
    InitialMapPosition: MapInitialPosition;
    HelpLink: string;
};

export enum Feature {
    SeeEvents,
    CreateSubstation,
    Others
}

function isConfiguration(configuration: unknown): configuration is Configuration {
    return (configuration as Configuration).Name !== undefined && (configuration as Configuration).BaseUrl !== undefined;
}

async function loadConfiguration(name: string): Promise<Configuration | undefined> {
    const fileName = window.location.origin + '/files/configs/' + name + '.json';
    const response = await fetch(fileName, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    });

    const object = (await response.json()) as unknown;

    if (isConfiguration(object)) {
        return object;
    } else {
        return undefined;
    }
}

export function loadDefaultConfig(): Promise<Configuration | undefined> {
    return loadConfiguration((import.meta.env.VITE_CONFIG_NAME as string) ?? 'rancher');
}
