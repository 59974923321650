// material-ui
import { drawerWidth } from '_global/Services/store/constant';
import { Box, Drawer, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BrowserView, MobileView } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

import LogoSection from '../LogoSection';
// project imports
import MenuList from './MenuList';

// ==============================|| SIDEBAR DRAWER ||============================== //

export interface SidebarProps {
    drawerOpen: boolean;
    drawerToggle: () => void;
}

const Sidebar = (props: SidebarProps) => {
    const { drawerOpen, drawerToggle } = props;

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        paddingLeft: '8px',
                        paddingRight: '8px'
                    }}
                >
                    <MenuList />
                    <Box m={1} display="flex" justifyContent="center" alignItems="flex-end" sx={{ height: 'auto', align: 'flex-end' }}>
                        <Typography variant="subtitle2">
                            <FormattedMessage id={'deepgrid_info_copyright'} />
                        </Typography>
                        <Typography variant="subtitle2">{`v${import.meta.env.VITE_VERSION ?? import.meta.env.PACKAGE_VERSION}`}</Typography>
                    </Box>
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                </Box>
            </MobileView>
        </>
    );

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '68px'
                        }
                    }
                }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

export default Sidebar;
