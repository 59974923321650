import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

export default function LastDataTableHeadBusbar() {
    const position = 'left';
    const columns = [
        'Line',
        'Imax (A)',
        'Imin (A)',
        'Irms (A)',
        'Iangle (ºIag)',
        'Vmax (V)',
        'Vmin (V)',
        'Vrms (V)',
        'Vangle (ºIag)',
        'Frequency (Hz)',
        'Act.P. (kW)',
        'React.P. (kVAr)',
        'App.P. (kVA)',
        'Pow. Factor',
        'ITHD (%)',
        'VTHD (%)'
    ];

    return (
        <TableHead>
            <TableRow>
                {columns.map((column, i: number) => (
                    <TableCell align={position} key={i}>
                        <Typography sx={{ fontSize: 11, color: 'black', textAlign: 'center', fontWeight: 'bold' }} variant="body1">
                            {column}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
