enum LASTCONTACT_TYPE {
    StateField = 0,
    DTVIStateField = 1,
    StateFieldAck = 2,
    LegacyStateField = 3,
    StateAlarm = 4,
    StateAlarmAck = 5,
    RawData = 6,
    AlarmStatus = 7,
    Unknown = -1
}

export default LASTCONTACT_TYPE;
