import { Pagination } from '_global/Services/store/gridsPaginationSlice';
import { TypeColumn, TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types';
import { SortInfoType } from 'models/sortInfo';
import { SetStateAction } from 'react';
import { Location } from 'react-router-dom';
import { TypeColumnExtended } from 'views/Assets/grid/AssetsGrid';

interface IGridsPaginationsUpdateGrid {
    gridName: string;
    gridsPaginations: Pagination[];
    columns: TypeColumn[];
    sortInfo?: SortInfoType;
    setGridSkip: (value: SetStateAction<number | undefined>) => void;
    setGridSortInfo: (value: SetStateAction<TypeSingleSortInfo | undefined>) => void;
    setGridColumnOrder: (value: SetStateAction<string[] | undefined>) => void;
    setGridLimit: (value: SetStateAction<number | undefined>) => void;
    location: Location;
    isFirmware?: boolean;
}

export default function gridsPaginationsUpdateGrid({
    gridName,
    gridsPaginations,
    columns,
    sortInfo,
    setGridSkip,
    setGridSortInfo,
    setGridColumnOrder,
    setGridLimit,
    location,
    isFirmware
}: IGridsPaginationsUpdateGrid) {
    if (gridsPaginations?.length > 0 && location.pathname) {
        gridsPaginations.forEach((gridPagination: Pagination) => {
            if (gridPagination.url === location.pathname && gridPagination.key === gridName) {
                if (gridPagination.grid?.sortInfo) {
                    if (sortInfo) {
                        const section = columns.find((section) => section.name === gridPagination.grid.sortInfo?.id);
                        if ((section as TypeColumnExtended)?.columnSortAsc && (section as TypeColumnExtended)?.columnSortDesc) {
                            if (isFirmware) {
                                sortInfo(
                                    gridPagination.grid.sortInfo.id as string,
                                    gridPagination.grid.sortInfo.dir as number,
                                    gridPagination.grid.sortInfo.type as string,
                                    (section as TypeColumnExtended).columnSortAsc,
                                    (section as TypeColumnExtended).columnSortDesc,
                                    (gridPagination.grid.sortInfo.id as string) === 'firmware' ? 'name' : undefined
                                );
                            } else {
                                sortInfo(
                                    gridPagination.grid.sortInfo.id as string,
                                    gridPagination.grid.sortInfo.dir as number,
                                    gridPagination.grid.sortInfo.type as string,
                                    (section as TypeColumnExtended).columnSortAsc,
                                    (section as TypeColumnExtended).columnSortDesc
                                );
                            }
                        } else {
                            if (isFirmware) {
                                sortInfo(
                                    gridPagination.grid.sortInfo.id as string,
                                    gridPagination.grid.sortInfo.dir as number,
                                    gridPagination.grid.sortInfo.type as string,
                                    (section as TypeColumnExtended).columnSortAsc,
                                    undefined,
                                    (gridPagination.grid.sortInfo.id as string) === 'firmware' ? 'name' : undefined
                                );
                            } else {
                                sortInfo(
                                    gridPagination.grid.sortInfo.id as string,
                                    gridPagination.grid.sortInfo.dir as number,
                                    gridPagination.grid.sortInfo.type as string
                                );
                            }
                        }
                    }
                }
                setGridSkip(gridPagination.grid?.skip ?? 0);
                setGridSortInfo(gridPagination.grid?.sortInfo ?? { dir: 0, name: '', id: '' });
                setGridColumnOrder(gridPagination.grid?.columnOrder ?? (columns.map((column) => column.name) as string[]));
                setGridLimit(gridPagination.grid?.limit ?? 0);
            }
        });
    } else {
        setGridSkip(0);
        setGridSortInfo({ dir: 0, name: '', id: '' });
        setGridColumnOrder(columns.map((column) => column.name) as string[]);
        setGridLimit(0);
    }
}
