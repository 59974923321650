import '@inovua/reactdatagrid-community/index.css';

import { Box, Link, TableCell, TableRow, Typography } from '@mui/material';
import { ProcessedEvent } from 'models/events/ProcessedEvent';
import { EventSeverity, EventStatus } from 'models/graphql-global-types';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import SeverityCell from '../grid/cells/SeverityCell';
import StatusCell from '../grid/cells/StatusCell';

type IEventHistoryTableRow = {
    column: string;
    value: string;
    alarm: ProcessedEvent;
};
export default function EventHistoryTableRow(props: IEventHistoryTableRow) {
    const { value, column, alarm } = props;
    const statusColumn = 'status';
    const severityColumn = 'severity';
    const typeColumn = 'type';
    const assetColumn = 'asset';
    const deviceColumn = 'device';
    const navigate = useNavigate();

    return (
        <TableRow hover>
            <TableCell sx={{ borderBottom: 'none' }} variant="head">
                <Typography fontSize={12} sx={{ color: 'gray' }}>
                    <FormattedMessage id={column} />
                </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none' }}>
                {column === statusColumn && (
                    <Box sx={{ mt: -2 }}>
                        <StatusCell status={value as EventStatus} />
                    </Box>
                )}
                {column === severityColumn && (
                    <Box sx={{ mt: -2 }}>
                        <SeverityCell severity={value as EventSeverity} />
                    </Box>
                )}
                {column === typeColumn && (
                    <Typography color={'black'}>
                        <FormattedMessage id={value.toLowerCase()} />
                    </Typography>
                )}
                {column === assetColumn && (
                    <Link
                        onClick={() => navigate(`/assets/${alarm?.equipmentId}`)}
                        sx={{ color: 'blue', textDecorationColor: 'blue', cursor: 'pointer' }}
                    >
                        <FormattedMessage id={value} />
                    </Link>
                )}
                {column === deviceColumn && (
                    <Link
                        onClick={() => navigate(`/devices/${alarm?.deviceId ?? ''}`)}
                        sx={{ color: 'blue', textDecorationColor: 'blue', cursor: 'pointer' }}
                    >
                        <FormattedMessage id={value} />
                    </Link>
                )}

                {column !== statusColumn &&
                    column !== severityColumn &&
                    column !== typeColumn &&
                    column !== assetColumn &&
                    column !== deviceColumn && (
                        <Typography color={'black'}>
                            <FormattedMessage id={value} />
                        </Typography>
                    )}
            </TableCell>
        </TableRow>
    );
}
