import MainCard from '_global/Components/base/cards/MainCard';
import { modeAtomAssets } from '_global/Utils/hooks/jotai';
import { IconToNode } from '_global/Utils/IconToNode';
import { useQuery } from '@apollo/client';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { Autocomplete, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import Mode from 'models/enums/ModeEnum';
import { TIME_SERVER_TYPE } from 'models/graphql-global-types';
import { BULK_LIST } from 'queries/BulkList';
import { STANDARDS } from 'queries/Standards';
import { deviceBulkConfigurations, deviceBulkConfigurations_deviceBulkConfigurations_nodes } from 'queries/Types/DeviceBulkConfigurations';
import { standards, standards_alarmStandards_nodes } from 'queries/Types/standards';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { AssetProps } from '../utils/AssetModel';

const Configuration = (props: AssetProps) => {
    const { assetDetails, handleChangeBulk, handleChangeAlarm } = props;
    const [mode] = useAtom(modeAtomAssets);
    const [bulkConfigurations, setBulkConfigurations] = useState<
        deviceBulkConfigurations_deviceBulkConfigurations_nodes[] | null | undefined
    >([]);
    const [standardsOption, setStandardsOption] = useState<standards_alarmStandards_nodes[]>([]);
    const { data } = useQuery<deviceBulkConfigurations>(BULK_LIST, {
        variables: { cursor: null, numberOfElements: 100, filter: null }
    });
    const { data: standardsData } = useQuery<standards>(STANDARDS, { variables: { first: 100 } });
    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setBulkConfigurations(data?.deviceBulkConfigurations?.nodes);
        }
    }, [data]);

    useEffect(() => {
        if (standardsData) {
            setStandardsOption(standardsData?.alarmStandards?.nodes ?? []);
        }
    }, [standardsData]);

    const configurationInput = (isActive: boolean) => {
        return (
            <Autocomplete
                disabled={!isActive}
                getOptionLabel={(option: deviceBulkConfigurations_deviceBulkConfigurations_nodes) => {
                    return `${option.name} - (${option.aquisitionRate} / ${option.communicationRate})`;
                }}
                isOptionEqualToValue={(option, val) => {
                    if (val.id === '') {
                        return true;
                    }
                    return option.id === val.id;
                }}
                key={assetDetails.equipBulkConfigurationId}
                onChange={(event, newValue) => {
                    handleChangeBulk && handleChangeBulk((newValue?.id as number) ?? null, newValue?.name ?? null);
                }}
                options={bulkConfigurations ?? []}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: assetDetails.equipBulkConfigurationId && mode === Mode.PREVIEW && (
                                <InputAdornment
                                    onClick={() => {
                                        if (assetDetails.equipBulkConfigurationId) {
                                            navigate(`/configurationProfiles/${assetDetails.equipBulkConfigurationId}`);
                                        }
                                    }}
                                    position="start"
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <IconToNode definition={faArrowUpRightFromSquare} />
                                </InputAdornment>
                            )
                        }}
                        label={<FormattedMessage id={'configuration'} />}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                        <Typography>{option?.name}</Typography>
                        <Typography sx={{ color: '#9e9e9e', fontSize: '12px' }}>
                            <FormattedMessage id={'aquisition_rate'} />
                            {option?.aquisitionRate}
                        </Typography>
                        <Typography sx={{ color: '#9e9e9e', fontSize: '12px' }}>
                            <FormattedMessage id={'communication_rate'} />
                            {option?.communicationRate}
                        </Typography>
                    </li>
                )}
                sx={{ maxHeight: '51px' }}
                value={
                    bulkConfigurations?.find((el) => el.id === assetDetails?.equipBulkConfigurationId) ?? {
                        __typename: 'DeviceBulkConfiguration',
                        id: '',
                        name: '',
                        aquisitionRate: 0,
                        communicationRate: 0,
                        isDefault: false,
                        timeServerUrl: '',
                        timeServerType: TIME_SERVER_TYPE.DEEPGRID,
                        timeServerProtocol: '',
                        timeServerPort: 0,
                        serverUrl: '',
                        deviceTypeConfigurations: [],
                        protectionEarthFrequencyF1: 0,
                        protectionEarthFrequencyF2: 0,
                        protectionEarthFrequencyF3: 0,
                        serviceEarthFrequencyF1: 0,
                        serviceEarthFrequencyF2: 0,
                        serviceEarthFrequencyF3: 0
                    }
                }
            />
        );
    };

    const alarmProFileInput = (isActive: boolean) => {
        return (
            <Autocomplete
                disabled={!isActive}
                getOptionLabel={(option: standards_alarmStandards_nodes) => {
                    return option?.name;
                }}
                isOptionEqualToValue={(option, val) => {
                    if (val.id === '') {
                        return true;
                    }
                    return option?.id === val?.id;
                }}
                key={assetDetails?.alarmStandardId}
                onChange={(event, newValue) => {
                    handleChangeAlarm && handleChangeAlarm((newValue?.id as number) ?? null, newValue?.name ?? null);
                }}
                options={standardsOption ?? []}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: assetDetails.alarmStandardId && mode === Mode.PREVIEW && (
                                <InputAdornment
                                    onClick={() => {
                                        if (assetDetails.alarmStandardId) navigate(`/alarmProfiles/${assetDetails?.alarmStandardId}`);
                                    }}
                                    position="start"
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <IconToNode definition={faArrowUpRightFromSquare} />
                                </InputAdornment>
                            )
                        }}
                        label={<FormattedMessage id={'alarm_profile'} />}
                    />
                )}
                sx={{ maxHeight: '51px' }}
                value={
                    standardsOption?.find((el) => el.id === assetDetails?.alarmStandardId) ?? {
                        __typename: 'AlarmStandard',
                        name: '',
                        id: '',
                        lastModifiedDate: null,
                        lastModifiedBy: null,
                        editable: false,
                        createdDate: null,
                        createdBy: null
                    }
                }
            />
        );
    };

    return (
        <MainCard className="main-card" content={false} title={<FormattedMessage id={'configuration'} />}>
            <Grid container spacing={2} sx={{ px: 3, pb: 4, pt: 2 }}>
                <Grid item xs={12}>
                    {configurationInput(mode === Mode.EDIT || mode === Mode.ADD)}
                </Grid>
                <Grid item sx={{ mt: 1 }} xs={12}>
                    {alarmProFileInput(mode === Mode.EDIT || mode === Mode.ADD)}
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default Configuration;
