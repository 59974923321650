import { Grid } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import {
    CATEGORY,
    HISTORICAL_DATA_AGGREGATION_UNIT,
    HISTORICAL_DATA_LINE,
    HISTORICAL_DATA_MINUTES,
    HISTORICAL_DATA_TIME_FRAME,
    HISTORICAL_DATA_TYPE
} from 'models/asset/AssetHistoricalData';
import { FeederType } from 'models/asset/AssetModel';
import { useCallback, useEffect, useState } from 'react';
import { IHistoricalDataFilters } from 'views/Assets/utils/AssetModel';

import AggregationUnitFilter from './filters/AggregationFilter';
import FeederFilter from './filters/FeederFilter';
import { getFilters } from './filters/Filters';
import LineFilter from './filters/LineFilter';
import MinutesFilter from './filters/MinutesFilter';
import TimeFrameFilter from './filters/TimeFrameFilter';
import TypeFilter from './filters/TypeFilter';
import WindowFilter from './filters/WindowFilter';

export default function HistoricalDataFilters(props: IHistoricalDataFilters) {
    const { asset, category, clearFilter, onFiltersChange, onConfirmPressed, onSaveGrafanaFilter } = props;

    function getBusBar(): number | undefined {
        return asset.feeders.find((x) => x.feederType == FeederType.BUSBAR)?.id;
    }
    function getProtectionEarth(): number | undefined {
        return asset.feeders.find((x) => x.feederType == FeederType.EARTH_PROTECTION_LINE)?.id;
    }

    const [selectedFeederId, setSelectedFeederId] = useState<number | undefined>(getBusBar());
    const [selectedFeederEarthId, setSelectedFeederEarthId] = useState<number | undefined>(getProtectionEarth());
    const [selectedLine, setSelectedLine] = useState<HISTORICAL_DATA_LINE>(HISTORICAL_DATA_LINE.L1);
    const [selectedType, setSelectedType] = useState<HISTORICAL_DATA_TYPE>(HISTORICAL_DATA_TYPE.MEAN);
    const [selectedTimeFrame, setSelectedTimeFrame] = useState<HISTORICAL_DATA_TIME_FRAME>(HISTORICAL_DATA_TIME_FRAME.TODAY);
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(1, 'day'));
    const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
    const [selectedWindowStartTime, setSelectedWindowStartTime] = useState<string>(dayjs().subtract(1, 'day').unix().toString() ?? '');
    const [selectedWindowEndTime, setSelectedWindowEndTime] = useState<string>(dayjs().unix().toString() ?? '');
    const [selectedAggregationUnit, setSelectedAggregationUnit] = useState<HISTORICAL_DATA_AGGREGATION_UNIT>(
        HISTORICAL_DATA_AGGREGATION_UNIT.NONE
    );
    const [selectedMinutes, setSelectedMinutes] = useState<HISTORICAL_DATA_MINUTES>(HISTORICAL_DATA_MINUTES.THIRTY_MINUTES);

    useEffect(() => {
        if (clearFilter) {
            setSelectedFeederId(getBusBar());
            setSelectedFeederEarthId(getProtectionEarth());
            setSelectedLine(HISTORICAL_DATA_LINE.L1);
            setSelectedType(HISTORICAL_DATA_TYPE.MEAN);
            setSelectedTimeFrame(HISTORICAL_DATA_TIME_FRAME.TODAY);
            setSelectedAggregationUnit(HISTORICAL_DATA_AGGREGATION_UNIT.NONE);
        }
    }, [clearFilter]);

    useEffect(() => {
        if (onConfirmPressed) {
            onSaveGrafanaFilter(
                getFilters(
                    category,
                    selectedFeederId,
                    selectedFeederEarthId,
                    selectedLine,
                    selectedType,
                    selectedTimeFrame,
                    selectedWindowStartTime,
                    selectedWindowEndTime,
                    selectedAggregationUnit,
                    selectedMinutes
                )
            );
        }
    }, [onConfirmPressed]);

    const onFeederChange = useCallback(
        (value: number) => {
            if (category === CATEGORY.EARTH_SYSTEM) {
                setSelectedFeederEarthId(value);
            } else {
                setSelectedFeederId(value);
            }

            onFiltersChange();
        },
        [selectedFeederId, selectedFeederEarthId, category]
    );

    const onLineChange = useCallback(
        (value: HISTORICAL_DATA_LINE) => {
            setSelectedLine(value);
            onFiltersChange();
        },
        [selectedLine]
    );

    const onTypeChange = useCallback(
        (value: HISTORICAL_DATA_TYPE) => {
            setSelectedType(value);
            onFiltersChange();
        },
        [selectedType]
    );

    const onTimeFrameChange = useCallback(
        (value: HISTORICAL_DATA_TIME_FRAME) => {
            setSelectedTimeFrame(value);
            onFiltersChange();
        },
        [selectedTimeFrame]
    );

    const onStartTimeWindowChange = useCallback(
        (value: Dayjs | null) => {
            setSelectedWindowStartTime(value?.unix().toString() ?? '');
            setStartDate(value);
            onFiltersChange();
        },
        [selectedWindowStartTime]
    );

    const onEndTimeWindowChange = useCallback(
        (value: Dayjs | null) => {
            setSelectedWindowEndTime(value?.unix().toString() ?? '');
            setEndDate(value);
            onFiltersChange();
        },
        [selectedWindowEndTime]
    );

    const onAggregationUnitChange = useCallback(
        (value: HISTORICAL_DATA_AGGREGATION_UNIT) => {
            setSelectedAggregationUnit(value);
            onFiltersChange();
        },
        [selectedAggregationUnit]
    );

    const onMinutesChange = useCallback(
        (value: HISTORICAL_DATA_MINUTES) => {
            setSelectedMinutes(value);
            onFiltersChange();
        },
        [selectedMinutes]
    );

    const renderFeederFilter = useCallback(() => {
        if (category !== CATEGORY.NETWORK) {
            const feedersEarth = asset.feeders
                .filter((x) => x.feederType === FeederType.EARTH_PROTECTION_LINE || x.feederType === FeederType.EARTH_SERVICE_LINE)
                .sort((a, b) => a.id - b.id);

            const feedersNotEarth = asset.feeders
                .filter((feeder) => !feedersEarth.some((feederEarth) => feeder.id === feederEarth.id))
                .sort((a, b) => a.id - b.id);

            return (
                <Grid item key={'feeder'} sx={{ width: '100%', pl: 0 }} xs={2}>
                    <FeederFilter
                        feeders={category === CATEGORY.EARTH_SYSTEM ? feedersEarth : feedersNotEarth}
                        isEarthSystem={category === CATEGORY.EARTH_SYSTEM}
                        onChange={onFeederChange}
                        selectedFeederId={category === CATEGORY.EARTH_SYSTEM ? selectedFeederEarthId : selectedFeederId}
                    />
                </Grid>
            );
        } else return <></>;
    }, [category, selectedFeederId, selectedFeederEarthId]);

    const renderLineFilter = useCallback(() => {
        if ((category === CATEGORY.ENERGY || category === CATEGORY.POWER) && selectedFeederId)
            return (
                <Grid item key={'line'} sx={{ width: '100%', pl: 0 }} xs={2}>
                    <LineFilter
                        masterBusLines={asset.masterBusLines}
                        onChange={(value) => onLineChange(value)}
                        selectedLine={selectedLine}
                    />
                </Grid>
            );
        else return <></>;
    }, [category, selectedLine]);

    const renderTypeFilter = useCallback(() => {
        if ((category === CATEGORY.MEASURE && selectedFeederId) || category === CATEGORY.NETWORK) {
            return (
                <Grid item key={'type'} sx={{ width: '100%', pl: 0 }} xs={2}>
                    <TypeFilter onChange={(value) => onTypeChange(value)} selectedType={selectedType} />
                </Grid>
            );
        } else return <></>;
    }, [category, selectedType]);

    const renderWindowFilter = useCallback(() => {
        if (selectedTimeFrame === HISTORICAL_DATA_TIME_FRAME.WINDOW)
            return (
                <WindowFilter
                    endDate={endDate}
                    onEndWindowChange={(value) => onEndTimeWindowChange(value)}
                    onStartWindowChange={(value) => onStartTimeWindowChange(value)}
                    startDate={startDate}
                />
            );
        else return <></>;
    }, [selectedTimeFrame, startDate, endDate]);

    const renderMinutesFilter = useCallback(() => {
        if (selectedAggregationUnit === HISTORICAL_DATA_AGGREGATION_UNIT.MINUTES) {
            return (
                <Grid item key={'minutes'} sx={{ width: '100%', pl: 0 }} xs={2}>
                    <MinutesFilter onChange={(value) => onMinutesChange(value)} selectedMinutes={selectedMinutes} />
                </Grid>
            );
        } else return <></>;
    }, [selectedAggregationUnit, selectedMinutes]);

    return (
        <>
            {renderFeederFilter()}
            {renderLineFilter()}
            {renderTypeFilter()}
            <Grid item key={'timeframe'} sx={{ width: '100%', pl: 0 }} xs={2}>
                <TimeFrameFilter onChange={(value) => onTimeFrameChange(value)} selectedTimeFrame={selectedTimeFrame} />
            </Grid>
            {renderWindowFilter()}
            {category !== CATEGORY.EARTH_SYSTEM && (
                <Grid item key={'aggregation-unit'} sx={{ width: '100%', pl: 0 }} xs={2}>
                    <AggregationUnitFilter
                        onChange={(value) => onAggregationUnitChange(value)}
                        selectedAggregationUnit={selectedAggregationUnit}
                    />
                </Grid>
            )}
            {renderMinutesFilter()}
        </>
    );
}
