import CollapseCard from '_global/Components/base/cards/CollapseCard';
import { getData } from '_global/Services/api/networkRequester';
import { RootState } from '_global/Services/store';
import { userLanguageAtom } from '_global/Utils/hooks/jotai';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, CardContent, Tab, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { AssetInstallationIssues } from 'models/asset/AssetInstalationIssues';
import { AssetResponse } from 'models/asset/AssetResponse';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const InstalationIssues = ({ asset }: { asset: AssetResponse }) => {
    const features = useSelector((state: RootState) => state.features);
    const [installationIssues, setInstallationIssues] = useState<AssetInstallationIssues | undefined>(undefined);
    const [tabValue, setTabValue] = useState<string>('0');
    const [userLanguage] = useAtom(userLanguageAtom);

    const handleChangeTab = useCallback((event: SyntheticEvent, tab: number) => {
        setTabValue(tab.toString());
    }, []);

    useEffect(() => {
        getData<AssetInstallationIssues>(
            `${features.configuration?.BaseUrl ?? ''}/api/general_assessment?lang=${userLanguage ?? 'en-GB'}&asset_id=${asset.id}`
        )
            .then((data) => {
                setInstallationIssues(data);
            })
            .catch((e) => {
                setInstallationIssues(undefined);
            });
    }, [asset]);

    return (
        <CollapseCard
            startExpanded={installationIssues?.installation_problems.any ?? true}
            subtitle={
                <Typography sx={{ width: '100%' }}>
                    <FormattedMessage id={'latest_verification'} />:{' '}
                    {installationIssues?.date ? new Date(installationIssues.date).toLocaleString() : '---'}
                </Typography>
            }
            title={
                <Typography sx={{ width: '100%', fontSize: '1.125rem', color: 'black', fontWeight: 500 }}>
                    <FormattedMessage id={'installation_issues'} />
                </Typography>
            }
        >
            <TabContext value={tabValue}>
                <TabList onChange={handleChangeTab} scrollButtons="auto" value={tabValue.toString()} variant="scrollable">
                    {installationIssues?.installation_problems.feeders.map((feeder, index) => (
                        <Tab
                            key={`${feeder.feeder_name}${index}`}
                            label={feeder.feeder_name.length < 20 ? feeder.feeder_name : `${feeder.feeder_name.substring(0, 17)}...`}
                            value={index.toString()}
                        />
                    ))}
                </TabList>
                {installationIssues?.installation_problems.feeders.map((feeder, index) => (
                    <TabPanel key={feeder.feeder_number} value={index.toString()}>
                        <Card elevation={2} sx={{ minWidth: 275 }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                {feeder.messages.map((msg) => (
                                    <Box key={msg} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <FontAwesomeIcon color="#d71e1d" icon={faInfoCircle} opacity="100%" size="xl" />
                                        <Typography>{msg}</Typography>
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                        <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, mt: 4 }}>
                            <FontAwesomeIcon color="#218be1" icon={faInfoCircle} opacity="100%" size="sm" />
                            <FormattedMessage id={'instalation_issues_info'} />
                        </Box>
                    </TabPanel>
                ))}
            </TabContext>
        </CollapseCard>
    );
};

export default InstalationIssues;
