import { TypeColumn, TypeComputedProps, TypeDataSource, TypeSortInfo } from '@inovua/reactdatagrid-community/types';
import { DeviceConfigurationProfileResponse } from 'models/device/DeviceConfigurationProfile';
import { BulkConfigurationLayout } from 'models/layout/BulkConfiguration';
import { SortInfoType } from 'models/sortInfo';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { IValidations } from 'views/Users/utils/UsersModels';

export interface IConfigurationProfile {
    bulkConfiguration: BulkConfigurationLayout;
    handleChange?: (newValue: string, id: string) => void;
    handleChangeBool?: (newValue: boolean, id: string) => void;
    handleChangeNumber?: (newValue: number | null, id: string) => void;
    isDisabled: boolean;
    isDefault?: boolean;
    isEarth?: boolean;
}

export interface IConfigurationProfileDetails {
    selectedBulkConfigId?: number;
}

export enum TabValue {
    Global = 0,
    Earth = 1,
    Ingrid = 2
}

export type IConfigurationProfileGrid = {
    data: TypeDataSource;
    loading: boolean;
    columns: TypeColumn[];
    onRowPressed: (bulk: DeviceConfigurationProfileResponse) => void;
    setGridRef: Dispatch<SetStateAction<((gridApiRef: MutableRefObject<TypeComputedProps | null>) => void) | undefined>>;
    sortInfo: SortInfoType;
    sortInfoC: TypeSortInfo;
};

export const defaultValidationConfigurationProfile = [
    { name: 'name', error: false, message: '' },
    { name: 'aquisitionRateEarth', error: false, message: '' },
    { name: 'communicationRateEarth', error: false, message: '' },
    { name: 'aquisitionRate', error: false, message: '' },
    { name: 'communicationRate', error: false, message: '' },
    { name: 'bluetoothTimeout', error: false, message: '' },
    { name: 'serverUrl', error: false, message: '' },
    { name: 'timeServerPort', error: false, message: '' },
    { name: 'timeServerProtocol', error: false, message: '' },
    { name: 'timeServerUrl', error: false, message: '' },
    { name: 'timeServerType', error: false, message: '' },
    { name: 'protectionEarthFrequencyF1', error: false, message: '' },
    { name: 'protectionEarthFrequencyF2', error: false, message: '' },
    { name: 'protectionEarthFrequencyF3', error: false, message: '' },
    { name: 'serviceEarthFrequencyF1', error: false, message: '' },
    { name: 'serviceEarthFrequencyF2', error: false, message: '' },
    { name: 'serviceEarthFrequencyF3', error: false, message: '' },
    { name: 'dnsServerName1', error: false, message: '' },
    { name: 'proxyUrl', error: false, message: '' },
    { name: 'proxyPort', error: false, message: '' }
] as IValidations[];

export const MIN_VALUE = 1000;
export const MAX_VALUE = 3000;
