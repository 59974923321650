import MapImageComponent from '_global/Components/base/map/MapImageComponent';
import { COLORS } from '_global/Utils/Colors';
import { modeAtomAssets, validationSetAssets } from '_global/Utils/hooks/jotai';
import { IconToNode } from '_global/Utils/IconToNode';
import { faArrowUpRightFromSquare, faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { Autocomplete, Button, Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, TextField } from '@mui/material';
import { useAtom } from 'jotai';
import { FeederType } from 'models/asset/AssetModel';
import Mode from 'models/enums/ModeEnum';
import { OperationalArea } from 'models/layout/OperationalArea';
import { RegionList_regions_nodes_operationalAreas } from 'queries/Types/RegionList';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { InfoDisplayAddProps } from 'views/Assets/utils/AssetModel';
import GeoProcessor from 'views/Settings/GeoProcessor';

import AssetTypeSettings from './AssetTypeSettings';

function InfoDisplay(props: InfoDisplayAddProps) {
    const {
        assetDetails,
        assetInfoData,
        handleChangeNumber,
        handleOnChange,
        handleChange,
        isOpen,
        setIsOpen,
        handleChangeAssetType,
        opAreas,
        handleChangeBool
    } = props;
    const intl = useIntl();
    const navigate = useNavigate();
    const [mode] = useAtom(modeAtomAssets);
    const [hasError] = useAtom(validationSetAssets);

    const handleChangeOpArea = useCallback(
        (newValue: RegionList_regions_nodes_operationalAreas) => {
            handleChangeNumber && handleChangeNumber(newValue.id as number, 'operationalAreaId');
            handleChange && handleChange(newValue.name, 'operationalAreaName');

            const opAreaSelected = opAreas.find((opArea) => opArea && opArea.id !== 1 && opArea.id === newValue.id) as OperationalArea;
            const centerPoint = GeoProcessor.centerPoint(opAreaSelected);

            if (centerPoint) {
                handleChangeNumber && handleChangeNumber(centerPoint.geometry.coordinates[0], 'latitude');
                handleChangeNumber && handleChangeNumber(centerPoint.geometry.coordinates[1], 'longitude');
            }
        },
        [assetDetails.operationalAreaId, assetInfoData]
    );

    return (
        <Grid columnSpacing={3} container sx={{ px: 3, py: 2 }}>
            <Grid item sm={6} sx={{ mt: 0.5 }} xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <AssetTypeSettings
                            {...{ assetDetails, assetInfoData, mode, handleChangeNumber, handleChange, handleChangeAssetType }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={6}>
                        <TextField
                            disabled={mode !== Mode.EDIT && mode !== Mode.ADD}
                            error={!!hasError[0].hasError}
                            fullWidth
                            label={<FormattedMessage id={'name'} />}
                            onChange={(event) => {
                                handleOnChange && handleOnChange(event, 'name');
                            }}
                            required={true}
                            value={assetDetails.name ?? ''}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            disabled={mode !== Mode.EDIT && mode !== Mode.ADD}
                            error={!!hasError[2].hasError}
                            fullWidth
                            label={<FormattedMessage id={'registry_number'} />}
                            onChange={(event) => {
                                handleOnChange && handleOnChange(event, 'registryNumber');
                            }}
                            required={true}
                            value={assetDetails.registryNumber ?? ''}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={6}>
                        {mode === Mode.PREVIEW ? (
                            <TextField
                                InputProps={{
                                    startAdornment: assetDetails?.regionId !== 0 && assetDetails?.regionId !== 1 && (
                                        <InputAdornment
                                            onClick={() => {
                                                if (assetDetails.regionId) navigate(`/regions/${assetDetails?.regionId}`);
                                            }}
                                            position="start"
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <IconToNode definition={faArrowUpRightFromSquare} />
                                        </InputAdornment>
                                    )
                                }}
                                disabled={true}
                                fullWidth
                                label={<FormattedMessage id={'region'} />}
                                value={assetDetails.regionName ?? ''}
                                variant="outlined"
                            />
                        ) : (
                            <Autocomplete
                                disableClearable
                                disabled={mode !== Mode.EDIT && mode !== Mode.ADD}
                                getOptionLabel={(option) => {
                                    return option?.name ? option?.name : '';
                                }}
                                isOptionEqualToValue={(option, val) => {
                                    if (val?.name === '') {
                                        return true;
                                    }

                                    return option?.name.toString() === val?.name.toString();
                                }}
                                onChange={(event, newValue) => {
                                    if (handleChangeNumber) {
                                        handleChangeNumber(newValue.id as number, 'regionId');
                                        handleChangeNumber(0, 'operationalAreaId');
                                        handleChangeNumber(40, 'latitude');
                                        handleChangeNumber(-8, 'longitude');
                                    }
                                    if (handleChange) {
                                        handleChange(newValue.name, 'regionName');
                                        handleChange('', 'operationalAreaName');
                                    }
                                }}
                                options={assetInfoData.optionsRegions ?? []}
                                renderInput={(params) => (
                                    <TextField
                                        error={!!hasError[5].hasError}
                                        required={true}
                                        {...params}
                                        label={<FormattedMessage id={'region'} />}
                                    />
                                )}
                                value={
                                    assetInfoData.optionsRegions?.find((region) => {
                                        return region?.name === assetDetails.regionName;
                                    }) ?? {
                                        __typename: 'Region',
                                        code: '',
                                        companyId: '',
                                        gpsPoints: [],
                                        id: '',
                                        name: '',
                                        operationalAreas: []
                                    }
                                }
                            />
                        )}
                    </Grid>

                    <Grid item xs={6}>
                        {mode === Mode.PREVIEW ? (
                            <TextField
                                InputProps={{
                                    startAdornment: assetDetails?.operationalAreaId !== 0 && assetDetails?.operationalAreaId !== 1 && (
                                        <InputAdornment
                                            onClick={() => {
                                                if (assetDetails.operationalAreaId) navigate(`/opareas/${assetDetails?.operationalAreaId}`);
                                            }}
                                            position="start"
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <IconToNode definition={faArrowUpRightFromSquare} />
                                        </InputAdornment>
                                    )
                                }}
                                disabled={true}
                                fullWidth
                                label={<FormattedMessage id={'operational_area'} />}
                                value={assetDetails.operationalAreaName ?? ''}
                                variant="outlined"
                            />
                        ) : (
                            <Autocomplete
                                disableClearable
                                disabled={!(mode === Mode.ADD || mode === Mode.EDIT) || assetDetails.regionName === ''}
                                getOptionLabel={(option) => {
                                    return option?.name ?? '';
                                }}
                                isOptionEqualToValue={(option, val) => {
                                    if (val?.name === '') {
                                        return true;
                                    }

                                    return option?.name === val?.name;
                                }}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        handleChangeOpArea(newValue);
                                    }
                                }}
                                options={assetInfoData.optionsOpArea ?? []}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!hasError[6].hasError}
                                        label={<FormattedMessage id={'operational_area'} />}
                                        required={true}
                                    />
                                )}
                                sx={{ maxHeight: '51px' }}
                                value={
                                    (assetInfoData.optionsOpArea as RegionList_regions_nodes_operationalAreas[])?.find((opArea) => {
                                        return opArea?.name === assetDetails.operationalAreaName;
                                    }) ?? { __typename: 'OperationalArea', code: '', gpsPoints: [], id: 0, name: '', regionId: 0 }
                                }
                            />
                        )}
                    </Grid>
                </Grid>
                {mode === Mode.PREVIEW && (
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={6}>
                            <TextField
                                autoComplete="off"
                                disabled
                                fullWidth
                                label={<FormattedMessage id={'subs_lat'} />}
                                onChange={(event) => {
                                    handleOnChange && handleOnChange(event, 'latitude');
                                }}
                                required={true}
                                value={assetDetails.latitude !== 0 ? assetDetails.latitude : ''}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                autoComplete="off"
                                disabled
                                fullWidth
                                label={<FormattedMessage id={'longitude'} />}
                                onChange={(event) => {
                                    handleOnChange && handleOnChange(event, 'longitude');
                                }}
                                required={true}
                                value={assetDetails.longitude !== 0 ? assetDetails.longitude : ''}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            disabled={mode !== Mode.EDIT && mode !== Mode.ADD}
                            fullWidth
                            label={<FormattedMessage id={'description'} />}
                            multiline
                            onChange={(event) => {
                                handleOnChange && handleOnChange(event, 'description');
                            }}
                            rows={8}
                            value={assetDetails?.description ?? ''}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={assetDetails.hasEarth}
                                        disabled={
                                            mode !== Mode.ADD || !!assetDetails.feeders.find((x) => x.feederType === FeederType.BUSBAR)
                                        }
                                        onClick={() => handleChangeBool && handleChangeBool(!assetDetails.hasEarth, 'hasEarth')}
                                        sx={{
                                            '&.Mui-checked': {
                                                color: COLORS.ENEIDA_ALTERNATIVE_BLUE
                                            }
                                        }}
                                    />
                                }
                                label={<FormattedMessage id={'hasEarth'} />}
                                labelPlacement="start"
                                sx={{ m: 0 }}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={6} sx={{ mt: !(mode === Mode.PREVIEW) ? 1 : 0 }} xs={12}>
                {!(mode === Mode.PREVIEW) ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="off"
                                disabled
                                error={!!hasError[3].hasError}
                                fullWidth
                                label={<FormattedMessage id={'latitude'} />}
                                required
                                type={'number'}
                                value={assetDetails.latitude}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item sx={{ mt: 1 }} xs={12}>
                            <TextField
                                disabled
                                error={!!hasError[4].hasError}
                                fullWidth
                                label={<FormattedMessage id={'longitude'} />}
                                required
                                type={'number'}
                                value={assetDetails.longitude}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item sx={{ minHeight: '67px', display: 'flex', alignItems: 'center', mt: 1 }} xs={12}>
                            <Button
                                color="secondary"
                                disabled={assetDetails.operationalAreaId === 0 || assetDetails.operationalAreaId === 1}
                                fullWidth
                                onClick={() => setIsOpen(!isOpen)}
                                startIcon={<IconToNode definition={faLocationDot} size={'xs'} />}
                                sx={{ height: '40px' }}
                                variant="contained"
                            >
                                {intl.formatMessage({ id: 'choose_map' })}
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <MapImageComponent latitude={assetDetails.latitude} longitude={assetDetails.longitude} />
                )}
            </Grid>
        </Grid>
    );
}

export default InfoDisplay;
