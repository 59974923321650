import CollapseCard from '_global/Components/base/cards/CollapseCard';
import { getData } from '_global/Services/api/networkRequester';
import { RootState } from '_global/Services/store';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { CircularProgress, Divider, Grid, Tab, Table, TableCell, TableContainer } from '@mui/material';
import { Box } from '@mui/system';
import CsvExporter from 'logic/csv/CsvExporter';
import { NewSimpleLastData } from 'models/dataStorage/NewSimpleLastData';
import Papa from 'papaparse';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ILastDataStatusRequest } from 'views/Assets/utils/AssetModel';

import { LastDataTabValue } from '../overview/lastdata/LastDataPreview';
import LastDataTableHeadBusbar from './LastDataTableHeadBusbar';
import LastDataTableHeadFeeders from './LastDataTableHeadFeeders';
import LastDataTableRow from './LastDataTableRow';
import LastDataTitle from './LastDataTitle';
import ProtectionEarthTableRow from './ProtectionEarthTableRow';

export default function LastDataTable(props: { id: number }) {
    const { id } = props;
    const [isLoading, setIsLoading] = useState(false);

    const features = useSelector((state: RootState) => state.features);
    const [lastDataResponse, setLastDataResponse] = useState<NewSimpleLastData>();
    const [statusRequest, setStatusRequest] = useState<ILastDataStatusRequest>({ global: false, earth: false });

    const [tabValue, setTabValue] = useState<string>(LastDataTabValue.GLOBAL.toString());

    const fetchRequestGlobal = useCallback(() => {
        setIsLoading(true);
        const queryURL = '/datastorage/api/Substation/NewLastData?substationId';
        const endpoint = `${features.configuration?.BaseUrl ?? ''}${queryURL}=${id}`;
        getData<NewSimpleLastData>(endpoint)
            .then((data) => {
                data && setStatusRequest({ ...statusRequest, global: data.supervisedLvPower, earth: data.hasEarth });
                setLastDataResponse(data);
                setIsLoading(false);
            })
            .catch((e) => {
                console.error(e);
                setStatusRequest({ ...statusRequest, global: false, earth: false });
                setIsLoading(false);
            });
    }, [props]);

    useEffect(() => {
        fetchRequestGlobal();
    }, [props]);

    useEffect(() => {
        if (!statusRequest.global && statusRequest.earth) {
            setTabValue(LastDataTabValue.EARTH.toString());
        } else {
            setTabValue(LastDataTabValue.GLOBAL.toString());
        }
    }, [statusRequest]);

    const handleRefresh = useCallback(() => {
        setIsLoading(true);
        fetchRequestGlobal();
    }, [lastDataResponse]);

    const handleExportData = useCallback(() => {
        if (!lastDataResponse) return;

        const str = Papa.unparse(lastDataResponse.feeders);
        const bytes = new TextEncoder().encode(str);
        const blob = new Blob([bytes], {
            type: 'text/csv;charset=utf-8;'
        });
        CsvExporter.exportBlobToCSV(blob);
    }, [lastDataResponse]);

    const noData = useMemo(() => {
        return (
            <Grid alignItems="center" container direction="column" justifyContent="center">
                <Grid item sx={{ mt: '8%', mb: '6%' }}>
                    <FormattedMessage id={'no-data'} />
                </Grid>
            </Grid>
        );
    }, []);

    const handleChangeTab = useCallback((event: SyntheticEvent, tab: LastDataTabValue) => {
        setTabValue(tab.toString());
    }, []);

    return (
        <Box sx={{ p: 2 }}>
            <TabContext value={tabValue.toString()}>
                <TabList onChange={handleChangeTab} value={tabValue.toString()}>
                    <Tab label={<FormattedMessage id={'global'} />} value={LastDataTabValue.GLOBAL.toString()} />
                    {lastDataResponse?.hasEarth && (
                        <Tab label={<FormattedMessage id={'earth'} />} value={LastDataTabValue.EARTH.toString()} />
                    )}
                </TabList>
                <TabPanel value={LastDataTabValue.GLOBAL.toString()}>
                    <>
                        <Box sx={{ mt: 2 }}>
                            {lastDataResponse && (
                                <LastDataTitle
                                    lastData={lastDataResponse}
                                    onExportData={handleExportData}
                                    onRefresh={handleRefresh}
                                    title={'last-data'}
                                />
                            )}
                        </Box>

                        {!isLoading ? (
                            lastDataResponse?.supervisedLvPower ? (
                                <>
                                    <CollapseCard
                                        startExpanded={true}
                                        subtitle={
                                            lastDataResponse?.busbar.date ? new Date(lastDataResponse?.busbar.date).toLocaleString() : '---'
                                        }
                                        sx={{ mb: 2 }}
                                        title={'Busbar'}
                                    >
                                        <TableContainer sx={{ px: 2 }}>
                                            <Table sx={{ minWidth: 750 }}>
                                                <LastDataTableHeadBusbar />
                                                {lastDataResponse?.busbar.lines.map((line) => (
                                                    <LastDataTableRow applyFilter={false} key={line.lineName} lastData={line} />
                                                ))}
                                            </Table>
                                        </TableContainer>
                                    </CollapseCard>
                                    <Divider sx={{ my: 4 }} />
                                    {lastDataResponse.feeders.map((feeder, index: number) => {
                                        return (
                                            <CollapseCard
                                                key={index}
                                                startExpanded={true}
                                                subtitle={feeder.date ? new Date(feeder.date).toLocaleString() : '---'}
                                                sx={{ mb: 2 }}
                                                title={feeder.feederName}
                                            >
                                                <TableContainer sx={{ px: 2 }}>
                                                    <Table sx={{ minWidth: 750 }}>
                                                        <LastDataTableHeadFeeders />
                                                        {feeder.lines.map((line) => (
                                                            <LastDataTableRow applyFilter={true} key={index} lastData={line} />
                                                        ))}
                                                    </Table>
                                                </TableContainer>
                                            </CollapseCard>
                                        );
                                    })}
                                </>
                            ) : (
                                noData
                            )
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                                <CircularProgress />
                            </Box>
                        )}
                    </>
                </TabPanel>
                {lastDataResponse?.hasEarth && (
                    <TabPanel value={LastDataTabValue.EARTH.toString()}>
                        <>
                            <Box sx={{ mt: 2 }}>
                                <LastDataTitle onExportData={handleExportData} onRefresh={handleRefresh} title={'last-data'} />
                            </Box>

                            {!isLoading ? (
                                <>
                                    <CollapseCard
                                        key={lastDataResponse?.serviceEarth.feederName}
                                        startExpanded={true}
                                        subtitle={
                                            lastDataResponse?.serviceEarth.date
                                                ? new Date(lastDataResponse?.serviceEarth.date).toLocaleString()
                                                : '---'
                                        }
                                        sx={{ mb: 2 }}
                                        title={<FormattedMessage id={lastDataResponse?.serviceEarth.feederName ?? 'id'} />}
                                    >
                                        <TableContainer sx={{ px: 2 }}>
                                            <Table sx={{ minWidth: 750 }}>
                                                {lastDataResponse?.hasServiceEarthData ? (
                                                    <ProtectionEarthTableRow lastData={lastDataResponse.serviceEarth} />
                                                ) : (
                                                    <TableCell> {noData}</TableCell>
                                                )}
                                            </Table>
                                        </TableContainer>
                                    </CollapseCard>

                                    <CollapseCard
                                        key={lastDataResponse?.protectionEarth.feederName}
                                        startExpanded={true}
                                        subtitle={
                                            lastDataResponse?.protectionEarth.date
                                                ? new Date(lastDataResponse?.protectionEarth.date).toLocaleString()
                                                : '---'
                                        }
                                        sx={{ mb: 2 }}
                                        title={<FormattedMessage id={lastDataResponse?.protectionEarth.feederName ?? 'id'} />}
                                    >
                                        <TableContainer sx={{ px: 2 }}>
                                            <Table sx={{ minWidth: 750 }}>
                                                {lastDataResponse?.hasProtectionEarthData ? (
                                                    <ProtectionEarthTableRow lastData={lastDataResponse.protectionEarth} />
                                                ) : (
                                                    <TableCell> {noData}</TableCell>
                                                )}
                                            </Table>
                                        </TableContainer>
                                    </CollapseCard>
                                </>
                            ) : (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                                    <CircularProgress />
                                </Box>
                            )}
                        </>
                    </TabPanel>
                )}
            </TabContext>
        </Box>
    );
}
