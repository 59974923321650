import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { HISTORICAL_DATA_TIME_FRAME } from 'models/asset/AssetHistoricalData';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export default function TimeFrameFilter(props: {
    onChange: (value: HISTORICAL_DATA_TIME_FRAME) => void;
    selectedTimeFrame: HISTORICAL_DATA_TIME_FRAME;
}) {
    const { onChange, selectedTimeFrame } = props;

    const onTimeFrameChange = useCallback(
        (event: SelectChangeEvent<HISTORICAL_DATA_TIME_FRAME>) => {
            onChange(event.target.value as HISTORICAL_DATA_TIME_FRAME);
        },
        [selectedTimeFrame]
    );

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="timeframe">
                <FormattedMessage id={'timeframe'} />
            </InputLabel>
            <Select
                id="timeframe"
                value={selectedTimeFrame}
                onChange={(e) => onTimeFrameChange(e)}
                label={<FormattedMessage id={'timeframe'} />}
            >
                {Object.values(HISTORICAL_DATA_TIME_FRAME).map((value, index) => {
                    return (
                        <MenuItem key={index} value={value}>
                            <FormattedMessage id={value} />
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
