import MainCard from '_global/Components/base/cards/MainCard';
import { defaultRowStyle } from '_global/Components/base/grids/RowUtils';
import { addGridsPaginations, Pagination } from '_global/Services/store/gridsPaginationSlice';
import { useAppDispatch, useAppSelector } from '_global/Services/store/hooks';
import gridsPaginationsUpdateGrid from '_global/Utils/gridsPaginations';
import { useQuery } from '@apollo/client';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeDataSource, TypePaginationProps, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-community/types';
import { Box, Grid, Typography } from '@mui/material';
import { assetToRow } from 'models/asset/AssetResponse';
import { IReportDetails } from 'models/report/ReportResponse';
import { ASSETS } from 'queries/Assets';
import { Assets } from 'queries/Types/Assets';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { default_columns_assets } from 'views/Assets/grid/AssetsGrid';

const gridStyle = {
    flexGrow: 1,
    border: 'none',
    width: '100%',
    height: '100%'
};

export interface PreviewTabProps {
    report: IReportDetails;
}

export const DetailsTab = ({ report }: PreviewTabProps) => {
    const { data: assetsData, loading, fetchMore } = useQuery<Assets>(ASSETS);
    const [flagRefetch, setFlagRefetch] = useState<Record<string, Record<string, number[]>> | null>(null);

    const nodes = useMemo(() => assetsData?.assets?.nodes, [assetsData?.assets?.nodes]);

    const pageInfo = useMemo(() => assetsData?.assets?.pageInfo, [assetsData?.assets?.pageInfo]);

    const location = useLocation();
    const dispatch = useAppDispatch();
    const [gridSkip, setGridSkip] = useState<number | undefined>(undefined);
    const [gridSortInfo, setGridSortInfo] = useState<TypeSingleSortInfo | undefined>(undefined);
    const [gridColumnOrder, setGridColumnOrder] = useState<string[] | undefined>(undefined);
    const [gridLimit, setGridLimit] = useState<number | undefined>(undefined);
    const gridsPaginations = useAppSelector((state) => state.gridsPaginations.gridsPaginations);
    const firstRenderRef = useRef(true);

    useEffect(() => {
        gridsPaginationsUpdateGrid({
            gridName: 'DetailsTabGrid',
            gridsPaginations,
            columns: default_columns_assets,
            setGridSkip,
            setGridSortInfo,
            setGridColumnOrder,
            setGridLimit,
            location
        });
    }, [gridsPaginations]);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
        } else {
            dispatch(
                addGridsPaginations({
                    url: location.pathname,
                    grid: { skip: gridSkip, sortInfo: gridSortInfo, columnOrder: gridColumnOrder, limit: gridLimit },
                    key: `DetailsTabGrid`
                })
            );
        }
    }, [gridSkip, gridSortInfo, gridColumnOrder, gridLimit]);

    const loadData: TypeDataSource = useCallback(
        async (props: TypePaginationProps) => {
            const response = await fetchMore({
                variables: {
                    first: props.skip + props.limit,
                    filter: flagRefetch ?? null
                }
            });

            const assets = response?.data?.assets?.nodes?.map((asset) => assetToRow(asset)) ?? [];
            return {
                data: assets.slice(props.skip, assets.length),
                count: response.data.assets?.totalCount ?? 0
            };
        },
        [pageInfo, pageInfo?.endCursor, nodes, flagRefetch]
    );
    useEffect(() => {
        if (report.equipments.length > 0) {
            setFlagRefetch({ id: { in: report.equipments } });
        }
    }, [report]);

    return (
        <>
            <MainCard className="main-card" content={false} title={<FormattedMessage id={'report_details'} />}>
                <Grid container spacing={2} sx={{ px: 2, pb: 4, pt: 2 }}>
                    <Grid item sm={6} xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                <FormattedMessage id="report_name" />:
                            </Typography>
                            <Typography>{report?.name}</Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                <FormattedMessage id="report_type" />:
                            </Typography>
                            <Typography>{report?.report_type ? report.title : ''}</Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                <FormattedMessage id="period_start" />:
                            </Typography>
                            <Typography>
                                {new Date(report?.period_start as string).toLocaleString([], {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric'
                                })}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                <FormattedMessage id="period_end" />:
                            </Typography>
                            <Typography>
                                {new Date(report?.period_end as string).toLocaleString([], {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric'
                                })}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                <FormattedMessage id="requested_by" />:
                            </Typography>
                            <Typography>{report?.requested_by}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                <FormattedMessage id="description" />:
                            </Typography>
                            <Typography>{report?.description}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
            {report.equipments.length > 0 && (
                <MainCard className="main-card" content={false} sx={{ mt: 2 }} title={<FormattedMessage id={'assets'} />}>
                    <Box sx={{ m: 2, height: 400 }}>
                        <ReactDataGrid
                            columnOrder={gridColumnOrder}
                            columns={default_columns_assets}
                            dataSource={loadData}
                            idProperty="id"
                            limit={gridLimit ? gridLimit : 10}
                            loading={loading}
                            onColumnOrderChange={(columnOrder) => {
                                setGridColumnOrder(columnOrder);
                            }}
                            onLimitChange={(limit) => {
                                setGridLimit(limit);
                            }}
                            onSkipChange={(skip) => {
                                setGridSkip(skip);
                            }}
                            onSortInfoChange={(column: TypeSortInfo) => {
                                setGridSortInfo(column as TypeSingleSortInfo);
                            }}
                            pagination={true}
                            rowHeight={50}
                            rowStyle={defaultRowStyle}
                            showCellBorders={'horizontal'}
                            showColumnMenuTool={false}
                            showZebraRows={false}
                            skip={gridSkip}
                            sortInfo={gridSortInfo}
                            style={gridStyle}
                        />
                    </Box>
                </MainCard>
            )}
        </>
    );
};
