import { ASSET_STATE, ASSET_TYPE } from 'models/graphql-global-types';
import { AssetsInstance } from 'queries/Assets';

export interface AssetResponse {
    id: number;
    registryNumber: string | null;
    name: string | null;
    parentAssetName: string | null;
    parentFeederName: string | null;
    assetType: ASSET_TYPE;
    longitude: number | null;
    latitude: number | null;
    operationalArea: string | undefined;
    device: string | undefined;
    region: string | undefined;
    state: ASSET_STATE;
    alarmStandardName: string | null;
    description: string | null;
    createdDate: Date | null;
    lastModifiedDate: Date | null;
    isEnabled: boolean;
}

export function assetToRow(asset: AssetsInstance): AssetResponse {
    return {
        id: Number(asset.id),
        registryNumber: asset.registryNumber,
        name: String(asset.name),
        parentAssetName: asset?.parentAssetName,
        parentFeederName: asset?.parentFeederName,
        assetType: asset.assetType,
        longitude: asset?.longitude,
        latitude: asset?.latitude,
        operationalArea: asset?.operationalArea?.name,
        device: asset.devices?.[0]?.serialNumber,
        region: asset?.region?.name,
        state: asset.state,
        alarmStandardName: asset?.alarmStandardName,
        description: asset?.description,
        createdDate: new Date(String(asset.createdDate)),
        lastModifiedDate: new Date(String(asset.lastModifiedDate)),
        isEnabled: asset.isEnabled
    };
}
