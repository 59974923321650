import { RootState } from '_global/Services/store';
import { hasLastDataSet } from '_global/Utils/hooks/jotai';
import {
    Alert,
    Box,
    Divider,
    FormControl,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    styled,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useAtom } from 'jotai';
import { GraphPanel, GraphTag, GraphType, GraphTypeVisualization } from 'models/enums/GlobalState';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import GrafanaHolder from '../../../../_global/Components/base/grafana/GrafanaHolder';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',

    ...theme.typography.body2,

    padding: theme.spacing(1),

    textAlign: 'center',

    color: theme.palette.text.secondary
}));

export default function GlobalStateGrid() {
    const features = useSelector((state: RootState) => state.features);

    const [hasLastData] = useAtom(hasLastDataSet);

    const baseURL = features.configuration?.BaseUrl ?? '';
    const grafana = features.configuration?.Grafana.url ?? '';
    const grafanaURL = '/d/FKfdiXm4k/substation-gs?orgId=1&kiosk&theme=light';

    const [graphType, setGraphType] = useState([
        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_CURRENT_IMBALANCE_HOUR}`,
        `${GraphTag.VIEW_PANEL}${GraphPanel.FEEDER_CURRENT_IMBALANCE_HOUR}`
    ]);
    const [graphTypeVisibility, setGraphTypeVisibility] = useState(0);
    const [graphName, setGraphName] = useState(0);

    const renderGrafanaGraph = () => {
        if (graphType.length !== 0) {
            return graphType.map((graph) => {
                const url = `${baseURL}${grafana}${grafanaURL}${graph}`;

                return <GrafanaHolder key={graph} url={url} />;
            });
        } else {
            return (
                <Alert severity="info">
                    <FormattedMessage id="noData" />
                </Alert>
            );
        }
    };

    const switchGraphs = (graphType: GraphType): string[] => {
        switch (graphType) {
            case GraphType.CURRENT_IMBALANCE:
                if (graphTypeVisibility === 0) {
                    return [
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_CURRENT_IMBALANCE_HOUR}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.FEEDER_CURRENT_IMBALANCE_HOUR}`
                    ];
                } else {
                    return [
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_CURRENT_IMBALANCE_PERCENTAGE}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.FEEDER_CURRENT_IMBALANCE_PERCENTAGE}`
                    ];
                }
            case GraphType.NEUTRAL_CURRENT:
                if (graphTypeVisibility === 0) {
                    return [
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_CURRENT_VECTORS}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_CURRENT_VECTORS_MAGNITUDES_HOUR}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.FEEDER_CURRENT_VECTORS}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.FEEDER_CURRENT_VECTORS_MAGNITUDES_HOUR}`
                    ];
                } else {
                    return [
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_CURRENT_VECTORS_MAGNITUDES_NEUTRAL_MAGNITUDE}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.FEEDER_CURRENT_VECTORS_MAGNITUDES_NEUTRAL_MAGNITUDE}`
                    ];
                }
            case GraphType.CONGESTION_VISIBILITY:
                if (graphTypeVisibility === 0) {
                    return [
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_POWER_OCCUPATION_HOUR}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_ACTIVE_POWER_HOUR_ACTIVE_POWER}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_REACTIVE_POWER_HOUR_REACTIVE_POWER}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_POWER_OCCUPATION_FORECAST}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.FEEDER_PHASE_OVERCURRENT_FORECAST}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.FEEDER_PHASE_CURRENT_OCCUPATION}`
                    ];
                } else {
                    return [
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_POWER_OCCUPATION_PERCENTAGE}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_ACTIVE_POWER}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_REACTIVE_HOUR}`
                    ];
                }
            case GraphType.VOLTAGE:
                if (graphTypeVisibility === 0) {
                    return [
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_VOLTAGE_IMBALANCE_HOUR}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_PHASE_VOLTAGE_VECTOR_SUM_MAGNITUDE_HOUR}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.L1_PHASE_VOLTAGE_HOUR}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_VOLTAGE_VECTORS}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.FEEDER_VOLTAGE_IMBALANCE_HOUR}`
                    ];
                } else {
                    return [
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_VOLTAGE_IMBALANCE_VOLTAGE_IMBALANCE}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.SUBSTATION_PHASE_VOLTAGE_VECTOR_SUM_MAGNITUDE_VOLTAGE}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.L1_PHASE_VOLTAGE_VOLTAGE}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.FEEDER_VOLTAGE_IMBALANCE_VOLTAGE_IMBALANCE}`
                    ];
                }
            case GraphType.THD:
                if (graphTypeVisibility === 0) {
                    return [
                        `${GraphTag.VIEW_PANEL}${GraphPanel.L1_PHASE_VOLTAGE_THD_HOUR}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.L1_PHASE_CURRENT_THD_HOUR}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.L1_FEEDER_PHASE_CURRENT_THD_HOUR}`
                    ];
                } else {
                    return [
                        `${GraphTag.VIEW_PANEL}${GraphPanel.L1_PHASE_VOLTAGE_THD_CURRENT_THD}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.L1_PHASE_CURRENT_THD_CURRENT_THD}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.L1_FEEDER_PHASE_CURRENT_THD_CURRENT_THD}`
                    ];
                }
            case GraphType.PV_INJECTION:
                if (graphTypeVisibility === 0) {
                    return [
                        `${GraphTag.VIEW_PANEL}${GraphPanel.L1_PHASE_ACTIVE_POWER_HOUR}`,
                        `${GraphTag.VIEW_PANEL}${GraphPanel.L1_FEEDER_PHASE_ACTIVE_POWER_HOUR}`
                    ];
                } else {
                    return [];
                }
            default:
                return [''];
        }
    };

    const handleGraphTypeChange = useCallback(
        (event: SelectChangeEvent) => {
            setGraphType(switchGraphs(parseInt(event.target.value)));
            setGraphName(parseInt(event.target.value));
        },
        [graphType]
    );

    const handleChange = useCallback(
        (event: React.MouseEvent<HTMLElement>, graphType: number) => {
            if (graphType === null) {
                setGraphTypeVisibility(graphTypeVisibility);
            } else if (graphType != graphTypeVisibility) {
                setGraphTypeVisibility(graphType);
            }
        },

        [graphTypeVisibility]
    );

    useEffect(() => {
        setGraphType(switchGraphs(graphName));
    }, [graphTypeVisibility]);

    const renderMenuGraphType = useMemo(() => {
        return (
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleGraphTypeChange}
                    value={graphName.toString()}
                >
                    <MenuItem value={GraphType.CURRENT_IMBALANCE}>
                        <FormattedMessage id={'current_imbalance'} />
                    </MenuItem>

                    <MenuItem value={GraphType.NEUTRAL_CURRENT}>
                        <FormattedMessage id={'neutral_current'} />
                    </MenuItem>

                    <MenuItem value={GraphType.CONGESTION_VISIBILITY}>
                        <FormattedMessage id={'congestion_visibility'} />
                    </MenuItem>

                    <MenuItem value={GraphType.VOLTAGE}>
                        <FormattedMessage id={'voltage'} />
                    </MenuItem>

                    <MenuItem value={GraphType.THD}>
                        <FormattedMessage id={'thd'} />
                    </MenuItem>

                    <MenuItem value={GraphType.PV_INJECTION}>
                        <FormattedMessage id={'pv_injection'} />
                    </MenuItem>
                </Select>
            </FormControl>
        );
    }, [graphType]);

    const renderMenuGraphVisualizationType = useMemo(() => {
        return (
            <ToggleButtonGroup aria-label="Platform" color="secondary" exclusive onChange={handleChange} value={graphTypeVisibility}>
                <ToggleButton value={GraphTypeVisualization.HOURLY}>
                    <FormattedMessage id={'hourly'} />
                </ToggleButton>

                <ToggleButton value={GraphTypeVisualization.HISTOGRAM}>
                    <FormattedMessage id={'histogram'} />
                </ToggleButton>
            </ToggleButtonGroup>
        );
    }, [graphTypeVisibility]);

    return (
        <Box>
            {hasLastData ? (
                <Grid container spacing={1}>
                    <>
                        <Grid item xs={10}>
                            {renderMenuGraphType}
                        </Grid>
                        <Grid item xs={2}>
                            {renderMenuGraphVisualizationType}
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Divider sx={{ borderColor: 'black' }} />

                            <Item>{renderGrafanaGraph()}</Item>
                        </Grid>
                    </>
                </Grid>
            ) : (
                <Grid alignItems="center" container direction="column">
                    <Grid item sx={{ mt: '10%', mb: '5%' }}>
                        <FormattedMessage id={'no-data'} />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}
