import { IconToNode } from '_global/Utils/IconToNode';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import {
    Box,
    Card,
    CardContent,
    CardContentProps,
    CardHeader,
    CardHeaderProps,
    CardProps,
    Collapse,
    Divider,
    IconButton,
    IconButtonProps,
    styled,
    SxProps,
    Theme,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { KeyedObject } from 'models';
import React, { Ref, useState } from 'react';

const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

export interface MainCardProps extends KeyedObject {
    border?: boolean;
    boxShadow?: boolean;
    children: React.ReactNode | string;
    style?: React.CSSProperties;
    content?: boolean;
    className?: string;
    contentClass?: string;
    contentSX?: CardContentProps['sx'];
    darkTitle?: boolean;
    sx?: CardProps['sx'];
    secondary?: CardHeaderProps['action'];
    shadow?: string;
    elevation?: number;
    title?: React.ReactNode | string;
    subtitle?: string;
    startExpanded: boolean;
    extraButtons?: React.ReactNode;
}

interface ExpandMoreProps extends IconButtonProps {
    expand: string;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
    })
}));

function Header(
    sx: CardProps,
    title: React.ReactNode | string,
    subtitle: string | undefined,
    expand: boolean,
    onClick: () => void,
    extraButtons: React.ReactNode
) {
    const [expandButton, setExpandButton] = useState<boolean>(expand);

    const onClickExpand = (expand: boolean) => {
        setExpandButton(expand);
    };

    return (
        <CardHeader
            sx={sx}
            title={title}
            subheader={<div style={{ paddingTop: '5px' }}>{subtitle}</div>}
            style={{ paddingBottom: '10px' }}
            action={
                <>
                    {!extraButtons ? <></> : extraButtons}

                    <ExpandMore
                        expand={expand.toString()}
                        onClick={() => {
                            onClick();
                            onClickExpand(!expandButton);
                        }}
                        aria-expanded={expand}
                        aria-label="show more"
                    >
                        {!expandButton ? (
                            <IconToNode definition={faChevronUp} size={'xs'} />
                        ) : (
                            <IconToNode definition={faChevronDown} size={'xs'} />
                        )}
                    </ExpandMore>
                </>
            }
        />
    );
}

function CollapsibleContent(
    intro: boolean,
    title: React.ReactNode | string,
    theme: Theme,
    sx: SxProps<Theme>,
    className: string,
    children: React.ReactNode,
    content: boolean
) {
    return (
        <Collapse in={intro} timeout="auto" unmountOnExit>
            {title && (
                <Box component="div" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', px: 3 }}>
                    <Divider sx={{ width: '100%', height: '1px', borderColor: theme.palette.secondary.main }} />
                </Box>
            )}

            {content && (
                <CardContent sx={sx} className={className}>
                    {children}
                </CardContent>
            )}
            {!content && children}
        </Collapse>
    );
}

const CollapseCard = React.forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            shadow,
            sx = {},
            title,
            subtitle,
            startExpanded = false,
            extraButtons,
            ...others
        }: MainCardProps,
        ref: Ref<HTMLDivElement>
    ) => {
        const theme = useTheme();
        const [expanded, setExpanded] = useState<boolean>(startExpanded);

        const handleExpandClick = () => {
            setExpanded(!expanded);
        };

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + '75',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    ':hover': {
                        boxShadow: boxShadow
                            ? shadow ||
                              (theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)')
                            : 'inherit'
                    },
                    ...sx
                }}
            >
                {!darkTitle && title && Header(headerSX, title, subtitle, startExpanded, handleExpandClick, extraButtons)}
                {darkTitle &&
                    title &&
                    Header(
                        headerSX,
                        <Typography variant="h3">{title}</Typography>,
                        subtitle,
                        startExpanded,
                        handleExpandClick,
                        extraButtons
                    )}
                {CollapsibleContent(expanded, title, theme, contentSX, contentClass, children, content)}
            </Card>
        );
    }
);

export default CollapseCard;
