import { typeFlagRefetch } from '_global/Components/base/filter/FilterTypes';
import { getActiveFilters, getSubmitFilterWithOperators, refactorFiltersToBeQuery } from '_global/Components/base/filter/FilterUtils';
import FilterView from '_global/Components/base/filter/FilterView';
import AlertModal from '_global/Components/base/modals/AlertModal';
import { removeGridPagination } from '_global/Services/store/gridsPaginationSlice';
import { COLORS } from '_global/Utils/Colors';
import { clearUserSectionFilters, getUserConfig, gridUpdate, saveUserFilters } from '_global/Utils/GridUtils';
import { filterAtomEvents, modeAtomEvents, tabAtomEvents } from '_global/Utils/hooks/jotai';
import { useQuery } from '@apollo/client';
import { TypeComputedProps, TypeDataSource, TypePaginationProps, TypeSortInfo } from '@inovua/reactdatagrid-community/types';
import { TypeColumn } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { Divider, Paper, Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { useRemoteAccessGroupConfiguration, useRemoteConfiguration } from 'logic/configuration/Hooks';
import { convertSelectedVisibililty } from 'logic/configuration/Mocks';
import MessageType from 'models/enums/MessageTypes';
import Mode from 'models/enums/ModeEnum';
import { OrderDirection } from 'models/enums/OrderDirection';
import { eventToRow, ProcessedEvent } from 'models/events/ProcessedEvent';
import { ENTITY_TYPE, EventSeverity, EventStatus, EventType, SOURCE } from 'models/graphql-global-types';
import { columnsMap, Section, Visibility } from 'models/settings/RemoteConfiguration';
import { EVENTS } from 'queries/EventsQuery';
import { Events } from 'queries/Types/Events';
import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from '../../_global/Services/store/hooks';
import { addTab } from '../../_global/Services/store/tabComponentSlice';
import { runFunctionWithCacheAsync } from '../../logic/cache/useCachedFunction';
import EventsContent from './EventsContent';
import DateCell from './grid/cells/DateCell';
import EntityCell from './grid/cells/EntityCell';
import SeverityCell from './grid/cells/SeverityCell';
import SourceCell from './grid/cells/SourceCell';
import StatusCell from './grid/cells/StatusCell';
import TypeCell from './grid/cells/TypeCell';
import EventsGrid from './grid/EventsGrid';
import DataFilter from './grid/filter/DataFilter';

const filtersValue = [
    { name: 'source', type: 'enum', i18n: 'event_type', options: SOURCE },
    { name: 'sourceEntityType', type: 'enum', i18n: 'entity', options: ENTITY_TYPE },
    { name: 'name', type: 'string', i18n: 'name' },
    { name: 'type', type: 'enum', i18n: 'type', options: EventType },
    { name: 'startDate', type: 'date', i18n: 'startDate' },
    { name: 'endDate', type: 'date', i18n: 'endDate' },
    { name: 'registrationNumber', type: 'string', i18n: 'asset' },
    { name: 'deviceSerial', type: 'string', i18n: 'device' },
    { name: 'feederName', type: 'string', i18n: 'feeder' },
    { name: 'line', type: 'string', i18n: 'line' },
    { name: 'createdDate', type: 'date', i18n: 'createdDate' },
    { name: 'createdBy', type: 'string', i18n: 'createdBy' },
    { name: 'lastModifiedDate', type: 'date', i18n: 'lastModifiedDate' },
    { name: 'lastModifiedBy', type: 'string', i18n: 'lastModifiedBy' },
    { name: 'severity', type: 'enum', i18n: 'severity', options: EventSeverity },
    { name: 'status', type: 'enum', i18n: 'status', options: EventStatus }
];

const customCellStyle = () => {
    return {
        borderBottomColor: '#F5F5F5'
    };
};

export const default_columns_event: TypeColumn[] = [
    {
        name: 'source',
        header: <FormattedMessage id={'event_type'} />,
        minWidth: 130,
        flex: 0.6,
        render: ({ value }) => <SourceCell source={value as SOURCE} />,
        style: customCellStyle,
        visible: false
    },
    {
        name: 'sourceEntityType',
        header: <FormattedMessage id={'entity'} />,
        minWidth: 150,
        flex: 0.7,
        render: ({ value }) => <EntityCell entityType={value as ENTITY_TYPE} />,
        style: customCellStyle,
        visible: false
    },
    {
        name: 'name',
        header: <FormattedMessage id={'name'} />,
        minWidth: 100,
        flex: 0.5,
        style: customCellStyle,
        visible: false
    },
    {
        name: 'type',
        header: <FormattedMessage id={'type'} />,
        minWidth: 200,
        flex: 1.2,
        render: ({ data }) => <TypeCell data={data as ProcessedEvent} />,
        style: customCellStyle,
        visible: true
    },
    {
        name: 'startDate',
        header: <FormattedMessage id={'startDate'} />,
        minWidth: 150,
        flex: 0.4,
        render: ({ value }) => <DateCell value={value as Date} />,
        style: customCellStyle,
        visible: true
    },
    {
        name: 'endDate',
        header: <FormattedMessage id={'endDate'} />,
        minWidth: 150,
        flex: 0.4,
        render: ({ value }) => <DateCell value={value as Date} />,
        style: customCellStyle,
        visible: true
    },
    {
        name: 'registrationNumber',
        header: <FormattedMessage id={'asset'} />,
        minWidth: 100,
        flex: 0.5,
        render: ({ value }) => {
            if (value) return String(value);
            else return <FormattedMessage id={'na'} />;
        },
        style: customCellStyle,
        visible: true
    },
    {
        name: 'deviceSerial',
        header: <FormattedMessage id={'device'} />,
        minWidth: 100,
        flex: 0.5,
        render: ({ value }) => {
            if (value) return String(value);
            else return <FormattedMessage id={'na'} />;
        },
        style: customCellStyle,
        visible: false
    },
    {
        name: 'feederName',
        header: <FormattedMessage id={'feeder'} />,
        minWidth: 100,
        flex: 0.5,
        render: ({ value }) => {
            if (value) return String(value);
            else return <FormattedMessage id={'na'} />;
        },
        style: customCellStyle,
        visible: false
    },
    {
        name: 'line',
        header: <FormattedMessage id={'line'} />,
        minWidth: 100,
        flex: 0.3,
        style: customCellStyle,
        visible: false,
        render: ({ value }) => {
            if (value) return String(value);
            else return <FormattedMessage id={'na'} />;
        }
    },
    {
        name: 'createdDate',
        header: <FormattedMessage id={'createdDate'} />,
        minWidth: 150,
        flex: 0.4,
        render: ({ value }) => <DateCell value={value as Date} />,
        style: customCellStyle,
        visible: false
    },
    {
        name: 'createdBy',
        header: <FormattedMessage id={'createdBy'} />,
        minWidth: 150,
        flex: 0.4,
        style: customCellStyle,
        visible: false
    },
    {
        name: 'lastModifiedDate',
        header: <FormattedMessage id={'lastModifiedDate'} />,
        minWidth: 180,
        flex: 0.4,
        render: ({ value }) => <DateCell value={value as Date} />,
        style: customCellStyle,
        visible: false
    },
    {
        name: 'lastModifiedBy',
        header: <FormattedMessage id={'lastModifiedBy'} />,
        minWidth: 150,
        flex: 0.4,
        style: customCellStyle,
        visible: false
    },
    {
        name: 'severity',
        header: <FormattedMessage id={'severity'} />,
        minWidth: 100,
        flex: 0.6,
        render: ({ value }) => <SeverityCell severity={value as EventSeverity} />,
        style: customCellStyle,
        visible: false
    },
    {
        name: 'status',
        header: <FormattedMessage id={'status'} />,
        minWidth: 150,
        flex: 0.7,
        render: ({ value }) => <StatusCell status={value as EventStatus} />,
        style: customCellStyle,
        visible: true
    }
];

type AlarmsPageInternalProps = {
    showToast: (toastMessage: string, toastType: MessageType) => void;
};

const defaultSortInfo: TypeSortInfo = { name: 'id', dir: 1 };

export default function AlarmsPageInternal(props: AlarmsPageInternalProps) {
    const { showToast } = props;
    const [, setMode] = useAtom(modeAtomEvents);
    const [showingError, setShowingError] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<ProcessedEvent>();
    const dispatch = useAppDispatch();
    const { data: eventsData, error: eventsError, fetchMore, loading } = useQuery<Events>(EVENTS);
    const [gridRef, setGridRef] = useState<((gridApiRef: MutableRefObject<TypeComputedProps | null>) => void) | undefined>(undefined);
    const [enableFilter, setEnableFilter] = useState(false);
    const [numberActiveFilters, setNumberActiveFilters] = useState(0);
    const [flagRefetch, setFlagRefetch] = useState<typeFlagRefetch>(null);
    const nodes = useMemo(() => eventsData?.events?.nodes, [eventsData?.events?.nodes]);
    const pageInfo = useMemo(() => eventsData?.events?.pageInfo, [eventsData?.events?.pageInfo]);
    const [columns, setColumns] = useState<TypeColumn[]>(default_columns_event);
    const [filters, setFilters] = useAtom(filterAtomEvents);
    const [userVisibility, setUserVisibility] = useState<Visibility[]>([]);
    const [userConfig, setUserConfig] = useRemoteConfiguration();
    const [sortField, setSortFieldId] = useState<string | Record<string, string | Record<string, unknown>>>({ createdDate: 'DESC' });
    const [sortInfoC, setSortInfo] = useState<TypeSortInfo>(defaultSortInfo);
    const [userHasOptions, setUserHasOptions] = useState<boolean>(false);

    const userAccessGroup = useRemoteAccessGroupConfiguration();

    const initialFlagRefetch = useMemo(() => ({ equipmentId: { in: userAccessGroup.assets } }), [userAccessGroup.assets]);

    const refresh = () => {
        dispatch(
            removeGridPagination({
                key: `EventsGrid`,
                url: location?.pathname
            })
        );
        gridUpdate();
    };

    const loadData: TypeDataSource = useCallback(
        async (props: TypePaginationProps) => {
            const response = await runFunctionWithCacheAsync(
                async () =>
                    await fetchMore({
                        variables: {
                            first: props.skip + props.limit,
                            order: sortField,
                            filter: flagRefetch ?? initialFlagRefetch
                        }
                    }),
                [props, sortField, flagRefetch, initialFlagRefetch]
            );

            const events = response?.data?.events?.nodes?.filter((event) => event != null).map((event) => eventToRow(event)) ?? [];

            return {
                data: events.slice(props.skip, events.length),
                count: response.data.events?.totalCount ?? 0
            };
        },
        [pageInfo, nodes, flagRefetch, sortField, initialFlagRefetch, fetchMore]
    );

    const sortInfo = useCallback(
        (
            sortBy: string,
            dir: number,
            type?: string,
            columnSortAsc?: Record<string, Record<string, unknown>>,
            columnSortDesc?: Record<string, Record<string, unknown>>
        ) => {
            if (dir === OrderDirection.ASC) {
                if (columnSortAsc) {
                    setSortFieldId(columnSortAsc);
                } else {
                    setSortFieldId({ [sortBy]: 'ASC' });
                }
            } else {
                if (columnSortDesc) {
                    setSortFieldId(columnSortDesc);
                } else {
                    setSortFieldId({ [sortBy]: 'DESC' });
                }
            }
            if (sortBy === '') {
                setSortFieldId({ id: 'DESC' });
            }
            const newSortInfo = sortBy !== '' ? { type: type ?? 'string', name: sortBy, dir: dir as 1 | -1 | 0 } : null;
            setSortInfo(newSortInfo);
        },
        []
    );

    const updateUserSettings = (sections: Section[]) => {
        if (userConfig)
            setUserConfig(() => ({
                ...userConfig,
                userSettings: {
                    ...userConfig?.userSettings,
                    sections: sections
                }
            }));
    };

    const saveSettings = useCallback(() => {
        saveUserFilters('/alarms', updateUserSettings, filters, userVisibility, userConfig);
        showToast('user_settings_updated', MessageType.SUCCESS);
    }, [userVisibility, filters]);

    const updateVisibility = useCallback((columnsMap: columnsMap) => {
        setColumns((columns) => {
            const newColumns: TypeColumn[] = [];
            let visibility: Visibility[] = [];

            columnsMap.forEach((value, key) => {
                visibility = convertSelectedVisibililty(key.name, key.i18n, value, visibility);
                const index = columns.findIndex((nc) => nc.name === key.name);
                newColumns.push({ ...columns[index], visible: value });
            });
            setUserVisibility(visibility);

            return newColumns;
        });
    }, []);

    const handleDeleteAll = () => {
        setFlagRefetch(null);
        setFilters([{ name: '', operator: [], value: [] }]);
        setColumns(default_columns_event);
        updateVisibility(
            new Map(
                default_columns_event.map((obj) => {
                    return [{ name: obj.name ?? '', i18n: (obj.header?.props.id as string) ?? '' }, obj.visible ?? true];
                })
            )
        );
    };

    const handleSubmitFilter = () => {
        const { filterWithoutOperators, andFilter, orFilter } = refactorFiltersToBeQuery(filters, filtersValue);

        const newAndFilter = andFilter.concat([initialFlagRefetch]);

        if (Object.keys(filterWithoutOperators).length > 0) {
            setFlagRefetch(getSubmitFilterWithOperators(filters, filterWithoutOperators, newAndFilter, orFilter));
        } else {
            setFlagRefetch(null);
        }
    };

    useEffect(() => {
        if (filters && filters[0].name !== '') {
            const { filterWithoutOperators, andFilter, orFilter } = refactorFiltersToBeQuery(filters, filtersValue);

            if (Object.keys(filterWithoutOperators).length > 0) {
                setFlagRefetch(getSubmitFilterWithOperators(filters, filterWithoutOperators, andFilter, orFilter));
            } else {
                setFlagRefetch(null);
            }
        }
    }, []);

    useEffect(() => {
        if (filters.length > 0 && filters[0].name !== '') {
            setEnableFilter(true);
        } else {
            setEnableFilter(false);
        }
    }, []);

    useEffect(() => {
        setNumberActiveFilters(getActiveFilters(filters));
    }, [filters]);

    useEffect(() => {
        const options = getUserConfig('/alarms', userConfig);
        setUserHasOptions(!!options);

        options?.map((option) => {
            if (option.filters && option.filters[0].name !== '') {
                setFilters(option.filters);
                const { filterWithoutOperators, andFilter, orFilter } = refactorFiltersToBeQuery(option.filters, filtersValue);
                const newAndFilter = andFilter.concat([initialFlagRefetch]);

                if (Object.keys(filterWithoutOperators).length > 0) {
                    setFlagRefetch(getSubmitFilterWithOperators(filters, filterWithoutOperators, newAndFilter, orFilter));
                } else {
                    setFlagRefetch(null);
                }
            }
            if (option.visibility) {
                setUserVisibility(option.visibility);
                updateVisibility(
                    new Map(
                        option.visibility.map((elm) => {
                            return [{ name: elm.name ?? '', i18n: elm.i18n ?? '' }, elm.value ?? true];
                        })
                    )
                );
            }
        });
    }, [userConfig, initialFlagRefetch]);

    const [, setCurrentTab] = useAtom(tabAtomEvents);
    const tabs = useAppSelector((state) => state.tabs.tabs);

    useEffect(() => {
        if (selectedEvent) {
            const tab = Array.from(tabs).map(([name, value]) => ({ name: name, url: value.url ?? '' }));

            const existAlarm = tab.find((x) => x.name === selectedEvent.name);

            if (existAlarm) {
                if (existAlarm.url.includes('edit')) {
                    setMode(Mode.EDIT);
                } else {
                    setMode(Mode.PREVIEW);
                }
            } else {
                dispatch(
                    addTab({
                        name: selectedEvent.name ?? 'alarm',
                        component: <EventsContent alarmId={selectedEvent.id} />,
                        url: `/alarms/${selectedEvent.id}`
                    })
                );
                setMode(Mode.PREVIEW);
            }

            setCurrentTab(selectedEvent.name);
        }
    }, [selectedEvent]);

    const clearSettings = useCallback(() => {
        clearUserSectionFilters('/alarms', updateUserSettings, userConfig);
        handleDeleteAll();
    }, [userConfig, columns, filters, userVisibility]);

    return (
        <Paper sx={{ height: '100%' }}>
            <Paper elevation={2} sx={{ padding: '8px', height: '100%' }}>
                <Stack spacing={2} sx={{ p: 1 }}>
                    <DataFilter
                        clearSettings={clearSettings}
                        columns={columns}
                        onRefresh={refresh}
                        onVisibilityChanged={updateVisibility}
                        saveSettings={saveSettings}
                        userHasOptions={userHasOptions}
                        {...{ enableFilter, setEnableFilter, numberActiveFilters }}
                        gridRef={gridRef}
                    />
                    {enableFilter && (
                        <FilterView {...{ filters, setFilters, filtersValue, handleSubmitFilter, handleDeleteAll, setFlagRefetch }} />
                    )}
                    <Divider sx={{ borderColor: COLORS.ENEIDA_ALTERNATIVE_BLUE }} />
                    <EventsGrid
                        columns={columns}
                        data={loadData}
                        loading={loading}
                        onRowPressed={(event) => setSelectedEvent(event)}
                        setGridRef={setGridRef}
                        sortInfo={sortInfo}
                        sortInfoC={sortInfoC}
                    />
                </Stack>
            </Paper>

            {eventsError && (
                <AlertModal
                    isOpen={showingError}
                    message={JSON.stringify(eventsError)}
                    onOk={() => setShowingError(false)}
                    type={MessageType.ERROR}
                />
            )}
        </Paper>
    );
}
