import { TypeColumn, TypeComputedProps, TypeDataSource } from '@inovua/reactdatagrid-community/types';
import { IBatch } from 'models/batch/BatchDevices';
import { IConTestConfiguration } from 'models/conTest/ConTestConfiguration';
import { SortInfoType } from 'models/sortInfo';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { IValidations } from 'views/Users/utils/UsersModels';

export interface IGenericBatchGrid {
    conTestConfiguration: IConTestConfiguration;
    handleChangeAll: (items: number[], id: string) => void;
}
export interface IBatchInternalGrid extends IGenericBatchGrid {
    setBatchList: React.Dispatch<React.SetStateAction<IBatch[]>>;
}

export interface IBatchListButton extends IGenericBatchGrid {
    batchList: IBatch[];
}

export interface ITab {
    conTestConfigurationDetails: IConTestConfiguration;
    handleChangeAll: (items: number[], id: string) => void;
}

export interface IBatchGrid {
    data: TypeDataSource;
    loading: boolean;
    columns: TypeColumn[];
    setGridRef: Dispatch<SetStateAction<((gridApiRef: MutableRefObject<TypeComputedProps | null>) => void) | undefined>>;
    sortInfo: SortInfoType;
    conTestConfiguration: IConTestConfiguration;
}

export const defaultConTestConfigValidation = [
    { name: 'name', error: false, message: '' },
    { name: 'deviceBulkConfigurationId', error: false, message: '' }
] as IValidations[];
