export interface AssetModel {
    assetId: number;
    name: string;
    registryNumber: string;
    isEnabled: boolean;
    state: string;
    assetType: number;
    latitude: number;
    longitude: number;
    description: string;
    transformer: Transformer;
    parentAssetId?: number;
    parentAssetName?: string;
    parentFeederId?: number;
    parentFeederName?: string;
    parentEquipmentId?: number;
    parentEquipmentName?: string;
    transformerId: number;
    alarmStandardId: number | null;
    alarmStandardName: string | null;
    regionId: number;
    regionName: string;
    operationalAreaId: number;
    operationalAreaName: string;
    devices: AssetDevice[];
    equipBulkConfigurationId: number | null;
    equipBulkConfigurationName: string | null;
    equipments: Equipment[];
    feeders: Feeder[];
    masterBusLines: MasterBusLine[];
    createdBy: string;
    createdDate: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
    validRegion_And_OperationalArea: boolean;
    hasEarth: boolean;
}

interface Transformer {
    id: number;
    assetId: number;
    parentEquipmentId?: number;
    parentEquipmentName?: string;
    parentFeederId?: number;
    name: string;
    description: string;
    isMainTranformer: boolean;
    equipmentType: string;
    brand: string;
    model: string;
    power: number;
    manufactureDate: string;
    serviceEntryDate: string;
    phaseRotation: string;
    phaseType: string | number;
    createdBy: string;
    lastModifiedBy: string;
}

export interface AssetDevice {
    id: number;
    externalId: number;
    serialNumber: string;
    assetId: number;
    createdDate: string;
    deviceInputNumbers: AssetDeviceInputNumber[];
}

interface AssetDeviceInputNumber {
    id: number;
    deviceId: number;
    deviceSerialNumber: string;
    externalId: number;
    portNumber: number;
    inputNumber: number;
    typeId: number;
    devicePortId: number;
    isEnabled: boolean;
    inputType: string;
    powerBusLineId: number;
    masterBusLineId: number;
}

interface Equipment {
    id: number;
    assetId: number;
    parentEquipmentId: number;
    parentEquipmentName: string;
    parentFeederId: number;
    name: string;
    description: string;
    isMainTranformer: boolean;
    equipmentType: string;
    brand: string;
    model: string;
    power: number;
    manufactureDate: string;
    serviceEntryDate: string;
    phaseRotation: string;
    phaseType: string;
    createdBy: string;
    lastModifiedBy: string;
}

export interface Feeder {
    id: number;
    assetId: number;
    equipmentId: number;
    alarmStandardId: number | null;
    alarmStandardName: string | null;
    name: string;
    description: string;
    number: number;
    isActive: boolean;
    fuseRating: number;
    cableTypeId: number | null;
    cableTypeName: string | null;
    createdBy: string;
    createdDate: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
    powerBusLines: PowerBusLine[];
    feederType: FeederType;
}

export enum GenericFeederType {
    FEEDER,
    BUSBAR,
    EARTH
}

export enum FeederType {
    BUSBAR = 1,
    POWERBUSLINE,
    EARTH_SERVICE_LINE,
    EARTH_PROTECTION_LINE,
    NEUTRAL_LINE
}

interface PowerBusLine {
    id: number;
    feederId: number;
    masterBusLineId: number;
    inputType: string;
    deviceInputId: number;
    deviceInputExternalId: number;
    deviceId: number;
    deviceExternalId: number;
    serialNumber: string;
    deviceInputNumber: number;
    devicePortNumber: number;
    deviceInputTypeId: number;
}

export interface MasterBusLine {
    id: number;
    assetId: number;
    equipmentId: number;
    referenceAngle: number;
    inputType: string;
    feederId: number;
    deviceInputId: number;
    deviceInputExternalId: number;
    deviceId: number;
    deviceExternalId: number;
    serialNumber: string;
    deviceInputNumber: number;
    devicePortNumber: number;
    deviceInputTypeId: number;
}
