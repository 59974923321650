import { gql } from '@apollo/client';

import { OpAreas_By_RegionID_regions_edges_node_operationalAreas } from './Types/OpAreas_By_RegionID';
import { OpAreas_By_RegionID_regions_edges_node } from './Types/OpAreas_By_RegionID';
import { OpAreas_By_RegionID } from './Types/OpAreas_By_RegionID';
import { operationalAreas, operationalAreas_operationalAreas_nodes } from './Types/operationalAreas';

export type OpAreaByRegionIDInstance = OpAreas_By_RegionID_regions_edges_node_operationalAreas;

export type OpAreasByRegionID = OpAreas_By_RegionID_regions_edges_node;
export type OpAreasByR = OpAreas_By_RegionID;
export type OpAreas = operationalAreas;

export type OpAreaById = operationalAreas_operationalAreas_nodes;

export const OPAREAS = gql`
    query operationalAreas($cursor: String, $numberOfElements: Int, $filter: OperationalAreaFilterInput) {
        operationalAreas(where: $filter, order: { id: ASC }, first: $numberOfElements, after: $cursor) {
            totalCount
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            nodes {
                name
                code
                id
                regionId
                gpsPoints {
                    id
                    latitude
                    longitude
                    designOrder
                }
            }
        }
    }
`;

export const OPAREAS_BY_REGION_ID = gql`
    query OpAreas_By_RegionID($cursor: String, $first: Int, $regionId: Long) {
        regions(first: $first, after: $cursor, where: { id: { eq: $regionId } }) {
            ...RegionsConnectionFragment
        }
    }

    fragment RegionsConnectionFragment on RegionsConnection {
        edges {
            node {
                gpsPoints {
                    id
                    latitude
                    longitude
                    designOrder
                }
                operationalAreas {
                    id
                    name
                    code
                    regionId
                }
            }
        }
        pageInfo {
            endCursor
            hasNextPage
        }
    }
`;

export const OPAREA_BY_ID = gql`
    query OpAreas_By_ID($cursor: String, $first: Int, $regionId: Long) {
        operationalAreas(first: $first, after: $cursor, where: { id: { eq: $regionId } }) {
            ...operationalAreasConnectionFragment
        }
    }

    fragment operationalAreasConnectionFragment on OperationalAreasConnection {
        edges {
            node {
                id
                name
                code
            }
        }
        pageInfo {
            endCursor
            hasNextPage
        }
    }
`;
