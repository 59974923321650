import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import exportReducer from './exportSlice';
import featureReducer from './featureSlice';
import gridsPaginationsReducer from './gridsPaginationSlice';
import snackbarReducer from './snackbarReducer';
import tabsComponentReducer from './tabComponentSlice';

const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    exporter: exportReducer,
    features: featureReducer,
    tabs: tabsComponentReducer,
    gridsPaginations: gridsPaginationsReducer
});

export default reducer;
