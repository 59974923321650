import RoundedCornersButton from '_global/Components/base/button/RoundedCornersButton';
import { Download, Refresh } from '@mui/icons-material';
import { Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import { LastDataTitleProps } from 'views/Assets/utils/AssetModel';

export default function LastDataTitle(props: LastDataTitleProps) {
    const { lastData, onRefresh, onExportData, title } = props;

    return (
        <Box>
            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item sx={{ display: 'flex ' }}>
                    <Grid item>
                        <Typography variant="h2">
                            <FormattedMessage id={title} />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item sx={{ display: 'flex ' }}>
                    <Grid item>
                        <RoundedCornersButton onClicked={onRefresh}>
                            <Refresh />
                        </RoundedCornersButton>
                    </Grid>
                    {lastData ? (
                        <>
                            {lastData.feeders && (
                                <Grid item sx={{ mx: 1 }}>
                                    <RoundedCornersButton onClicked={onExportData}>
                                        <Download />
                                    </RoundedCornersButton>
                                </Grid>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
            <Divider sx={{ mt: 1, mb: 2, border: 0.5, borderColor: 'black' }} />
        </Box>
    );
}
