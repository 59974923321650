import { typeFlagRefetch } from '_global/Components/base/filter/FilterTypes';
import { getActiveFilters, getSubmitFilterWithOperators, refactorFiltersToBeQuery } from '_global/Components/base/filter/FilterUtils';
import FilterView from '_global/Components/base/filter/FilterView';
import AlertModal from '_global/Components/base/modals/AlertModal';
import { removeGridPagination } from '_global/Services/store/gridsPaginationSlice';
import { useAppDispatch } from '_global/Services/store/hooks';
import { COLORS } from '_global/Utils/Colors';
import { clearUserSectionFilters, getUserConfig, gridUpdate, saveUserFilters } from '_global/Utils/GridUtils';
import {
    filterAtomAllReports,
    filterAtomCustomReports,
    filterAtomSystemReports,
    setUpdateReportAtom,
    setUpdateReportAtomCustom,
    setUpdateReportAtomSystem
} from '_global/Utils/hooks/jotai';
import { useQuery } from '@apollo/client';
import { TypeColumn, TypeComputedProps, TypeDataSource, TypePaginationProps, TypeSortInfo } from '@inovua/reactdatagrid-community/types';
import { Divider, Paper, Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { runFunctionWithCacheAsync } from 'logic/cache/useCachedFunction';
import { useRemoteConfiguration } from 'logic/configuration/Hooks';
import { convertSelectedVisibililty } from 'logic/configuration/Mocks';
import MessageType from 'models/enums/MessageTypes';
import { OrderDirection } from 'models/enums/OrderDirection';
import { reportToRow } from 'models/report/ReportResponse';
import { columnsMap, Section, Visibility } from 'models/settings/RemoteConfiguration';
import { REPORTS } from 'queries/Reports';
import { Reports } from 'queries/Types/Reports';
import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import DataFilter from 'views/Events/grid/filter/DataFilter';
import ReportsGrid from 'views/Reports/Grid/ReportsGrid';

import { default_columns_reports } from './Grid/ReportsGrid';
import { EReportsPageType } from './ReportsPage';

const filtersValue = [
    {
        name: 'created',
        type: 'date',
        i18n: 'date'
    },
    {
        name: 'description',
        type: 'string',
        i18n: 'description'
    },
    {
        name: 'equipments',
        type: 'array',
        i18n: 'equipments'
    },
    {
        name: 'errors',
        type: 'string',
        i18n: 'errors'
    },
    { name: 'name', type: 'string', i18n: 'name' },
    { name: 'title', type: 'string', i18n: 'type' },
    { name: 'requested_by', type: 'string', i18n: 'requestedBy' },
    {
        name: 'period_start',
        type: 'date',
        i18n: 'from'
    },
    {
        name: 'period_end',
        type: 'date',
        i18n: 'to'
    },
    {
        name: 'periodic',
        type: 'boolean',
        i18n: 'periodic'
    },
    {
        name: 'ready',
        type: 'boolean',
        i18n: 'ready'
    },
    { name: 'size', type: 'string', i18n: 'fileSize' },
    { name: 'title', type: 'string', i18n: 'title' }
];

interface ReportsPageInternalProps {
    reportPageType: EReportsPageType;
    showToast: (toastMessage: string, toastType: MessageType) => void;
}

const defaultSortInfo: TypeSortInfo = { name: 'created', dir: 1 };

const ReportsPageInternal = ({ reportPageType = EReportsPageType.ALL, showToast }: ReportsPageInternalProps) => {
    const dispatch = useAppDispatch();
    const [userConfig, setUserConfig] = useRemoteConfiguration();
    const [showingError, setShowingError] = useState(false);
    const setCustomUpdateReport = (reportPageType: EReportsPageType) => {
        switch (reportPageType) {
            case EReportsPageType.ALL:
                return setUpdateReportAtom;
            case EReportsPageType.CUSTOM:
                return setUpdateReportAtomCustom;
            case EReportsPageType.SYSTEM:
                return setUpdateReportAtomSystem;
        }
    };
    const [getUpdateReport, setUpdateReport] = useAtom(setCustomUpdateReport(reportPageType));

    const { data: reportsData, loading: areReportsLoading, error: reportsError, fetchMore } = useQuery<Reports>(REPORTS);

    const filterValuesByReportType = (reportPageType: EReportsPageType) => {
        switch (reportPageType) {
            case EReportsPageType.ALL:
                return filtersValue;
            case EReportsPageType.CUSTOM:
                return filtersValue.filter((el) => el.name !== 'periodic');
            case EReportsPageType.SYSTEM:
                return filtersValue.filter((el) => el.name !== 'periodic');
            default:
                return filtersValue;
        }
    };

    const filterDefaultValue = (reportPageType: EReportsPageType) => {
        switch (reportPageType) {
            case EReportsPageType.ALL:
                return filterAtomAllReports;
            case EReportsPageType.CUSTOM:
                return filterAtomCustomReports;
            case EReportsPageType.SYSTEM:
                return filterAtomSystemReports;
        }
    };

    const specialFilters: (reportPageType: EReportsPageType) => Record<string, Record<string, string | number | boolean | number[]>>[] = (
        reportPageType
    ) => {
        switch (reportPageType) {
            case EReportsPageType.ALL:
                return [];
            case EReportsPageType.CUSTOM:
                return [{ periodic: { eq: false } }];
            case EReportsPageType.SYSTEM:
                return [{ periodic: { eq: true } }];
            default:
                return [];
        }
    };

    const initialFlagRefetch: (reportPageType: EReportsPageType) => typeFlagRefetch = (reportPageType) => {
        switch (reportPageType) {
            case EReportsPageType.ALL:
                return null;
            case EReportsPageType.CUSTOM:
                return { periodic: { eq: false } };
            case EReportsPageType.SYSTEM:
                return { periodic: { eq: true } };
            default:
                return null;
        }
    };

    const [gridRef, setGridRef] = useState<((gridApiRef: MutableRefObject<TypeComputedProps | null>) => void) | undefined>(undefined);
    const [columns, setColumns] = useState<TypeColumn[]>(default_columns_reports);
    const [enableFilter, setEnableFilter] = useState(false);
    const [numberActiveFilters, setNumberActiveFilters] = useState(0);
    const [filters, setFilters] = useAtom(filterDefaultValue(reportPageType));
    const [flagRefetch, setFlagRefetch] = useState<typeFlagRefetch>(null);
    const [userVisibility, setUserVisibility] = useState<Visibility[]>([]);
    const [sortField, setSortField] = useState<string | Record<string, string | Record<string, unknown>>>({ created: 'DESC' });
    const [sortInfoC, setSortInfo] = useState<TypeSortInfo>(defaultSortInfo);

    const [userHasOptions, setUserHasOptions] = useState<boolean>(false);

    const nodes = useMemo(() => reportsData?.reports?.nodes, [reportsData?.reports?.nodes]);

    const pageInfo = useMemo(() => reportsData?.reports?.pageInfo, [reportsData?.reports?.pageInfo]);

    const refresh = () => {
        dispatch(
            removeGridPagination({
                key: `ReportsGrid`,
                url: location?.pathname
            })
        );
        gridUpdate();
    };

    useEffect(() => {
        if (getUpdateReport) {
            refresh();
            setUpdateReport(false);
        }
    }, [getUpdateReport]);

    const loadData: TypeDataSource = useCallback(
        async (props: TypePaginationProps) => {
            const response = await runFunctionWithCacheAsync(
                async () =>
                    await fetchMore({
                        variables: {
                            first: props.skip + props.limit,
                            order: sortField,
                            filter: flagRefetch ?? initialFlagRefetch(reportPageType)
                        }
                    }),
                [props.skip, props.limit, flagRefetch, sortField, initialFlagRefetch]
            );

            const reports = response?.data?.reports?.nodes?.map((report) => reportToRow(report)) ?? [];

            return {
                data: reports.slice(props.skip, reports.length),
                count: response.data.reports?.totalCount ?? 0
            };
        },
        [pageInfo, nodes, flagRefetch, reportPageType, sortField]
    );

    const sortInfo = useCallback(
        (
            sortBy: string,
            dir: number,
            type?: string,
            columnSortAsc?: Record<string, Record<string, unknown>>,
            columnSortDesc?: Record<string, Record<string, unknown>>
        ) => {
            if (dir === OrderDirection.ASC) {
                if (columnSortAsc) {
                    setSortField(columnSortAsc);
                } else {
                    setSortField({ [sortBy]: 'ASC' });
                }
            } else {
                if (columnSortDesc) {
                    setSortField(columnSortDesc);
                } else {
                    setSortField({ [sortBy]: 'DESC' });
                }
            }
            if (sortBy === '') {
                setSortField({ created: 'DESC' });
            }
            const newSortInfo = sortBy !== '' ? { type: type ?? 'string', name: sortBy, dir: dir as 1 | -1 | 0 } : null;
            setSortInfo(newSortInfo);
        },
        []
    );

    const handleSubmitFilter = () => {
        const { filterWithoutOperators, andFilter, orFilter } = refactorFiltersToBeQuery(filters, filtersValue);
        const newAndFilter = andFilter.concat(specialFilters(reportPageType));
        if (Object.keys(filterWithoutOperators).length > 0) {
            setFlagRefetch(getSubmitFilterWithOperators(filters, filterWithoutOperators, newAndFilter, orFilter));
        } else {
            setFlagRefetch(null);
        }
    };

    const handleDeleteAll = () => {
        if (numberActiveFilters <= 0) {
            return;
        }
        setFilters([{ name: '', operator: [], value: [] }]);
        setFlagRefetch(null);
    };

    useEffect(() => {
        if (filters && filters[0].name !== '') {
            const { filterWithoutOperators, andFilter, orFilter } = refactorFiltersToBeQuery(filters, filtersValue);
            const newAndFilter = andFilter.concat(specialFilters(reportPageType));

            if (Object.keys(filterWithoutOperators).length > 0) {
                setFlagRefetch(getSubmitFilterWithOperators(filters, filterWithoutOperators, newAndFilter, orFilter));
            } else {
                setFlagRefetch(null);
            }
        } else {
            setFlagRefetch(null);
        }
    }, [reportPageType]);

    useEffect(() => {
        if (filters.length > 0 && filters[0].name !== '') {
            setEnableFilter(true);
        } else {
            setEnableFilter(false);
        }
    }, [reportPageType]);

    useEffect(() => {
        setNumberActiveFilters(getActiveFilters(filters));
    }, [filters]);

    const updateReportSettings = (sections: Section[]) => {
        if (userConfig)
            setUserConfig(() => ({
                ...userConfig,
                userSettings: {
                    ...userConfig?.userSettings,
                    sections: sections
                }
            }));
    };

    const saveSettings = useCallback(() => {
        switch (reportPageType) {
            case EReportsPageType.ALL:
                saveUserFilters('/reports/all', updateReportSettings, filters, userVisibility, userConfig);
                break;
            case EReportsPageType.CUSTOM:
                saveUserFilters('/reports/custom', updateReportSettings, filters, userVisibility, userConfig);
                break;
            case EReportsPageType.SYSTEM:
                saveUserFilters('/reports/system', updateReportSettings, filters, userVisibility, userConfig);
                break;
        }
        showToast('user_settings_updated', MessageType.SUCCESS);
    }, [userVisibility, filters, reportPageType]);

    const updateVisibility = useCallback(
        (columnsMap: columnsMap) => {
            setColumns((columns) => {
                const newColumns: TypeColumn[] = [];
                let visibility: Visibility[] = [];

                columnsMap.forEach((value, key) => {
                    visibility = convertSelectedVisibililty(key.name, key.i18n, value, visibility);
                    const index = columns.findIndex((nc) => nc.name === key.name);
                    newColumns.push({ ...columns[index], visible: value });
                });

                setUserVisibility(visibility);

                return newColumns;
            });
        },
        [userConfig]
    );

    useEffect(() => {
        const options = getUserConfig(`/reports/${reportPageType}`, userConfig);
        setUserHasOptions(!!options);

        options?.map((option) => {
            if (option.filters && option.filters[0].name !== '') {
                setFilters(option.filters);
                const { filterWithoutOperators, andFilter, orFilter } = refactorFiltersToBeQuery(option.filters, filtersValue);
                const newAndFilter = andFilter.concat(specialFilters(reportPageType));

                if (Object.keys(filterWithoutOperators).length > 0) {
                    setFlagRefetch(getSubmitFilterWithOperators(filters, filterWithoutOperators, newAndFilter, orFilter));
                } else {
                    setFlagRefetch(null);
                }
            }
            if (option.visibility) {
                setUserVisibility(option.visibility);
                updateVisibility(
                    new Map(
                        option.visibility.map((elm) => {
                            return [{ name: elm.name ?? '', i18n: elm.i18n ?? '' }, elm.value ?? true];
                        })
                    )
                );
            }

            if (option.visibility) {
                setUserVisibility(option.visibility);
                updateVisibility(
                    new Map(
                        option.visibility.map((elm) => {
                            return [{ name: elm.name ?? '', i18n: elm.i18n ?? '' }, elm.value ?? true];
                        })
                    )
                );
            }
        });
    }, [userConfig, reportPageType]);

    const clearSettings = useCallback(() => {
        clearUserSectionFilters(`/reports/${reportPageType}`, updateReportSettings, userConfig);
        handleDeleteAll();
    }, [userConfig, reportPageType]);

    return (
        <Paper sx={{ height: '100%' }}>
            <Paper elevation={2} sx={{ padding: '8px', height: '100%' }}>
                <Stack spacing={2} sx={{ p: 1 }}>
                    <DataFilter
                        clearSettings={clearSettings}
                        onRefresh={refresh}
                        userHasOptions={userHasOptions}
                        {...{ enableFilter, setEnableFilter, numberActiveFilters }}
                        columns={columns}
                        gridRef={gridRef}
                        onVisibilityChanged={updateVisibility}
                        saveSettings={saveSettings}
                    />
                    {enableFilter && (
                        <FilterView
                            filtersValue={filterValuesByReportType(reportPageType)}
                            {...{ filters, setFilters, handleSubmitFilter, handleDeleteAll, setFlagRefetch }}
                        />
                    )}
                    <Divider sx={{ borderColor: COLORS.ENEIDA_ALTERNATIVE_BLUE }} />
                    <ReportsGrid
                        columns={columns}
                        data={loadData}
                        loading={areReportsLoading}
                        reportPageType={reportPageType}
                        setGridRef={setGridRef}
                        sortInfo={sortInfo}
                        sortInfoC={sortInfoC}
                    />
                </Stack>
            </Paper>

            {reportsError && (
                <AlertModal
                    isOpen={showingError}
                    message={JSON.stringify(reportsError)}
                    onOk={() => setShowingError(false)}
                    type={MessageType.ERROR}
                />
            )}
        </Paper>
    );
};

export default ReportsPageInternal;
