import { IconToNode } from '_global/Utils/IconToNode';
import { faClose, faList, faMagnifyingGlass, faPencil, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';

export type TabLabelProps = {
    shouldShow: boolean;
    name: string;
    onClick: () => void;
    colorDisplay: boolean;
    modeEdit: boolean;
};

export default function TabLabel(props: TabLabelProps) {
    const { shouldShow, name, onClick, colorDisplay, modeEdit } = props;

    const getIcon = useCallback(() => {
        switch (name) {
            case 'List':
                return <IconToNode definition={faList} sx={{ color: colorDisplay ? '#FFF' : 'rgb(158, 158, 158)' }} />;
            case 'add':
                return <IconToNode definition={faPlus} sx={{ color: colorDisplay ? '#FFF' : 'rgb(158, 158, 158)' }} />;
            default:
                if (modeEdit) {
                    return <IconToNode definition={faPencil} sx={{ color: colorDisplay ? '#FFF' : 'rgb(158, 158, 158)' }} />;
                } else {
                    return <IconToNode definition={faMagnifyingGlass} sx={{ color: colorDisplay ? '#FFF' : 'rgb(158, 158, 158)' }} />;
                }
        }
    }, [modeEdit, colorDisplay]);

    return (
        <Box sx={{ height: '52px', display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0px',
                    height: '52px',
                    minWidth: '80px'
                }}
            >
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
                    {getIcon()}
                    <Typography sx={{ color: colorDisplay ? '#FFF' : 'rgb(158, 158, 158)' }}>{name}</Typography>
                </Box>
            </Box>
            {shouldShow ? (
                <Box onClick={() => onClick()}>
                    <IconToNode definition={faClose} sx={{ color: '#FFF' }} />
                </Box>
            ) : (
                <></>
            )}
        </Box>
    );
}
