import '@inovua/reactdatagrid-community/index.css';

import { TableCell, TableHead, TableRow } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export default function EventHistoryTableHead() {
    const position = 'left';
    return (
        <TableHead>
            <TableRow>
                <TableCell key={'createdDate'} align={position}>
                    <FormattedMessage id={'date'} />
                </TableCell>
                <TableCell key={'status'} align={position}>
                    <FormattedMessage id={'status'} />
                </TableCell>
                <TableCell key={'notes'} align={position}>
                    <FormattedMessage id={'notes'} />
                </TableCell>
                <TableCell key={'createdBy'} align={position}>
                    <FormattedMessage id={'user'} />
                </TableCell>
            </TableRow>
        </TableHead>
    );
}
