export class Group<K extends PropertyKey, V> extends Map<K, V> {
    convertToObject(): Record<K, V> {
        const result = {} as Record<K, V>;

        for (const [key, value] of this.entries()) {
            result[key] = value;
        }

        return result;
    }

    static convertFromObject<K extends PropertyKey, V>(object: Record<string, V>): Group<K, V> {
        const map = new Group<K, V>();

        for (const key of Object.keys(object)) {
            map.set(key as K, object[key]);
        }

        return map;
    }
}
