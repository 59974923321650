const enum GraphType {
    CURRENT_IMBALANCE = 0,
    NEUTRAL_CURRENT = 1,
    CONGESTION_VISIBILITY = 2,
    VOLTAGE = 3,
    THD = 4,
    PV_INJECTION = 5
}

const enum GraphTag {
    VIEW_PANEL = '&viewPanel=',
    START_DATE = '&startDate=',
    END_DATE = '&endDate='
}

const enum GraphPanel {
    // CURRENT IMBALANCE
    SUBSTATION_CURRENT_IMBALANCE_HOUR = 23,
    FEEDER_CURRENT_IMBALANCE_HOUR = 24,
    SUBSTATION_CURRENT_IMBALANCE_PERCENTAGE = 28,
    FEEDER_CURRENT_IMBALANCE_PERCENTAGE = 29,
    // NEUTRAL CURRENT
    SUBSTATION_CURRENT_VECTORS = 5,
    SUBSTATION_CURRENT_VECTORS_MAGNITUDES_HOUR = 32,
    SUBSTATION_CURRENT_VECTORS_MAGNITUDES_NEUTRAL_MAGNITUDE = 37,
    FEEDER_CURRENT_VECTORS = 30,
    FEEDER_CURRENT_VECTORS_MAGNITUDES_HOUR = 33,
    FEEDER_CURRENT_VECTORS_MAGNITUDES_NEUTRAL_MAGNITUDE = 35,
    // CONGESTION VISIBILITY
    SUBSTATION_POWER_OCCUPATION_HOUR = 36,
    SUBSTATION_ACTIVE_POWER_HOUR_ACTIVE_POWER = 17,
    SUBSTATION_REACTIVE_POWER_HOUR_REACTIVE_POWER = 18,
    SUBSTATION_POWER_OCCUPATION_PERCENTAGE = 34,
    SUBSTATION_ACTIVE_POWER = 38,
    SUBSTATION_REACTIVE_HOUR = 39,
    SUBSTATION_POWER_OCCUPATION_FORECAST = 41,
    FEEDER_PHASE_OVERCURRENT_FORECAST = 55,
    FEEDER_PHASE_CURRENT_OCCUPATION = 54,
    // VOLTAGE
    SUBSTATION_VOLTAGE_IMBALANCE_HOUR = 43,
    SUBSTATION_PHASE_VOLTAGE_VECTOR_SUM_MAGNITUDE_HOUR = 44,
    L1_PHASE_VOLTAGE_HOUR = 19,
    SUBSTATION_VOLTAGE_VECTORS = 42,
    FEEDER_VOLTAGE_IMBALANCE_HOUR = 45,
    SUBSTATION_VOLTAGE_IMBALANCE_VOLTAGE_IMBALANCE = 46,
    SUBSTATION_PHASE_VOLTAGE_VECTOR_SUM_MAGNITUDE_VOLTAGE = 47,
    L1_PHASE_VOLTAGE_VOLTAGE = 48,
    FEEDER_VOLTAGE_IMBALANCE_VOLTAGE_IMBALANCE = 49,
    // THD
    L1_PHASE_VOLTAGE_THD_HOUR = 20,
    L1_PHASE_CURRENT_THD_HOUR = 21,
    L1_FEEDER_PHASE_CURRENT_THD_HOUR = 22,
    L1_PHASE_VOLTAGE_THD_CURRENT_THD = 50,
    L1_PHASE_CURRENT_THD_CURRENT_THD = 51,
    L1_FEEDER_PHASE_CURRENT_THD_CURRENT_THD = 52,
    // PV INJECTION
    L1_PHASE_ACTIVE_POWER_HOUR = 25,
    L1_FEEDER_PHASE_ACTIVE_POWER_HOUR = 53
}

const enum DashboardGraphPanel {
    PHASE_VOLTAGE = 7,
    PHASE_VOLTAGE_THD = 8,
    PHASE_CURRENT_THD = 9,
    FEEDER_PHASE_CURRENT_THD = 10,
    SUBSTATION_ACTIVE_POWER = 19,
    SUBSTATION_REACTIVE_POWER = 20,
    SUBSTATION_CURRENT_IMBALANCE = 27,
    FEEDER_CURRENT_IMBALANCE = 28,
    FEEDER_ACTIVE_POWER_PRODUCTION = 29,
    SUBSTATION_NEUTRAL_CURRENT_MAGNITUDE = 30,
    FEEDER_NEUTRAL_CURRENT_MAGNITUDE = 31,
    FEEDER_PHASE_CURRENT_OCCUPATION_FORECAST = 32,
    SUBSTATION_POWER_OCCUPATION_FORECAST = 33,
    FEEDER_PHASE_CURRENT_OCCUPATION = 34,
    SUBSTATION_POWER_OCCUPATION = 35,
    SUBSTATION_VOLTAGE_IMBALANCE = 36,
    SUBSTATION_PHASE_VOLTAGE_VECTOR_SUM_MAGNITUDE = 37
}

const enum GraphTypeVisualization {
    HOURLY = 0,
    HISTOGRAM = 1
}

export { DashboardGraphPanel, GraphPanel, GraphTag, GraphType, GraphTypeVisualization };
