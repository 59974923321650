import { TableBody, TableCell, TableRow } from '@mui/material';
import { DevicesTableBodyProps } from 'views/Assets/utils/AssetModel';

import DevicesTableRow from './DevicesTableRow';

export default function DevicesTableBody(props: DevicesTableBodyProps) {
    const { data, fetchAsset } = props;

    return (
        <>
            {data.length <= 0 ? (
                <TableBody>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                        <TableCell align={'left'}>No records available</TableCell>
                    </TableRow>
                </TableBody>
            ) : (
                data.map((device, index: number) => {
                    return <DevicesTableRow key={index} device={device} fetchAsset={fetchAsset} />;
                })
            )}
        </>
    );
}
