import './LastDataPreview.css';

import CollapseCard from '_global/Components/base/cards/CollapseCard';
import { getData } from '_global/Services/api/networkRequester';
import { RootState } from '_global/Services/store';
import { hasLastDataSet } from '_global/Utils/hooks/jotai';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
    Avatar,
    Box,
    CircularProgress,
    Grid,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { useAtom } from 'jotai';
import { NewSimpleLastData } from 'models/dataStorage/NewSimpleLastData';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ILastDataStatusRequest } from 'views/Assets/utils/AssetModel';

import ResistancesTableRow from '../../last-data/ResistancesTableRow';
import { getAlarmSeverityColor, getPowerOccupationColor } from './EventSeverityColors';

export enum LastDataTabValue {
    GLOBAL = 0,
    EARTH = 1
}

const LastDataPreview = (props: { id: number }) => {
    const { id } = props;
    const features = useSelector((state: RootState) => state.features);
    const [lastData, setLastData] = useState<NewSimpleLastData | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [, setHasLastData] = useAtom(hasLastDataSet);
    const [statusRequest, setStatusRequest] = useState<ILastDataStatusRequest>({ global: false, earth: false });
    const [tabValue, setTabValue] = useState<string>(LastDataTabValue.GLOBAL.toString());

    const handleChangeTab = useCallback((event: SyntheticEvent, tab: LastDataTabValue) => {
        setTabValue(tab.toString());
    }, []);

    const fetchRequestGlobal = useCallback(() => {
        setIsLoading(true);
        const url = '/datastorage/api/Substation/NewLastData';
        const endpoint = `${features.configuration?.BaseUrl ?? ''}${url}?substationId=${id ?? ''}`;
        getData<NewSimpleLastData>(endpoint)
            .then((data) => {
                data && setStatusRequest({ ...statusRequest, global: data.supervisedLvPower || data.hasBusbarData, earth: data.hasEarth });
                setLastData(data);
                setIsLoading(false);
            })
            .catch((e) => {
                console.error(e);
                setIsLoading(false);
                setStatusRequest({ ...statusRequest, global: false, earth: false });
                setLastData(undefined);
            });
    }, [props]);

    useEffect(() => {
        fetchRequestGlobal();
    }, [props]);

    useEffect(() => {
        if (!statusRequest.global && statusRequest.earth) {
            setTabValue(LastDataTabValue.EARTH.toString());
        } else {
            setTabValue(LastDataTabValue.GLOBAL.toString());
        }
    }, [statusRequest]);

    useEffect(() => {
        setHasLastData(lastData?.hasFeedersData ?? false);
    }, [lastData]);

    const lastDataTableHeader = useCallback(() => {
        return (
            <>
                <TableCell />
                <TableCell align="left">
                    <FormattedMessage id="busbar_alarms" />
                </TableCell>
                <TableCell align="left" sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                    <FormattedMessage id="apparent_power" />
                </TableCell>

                {lastData?.feeders.map((feeder, index) => {
                    return (
                        <>
                            <TableCell align="left" key={feeder.feederId}>
                                {feeder.feederName}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{ borderRight: index < lastData.feeders.length - 1 ? '1px solid rgba(224, 224, 224, 1)' : '' }}
                            >
                                <FormattedMessage id="ocupation" />
                            </TableCell>
                        </>
                    );
                })}
            </>
        );
    }, [lastData]);

    const lastDataBusbar = (lineName: string) => {
        const busbar = lastData?.busbar;
        if (busbar && !busbar.lines) return <TableCell key={busbar?.feederId} />;

        const line = busbar?.lines.filter((line) => {
            return line.lineName === lineName;
        });

        if (!line || line.length === 0) return <TableCell key={busbar?.feederId} />;

        return (
            <>
                {line[0].apparentPower === -2 ? (
                    <TableCell />
                ) : (
                    <TableCell align="left" key={busbar?.feederId}>
                        <Stack direction="row" style={{ alignItems: 'center' }}>
                            <Avatar sx={{ bgcolor: getAlarmSeverityColor(line[0].alarmSeverity), width: 16, height: 16 }}>{''}</Avatar>
                            <label style={{ marginLeft: '4px', minWidth: '60px' }}>{`${line[0].vRms ?? ''} V`}</label>
                        </Stack>
                    </TableCell>
                )}
            </>
        );
    };

    const lastDataApparentPower = (lineName: string) => {
        const busbar = lastData?.busbar;
        if (busbar && !busbar.lines) return <TableCell key={busbar?.feederId} />;

        if (lineName === 'POWEROCUPATION') {
            return (
                <>
                    <Tooltip title={`${busbar?.fusePower ?? 0} kVA`}>
                        <TableCell>
                            <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 2 }}>
                                <Box
                                    sx={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        bgcolor: getPowerOccupationColor(busbar?.powerOcupation ?? 0),
                                        width: 78,
                                        height: 20,
                                        borderRadius: '16px'
                                    }}
                                >
                                    <label style={{ color: 'white' }}>{`${busbar?.powerOcupation?.toFixed(2) ?? '0'}%`}</label>
                                </Box>
                            </Box>
                        </TableCell>
                    </Tooltip>
                </>
            );
        }

        const line = busbar?.lines.filter((line) => {
            return line.lineName === lineName;
        });
        if (!line || line.length === 0) return <TableCell key={busbar?.feederId} />;

        return (
            <>
                {line[0].apparentPower === -2 ? (
                    <TableCell />
                ) : (
                    <TableCell
                        align="left"
                        key={busbar?.feederId}
                        sx={{ minWidth: '140px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                        <Stack direction="row" spacing={10} style={{ alignItems: 'center' }}>
                            <label style={{ marginLeft: '10px' }}>{`${line[0].apparentPower?.toFixed(2) ?? ''} kVA`}</label>
                        </Stack>
                    </TableCell>
                )}
            </>
        );
    };

    const lastDataValues = useCallback(
        (lineName: string) => {
            return (
                <>
                    <TableCell sx={{ minWidth: '150px' }}>
                        <label className="headers">
                            <FormattedMessage id={lineName.toLowerCase() ?? 'id'} />
                        </label>
                    </TableCell>
                    {lastDataBusbar(lineName)}
                    {lastDataApparentPower(lineName)}

                    {lastData?.feeders.map((feeder, index) => {
                        if (!feeder.lines) return <TableCell key={feeder.feederId} />;

                        if (lineName === 'POWEROCUPATION') {
                            return (
                                <>
                                    <TableCell key={feeder.feederName} />
                                    <TableCell />
                                </>
                            );
                        }

                        if (lineName === 'CURRENTUNBALANCE') {
                            return (
                                <>
                                    <TableCell align="left" key={feeder?.feederId} sx={{ minWidth: '100px' }}>
                                        <Box
                                            sx={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                                bgcolor: getPowerOccupationColor(feeder?.currentUnbalance ?? 0),
                                                width: 78,
                                                height: 20,
                                                borderRadius: '16px'
                                            }}
                                        >
                                            <label style={{ color: 'white' }}>{`${feeder?.currentUnbalance?.toFixed(2) ?? '0'}%`}</label>
                                        </Box>
                                    </TableCell>
                                    <TableCell />
                                </>
                            );
                        }

                        const line = feeder.lines.filter((line) => {
                            return line.lineName === lineName;
                        });
                        if (!line || line.length === 0) return <TableCell key={feeder.feederId} />;
                        return (
                            <>
                                <TableCell align="left" key={feeder.feederId} sx={{ minWidth: '120px' }}>
                                    <Stack direction="row" spacing={10} style={{ alignItems: 'center' }}>
                                        <Avatar sx={{ bgcolor: getAlarmSeverityColor(line[0].alarmSeverity), width: 16, height: 16 }}>
                                            {''}
                                        </Avatar>
                                        <label style={{ marginLeft: '4px', minWidth: '60px' }}>{`${
                                            line[0]?.iRms !== null ? `${line[0].iRms} A` : 'NA'
                                        }`}</label>
                                    </Stack>
                                </TableCell>
                                {line[0].powerOcupation === -2 ? (
                                    <TableCell />
                                ) : (
                                    <TableCell
                                        sx={{
                                            minWidth: '100px',
                                            borderRight: index < lastData.feeders.length - 1 ? '1px solid rgba(224, 224, 224, 1)' : ''
                                        }}
                                    >
                                        {line[0]?.powerOcupation === -1 ? (
                                            <Typography>
                                                <FormattedMessage id="na" />
                                            </Typography>
                                        ) : (
                                            <Tooltip title={`${feeder?.fusePower ?? 0} A`}>
                                                <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 2 }}>
                                                    <Box
                                                        sx={{
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            bgcolor: getPowerOccupationColor(line[0]?.powerOcupation ?? 0),
                                                            width: 70,
                                                            height: 20,
                                                            borderRadius: '16px'
                                                        }}
                                                    >
                                                        <label style={{ color: 'white' }}>{`${
                                                            line[0]?.powerOcupation?.toFixed(2) ?? '0'
                                                        }%`}</label>
                                                    </Box>
                                                </Box>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                )}
                            </>
                        );
                    })}
                </>
            );
        },
        [lastData]
    );

    const noData = useMemo(() => {
        return (
            <Grid alignItems="center" container direction="column" justifyContent="center">
                <Grid item sx={{ mt: '8%', mb: '6%' }}>
                    <FormattedMessage id={'no-data'} />
                </Grid>
            </Grid>
        );
    }, []);

    const lastDataTable = useCallback(() => {
        if (!lastData)
            return (
                <Grid alignItems="center" container direction="column" justifyContent="center">
                    <Grid item sx={{ mt: '8%', mb: '6%' }}>
                        <FormattedMessage id={'no-data'} />
                    </Grid>
                </Grid>
            );

        return (
            <>
                <TableContainer>
                    <Table aria-labelledby="lastDatatable" size={'small'}>
                        <TableHead>
                            <TableRow>{lastDataTableHeader()}</TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>{lastDataValues('L1')}</TableRow>
                            <TableRow>{lastDataValues('L2')}</TableRow>
                            <TableRow>{lastDataValues('L3')}</TableRow>
                            <TableRow>{lastDataValues('NEUTRAL')}</TableRow>
                            <TableRow>{lastDataValues('CURRENTUNBALANCE')}</TableRow>
                            <TableRow>{lastDataValues('POWEROCUPATION')}</TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }, [lastData]);

    const headers = ['line', 'date', 'resistance (ohm)'];

    return (
        <>
            <CollapseCard startExpanded={true} title={<FormattedMessage id={'overview'} />}>
                <TabContext value={tabValue.toString()}>
                    <TabList onChange={handleChangeTab} value={tabValue.toString()}>
                        <Tab label={<FormattedMessage id={'global'} />} value={LastDataTabValue.GLOBAL.toString()} />
                        {lastData?.hasEarth && <Tab label={<FormattedMessage id={'earth'} />} value={LastDataTabValue.EARTH.toString()} />}
                    </TabList>
                    <TabPanel value={LastDataTabValue.GLOBAL.toString()}>
                        {!isLoading ? (
                            lastData?.supervisedLvPower ? (
                                lastDataTable()
                            ) : (
                                noData
                            )
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                                <CircularProgress />
                            </Box>
                        )}
                    </TabPanel>
                    {lastData?.hasEarth && (
                        <TabPanel value={LastDataTabValue.EARTH.toString()}>
                            <TableContainer sx={{ px: 2, width: '100%' }}>
                                <Table sx={{ minWidth: 750 }}>
                                    {!isLoading ? (
                                        <>
                                            <TableRow>
                                                {headers.map((header) => (
                                                    <TableCell align="center" key={header} variant="head">
                                                        <Typography sx={{ fontSize: 12, color: 'gray' }} variant="body1">
                                                            <FormattedMessage id={header} />
                                                        </Typography>
                                                    </TableCell>
                                                ))}
                                            </TableRow>

                                            <ResistancesTableRow
                                                hasEarth={lastData.hasProtectionEarthData}
                                                lastData={lastData.protectionEarth}
                                                title="protection-earth"
                                            />
                                            <ResistancesTableRow
                                                hasEarth={lastData.hasServiceEarthData}
                                                lastData={lastData.serviceEarth}
                                                title="EARTH_SERVICE_LINE"
                                            />
                                        </>
                                    ) : (
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                                            <CircularProgress />
                                        </Box>
                                    )}
                                </Table>
                            </TableContainer>
                        </TabPanel>
                    )}
                </TabContext>
            </CollapseCard>
        </>
    );
};

export default LastDataPreview;
