import { getData } from '_global/Services/api/networkRequester';
import { availableLanguages, userLocale } from '_global/Utils/GetCurrentLocale';
import { modeAtomReports } from '_global/Utils/hooks/jotai';
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useAtom } from 'jotai';
import { useConfiguration } from 'logic/configuration/Hooks';
import Mode from 'models/enums/ModeEnum';
import { IReportDetails } from 'models/report/ReportResponse';
import { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export interface InfoTabProps {
    report: IReportDetails;
    handleOnChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string, id: string) => void;
    handleOnChangeDate: (event: Dayjs | null, id: string) => void;
}

export interface IReportTypes {
    report_name: string;
    title: string;
}

const InfoTab = (props: InfoTabProps) => {
    const { report, handleOnChange, handleOnChangeDate } = props;
    const [mode] = useAtom(modeAtomReports);
    const numberOfRows = 6;

    const [locale, setLocale] = useState('en-gb');
    const configuration = useConfiguration();
    const [reportTypes, setReportTypes] = useState<IReportTypes[]>([]);

    useEffect(() => {
        if (configuration) {
            const reportTypesUrl = '/api/reporttype';
            const endpoint = `${configuration.BaseUrl}${reportTypesUrl}`;
            getData<IReportTypes[]>(endpoint)
                .then((data) => {
                    setReportTypes(data);
                })
                .catch(() => {
                    setReportTypes([]);
                });
        }
    }, [configuration]);

    useEffect(() => {
        function loadData() {
            const langExist = availableLanguages.indexOf(userLocale.toLowerCase());
            if (langExist === -1) {
                const split = userLocale.split('-');
                const exist = availableLanguages.indexOf(split[0].toLowerCase());
                if (exist !== -1) {
                    setLocale(availableLanguages[exist]);
                }
            } else {
                setLocale(availableLanguages[langExist]);
            }
        }
        if (userLocale) {
            void loadData();
        }
    }, [userLocale]);

    const onTypeChange = (event: SelectChangeEvent<string>) => {
        handleOnChange(event as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 'report_type');
        const title = reportTypes.find((x) => x.report_name === event.target.value)?.title;
        title && handleOnChange(title, 'title');
    };

    return (
        <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDayjs}>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <TextField
                    disabled={mode === Mode.PREVIEW}
                    fullWidth
                    helperText={<FormattedMessage id={'reportName_helperText'} />}
                    label={<FormattedMessage id={'name'} />}
                    onChange={(event) => handleOnChange(event, 'name')}
                    required
                    value={report?.name}
                    variant="outlined"
                />
                <TextField
                    disabled={mode === Mode.PREVIEW}
                    fullWidth
                    helperText={<FormattedMessage id={'reportDescription_helperText'} />}
                    label={<FormattedMessage id={'description'} />}
                    multiline
                    onChange={(event) => handleOnChange(event, 'description')}
                    rows={numberOfRows}
                    value={report?.description}
                    variant="outlined"
                />
                <FormControl fullWidth required>
                    <InputLabel id="reportTypeLabel">
                        <FormattedMessage id="report_type" />
                    </InputLabel>
                    <Select
                        disabled={mode === Mode.PREVIEW}
                        id="report_type"
                        label={<FormattedMessage id="report_type" />}
                        labelId="reportTypeLabel"
                        onChange={(e: SelectChangeEvent<string>) => onTypeChange(e)}
                        value={report?.report_type}
                    >
                        {reportTypes.map((value, index) => {
                            return (
                                <MenuItem key={index} value={value.report_name}>
                                    {value.title}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText>Choose the report typology.</FormHelperText>
                </FormControl>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Typography variant="h5">Date Range</Typography>
                    <Typography>Change the data range for the report..</Typography>
                    <Grid columns={2} container>
                        <Grid item style={{ paddingRight: 5 }} xs={1}>
                            <DatePicker
                                disabled={mode === Mode.PREVIEW}
                                label={<FormattedMessage id="period_start" />}
                                onChange={(event: Dayjs | null) => handleOnChangeDate(event, 'period_start')}
                                renderInput={(params) => <TextField fullWidth id={'period_start'} {...params} />}
                                value={report?.period_start ? dayjs(report?.period_start as string) : null}
                            />
                        </Grid>
                        <Grid item style={{ paddingLeft: 5 }} xs={1}>
                            <DatePicker
                                disabled={mode === Mode.PREVIEW}
                                label={<FormattedMessage id="period_end" />}
                                onChange={(event: Dayjs | null) => handleOnChangeDate(event, 'period_end')}
                                renderInput={(params) => <TextField fullWidth id={'period_end'} {...params} />}
                                value={report?.period_end ? dayjs(report?.period_end as string) : null}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </LocalizationProvider>
    );
};

export default InfoTab;
