import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CATEGORY } from 'models/asset/AssetHistoricalData';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { ICategory } from 'views/Assets/utils/AssetModel';

export default function Category(props: ICategory) {
    const { onChange, category, assetModel, hasLastData } = props;

    const onCategoryChange = useCallback(
        (event: SelectChangeEvent<CATEGORY>) => {
            onChange(event.target.value as CATEGORY);
        },
        [category]
    );

    return (
        <Grid key={'category'} item xs={2} sx={{ width: '100%', pl: 0 }}>
            <FormControl fullWidth>
                <InputLabel htmlFor="category">
                    <FormattedMessage id={'category'} />
                </InputLabel>
                <Select id="category" value={category} onChange={(e) => onCategoryChange(e)} label={<FormattedMessage id={'category'} />}>
                    {Object.values(CATEGORY)
                        .slice(hasLastData ? 0 : 4, assetModel && assetModel.hasEarth ? 5 : -2)
                        .map((value, index) => {
                            return (
                                <MenuItem key={index} value={value}>
                                    <FormattedMessage id={value} />
                                </MenuItem>
                            );
                        })}
                </Select>
            </FormControl>
        </Grid>
    );
}
