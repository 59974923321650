import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export default function DateCell(props: { value: Date }) {
    const { value } = props;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {value ? (
                <>
                    <Typography>
                        {new Date(value).toLocaleString([], {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit'
                        })}
                    </Typography>
                    <Typography>
                        {new Date(value).toLocaleString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                        })}
                    </Typography>
                </>
            ) : (
                <Typography>{<FormattedMessage id={'na'} />}</Typography>
            )}
        </Box>
    );
}
