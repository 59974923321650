import { CompanyByIDInstance, CompanyInstance } from 'queries/Companys';

export type ProcessedCompany = {
    id: number;
    name: string;
    country: string;
    description: string;
    website: string | null;
    image: string | null;
    enterpriseId: number;
};

export function companyToRow(event: CompanyByIDInstance | CompanyInstance): ProcessedCompany {
    return {
        id: Number(event.id),
        name: String(event.name),
        country: String(event.country),
        description: String(event.description),
        website: event.website,
        image: event.image,
        enterpriseId: Number(event.enterpriseId)
    };
}
