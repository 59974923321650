import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { Configuration } from 'logic/configuration/Configuration';

const cache = new InMemoryCache();

const customFetch = (uri: string, options: Record<string, any>) => {
    const { operationName } = JSON.parse(options.body);

    return fetch(`${uri}?service=${window.location.pathname.split('/')[1]}&tableName=${operationName}`, options);
};
function httpLink(features: Configuration | undefined): ApolloLink {
    return createHttpLink({
        uri: `${features?.BaseUrl ?? ''}/graphql`,
        fetch: customFetch
    });
}

const graphQLClient = (features: Configuration | undefined) => {
    console.log('Refreshing GraphQL');

    return new ApolloClient({
        link: httpLink(features),
        cache: cache
    });
};

export { graphQLClient };
