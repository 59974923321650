export enum CATEGORY {
    MEASURE = 'measure',
    POWER = 'power',
    ENERGY = 'energy',
    THD = 'thd',
    EARTH_SYSTEM = 'earth_system',
    NETWORK = 'network'
}

export enum HISTORICAL_DATA_TYPE {
    MIN = 'min',
    MEAN = 'mean',
    MAX = 'max'
}

export enum HISTORICAL_DATA_TIME_FRAME {
    TODAY = 'today',
    LAST_WEEK = 'last_week',
    LAST_MONTH = 'last_month',
    LAST_THREE_MONTH = 'last_three_months',
    LAST_SIX_MONTH = 'last_six_months',
    WINDOW = 'window'
}

export enum HISTORICAL_DATA_AGGREGATION_UNIT {
    NONE = 'none',
    MINUTES = 'minutes',
    HOURS = '1h',
    DAYS = '1d',
    MONTHS = '1mo',
    YEARS = '1y'
}

export enum HISTORICAL_DATA_LINE {
    L1 = '10',
    L2 = '11',
    L3 = '12',
    N = '13'
}

export enum HISTORICAL_DATA_MINUTES {
    FIVE_MINUTES = '5m',
    TEN_MINUTES = '10m',
    FIFTEEN_MINUTES = '15m',
    THIRTY_MINUTES = '30m'
}

export const HISTORICAL_DATA_VIEW_PANEL = {
    measure: [10, 11],
    power: [12, 13],
    energy: [16],
    thd: [14, 15],
    network: [17],
    earth_system: [3]
};
