import './ControlPanel.css';

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { Feature, FeatureCollection } from 'geojson';
import { useCallback, useEffect, useState } from 'react';

import ButtonComponent from '../button/Button';
import MapPolygonsHandler from './logic/MapPolygonsHandler';

export type MapFeaturesControlProps = {
    mapDraw: MapboxDraw;
    polygonHandler: MapPolygonsHandler;
    topMargin?: number;
};

export default function MapFeaturesControl(props: MapFeaturesControlProps) {
    const { mapDraw, polygonHandler, topMargin } = props;

    const [currentMode, setCurrentMode] = useState('simple_select');

    const [internalPolygons, setInternalPolygons] = useState<Feature[]>(polygonHandler.polygons ?? []);
    const [selectedPolygons, setSelectedPolygons] = useState<Feature[]>([]);

    useEffect(() => {
        polygonHandler.changeIsCreateAllowed(!(internalPolygons.length == 1));
    }, [internalPolygons]);

    useEffect(() => {
        polygonHandler.onChangedListener = (e) => setInternalPolygons(e);
        polygonHandler.onSelectedListener = (e) => setSelectedPolygons(e);

        return () => {
            polygonHandler.onSelectedListener = undefined;
            polygonHandler.onChangedListener = undefined;
        };
    }, []);

    useEffect(() => {
        setCurrentMode(mapDraw.getMode());
    }, [selectedPolygons]);

    useEffect(() => {
        if (internalPolygons.length > 0 && mapDraw.getAll().features.length === 0) {
            const collection: FeatureCollection = {
                type: 'FeatureCollection',
                features: [...internalPolygons]
            };

            mapDraw.add(collection);
        }
    }, [internalPolygons, currentMode]);

    useEffect(() => {
        switch (currentMode) {
            case 'draw_polygon':
                mapDraw.changeMode('draw_polygon');
                break;
            case 'simple_select':
                setSelectedPolygons([]);
                mapDraw.changeMode('simple_select', { featureIds: [] });
                break;
        }
    }, [currentMode]);

    const removeSelected = useCallback(() => {
        const ids = selectedPolygons.filter((element) => element.id !== undefined).map((element) => element.id?.toString() ?? '');

        mapDraw.delete(ids);
        polygonHandler.onChange(mapDraw.getAll().features);
        setInternalPolygons(polygonHandler.polygons);

        setSelectedPolygons([]);
        polygonHandler.changeIsCreateAllowed(true);
        setCurrentMode('simple_select');
    }, [selectedPolygons]);

    const stopDrawing = useCallback(() => {
        const features = mapDraw
            .getAll()
            .features.map((element) => element.id)
            .filter((element) => element !== undefined);

        setCurrentMode('simple_select');
        mapDraw.delete(features[features.length - 1]?.toString() ?? '');
    }, []);

    return (
        <Box className="control-panel" sx={{ marginTop: topMargin !== undefined ? `${topMargin}px!important` : '5px' }}>
            <Stack spacing={1}>
                <Typography variant="h4">Edit Mode</Typography>
                <Divider />

                {polygonHandler.isRemoveAllowed && selectedPolygons.length > 0 && mapDraw.getAll().features.length > 0 && (
                    <ButtonComponent onClick={removeSelected} title="Remove" />
                )}

                {polygonHandler.isCreateAllowed && currentMode === 'simple_select' && selectedPolygons.length === 0 && (
                    <ButtonComponent
                        disabled={currentMode !== 'simple_select' && selectedPolygons.length === 0}
                        onClick={() => setCurrentMode('draw_polygon')}
                        title="Create"
                    />
                )}

                {currentMode === 'draw_polygon' && <ButtonComponent onClick={stopDrawing} title="Cancel drawing" />}
            </Stack>
        </Box>
    );
}
