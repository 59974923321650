import { IconToNode } from '_global/Utils/IconToNode';
import { faBuilding, faCircleNodes, faMapLocationDot, faMountains, faUser, faUsersRectangle } from '@fortawesome/pro-solid-svg-icons';
import { Badge, Box, Checkbox, Grid, Typography } from '@mui/material';
import { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Mode from 'models/enums/ModeEnum';
import { IAssetReportDetails, IItemReportDetails, ReportSections } from 'models/report/ReportResponse';
import { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';

export interface AssetsListItemProps {
    draggedItems: IItemReportDetails[] | IAssetReportDetails[];
    numberOfCheckedItems: () => number | undefined;
    handleToggleAllItems: () => MouseEventHandler<HTMLButtonElement>;
    title: string;
    section: ReportSections;
    mode: Mode;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -10,
        top: 5,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px'
    }
}));

const styleIcon = { fontSize: 16, color: 'black' };

export const ListItemTitle = (props: AssetsListItemProps) => {
    const { draggedItems, numberOfCheckedItems, handleToggleAllItems, title, section, mode } = props;
    return (
        <Box>
            <Grid container direction="row">
                <Grid item sx={{ mx: -1 }}>
                    <Checkbox
                        checked={numberOfCheckedItems() === draggedItems.length && draggedItems.length !== 0}
                        disabled={draggedItems.length === 0 || mode === Mode.PREVIEW}
                        indeterminate={numberOfCheckedItems() !== draggedItems.length && numberOfCheckedItems() !== 0}
                        onClick={handleToggleAllItems()}
                    />
                </Grid>
                <Grid item sx={{ mx: 1, mt: 1.5 }}>
                    {section === ReportSections.ACCESSGROUPS && <IconToNode definition={faUsersRectangle} sx={styleIcon} />}
                    {section === ReportSections.COMPANIES && <IconToNode definition={faBuilding} sx={styleIcon} />}
                    {section === ReportSections.REGIONS && <IconToNode definition={faMountains} sx={styleIcon} />}
                    {section === ReportSections.OPAREAS && <IconToNode definition={faMapLocationDot} sx={styleIcon} />}
                    {section === ReportSections.ASSETS && <IconToNode definition={faCircleNodes} sx={styleIcon} />}
                    {section === ReportSections.USERS && <IconToNode definition={faUser} sx={styleIcon} />}
                </Grid>

                <Grid item>
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: 'black',
                            fontWeight: 'bold',
                            mt: 1.5,
                            textOverflow: 'ellipsis'
                        }}
                    >
                        <StyledBadge badgeContent={`${draggedItems.length}`} color="secondary">
                            <Box sx={{ mx: 0.5 }}>
                                <FormattedMessage id={title} />
                            </Box>
                        </StyledBadge>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ mx: 4, my: -1, mb: 1 }}>
                <Grid item>
                    {`${numberOfCheckedItems() ?? 0}/${draggedItems.length} `}
                    <FormattedMessage id={String('selected')} />
                </Grid>
            </Grid>
        </Box>
    );
};
