import { COLORS } from '_global/Utils/Colors';
import { IconToNode } from '_global/Utils/IconToNode';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import { Button } from '@mui/material';
import { AssetResponse } from 'models/asset/AssetResponse';
import { ASSET_STATE, ASSET_TYPE } from 'models/graphql-global-types';
import { AccessGroup, AssetAccessGroup, ItemAccessGroup } from 'models/layout/AccessGroup';
import { Company } from 'models/layout/Company';
import { OperationalArea } from 'models/layout/OperationalArea';
import { RegionDetails } from 'models/layout/Region';
import { IReportDetails } from 'models/report/ReportResponse';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import AssetStateCell from 'views/Assets/grid/cells/AssetStateCell';
import AssetTypeCell from 'views/Assets/grid/cells/AssetTypeCell';
import StatusCell from 'views/Assets/grid/cells/StatusCell';
import DateCell from 'views/Events/grid/cells/DateCell';

import CountryCell from './Cells/CountryCell';

export function renderColumnsCompanies(
    report: IReportDetails,
    handleChangeAll: (id: string, items: ItemAccessGroup[] | AssetAccessGroup[]) => void
): TypeColumn[] {
    return [
        {
            name: '',
            flex: 0.5,
            visible: true,
            render: ({ data }) => {
                const companyData = useMemo(() => {
                    const company = data as Company;
                    return { id: company.id, name: company.name };
                }, [data]);

                return (
                    <Button
                        disabled={report.companies.findIndex((x) => x.id === companyData.id) !== -1}
                        onClick={() => {
                            const index = report.companies.findIndex((x) => x.id === companyData.id);
                            if (index === -1) {
                                const arr = [...report.companies];
                                arr.push({ id: companyData.id ?? 0, name: companyData.name });
                                handleChangeAll('companies', arr);
                            }
                        }}
                        startIcon={<IconToNode definition={faPlusCircle} />}
                        sx={{ background: COLORS.ENEIDA_ALTERNATIVE_BLUE }}
                        variant="contained"
                    >
                        Add
                    </Button>
                );
            }
        },
        {
            name: 'name',
            header: <FormattedMessage id={'name'} />,
            flex: 1,
            visible: true
        },
        {
            name: 'country',
            header: <FormattedMessage id={'country'} />,
            flex: 0.5,
            visible: true,
            render: ({ value }) => <CountryCell country={String(value)} />
        },
        {
            name: 'website',
            header: <FormattedMessage id={'website'} />,
            flex: 1,
            visible: true,
            render: ({ value }) => {
                if (value) return String(value);
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'description',
            header: <FormattedMessage id={'description'} />,
            flex: 1.5,
            visible: true,
            render: ({ value }) => {
                if (value) return String(value);
                else return <FormattedMessage id={'na'} />;
            }
        }
    ];
}

export function renderColumnsRegions(
    report: IReportDetails,
    handleChangeAll: (id: string, items: ItemAccessGroup[] | AssetAccessGroup[]) => void
): TypeColumn[] {
    return [
        {
            name: '',
            flex: 0.5,
            visible: true,
            render: ({ data }) => {
                const regionData = useMemo(() => {
                    const region = data as RegionDetails;
                    return { id: region.id, name: region.name };
                }, [data]);

                return (
                    <Button
                        disabled={report.regions.findIndex((x) => x.id === regionData.id) !== -1}
                        onClick={() => {
                            const index = report.regions.findIndex((x) => x.id === regionData.id);
                            if (index === -1) {
                                const arr = [...report.regions];
                                arr.push({ id: regionData.id ?? 0, name: regionData.name });
                                handleChangeAll('regions', arr);
                            }
                        }}
                        startIcon={<IconToNode definition={faPlusCircle} />}
                        sx={{ background: COLORS.ENEIDA_ALTERNATIVE_BLUE }}
                        variant="contained"
                    >
                        Add
                    </Button>
                );
            }
        },
        {
            name: 'name',
            header: <FormattedMessage id={'name'} />,
            flex: 1.5,
            visible: true,
            render: ({ value }) => <FormattedMessage id={String(value)} />
        },
        {
            name: 'code',
            header: <FormattedMessage id={'code'} />,
            flex: 1.5
        }
    ];
}

export function renderColumnsOpAreas(
    report: IReportDetails,
    handleChangeAll: (id: string, items: ItemAccessGroup[] | AssetAccessGroup[]) => void
): TypeColumn[] {
    return [
        {
            name: '',
            flex: 0.5,
            visible: true,
            render: ({ data }) => {
                const OpAreaData = useMemo(() => {
                    const opArea = data as OperationalArea;
                    return { id: opArea.id, name: opArea.name };
                }, [data]);

                return (
                    <Button
                        disabled={report.operationalAreas.findIndex((x) => x.id === OpAreaData.id) !== -1}
                        onClick={() => {
                            const index = report.operationalAreas.findIndex((x) => x.id === OpAreaData.id);
                            if (index === -1) {
                                const arr = [...report.operationalAreas];
                                arr.push({ id: OpAreaData.id ?? 0, name: OpAreaData.name });
                                handleChangeAll('operationalAreas', arr);
                            }
                        }}
                        startIcon={<IconToNode definition={faPlusCircle} />}
                        sx={{ background: COLORS.ENEIDA_ALTERNATIVE_BLUE }}
                        variant="contained"
                    >
                        Add
                    </Button>
                );
            }
        },
        {
            name: 'name',
            header: <FormattedMessage id={'name'} />,
            flex: 1.5
        },
        {
            name: 'code',
            header: <FormattedMessage id={'code'} />,
            flex: 1.5
        }
    ];
}

export function renderColumnsAssets(
    report: IReportDetails,
    handleChangeAll: (id: string, items: ItemAccessGroup[] | AssetAccessGroup[]) => void
): TypeColumn[] {
    return [
        {
            name: '',
            flex: 0.5,
            visible: true,
            render: ({ data }) => {
                const assetData = useMemo(() => {
                    const asset = data as AssetResponse;
                    return {
                        id: asset.id,
                        name: asset.name,
                        registryNumber: asset.registryNumber,
                        externalAssetId: asset.id
                    };
                }, [data]);

                return (
                    <Button
                        disabled={report.assets.findIndex((x) => x.id === assetData.id) !== -1}
                        onClick={() => {
                            const index = report.assets.findIndex((x) => x.id === assetData.id);
                            if (index === -1) {
                                const arr = [...report.assets];
                                arr.push({
                                    id: assetData.id ?? 0,
                                    name: assetData.name ?? '',
                                    registryNumber: assetData.registryNumber ?? '',
                                    externalAssetId: assetData.externalAssetId
                                });
                                handleChangeAll('assets', arr);
                            }
                        }}
                        startIcon={<IconToNode definition={faPlusCircle} />}
                        sx={{ background: COLORS.ENEIDA_ALTERNATIVE_BLUE }}
                        variant="contained"
                    >
                        Add
                    </Button>
                );
            }
        },
        {
            name: 'assetType',
            header: <FormattedMessage id={'assetType'} />,
            flex: 0.5,
            minWidth: 150,
            visible: true,
            render: ({ value }) => <AssetTypeCell type={value as ASSET_TYPE} />
        },
        {
            name: 'registryNumber',
            header: <FormattedMessage id={'registryNumber'} />,
            flex: 0.5,
            visible: true,
            render: ({ value }) => <FormattedMessage id={String(value)} />
        },
        {
            name: 'name',
            header: <FormattedMessage id={'name'} />,
            flex: 0.5,
            visible: true,
            render: ({ value }) => <FormattedMessage id={String(value)} />
        },
        {
            name: 'longitude',
            header: <FormattedMessage id={'longitude'} />,
            flex: 0.5,
            visible: false,
            render: ({ value }) => {
                if (value) return <FormattedMessage id={String(value)} />;
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'latitude',
            header: <FormattedMessage id={'latitude'} />,
            flex: 0.5,
            visible: false,
            render: ({ value }) => {
                if (value) return <FormattedMessage id={String(value)} />;
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'device',
            header: <FormattedMessage id={'device'} />,
            flex: 0.5,
            visible: false,
            render: ({ value }) => {
                if (value) return <FormattedMessage id={String(value)} />;
                else return <FormattedMessage id={'no-device'} />;
            }
        },
        {
            name: 'lastCommunication',
            header: <FormattedMessage id={'lastCommunication'} />,
            flex: 0.6,
            visible: false,
            render: ({ value }) => {
                const date = value as Date;
                if (date && date.getDate()) return <FormattedMessage id={String(value)} />;
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'operationalArea',
            header: <FormattedMessage id={'operationalArea'} />,
            flex: 0.5,
            visible: true,
            render: ({ value }) => {
                if (value) return <FormattedMessage id={String(value)} />;
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'region',
            header: <FormattedMessage id={'region'} />,
            flex: 0.5,
            visible: true,
            render: ({ value }) => {
                if (value) return <FormattedMessage id={String(value)} />;
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'alarmStandardName',
            header: <FormattedMessage id={'alarmStandardName'} />,
            flex: 0.5,
            visible: true,
            render: ({ value }) => {
                if (value) return <FormattedMessage id={String(value)} />;
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'parentAssetName',
            header: <FormattedMessage id={'parentAssetName'} />,
            flex: 0.5,
            visible: false,
            render: ({ value }) => {
                if (value) return <FormattedMessage id={String(value)} />;
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'parentFeederName',
            header: <FormattedMessage id={'parentFeederName'} />,
            flex: 0.5,
            visible: false,
            render: ({ value }) => {
                if (value) return <FormattedMessage id={String(value)} />;
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'state',
            header: <FormattedMessage id={'state'} />,
            flex: 0.5,
            visible: true,
            render: ({ value }) => <AssetStateCell state={value as ASSET_STATE} />
        },
        {
            name: 'description',
            header: <FormattedMessage id={'description'} />,
            flex: 0.5,
            visible: false,
            render: ({ value }) => {
                if (value) return <FormattedMessage id={String(value)} />;
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'createdDate',
            header: <FormattedMessage id={'createdDate'} />,
            flex: 0.5,
            visible: false,
            render: ({ value }) => {
                const date = value as Date;
                if (date && date.getDate()) return <DateCell value={date} />;
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'lastModifiedDate',
            header: <FormattedMessage id={'lastModifiedDate'} />,
            flex: 0.5,
            visible: false,
            render: ({ value }) => {
                const date = value as Date;
                if (date && date.getDate()) return <DateCell value={date} />;
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'isEnabled',
            header: <FormattedMessage id={'isEnabled'} />,
            flex: 0.5,
            visible: false,
            render: ({ value }) => <StatusCell isEnable={value as boolean} />
        }
    ];
}

export function renderColumnsAccessGroups(
    report: IReportDetails,
    handleChangeAll: (id: string, items: ItemAccessGroup[] | AssetAccessGroup[]) => void
): TypeColumn[] {
    return [
        {
            name: '',
            flex: 0.4,
            visible: true,
            render: ({ data }) => {
                const accessGroupData = useMemo(() => {
                    const accessGroup = data as AccessGroup;
                    return {
                        id: accessGroup.id,
                        name: accessGroup.name
                    };
                }, [data]);

                return (
                    <Button
                        disabled={report.accessGroups.findIndex((x) => x.id === accessGroupData.id) !== -1}
                        onClick={() => {
                            const index = report.accessGroups.findIndex((x) => x.id === accessGroupData.id);
                            if (index === -1) {
                                const arr = [...report.accessGroups];
                                arr.push({
                                    id: accessGroupData.id ?? 0,
                                    name: accessGroupData.name ?? ''
                                });
                                handleChangeAll('accessGroups', arr);
                            }
                        }}
                        startIcon={<IconToNode definition={faPlusCircle} />}
                        sx={{ background: COLORS.ENEIDA_ALTERNATIVE_BLUE }}
                        variant="contained"
                    >
                        Add
                    </Button>
                );
            }
        },
        {
            name: 'name',
            header: <FormattedMessage id={'name'} />,
            flex: 0.5,
            visible: true
        },
        {
            name: 'description',
            header: <FormattedMessage id={'description'} />,
            flex: 0.5,
            visible: true,
            render: ({ value }) => {
                if (value) return String(value);
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'isEnabled',
            header: <FormattedMessage id={'isEnabled'} />,
            flex: 0.5,
            visible: true,
            render: ({ value }) => <StatusCell isEnable={value as boolean} />
        },
        {
            name: 'createdBy',
            header: <FormattedMessage id={'createdBy'} />,
            flex: 0.5,
            visible: false,
            render: ({ value }) => {
                if (value) return String(value);
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'createdDate',
            header: <FormattedMessage id={'createdDate'} />,
            flex: 0.5,
            visible: false,
            render: ({ value }) => {
                const date = value as Date;
                if (date && date.getDate()) return <DateCell value={value as Date} />;
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'lastModifiedBy',
            header: <FormattedMessage id={'lastModifiedBy'} />,
            flex: 0.5,
            visible: false,
            render: ({ value }) => {
                if (value) return String(value);
                else return <FormattedMessage id={'na'} />;
            }
        },
        {
            name: 'lastModifiedDate',
            header: <FormattedMessage id={'lastModifiedDate'} />,
            flex: 0.5,
            visible: false,
            render: ({ value }) => {
                const date = value as Date;
                if (date && date.getDate()) return <DateCell value={value as Date} />;
                else return <FormattedMessage id={'na'} />;
            }
        }
    ];
}
