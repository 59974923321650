import { DEVICE_TYPE } from 'models/graphql-global-types';
import { DevicesInstance } from 'queries/Devices';

export interface CreateDevice {
    serialNumber: string;
    name: string | null;
    externalReference: string | null;
    description: string | null;
}

export interface DeviceResponse {
    id: number;
    bulkConfigurationId: number;
    serialNumber: string;
    deviceType: DEVICE_TYPE;
    deviceToken: string;
    isEnabled: boolean;
    isDeleted: boolean;
    acquisitionRate: number;
    communicationRate: number;
    lastCommunication: Date;
    configRefreshInterval: number;
    lastConfigTs: string;
    createdBy: string;
    createdDate: Date;
    lastModifiedBy: string;
    lastModifiedDate: Date;
}

export function deviceToRow(device: DevicesInstance): DeviceResponse {
    return {
        id: Number(device.id),
        bulkConfigurationId: Number(device.bulkConfigurationId),
        serialNumber: String(device.serialNumber),
        deviceType: device.deviceType,
        isEnabled: device?.isEnabled ?? false,
        deviceToken: device.deviceToken,
        isDeleted: device.isDeleted,
        acquisitionRate: Number(device.acquisitionRate),
        communicationRate: Number(device.communicationRate),
        configRefreshInterval: Number(device.configRefreshInterval),
        lastConfigTs: String(device?.lastConfigTs),
        lastCommunication: new Date(String(device.lastCommunication)),
        createdBy: String(device?.createdBy),
        lastModifiedBy: String(device?.lastModifiedBy),
        createdDate: new Date(String(device.createdDate)),
        lastModifiedDate: new Date(String(device.lastModifiedDate))
    };
}

export const defaultDeviceValidationForm = [
    {
        name: 'bulkConfigurationId',
        hasError: false
    },

    {
        name: 'apnName',
        hasError: false
    },
    {
        name: 'apnUser',
        hasError: false
    },
    {
        name: 'apnAuthType',
        hasError: false
    },
    {
        name: 'apnPwd',
        hasError: false
    },

    {
        name: 'groupId',
        hasError: false
    },
    {
        name: 'username',
        hasError: false
    },
    {
        name: 'password',
        hasError: false
    },
    {
        name: 'url',
        hasError: false
    },
    {
        name: 'certificateUrl',
        hasError: false
    },
    {
        name: 'serialNumber',
        hasError: false
    },
    {
        name: 'serverAddress',
        hasError: false
    },
    {
        name: 'serverPort',
        hasError: false
    }
];
