import { IconToNode } from '_global/Utils/IconToNode';
import { faBrowser, faTimer } from '@fortawesome/pro-solid-svg-icons';
import { Box, Grid, Typography } from '@mui/material';
import { SOURCE } from 'models/graphql-global-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export default function SourceCell(props: { source: SOURCE }) {
    const { source } = props;
    const intl = useIntl();

    const sourceStyle = useMemo(() => {
        switch (source) {
            case SOURCE.DEVICE:
                return { icon: faTimer, title: 'real_time', bgColor: '#0097a7' };
            case SOURCE.DEEPGRID:
                return { icon: faBrowser, title: 'platform', bgColor: '#009688' };
        }
    }, [source]);

    return (
        <Box
            sx={{
                borderRadius: 2,
                width: 110,
                height: '35px',
                backgroundColor: sourceStyle.bgColor,
                my: 1,
                px: 1
            }}
        >
            <Grid container direction="row" spacing={1}>
                <Grid item>{sourceStyle.icon && <IconToNode definition={sourceStyle.icon} sx={{ color: 'white' }} />}</Grid>
                <Grid item>
                    <Typography fontSize={14} sx={{ fontWeight: 'bold', my: 0.2, color: 'white' }}>
                        {intl.formatMessage({ id: sourceStyle.title })}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
