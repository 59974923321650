import DeepgridLoader from '_global/Components/base/DeepgridLoader';
import { modeAtomReports } from '_global/Utils/hooks/jotai';
import { useLazyQuery } from '@apollo/client';
import { Box } from '@mui/system';
import { useAtom } from 'jotai';
import Mode from 'models/enums/ModeEnum';
import { IReportDetails } from 'models/report/ReportResponse';
import { IReportInstance, REPORT } from 'queries/Reports';
import { Reports } from 'queries/Types/Reports';
import { useCallback, useEffect, useState } from 'react';

import ReportDetails from './ReportDetails';

export default function ReportsContent(props: { reportId: string; onSubTabChange: (value: string) => void }) {
    const { reportId, onSubTabChange } = props;

    const [getReport] = useLazyQuery<Reports>(REPORT);

    const [report, setReport] = useState<IReportInstance>();
    const [isLoading, setIsLoading] = useState(true);
    const [mode] = useAtom(modeAtomReports);

    const fetchReport = useCallback(async () => {
        setIsLoading(true);
        const {
            data: reportData,
            error: reportError,
            loading: isReportLoading
        } = await getReport({ variables: { reportId: reportId ?? window.location.pathname.split('/')[3] } });

        if (reportData && reportData?.reports?.nodes) {
            setReport(reportData?.reports?.nodes[0]);
            setIsLoading(false);
        }

        if (reportError) {
            console.error(reportError);
        }

        if (!isReportLoading) {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (mode === Mode.PREVIEW) {
            void fetchReport();
        }
    }, [mode, props]);

    return (
        <Box>
            <DeepgridLoader open={isLoading} />
            {report && <ReportDetails onSubTabChange={(value) => onSubTabChange(value)} report={report as IReportDetails} />}
        </Box>
    );
}
