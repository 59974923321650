import { gql } from '@apollo/client';

import { standards_alarmStandards_nodes } from './Types/standards';

export type AlarmStandardsInstance = standards_alarmStandards_nodes;

export const STANDARDS = gql`
    query standards($first: Int, $filter: AlarmStandardFilterInput, $order: [AlarmStandardSortInput!]) {
        alarmStandards(where: $filter, first: $first, order: $order) {
            nodes {
                name
                id
                lastModifiedDate
                lastModifiedBy
                editable
                createdDate
                createdBy
            }
            totalCount
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;
