import type { FillLayer } from 'react-map-gl';
import type { LayerProps } from 'react-map-gl';

// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
export const dataLayer: FillLayer = {
    id: 'data',
    type: 'fill',
    paint: {
        'fill-color': '#3ed263',
        'fill-opacity': 0.5
    }
};

export const auxDataLayer: FillLayer = {
    id: 'auxData',
    type: 'fill',
    paint: {
        'fill-color': '#E86838',
        'fill-opacity': 0.2
    }
};

const colors = ['#D84315', '#FFC107', '#388E3C'];

export const clusterLayer: LayerProps = {
    id: 'clusters',
    type: 'circle',
    source: 'markers',
    filter: ['has', 'point_count'],
    paint: {
        'circle-color': ['step', ['get', 'point_count'], colors[2], 100, colors[1], 750, colors[0]],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
};

export const clusterCountLayer: LayerProps = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'markers',
    filter: ['has', 'point_count'],
    layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
    },
    paint: {
        'text-color': '#ffffff'
    }
};

export const unclusteredPointLayer: LayerProps = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'markers',
    filter: ['!', ['has', 'point_count']],
    paint: {
        'circle-radius': {
            base: 7,
            stops: [
                [12, 7],
                [44, 180]
            ]
        },
        'circle-color': [
            'match',
            ['get', 'markers'],
            'CRITICAL',
            colors[0],
            'WARNING',
            colors[1],
            'INFO',
            colors[2],
            'EMPTY',
            colors[2],
            /* other */ colors[2]
        ],
        'circle-stroke-width': 0,
        'circle-stroke-color': '#fff'
    }
};
