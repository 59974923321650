import '@inovua/reactdatagrid-community/index.css';

import { IconToNode } from '_global/Utils/IconToNode';
import { faChartMixed } from '@fortawesome/pro-solid-svg-icons';
import { Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

export default function MeasurementsTitle() {
    const intl = useIntl();
    const iconEntityStyle = { fontSize: 30, color: '#79B473' };

    return (
        <Grid container direction="row" spacing={1}>
            <Grid item>{<IconToNode definition={faChartMixed} sx={iconEntityStyle} />}</Grid>
            <Grid item>
                <Typography sx={{ mt: 1 }} variant="h4">
                    {intl.formatMessage({
                        id: 'measurements'
                    })}
                </Typography>
            </Grid>
        </Grid>
    );
}
