// material-ui
import { ReactComponent as EneidaLogo } from '_global/Resources/assets/images/eneidaLogo.svg';
import { ButtonBase } from '@mui/material';
// project imports
import config from 'config';
import { Link } from 'react-router-dom';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        <EneidaLogo />
    </ButtonBase>
);

export default LogoSection;
