import { gql } from '@apollo/client';

import { Assets_assets_nodes, Assets_assets_nodes_devices } from './Types/Assets';

export type AssetsInstance = Assets_assets_nodes;
export type AssetsDeviceInstance = Assets_assets_nodes_devices;

export const ASSETS = gql`
    query Assets($first: Int, $order: [AssetSortInput!], $filter: AssetFilterInput) {
        assets(where: $filter, order: $order, first: $first) {
            nodes {
                id
                parentAssetName
                parentFeederName
                assetType
                longitude
                latitude
                lastModifiedDate
                operationalArea {
                    name
                    __typename
                }
                region {
                    name
                    __typename
                }
                state
                registryNumber
                name
                isEnabled
                devices {
                    serialNumber
                    __typename
                }
                description
                alarmStandardName
                createdDate
                __typename
            }
            totalCount
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
                __typename
            }
            __typename
        }
    }
`;
