import Loadable from '_global/Components/base/Loadable';
import { useRemoteConfiguration } from 'logic/configuration/Hooks';
import { lazy, useEffect, useState } from 'react';
import { EReportsPageType } from 'views/Reports/ReportsPage';

import MainLayout from '../../controllers/layout/MainLayout';
import NoMatch from './NoMatch';

const Dashboard = Loadable(lazy(() => import('views/Dashboard/DashboardPage')));
const Assets = Loadable(lazy(() => import('views/Assets/AssetsPage')));
const ReportsAll = Loadable(
    lazy(() => import('views/Reports/ReportsPage')),
    { reportPageType: EReportsPageType.ALL }
);
const ReportsSystem = Loadable(
    lazy(() => import('views/Reports/ReportsPage')),
    { reportPageType: EReportsPageType.SYSTEM }
);
const ReportsCustom = Loadable(
    lazy(() => import('views/Reports/ReportsPage')),
    { reportPageType: EReportsPageType.CUSTOM }
);
const Regions = Loadable(lazy(() => import('views/Regions/RegionsPage')));
const OpAreas = Loadable(lazy(() => import('views/OpAreas/OpAreasPage')));
const Companies = Loadable(lazy(() => import('views/Company/CompanyPage')));
const Alarms = Loadable(lazy(() => import('views/Events/AlarmsPage')));
const Devices = Loadable(lazy(() => import('views/Devices/DevicesPage')));
const ConfigurationProfiles = Loadable(lazy(() => import('views/BulkConfiguration/ConfigurationProfilesPage')));
const Users = Loadable(lazy(() => import('views/Users/UsersPage')));
const UserRoles = Loadable(lazy(() => import('views/UserRoles/UserRolesPage')));
const AccessGroups = Loadable(lazy(() => import('views/AccessGroups/AccessGroupsPage')));
const CableTypes = Loadable(lazy(() => import('views/CableTypes/CableTypesPage')));
const AlarmProfiles = Loadable(lazy(() => import('views/Profiles/AlarmProfilesPage')));
// const UnderConstruction = Loadable(lazy(() => import('views/UnderConstruction/UnderConstruction')));
const UserProfile = Loadable(lazy(() => import('views/UserProfile/UserProfile')));
const FirmwareTasks = Loadable(lazy(() => import('views/FirmwareTasks/FirmwareTasksPage')));
const Firmware = Loadable(lazy(() => import('views/Firmware/FirmwarePage')));
const ConTestConfiguration = Loadable(lazy(() => import('views/ConTestConfiguration/ConTestConfigurationPage')));

const getMainElements: Record<string, JSX.Element> = {
    Dashboard: <Dashboard />,
    Assets: <Assets />,
    CableTypes: <CableTypes />,
    Alarms: <Alarms />,
    AlarmProfiles: <AlarmProfiles />,
    ReportsAll: <ReportsAll />,
    ReportsSystem: <ReportsSystem />,
    ReportsCustom: <ReportsCustom />,
    Companies: <Companies />,
    Regions: <Regions />,
    OpAreas: <OpAreas />,
    Devices: <Devices />,
    ConfigurationProfiles: <ConfigurationProfiles />,
    FirmwareTasks: <FirmwareTasks />,
    Firmware: <Firmware />,
    Users: <Users />,
    UserRoles: <UserRoles />,
    AccessGroups: <AccessGroups />,
    ConTestConfiguration: <ConTestConfiguration />
};

function mainRoutes() {
    const [config] = useRemoteConfiguration();
    const [mainPaths, setMainPaths] = useState<{
        path: string;
        element: JSX.Element;
        children: {
            path: string;
            element: JSX.Element;
        }[];
    } | null>(null);

    useEffect(() => {
        if (config) {
            const routes = config?.sidebar?.routes?.map((route) => {
                const element = getMainElements[route.element];
                return { path: route.path, element };
            });

            routes.push(
                {
                    path: '*',
                    element: <NoMatch />
                },
                {
                    path: '/user/profile',
                    element: <UserProfile />
                }
            );
            const allRoutes = {
                path: '/',
                element: <MainLayout />,
                children: routes
            };
            setMainPaths(allRoutes);
        }
    }, [config]);

    return mainPaths;
}

export default mainRoutes;
