import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Mode from 'models/enums/ModeEnum';
import { IAssetReportDetails, IItemReportDetails } from 'models/report/ReportResponse';

export interface ListItemProps {
    item: IItemReportDetails | IAssetReportDetails;
    handleToggleItems: () => void;
    checkedItems: boolean;
    mode: Mode;
}

export const GlobalListItem = (props: ListItemProps) => {
    const { item, handleToggleItems, checkedItems, mode } = props;

    return (
        <ListItem key={item.id} onClick={mode !== Mode.PREVIEW ? handleToggleItems : undefined} role="listitem">
            <ListItemIcon>
                <Checkbox checked={checkedItems} disabled={mode === Mode.PREVIEW} tabIndex={-1} />
            </ListItemIcon>
            <ListItemText primary={item.name} />
        </ListItem>
    );
};
