import '@inovua/reactdatagrid-community/index.css';

import { IconToNode } from '_global/Utils/IconToNode';
import { faCircleNodes, faHorizontalRule, faMicrochip, faTimeline } from '@fortawesome/pro-solid-svg-icons';
import { Grid, Typography } from '@mui/material';
import { AlarmEntityType } from 'models/events/ProcessedEvent';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export default function EventEntityTitle(props: { entityType: number }) {
    const { entityType } = props;
    const intl = useIntl();
    const iconEntityStyle = { fontSize: 30, color: '#fbc02d' };

    const icon = useMemo(() => {
        switch (entityType) {
            case AlarmEntityType.ASSET:
                return faCircleNodes;
            case AlarmEntityType.ASSET_BUSBAR:
                return faHorizontalRule;
            case AlarmEntityType.DEVICE:
                return faMicrochip;
            case AlarmEntityType.FEEDER:
                return faTimeline;
        }
    }, [entityType]);

    return (
        <Grid container direction="row" spacing={1}>
            <Grid item>{icon && <IconToNode definition={icon} sx={iconEntityStyle} />}</Grid>
            <Grid item>
                <Typography sx={{ mt: 1 }} variant="h4">
                    {intl.formatMessage({
                        id: Object.entries(AlarmEntityType)
                            .find(([, val]) => val === entityType)?.[0]
                            .toLowerCase()
                    })}
                </Typography>
            </Grid>
        </Grid>
    );
}
