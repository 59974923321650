import { gql } from '@apollo/client';

import { accessGroups_accessGroups_nodes } from './Types/accessGroups';

export type AccessGroupInstance = accessGroups_accessGroups_nodes;

export const ACCESSGROUPS = gql`
    query accessGroups($cursor: String, $numberOfElements: Int, $filter: AccessGroupFilterInput, $order: [AccessGroupSortInput!]) {
        accessGroups(where: $filter, order: $order, first: $numberOfElements, after: $cursor) {
            totalCount
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            nodes {
                id
                name
                description
                isEnabled
                lastModifiedBy
                lastModifiedDate
                createdDate
                createdBy
                isProtected
                isDefault
            }
        }
    }
`;
