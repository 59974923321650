import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Feeder } from 'models/asset/AssetModel';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export default function FeederFilter(props: {
    onChange: (value: number) => void;
    feeders: Feeder[];
    selectedFeederId: number | undefined;
    isEarthSystem: boolean;
}) {
    const { onChange, feeders, selectedFeederId, isEarthSystem } = props;

    const onFeederChange = useCallback(
        (event: SelectChangeEvent<number>) => {
            onChange(event.target.value as number);
        },
        [selectedFeederId]
    );

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="feeder">
                <FormattedMessage id={isEarthSystem ? 'line' : 'feeder'} />
            </InputLabel>
            <Select id="feeders" value={selectedFeederId} onChange={(e) => onFeederChange(e)} label={<FormattedMessage id={'feeder'} />}>
                {feeders.map((feeder, index) => {
                    return (
                        <MenuItem key={index} value={feeder.id}>
                            <FormattedMessage id={feeder.name} />
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
