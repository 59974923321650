import { gql } from '@apollo/client';

import { RegionList_regions_nodes } from './Types/RegionList';
import { Regions_By_CompanyID, Regions_By_CompanyID_regions_edges_node } from './Types/Regions_By_CompanyID';

export type RegionInstance = RegionList_regions_nodes | null;
export type RegionsByCompany = Regions_By_CompanyID;
export type RegionsInstance = Regions_By_CompanyID_regions_edges_node | null;

export const REGION = gql`
    query RegionList($cursor: String, $numberOfElements: Int, $filter: RegionFilterInput) {
        regions(where: $filter, order: { id: ASC }, first: $numberOfElements, after: $cursor) {
            totalCount
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            nodes {
                id
                name
                code
                companyId
                operationalAreas {
                    id
                    name
                    code
                    regionId
                    gpsPoints {
                        longitude
                        latitude
                        designOrder
                    }
                }
                gpsPoints {
                    longitude
                    latitude
                    designOrder
                }
            }
        }
    }
`;

export const REGIONS_BY_COMPANY_ID = gql`
    query Regions_By_CompanyID($cursor: String, $first: Int, $companyId: Long) {
        regions(first: $first, after: $cursor, where: { companyId: { eq: $companyId } }) {
            ...RegionsConnectionFragment
        }
    }

    fragment RegionsConnectionFragment on RegionsConnection {
        edges {
            node {
                id
                name
                code
                companyId
                operationalAreas {
                    name
                    code
                    regionId
                    gpsPoints {
                        longitude
                        latitude
                        designOrder
                    }
                }
                gpsPoints {
                    longitude
                    latitude
                    designOrder
                }
            }
        }
        pageInfo {
            endCursor
            hasNextPage
        }
        totalCount
    }
`;
