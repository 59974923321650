import { removeGridPagination } from '_global/Services/store/gridsPaginationSlice';
import { COLORS } from '_global/Utils/Colors';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, useMediaQuery } from '@mui/material';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { ReactNode, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Group } from '../../../../logic/misc/MapExtensions';
import { useAppDispatch, useAppSelector } from '../../../Services/store/hooks';
import { removeTab, setTabs } from '../../../Services/store/tabComponentSlice';
import TabLabel from './TabLabel';

export type GenericTabHolderProps = {
    name: string;
    autoOpen?: boolean;
    sx?: SxProps<Theme>;
    children: ReactNode;
    url?: string;
    otherTabs?: { name: string; component: ReactNode; url?: string }[];
    onVisibilityChanged?: (currentTab: string) => void;
    disableRemove?: boolean;
    currentTab: string;
    setCurrentTab?: (update: string) => void;
};

export const GenericTabHolder = ({
    children,
    name,
    sx,
    onVisibilityChanged,
    url,
    otherTabs,
    disableRemove = false,
    currentTab,
    setCurrentTab
}: GenericTabHolderProps) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const tabs = useAppSelector((state) => state.tabs.tabs);
    const dispatch = useAppDispatch();
    const [needsDelete, setNeedsDelete] = useState<{ removeName: string; removeUrl?: string } | undefined>(undefined);
    const navigate = useNavigate();

    const removeTabInternal = useCallback(
        (removeName: string, removeUrl?: string) => {
            if (!disableRemove) {
                if (currentTab === removeName && setCurrentTab) {
                    const entries = Array.from(tabs).map(([name]) => name);
                    const position = entries.findIndex((element) => element === removeName);

                    if (entries.length - 1 === 1) {
                        setCurrentTab(name);
                    } else if (entries[position - 1] === name) {
                        setCurrentTab(entries[position + 1]);
                    } else {
                        setCurrentTab(entries[position - 1]);
                    }
                }

                setNeedsDelete({ removeName, removeUrl });
            }
        },
        [tabs, currentTab]
    );

    const containsTab = useCallback(
        (tabName: string) => {
            return (
                Array.from(tabs)
                    .map(([internalName]) => internalName)
                    .filter((internalName: string) => internalName === tabName).length > 0
            );
        },
        [tabs]
    );

    useEffect(() => {
        const map = new Group<string, { component: ReactNode; url?: string }>();
        map.set(name, { component: children, url: url });

        otherTabs && otherTabs.forEach((tab) => map.set(tab.name, { component: tab.component, url: tab.url }));

        dispatch(setTabs(map));
    }, []);

    useEffect(() => {
        if (needsDelete !== undefined) {
            setNeedsDelete(undefined);
            dispatch(removeTab(needsDelete));
            if (needsDelete?.removeUrl) {
                dispatch(removeGridPagination({ url: needsDelete.removeUrl }));
            }
        }
    }, [needsDelete]);

    useEffect(() => {
        if (onVisibilityChanged) {
            onVisibilityChanged(currentTab);
        }
    }, [currentTab]);

    useLayoutEffect(() => {
        const entries = Array.from(tabs).map(([name]) => name);
        const position = entries.findIndex((element) => element === currentTab);
        if (position > -1 && window.location.pathname !== Array.from(tabs).at(position)?.[1].url) {
            navigate(Array.from(tabs).at(position)?.[1].url ?? '');
        }
    }, [tabs, currentTab]);

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: !matchUpMd ? 'calc(100vh - 190px)' : 'calc(100vh - 165px)',
                height: '100%',
                ...sx,
                borderRadius: '8px 8px 0 0'
            }}
        >
            {tabs.size > 0 && (
                <TabContext value={containsTab(currentTab) ? currentTab : Array.from(tabs).map(([name]) => name)[0]}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            TabIndicatorProps={{
                                style: {
                                    display: 'none'
                                }
                            }}
                            onChange={(event, value: string) => setCurrentTab && setCurrentTab(value)}
                            scrollButtons="auto"
                            sx={{
                                minHeight: '42px',
                                '& .MuiTabs-flexContainer': {
                                    borderBottom: 0
                                },

                                background: '#FAFAFA',
                                '.Mui-selected': {
                                    background: COLORS.ENEIDA_BLUE,
                                    color: 'white',
                                    borderRadius: '8px 8px 0 0'
                                }
                            }}
                            variant="scrollable"
                        >
                            {Array.from(tabs).map(([name, value], index) => {
                                return (
                                    <Tab
                                        component={Link}
                                        iconPosition="start"
                                        key={index}
                                        label={
                                            <TabLabel
                                                colorDisplay={currentTab === name}
                                                modeEdit={value.url?.toString().includes('edit') ?? false}
                                                name={name}
                                                onClick={() => removeTabInternal(name, value.url)}
                                                shouldShow={index > 0 && currentTab === name && !disableRemove}
                                            />
                                        }
                                        sx={{ minHeight: '42px', height: '42px', px: 2 }}
                                        to={`${value.url ?? ''}`}
                                        value={name}
                                        wrapped
                                    />
                                );
                            })}
                        </TabList>
                    </Box>
                    {Array.from(tabs).map(([name, value], index) => (
                        <TabPanel key={index} sx={{ padding: 2, overflowY: 'scroll', height: '100%' }} value={name}>
                            <Box sx={{ marginBottom: '100px' }}>{value.component}</Box>
                        </TabPanel>
                    ))}
                </TabContext>
            )}
        </Box>
    );
};
