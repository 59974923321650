import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties } from '@mui/material/styles/createMixins';

export type IconToNodeProps = {
    definition: IconDefinition;
    size?: SizeProp;
    sx?: CSSProperties;
};

export function IconToNode(props: IconToNodeProps) {
    const { definition, size, sx } = props;

    return <FontAwesomeIcon icon={definition} size={size ?? 'lg'} style={sx} />;
}

export const IconToNodeXanato = (definition: IconDefinition) => {
    return () => IconToNode({ definition: definition });
};
