import './Button.scss';

import { Box, Button, Stack } from '@mui/material';
import { ButtonPropsColorOverrides } from '@mui/material/Button/Button';
import { SxProps, Theme } from '@mui/material/styles';
import { OverridableStringUnion } from '@mui/types';
import { KeyedObject } from 'models';
import { MouseEventHandler, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface ButtonProps<T> extends KeyedObject {
    title: string;
    variant?: 'text' | 'outlined' | 'contained';
    icon?: ReactNode;
    disabled?: boolean;
    onClick?: MouseEventHandler<T>;
    isErrored?: boolean;
    color?: OverridableStringUnion<
        'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        ButtonPropsColorOverrides
    >;
    sx?: SxProps<Theme>;
}

export default function ButtonComponent(props: ButtonProps<HTMLElement>) {
    const { title, variant = 'contained', icon, disabled, onClick, sx, isErrored, color } = props;

    return (
        <Button
            fullWidth
            variant={variant}
            onClick={onClick}
            disabled={disabled ?? false}
            sx={sx}
            color={isErrored ? 'error' : color ?? 'secondary'}
        >
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                {icon && <>{icon}</>}
                <Box>
                    <FormattedMessage id={title ?? null} />
                </Box>
            </Stack>
        </Button>
    );
}
